<div class="row">
    <div class="col-9 text-lg-left">
        <h1 class="text-uppercase text-orange">Enums Management</h1>
    </div>
    <div class="col-3 text-md-right">
        <button class="btn btn-md btn-orange" (click)="handleEnumClick('new')">New Enum Set</button>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <table class="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">
                    Name
                </th>
                <th scope="col">
                    Items
                </th>
            </tr>
            </thead>
            <tbody>
            <tr class="hover-cursor" *ngFor="let enum of enums" (click)="handleEnumClick(enum.name)">
                <td>{{enum.name}}</td>
                <td>{{enum.itemsCount}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

