/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./formDueAt.component";
import * as i3 from "../../service/currentUser/CurrentUserService";
import * as i4 from "../../service/ModalService";
import * as i5 from "../../service/FormService";
import * as i6 from "../../service/ErrorHandlerService";
var styles_FormDueAtComponent = [];
var RenderType_FormDueAtComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormDueAtComponent, data: {} });
export { RenderType_FormDueAtComponent as RenderType_FormDueAtComponent };
function View_FormDueAtComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-pencil text-primary hover-cursor ml-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateRecordDate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_FormDueAtComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "span", [["class", "ml-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Form Due At: "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "text-primary"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormDueAtComponent_1)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isDueDateEditable; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", (_co.inline ? "d-inline" : "row"), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", (_co.inline ? "d-inline" : "col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"), ""); _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.dueAtString; _ck(_v, 5, 0, currVal_2); }); }
export function View_FormDueAtComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "form-due-at", [], null, null, null, View_FormDueAtComponent_0, RenderType_FormDueAtComponent)), i0.ɵdid(1, 114688, null, 0, i2.FormDueAtComponent, [i3.CurrentUserService, i4.ModalService, i5.FormService, i6.ErrorHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormDueAtComponentNgFactory = i0.ɵccf("form-due-at", i2.FormDueAtComponent, View_FormDueAtComponent_Host_0, { formData: "formData", isDueAtEditable: "isDueAtEditable", inline: "inline" }, {}, []);
export { FormDueAtComponentNgFactory as FormDueAtComponentNgFactory };
