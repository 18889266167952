/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./header.component";
var styles_HeaderComponent = [];
var RenderType_HeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "col-12 col-lg-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "logoImg"], ["src", "assets/images/logo.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 11, "div", [["class", "col-12 pt-2 pt-lg-0 col-lg-6 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 10, "div", [["class", "border border-dark rounded border-dashed d-inline-block float-lg-right py-3 px-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "strong", [["class", "font-weight-heavy"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Issue Number:"])), (_l()(), i0.ɵted(9, null, [" ", " "])), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [["class", "row mt-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "strong", [["class", "font-weight-heavy"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Date Created:"])), (_l()(), i0.ɵted(14, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.formData ? (_co.formData.issueNumber || "TBA") : "TBA"); _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.dateString; _ck(_v, 14, 0, currVal_1); }); }
export function View_HeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i0.ɵdid(1, 114688, null, 0, i1.HeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i0.ɵccf("header", i1.HeaderComponent, View_HeaderComponent_Host_0, { formData: "formData", formRecord: "formRecord" }, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
