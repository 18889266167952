import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { RxFormsService } from "../../../../../service/util/reactiveForms";
import { ModalService } from "../../../../../service/ModalService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { trimRequiredValidator } from "../../../../../validators/reactiveFormValidators";
import { DocumentsService } from 'service/admin/DocumentsService';
import { IDocumentOutputModel } from '../../../../../../common/contracts/documentDRM';
import { Select2OptionData } from 'ng2-select2';
import * as moment from 'moment';
import { FormField, RecordParamType } from 'model/Form';
import { IUpdateTagInputModel } from '../../../../../../common/contracts/tag';
import { TagsService } from 'service/admin/TagsService';
import { DocumentTagsService } from 'service/admin/DocumentTagService';
import { forkJoin, of, Observable } from 'rxjs';
import { DocumentMetaService } from "service/admin/DocumentMetaService";
import { FormService } from 'service/FormService';
import { IFormsQueryParams } from '../../../../../../common/contracts/form';
import { CategoryService } from 'service/CategoryService';
import { CurrentUserService } from 'service/currentUser/CurrentUserService';
import { PersistentStorageService } from 'service/PersistentStorageService';
import { stringifyObjectValues } from 'service/util/stringifyObjectValues';
import { logger } from 'service/util/Logger';
import { DocumentIntanceService } from 'service/admin/DocumentInstanceService';
import { saveAs } from 'file-saver';
import { DocumentService } from "service/DocumentService";
import { has } from "lodash";
import { DocumentCollectionsService } from 'service/admin/DocumentCollectionsService';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../../../environments/environment';
import { IDocumentMetaOutputModel } from '../../../../../../common/contracts/documentMeta';

@Component({
  selector: 'app-groups-component',
  templateUrl: './editDocument.component.html'
})
export class EditDocumentComponent implements OnInit, OnChanges {

  public className = "EditDocumentComponent";

  /* Is this user authorised to modify documents  */
  public isDocAdmin: boolean = this.currentUserService.isAdminOrDocumentManager.getValue();

  /** Temporary Statics */
  public readOnly: boolean = false;
  public hideHeader: boolean = false;

  public document: IDocumentOutputModel;
  public isNew: boolean = false;
  public documentId: number;
  private documentCollectionId: number;
  public dateString: string;
  public documentTypeData: Select2OptionData
  public reviewDate: string;
  public metaTags: IUpdateTagInputModel[];
  public allTags: any[] = [];
  public masterMetaTags: any[] = [];
  public masterTags: any[] = [];

  public metaTagIdModel: number | null = null;
  public metaTagValueModel: string = '';

  public selectedTags;
  public userSelectedTags: any[];
  public documentTags: any;

  public documentMeta: IDocumentMetaOutputModel[] = [];
  public metaIdValuePairArray: { tagId: number, value: string, name?: string }[] = [];

  public showAllOptions = [
    { value: 'all', text: 'All' },
    { value: 'complete', text: 'Complete' },
    { value: 'active', text: 'Active' },
    { value: 'deleted', text: 'Deleted' },
  ];
  isAdminOrManager: boolean;

  public documentForm = new FormGroup({
    documentName: new FormControl({ value: '', disabled: !this.isDocAdmin }, [trimRequiredValidator]),
    nextReviewDate: new FormControl({ value: '', disabled: !this.isDocAdmin }),
    type: new FormControl({ value: '', disabled: !this.isDocAdmin }),
    documentIdentity: new FormControl({ value: '', disabled: !this.isDocAdmin }),
  });

  public documentCollection = new FormField<String>('', {
    validation: FormField.ValidationMethods.IsNotBlank,
    recordParamType: RecordParamType.Number
  });

  public documentStandard = new FormField<String>('', {
    validation: FormField.ValidationMethods.IsNotBlank
  });

  public departmentId: string;

  public formsQueryParams: IFormsQueryParams = {
    skip: 0,
    limit: 10,
    sortBy: 'dueAt',
    order: 'asc',
    assignedUserId: undefined,
    locationId: undefined,
    showAll: 'active',
    dueFilter: undefined,
    createdAtStart: undefined,
    createdAtEnd: undefined,
    dueAtStart: undefined,
    dueAtEnd: undefined,
    search: undefined,
    category: 4,
    groupId: undefined,
    documentId: undefined,
  };

  currentUserId: any;
  tasks: any[] = [];
  currentPage: number = 1;
  pageSize: number = this.pss.pageSize;
  totalItems: number;
  latestAprovedVersion: any;
  latestDraftRecord: any;
  hasFormTouched: boolean = false;
  isFormLoading: boolean = true;
  oldReviewDateToCompare: string;
  public sortBy = 'name';
  public sortOrder = 'asc';
  hasDraftFromCompletedTasks: boolean = false;
  reviewSchedule: string;
  documentCreatedAt: string;

  constructor(
    // documentService - drm document collection service
    private documentService: DocumentsService,
    // documentsService - document service
    private documentsService: DocumentService,
    private tagService: TagsService,
    private documentTagService: DocumentTagsService,
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private errorHandlerService: ErrorHandlerService,
    private documentMetaService: DocumentMetaService,
    private formService: FormService,
    private categoryService: CategoryService,
    private currentUserService: CurrentUserService,
    private pss: PersistentStorageService,
    private documentInstanceService: DocumentIntanceService,
    private documentCollectionService: DocumentCollectionsService
  ) {

  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.documentId) {
        if (/^\d+$/.test(params.documentId) !== true) {
          console.error('Invalid document router param');
          this.router.navigate(['/404'], { replaceUrl: true });
          return;
        }

        this.documentId = parseInt(params.documentId, 10);
        this.documentCollectionId = parseInt(params.documentCollectionId, 10);

        this.tagService.getTags().subscribe((allTags) => {
          this.masterTags = allTags.filter((tag) => { return (tag.isMeta === false) });
          this.allTags = this.masterTags;
          this.masterMetaTags = allTags.filter((tag) => { return (tag.isMeta === true) });
          this.metaTags = this.masterMetaTags;

          this.documentService.getDocumentById(this.documentId).subscribe((document) => {
            this.document = document;

            if (this.document.isArchived) {
              this.readOnly = true;
            }

            this.documentForm.setValue({
              documentName: this.document.name,
              nextReviewDate: moment(this.document.nextReviewAt).tz(environment.timeZone).format('DD/MM/YYYY'),
              type: this.document.type,
              documentIdentity: this.document.documentIdentity,
            });
            this.oldReviewDateToCompare = this.getReviewDateForCompare();

            this.departmentId = this.document.departmentId.toString();
            this.documentStandard = new FormField<String>(this.document.standard.toString(), {
              validation: FormField.ValidationMethods.IsNotBlank
            });
            this.documentCollection = new FormField<String>(this.document.documentCollectionId.toString(), {
              validation: FormField.ValidationMethods.IsNotBlank
            });

            this.dateString = moment(this.document.nextReviewAt).tz(environment.timeZone).format('DD/MM/YYYY');
            this.documentCreatedAt = moment(this.document.createdAt).tz(environment.timeZone).format('DD/MM/YYYY');

            this.activatedRoute.queryParams.subscribe(queryParams => {
              // fill query params for tasks
              this.categoryService.getCategories().subscribe(categories => {
                const drmTaskCategory = categories.find(cat => cat.name === 'DRM-Task');
                this.formsQueryParams.category = drmTaskCategory && drmTaskCategory.id;
              });
              this.formsQueryParams.documentId = this.documentId.toString();
              this.currentUserId = this.currentUserService.currentUserId.getValue();
              this.isDocAdmin = this.currentUserService.isAdminOrDocumentManager.getValue();
              this.isAdminOrManager = this.currentUserService.isAdministratorOrManager.getValue();
              this.formsQueryParams.skip = (this.currentPage - 1) * this.pageSize;
              this.formsQueryParams.limit = this.pageSize;

              this.loadForms();
              this.getLatestAprovedDocument();
              this.getLatestDraft();
            });

            //Populate tags for the document
            this.userSelectedTags = [];
            this.documentTagService.getTagByDocumentId(document.id).subscribe((tags: any) => {
              let tagIdArray = tags.map(tag => tag.tagId.toString());
              this.selectedTags = tagIdArray.join();
              this.documentTags = tags;
            });

            // Populate meta tags
            this.documentMetaService.getMetaByDocumentId(document.id).subscribe((meta) => {
              this.documentMeta = meta;
              this.metaIdValuePairArray = this.documentMeta.map(docMeta => ({
                tagId: docMeta.tagId,
                value: docMeta.value
              }));
              this.polyfillMetaTagNames();
              this.isFormLoading = false;
            });
          });
        });
      } else {
        if (/^\d+$/.test(params.documentCollectionId) !== true) {
          console.error('Invalid document router param');
          this.router.navigate(['/404'], { replaceUrl: true });
          return;
        }
        this.isNew = true;
        this.documentCollectionId = parseInt(params.documentCollectionId, 10);
        this.documentCollectionService.getDocumentCollectionById(this.documentCollectionId).subscribe(collection => {
          if (collection && collection.reviewSchedule) {
            let reviewDate = moment().tz(environment.timeZone);

            switch (collection.reviewSchedule) {
              case "ANNUALLY":
                reviewDate = reviewDate.add(1, 'year');
                break;
              case "BIANNUALLY":
                reviewDate = reviewDate.add(6, 'month');
                break;
              case "BIENNIALLY":
                reviewDate = reviewDate.add(2, 'year');
                break;
              default:
                throw new Error("Unknown ReviewSchedule " + collection.reviewSchedule)
            }

            this.documentForm.controls.nextReviewDate.setValue(reviewDate.format('DD/MM/YYYY'));
          } else {
            this.documentForm.controls.nextReviewDate.setValue(moment().tz(environment.timeZone).format('DD/MM/YYYY'));
          }
          this.tagService.getTags().subscribe((allTags) => {
            this.masterTags = allTags.filter((tag) => { return (tag.isMeta === false) });
            this.masterMetaTags = allTags.filter((tag) => { return (tag.isMeta === true) });
            this.metaTags = this.masterMetaTags;
            this.allTags = this.masterTags;
          });
        });
      }
    });
  }

  public submit() {
    if (this.documentForm.invalid) {
      RxFormsService.touchAllFormControls(this.documentForm);
      return;
    }
    const observer = {
      next: (document) => this.router.navigate([`../${this.documentId || document.id}`], { relativeTo: this.route }),
      error: error => this.errorHandlerService.handleHttpError(error),
    };

    if (!this.documentId) {
      this.documentService.createDocument({
        documentCollectionId: this.documentCollectionId,
        departmentId: Number(this.departmentId),
        name: this.documentForm.controls.documentName.value.trim(),
        documentIdentity: (this.documentForm.controls.documentIdentity.value || '').trim(),
        type: this.documentForm.controls.type.value,
        nextReviewAt: moment(this.documentForm.controls.nextReviewDate.value, 'DD/MM/YYYY').toISOString(),
        standard: Number(this.documentStandard.value)
      }).subscribe((data: IDocumentOutputModel) => {
        this.documentId = data.id;

        const tagAndMetaObservables: Observable<any>[] = [];

        if (!!this.userSelectedTags) {
          tagAndMetaObservables.push(...this.userSelectedTags.map((tag) => {
            return this.documentTagService.createTag({ documentId: Number(data.id), tagId: Number(tag) });
          }));
        }

        if (this.metaIdValuePairArray.length) {
          tagAndMetaObservables.push(this.documentMetaService.syncMeta(this.documentId, this.metaIdValuePairArray));
        }

        if (tagAndMetaObservables.length) {
          forkJoin(tagAndMetaObservables).subscribe(observer);
        } else {
          this.router.navigate([`../${this.documentId || data.id}`], { relativeTo: this.route });
        }

      }, (err) => {
        this.modalService.alert({
          title: 'Could not create document!',
          message: err.error.message || 'Please fill all required fields.'
        })
      });
    } else {
      const apiActions: Observable<any>[] = [];
      apiActions.push(
        this.documentService.updateDocument({
          id: this.documentId,
          departmentId: Number(this.departmentId),
          name: this.documentForm.controls.documentName.value.trim(),
          documentIdentity: (this.documentForm.controls.documentIdentity.value || '').trim(),
          type: this.documentForm.controls.type.value,
          nextReviewAt: moment(this.documentForm.controls.nextReviewDate.value, 'DD/MM/YYYY').toISOString(),
          standard: Number(this.documentStandard.value),
          documentCollectionId: Number(this.documentCollection.value)
        })
      );

      // Tags
      let documentTagIdArray = this.documentTags.map(tag => tag.tagId);

      let tagsToAdd = this.userSelectedTags.filter(x => !documentTagIdArray.includes(x));

      let tagsToArchive = documentTagIdArray.filter(x => !this.userSelectedTags.includes(x));

      apiActions.push(...tagsToAdd.map(tag => {
        return this.documentTagService.createTag({ documentId: this.documentId, tagId: tag })
      }));

      apiActions.push(...tagsToArchive.map(tag => {
        return this.documentTagService.archiveTag(this.documentTags.find(t => t.tagId === tag).id);
      }));

      // Meta
      apiActions.push(this.documentMetaService.syncMeta(this.documentId, this.metaIdValuePairArray))

      forkJoin(apiActions).subscribe(() => this.goBack());
    }
  }

  public polyfillMetaTagNames() {
    this.metaIdValuePairArray.forEach(pair => {
      if (!pair.name) {
        let tagName = this.masterMetaTags.find(m => pair.tagId === m.id).name;
        pair.name = tagName;
      }
    })
  }

  public archiveDocument() {
    this.modalService.confirmRx({
      title: 'Warning',
      message: 'Are you sure that you want to delete this document?'
    }).subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.documentService.archiveDocument(this.documentId).subscribe(
          () => this.goBack(),
          (err) => this.errorHandlerService.handleHttpError(err)
        );
      }
    });
  }

  public cancel() {
    this.goBack();
  }

  private goBack() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  handleTagChange($event) {
    this.userSelectedTags = $event.map(item => Number(item.id));
    this.hasFormTouched = true;
  }

  handleMetaChange($event) {
    const selectedItems = $event.map(item => Number(item.id));
    if (selectedItems.length) {
      this.metaTagIdModel = selectedItems[0];
    } else {
      this.metaTagIdModel = null;
    }
  }

  addNewDocumentMeta() {
    const signature = this.className + 'addNewDocumentMeta: ';

    if (!this.metaTagIdModel) {
      var msg = "Invalid Meta Tag Selected";
      alert(msg);
      logger.error(signature + msg);
      return;
    }

    if (!this.metaTagValueModel) {
      var msg = "Invalid Meta Tag Value";
      alert(msg);
      logger.error(signature + msg);
      return;
    }

    this.metaIdValuePairArray.push({ tagId: this.metaTagIdModel, value: this.metaTagValueModel });
    this.polyfillMetaTagNames();
    this.hasFormTouched = true;
  }

  removeMeta(idxToRemove: number) {
    this.metaIdValuePairArray = this.metaIdValuePairArray.filter((pair, idx) => idx !== idxToRemove);
    this.hasFormTouched = true;
  }

  loadForms() {
    this.formService.getForms(this.formsQueryParams).subscribe(data => {
      this.tasks = data.items;
      this.totalItems = data.totalCount;
    });
  }

  progressForm(row) {
    this.router.navigateByUrl(`/document-review-task/${row.id}`);
  }

  public handlePageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    if (this.currentPage === 1) {
      this.formsQueryParams.skip = 0;
      this.formsQueryParams.limit = this.pageSize;
      this.loadForms();
    } else {
      this.currentPage = 1;
      this.handlePageChange();
    }
  }
  handlePageChange() {
    this.pushQueryParams();
  }

  private pushQueryParams() {
    const queryParams = stringifyObjectValues({
      page: this.currentPage,
      assignedUser: this.isAdminOrManager ? this.formsQueryParams.assignedUserId : undefined,
      // location: this.isAdminOrManager ? (this.formsQueryParams.locationId || undefined) : undefined,
      group: this.isAdminOrManager ? (this.formsQueryParams.groupId || undefined) : undefined,
      showAll: this.isAdminOrManager ? (this.formsQueryParams.showAll || undefined) : undefined,
      showOnly: this.formsQueryParams.dueFilter || undefined,
      sortBy: this.formsQueryParams.sortBy,
      order: this.formsQueryParams.order,
      createdAtStart: this.formsQueryParams.createdAtStart || undefined,
      createdAtEnd: this.formsQueryParams.createdAtEnd || undefined,
      dueAtStart: this.formsQueryParams.dueAtStart || undefined,
      dueAtEnd: this.formsQueryParams.dueAtEnd || undefined,
      search: this.formsQueryParams.search || undefined,
      category: this.formsQueryParams.category || undefined,
    });

    if (this.isAdminOrManager &&
      typeof this.formsQueryParams.groupId === 'string' &&
      this.formsQueryParams.groupId.length === 0
    ) queryParams.group = '';

    // if( this.isAdminOrManager && 
    //   typeof this.formsQueryParams.locationId === 'string' &&
    //   this.formsQueryParams.locationId.length === 0
    // ) queryParams.location = '';

    logger.silly("Pushing QueryParams", queryParams);
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: '',
    });
  }
  public handleShowAllFilterChange(option: 'all' | 'complete' | 'deleted' | 'active') {
    this.formsQueryParams.showAll = option;
    delete this.formsQueryParams.dueFilter;

    /**
     * if sorting by archived date was active and a user switched to active filter, turn sort by dueAt as default
     */
    if (this.formsQueryParams.sortBy === 'archivedAt' && option === 'active') {
      this.formsQueryParams.sortBy = 'dueAt';
      this.formsQueryParams.order = 'asc';
    }

    this.currentPage = 1;
    this.pushQueryParams();
  }
  public handleDepartmentChange($event) {
    if (!!$event.length) {
      this.departmentId = $event[0].id;
      if (!this.isFormLoading && $event[0].selected) {
        this.hasFormTouched = true;
      }
    }
  }

  public handleSelectChange(event) {
    if (!!event.length && !this.isFormLoading && event[0].selected) {
      this.hasFormTouched = true;
    }
  }

  private getLatestAprovedDocument() {
    this.documentService.getDocumentInstanceByDocumentId(this.documentId)
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 404) {
            return of(null);
          }

          throw err;
        })
      )
      .subscribe(file => {
        // saveAs(file,`document-${this.documentId}-latest-aproved`);
        this.latestAprovedVersion = file;
      });
  }

  public downloadLatestAprovedVersion() {
    this.documentInstanceService.downloadDocument(this.latestAprovedVersion.id).subscribe(data => {
      saveAs(data, this.latestAprovedVersion.fileName);
    });
  }

  public getLatestDraft() {
    const signature = this.className + ".getLatestDraft: ";
    this.formService.getFormsFromDocumentId(this.documentId)
      .subscribe(data => {
        if (data.length === 0) {
          this.documentInstanceService.getDraftDocument(this.documentId)
            .pipe(
              catchError(err => {
                if (
                  err instanceof HttpErrorResponse &&
                  err.status === 404 &&
                  has(err, 'error.message') &&
                  err.error.message === 'No approved version of document not found.'
                ) {
                  logger.silly(signature + "Intercepted expected failure");
                  return of(null);
                }

                throw err;
              })
            )
            .subscribe(data => {
              if (data) {
                this.latestDraftRecord = data;
                this.hasDraftFromCompletedTasks = true;
              } else {
                this.latestDraftRecord = null;
              }
            });
        } else {
          const formsCreatedAtArray = data.map(form => new Date(form.createdAt)),
            latestFormCreatedAt = new Date(Math.max.apply(null, formsCreatedAtArray)),
            latestFormRecords = data.find(form => (new Date(form.createdAt).getTime() === latestFormCreatedAt.getTime())).records,
            latestRecord = latestFormRecords.find(record => new Date(record.createdAt).getTime() === new Date(Math.max.apply(null, latestFormRecords.filter(record => !!record.documents.length).map(record => new Date(record.createdAt)))).getTime())

          this.latestDraftRecord = latestRecord;
        }
      });
  }

  public downloadLatestDraft() {
    if (this.latestDraftRecord === null) {
      this.downloadLatestAprovedVersion();
    } else if (this.hasDraftFromCompletedTasks && this.latestDraftRecord) {
      this.documentInstanceService.downloadDraftDocument(this.latestDraftRecord.id).subscribe(data => {
        saveAs(data, this.latestDraftRecord.originalFileName);
      })
    } else {
      this.documentsService.downloadDocument(this.latestDraftRecord.documents[0].id).subscribe(file => {
        saveAs(file, this.latestDraftRecord.documents[0].fileName)
      });
    }
  }

  public getReviewDateForCompare(): string {
    const val: any = this.documentForm.controls.nextReviewDate.value;

    if (isNullOrUndefined(this.documentForm.controls.nextReviewDate.value)) return "";

    const strVal = String(val);

    if (strVal.length === 0) return "";

    return moment(this.documentForm.controls.nextReviewDate.value, 'DD/MM/YYYY').tz(environment.timeZone).toString();
  }

  // A consistent way to create a review date string that can be compared when checking save button activation
  public shouldDisableSaveButton(): boolean {
    if (this.readOnly) {
      return true;
    }

    let date = this.getReviewDateForCompare();
    let hasDateChanged = !(date === this.oldReviewDateToCompare);
    if (hasDateChanged) {
      this.oldReviewDateToCompare = date;
      if (!this.isFormLoading) {
        this.hasFormTouched = true;
      }
    }

    return (this.documentForm.pristine && !this.hasFormTouched);
  }

  public handleSortChange(sortField: any) {
    if (sortField === this.sortBy) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      this.formsQueryParams.order = this.sortOrder;
    } else {
      this.sortBy = sortField;
      this.sortOrder = 'asc';
      this.formsQueryParams.sortBy = sortField;
      this.formsQueryParams.order = this.sortOrder;
    }

    this.handlePageChange();
  }
}