<div class="row">
    <div class="col-9 text-lg-left">
        <h1 class="text-uppercase text-orange">Group Management</h1>
    </div>
    <div class="col-3 text-md-right">
        <button class="btn btn-md btn-orange" (click)="handleGroupClick('new')">New Group</button>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <table class="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">
                    <span class="sortable-col-name"
                        (click)="handleSortChange('groupName')"
                        [ngClass]="{active: sortBy === 'groupName', reverse: sortOrder !== 'asc'}">
                            Name<i class="fa fa-caret-up"></i>
                    </span>
                </th>
                <th scope="col">
                    <span class="sortable-col-name"
                          (click)="handleSortChange('usersCount')"
                          [ngClass]="{active: sortBy === 'usersCount', reverse: sortOrder !== 'asc'}">
                            Users<i class="fa fa-caret-up"></i>
                    </span>
                </th>
                <th scope="col">
                    <span class="sortable-col-name"
                        (click)="handleSortChange('createdAt')"
                        [ngClass]="{active: sortBy === 'createdAt', reverse: sortOrder !== 'asc'}">
                            Created At<i class="fa fa-caret-up"></i>
                    </span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr class="hover-cursor" *ngFor="let group of groups" (click)="handleGroupClick(group.id)">
                <td>{{group.groupName}}</td>
                <td>{{group.usersCount}}</td>
                <td>{{group.createdAt | date : 'mediumDate'}}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <pagination
            [collectionSize]="totalItems"
            [(page)]="currentPage"
            [(pageSize)]="pageSize"
            (pageChange)="handlePageChange()"></pagination>
    </div>
</div>

