import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import * as moment from 'moment';
import 'moment-timezone';
import {FormField} from "../../../../../model/Form";
import {FormComponent} from "../../../../../model/FormComponent";
import {Session} from "../../../../../service/util/Session";
import {CurrentUserService} from "../../../../../service/currentUser/CurrentUserService";
import {FormService} from "../../../../../service/FormService";
import {FormCategory} from "../../../../../../model/Category";
import {CategoryService} from "../../../../../service/CategoryService";
import {FormRecordService} from "../../../../../service/FormRecordService";
import {
  IFormOutputModel,
  IFormRecordOutputModel,
  IFormRecordPropertyParam
} from "../../../../../../common/contracts/form";
import { Router } from "@angular/router";
import {Observable} from "rxjs";
import { ICategoryOutputModel } from "../../../../../../common/contracts/category"
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import {IDocumentType} from "../../../../../../common/contracts/document";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'report-form-0',
  templateUrl: './reportFormStage0.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ReportFormStage0Component extends FormComponent implements OnInit {

  // Existing Form Data
  @Input() readOnly: boolean = false;
  @Input() hideHeader: boolean = false;

  @Input() formData: IFormOutputModel;
  @Input() sequence: number;

  public formRecord: IFormRecordOutputModel;

  public dateString: string;
  public formCategoryId: number;

  public documents: Array<IDocumentType> = [];

  /*
    This should have been done properly using something that implements FormControl but its
    too late now
   */
  public form:{ [key:string]:FormField<any> } = {
    originatorName: new FormField<String>('', {
      validation: FormField.ValidationMethods.IsNotBlank
    }),
    location: new FormField<String>('', {
      validation: FormField.ValidationMethods.None
    }),
    type: new FormField<String>('', {
      validation: FormField.ValidationMethods.IsNotBlank
    }),
    department: new FormField<String>('', {
      validation: FormField.ValidationMethods.IsNotBlank
    }),
    originatorType: new FormField<number>(null, {
      validation: FormField.ValidationMethods.IsNotBlank
    }),
    residentRoomNumber: new FormField<String>('', {
      placeholder: "Unit/SA Number",
      validation: FormField.ValidationMethods.IsNotBlank
    }),
    residentEmail: new FormField<String>('', {
      validation: [
        FormField.ValidationMethods.IsEmail,
        FormField.ValidationMethods.IsBlank
      ]
    }),
    residentName: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
    }),
    originatorEmail: new FormField<String>('', {
      validation: [
        FormField.ValidationMethods.IsEmail,
        FormField.ValidationMethods.IsBlank,
      ]
    }),
    summary: new FormField<String>('', {
      validation: FormField.ValidationMethods.IsNotBlank
    }),
  };

  constructor(
    public session: Session,
    public currentUserService: CurrentUserService,
    public formService: FormService,
    public categoryService: CategoryService,
    public formRecordService: FormRecordService,
    public router: Router,
    private errorHandler: ErrorHandlerService,
  ) {
    super(router);
  }

  registerFormFields() {
    this.formFields.push( ...Object.keys(this.form).map((k:string) => this.form[k]) );
  }

  private getEmailToNotifyOnComplete():string | null {
    let resultArray: String[] = [];

    if( this.originatorOptions.residentEmail && this.form.residentEmail.value.length > 0 ) {
      resultArray.push(this.form.residentEmail.value)
    }

    if( this.originatorOptions.originatorEmail && this.form.originatorEmail.value.length > 0 ) {
      resultArray.push(this.form.originatorEmail.value);
    }

    return ( resultArray.length > 0 ? resultArray.join(", ") : null );
  }

  onSubmit(isDraft: boolean = false) {
    this.session.lockInput(  () => {
      return new Promise((resolve, reject) => {

        let formLocationId:number | null = this.form.location.isValid && this.form.location.value !== null ? Number(this.form.location.value) : null;
        if( formLocationId === 0 ) formLocationId = null;

        let userGroupId:number | null = this.form.department.isValid ? Number(this.form.department.value) : null;
        let stage: number = isDraft ? 0 : 1;
        let notifyOnComplete: string | null = this.getEmailToNotifyOnComplete();

        let formFunc:Observable<any>;

        // The assigned user should always be reset to null on submission of stage 0
        let assignedUserId: number | null = null;

        if( this.currentUserService.userData && isDraft )
          assignedUserId = this.currentUserService.userData.id;

        const isNewForm: boolean = !(this.formData && this.formData.id);

        /* NOTE : The assigned userID should be set to null if this is not a draft */

        if (!isNewForm) {
          //FormFunc = update
          formFunc = this.formService.updateForm({
            id: this.formData.id,
            formLocationId,
            userGroupId,
            notifyOnComplete,
            stage,
            assignedUserId
          });
        } else {
          // The actual due date is 21 days however we want this to show up red on the due date
          let dueAt = moment().tz(environment.timeZone).add(10,'days').set({
            'hour': 0,
            'minute': 0,
            'second': 0
          }).toISOString(false);

          // This should be back to the client within 14 days
          let alertAt = moment().tz(environment.timeZone).add(7,'days').set({
            'hour': 0,
            'minute': 0,
            'second': 0
          }).toISOString(false);

          formFunc = this.formService.createForm({
            categoryId: this.formCategoryId,
            formLocationId,
            userGroupId,
            dueAt,
            alertAt,
            notifyOnComplete,
            stage,
            parentFormId: null,
            assignedUserId
          });
        }

        formFunc.subscribe((data:any) => {

          let properties: Partial<IFormRecordPropertyParam>[] = [];
          let formId:number = ( this.formData && this.formData.id ) ? this.formData.id :  data['id'];

          if( userGroupId )
            properties.push({
              name: "userGroupId",
              intData: userGroupId
            });

          // This desperately needs to be refactored but in the interest of brevity...
          properties.push({
            name: "reportFormType",
            enumId: Number(this.form.type.value)
          });

          properties.push({
            name: "originatorName",
            stringData: this.form.originatorName.value
          });

          properties.push({
            name: "originatorTypeId",
            enumId: Number(this.form.originatorType.value)
          });

          if( this.originatorOptions.residentRoomNumber && this.form.residentRoomNumber.value.length > 0 )
            properties.push({
              name: "residentRoomNumber",
              stringData: this.form.residentRoomNumber.value
            });

          if( this.originatorOptions.residentEmail && this.form.residentEmail.value.length > 0 )
            properties.push({
              name: "residentEmail",
              stringData: this.form.residentEmail.value
            });

          if( this.originatorOptions.residentName && this.form.residentName.value.length > 0 )
            properties.push({
              name: "residentName",
              stringData: this.form.residentName.value
            });

          if( this.originatorOptions.originatorEmail && this.form.originatorEmail.value.length > 0 )
            properties.push({
              name: "originatorEmail",
              stringData: this.form.originatorEmail.value
            });

          properties.push({
            name: "summary",
            stringData: this.form.summary.value
          });

          // TODO: Add issue number

          this.formRecordService.createRecord({
            formId: formId,
            // Intentionally cast the properties object since we know its correct
            properties: properties as any,
            stage: 0,
            documents: this.documents.map(doc => ({id: doc.id, isTicked: !!doc.isTicked})), // send list of attached documents
            isComplete: !isDraft
          })
          .subscribe( (data:any) => {
            //Done creating the form and appending its properties
            resolve();

            if (isNewForm) {
              // go to dashboard
              this.router.navigate(['/dashboard']);
            } else {
              this.closeForm();
            }

          }, err => {
            this.errorHandler.handleHttpError(err);
            reject("Error creating new record");
          });
        }, (err) => {
          this.errorHandler.handleHttpError(err);
          reject("Error Creating or updating form");
        });
      })
    });
  }

  ngOnInit() {
    this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);

    this.registerFormFields();

    this.loadEnums();

    this.repopulateFormFromData();
  }

  private repopulateFormFromData() {
    if( !this.formData || !this.formData.records || !this.formData.records.length )
      return;

    let stageRecords = this.formData.records.filter( record => record.stage === 0);
    if( stageRecords.length === 0 )
      return;

    if( !this.sequence ) {
      let mostRecentRecord = stageRecords.sort( (a, b) => a.sequence > b.sequence ? 1 : -1 ).pop();

      if (!mostRecentRecord)
        throw new Error("internal error");

      // If the most recent record was a submission, we are not going to use it
      if( mostRecentRecord.isComplete )
        return;

      this.formRecord = mostRecentRecord;
    } else {
      let targetRecord = stageRecords.find( record => record.sequence === this.sequence );

      if( !targetRecord )
        throw new Error("internal error");

      this.formRecord = targetRecord;
    }

    /**
     * get attached documents
     */
    this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);

    /**
     * show update date from record's createdAt
     */
    this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);


    //Convert the properties into easily accessible IFormRecordPropertyParam
    if (!this.formRecord.properties) {
      return;
    }

    let simpleProperties:{[key:string]: IFormRecordPropertyParam} = {};

    this.formRecord.properties.forEach( recordProperty => {
      //eject invalid property
      if( !recordProperty.property )
        return;

      let result:Partial<IFormRecordPropertyParam> = {
        name: recordProperty.property.name
      };

      if(recordProperty.stringData)
        result.stringData = recordProperty.stringData;

      if(recordProperty.intData)
        result.intData = recordProperty.intData;

      if(recordProperty.jsonData)
        result.jsonData = recordProperty.jsonData;

      if(recordProperty.enumId)
        result.enumId = recordProperty.enumId;

      simpleProperties[result.name as string] = result as IFormRecordPropertyParam;
    });

    if( simpleProperties['userGroupId'] )
      this.form.department.value = String(simpleProperties['userGroupId'].intData);
    else if( this.formData['userGroupId'] )
      this.form.department.value = String(this.formData['userGroupId']);

    if( this.formData['formLocationId'] )
      this.form.location.value = String(this.formData['formLocationId']);

    //Repopulate from the simpleProperties
    if( simpleProperties['originatorName'] )
      this.form.originatorName.value = simpleProperties['originatorName'].stringData;

    if( simpleProperties['reportFormType'] )
      this.form.type.value = String(simpleProperties['reportFormType'].enumId);

    if( simpleProperties['originatorTypeId'] )
      this.form.originatorType.value = String(simpleProperties['originatorTypeId'].enumId);

    if( simpleProperties['residentRoomNumber'] )
      this.form.residentRoomNumber.value = simpleProperties['residentRoomNumber'].stringData;

    if( simpleProperties['residentEmail'] )
      this.form.residentEmail.value = simpleProperties['residentEmail'].stringData;

    if( simpleProperties['residentName'] )
      this.form.residentName.value = simpleProperties['residentName'].stringData;

    if( simpleProperties['originatorEmail'] )
      this.form.originatorEmail.value = simpleProperties['originatorEmail'].stringData;

    if( simpleProperties['summary'] )
      this.form.summary.value = simpleProperties['summary'].stringData;
  }

  private loadEnums() {
    this.session.lockInputRx(this.categoryService.getCategories())
      .subscribe( (data: ICategoryOutputModel[]) => {

        let reportCategory: ICategoryOutputModel | undefined = data.find( (data:FormCategory) => !!data.name.match(/^Report/) );

        if( reportCategory )
          this.formCategoryId = reportCategory.id as number;
        //else
          //Notify the user an error has occurred, as we can't find the right form category

      }, (err) => {
        console.error('Error getting enums', err);
        this.errorHandler.handleHttpError(err);
      });
  }

  public originatorOptions = {
    residentRoomNumber: true,
    residentName: false,
    residentEmail: true,
    originatorEmail: false,
    isFamilyMember: false,
    isStaffMember: false
  };

  originatorChanged(selectedOpts:IdTextPair[]) {
    this.select2Changed(selectedOpts, this.form.originatorType);

    let optionsBeforeChange = Object.assign({}, this.originatorOptions);

    let originatorVal = this.form.originatorType.value;
    let originatorOption:IdTextPair | undefined = selectedOpts.find( (type:IdTextPair) => type.id === originatorVal );

    this.originatorOptions.isFamilyMember = false;
    this.originatorOptions.isStaffMember = false;

    if( originatorOption && (
      !!originatorOption.text.match(/Staff Member/)
      || !!originatorOption.text.match(/Visitor/)
    )) {
      Object.assign(this.originatorOptions, {
        residentRoomNumber: false,
        residentName: false,
        residentEmail: false,
        originatorEmail: true,
        isStaffMember: true
      });
    } else if( originatorOption && !!originatorOption.text.match(/Family Member/) ) {
      Object.assign(this.originatorOptions, {
        residentRoomNumber: true,
        residentName: true,
        residentEmail: false,
        originatorEmail: true,
        isFamilyMember: true
      });
    } else {
      Object.assign(this.originatorOptions, {
        residentRoomNumber: true,
        residentName: false,
        residentEmail: true,
        originatorEmail: false
      });
    }

    // This should always be evaluated
    if( this.originatorOptions.isStaffMember ) {
      this.form.residentRoomNumber.validation = FormField.ValidationMethods.None;
      this.form.originatorEmail.validation = FormField.ValidationMethods.IsEmail;
    } else {
      this.form.residentRoomNumber.validation =
        (this.originatorOptions.residentRoomNumber) ? FormField.ValidationMethods.IsNotBlank : FormField.ValidationMethods.None;

      // @ts-ignore - Typescript is warning that an array cannot be assigned to the singular
      // when the class is specifically designed for this
      this.form.originatorEmail.validation = [
        FormField.ValidationMethods.IsEmail,
        FormField.ValidationMethods.IsBlank,
      ];
    }

    this.form.residentRoomNumber.validate();

    if( this.originatorOptions.isFamilyMember )
      this.form.residentRoomNumber.placeholder = 'Room Number';
    else
      this.form.residentRoomNumber.placeholder = 'Unit/SA Number';
    // End Always Evaluate

    if( optionsBeforeChange.residentEmail !== this.originatorOptions.residentEmail ) {
        this.form.residentEmail.validate()
    }

    if( optionsBeforeChange.residentName !== this.originatorOptions.residentName ) {
        this.form.residentName.validate()
    }

    if( optionsBeforeChange.originatorEmail !== this.originatorOptions.originatorEmail ) {
        this.form.originatorEmail.validate()
    }
  }

}
