import { Injectable } from '@angular/core';
import {GenericService} from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  ICreateUserInputModel,
  IUpdateUserInputModel,
  IUserOutputModel, IUsersQueryParams,
  UsersPaginationResponse
} from "../../../common/contracts/users";
import { stringifyObjectValues } from "../util/stringifyObjectValues";

const defaultUsersQueryParams: IUsersQueryParams = {
  skip: 0,
  limit: 10,
  sortBy: 'name',
  order: 'asc',
  search: undefined,
};

@Injectable()
export class UsersService extends GenericService {

  static apiPrefix = '/api/users';

  constructor(private http: HttpClient) {
    super();
  }

  public getUsers(params: IUsersQueryParams = defaultUsersQueryParams) {
    const queryParams = new HttpParams({
      fromObject: stringifyObjectValues(params)
    });
    return this.http.get<UsersPaginationResponse>(UsersService.apiPrefix, { params: queryParams });
  }

  public getUserById(id: number, allowArchived: boolean = false) {
    return this.http.get<IUserOutputModel>(`${UsersService.apiPrefix}/${id}`, {
      params: {
        allowArchived: allowArchived.toString()
      }
    });
  }

  public createUser(userData: ICreateUserInputModel) {
    return this.http.post(UsersService.apiPrefix, userData);
  }

  public updateUser(userData: IUpdateUserInputModel) {
    return this.http.put(UsersService.apiPrefix, userData);
  }

  public archiveUser(id: number) {
    return this.http.delete(`${UsersService.apiPrefix}/${id}`);
  }

  public restoreUser(id: number) {
    return this.http.put(`${UsersService.apiPrefix}/${id}/restore`, {});
  }
}
