import { Router } from "@angular/router";
import { CurrentUserService } from "../currentUser/CurrentUserService";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "../currentUser/CurrentUserService";
import * as i2 from "@angular/router";
var CurrentUserRouterGuard = /** @class */ (function () {
    function CurrentUserRouterGuard(currentUserService, router) {
        this.currentUserService = currentUserService;
        this.router = router;
    }
    CurrentUserRouterGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        if (this.currentUserService.userData) {
            return true;
        }
        else {
            if (this.currentUserService.token) {
                this.currentUserService.recoverTokenFromLocalStorage();
                return this.currentUserService.getCurrentUser().pipe(map(function () { return true; }), catchError(function (err) {
                    console.log('Error getting current user. Redirecting to login.', err);
                    _this.router.navigate(['/login']);
                    return of(false);
                }));
            }
            else {
                this.router.navigate(['/login']);
                return false;
            }
        }
    };
    CurrentUserRouterGuard.ngInjectableDef = i0.defineInjectable({ factory: function CurrentUserRouterGuard_Factory() { return new CurrentUserRouterGuard(i0.inject(i1.CurrentUserService), i0.inject(i2.Router)); }, token: CurrentUserRouterGuard, providedIn: "root" });
    return CurrentUserRouterGuard;
}());
export { CurrentUserRouterGuard };
