
  <div class="form-inline">
    <div class="form-group">
      <input type="text"
        *ngIf="showOffsetAmount"
        class="col-2 form-control text-center p-1"
        [placeholder]="'#'"
        [(ngModel)]="offsetAmount"
		(input)="onInput($event)"
		(keyup)="onKeyUp($event)"
        [ngModelOptions]="{standalone: true}"
        numbersOnly
        maxlength="4">
      <div 
        class="drop-down-wrapper pt-1"
        [ngClass]="{'pl-2': showOffsetAmount}"
        placement="bottom-right"
        ngbDropdown
      >
        <a class="dropdown-link" ngbDropdownToggle>{{selectedOffsetPeriod||'Select Offset'}}</a>
        <div ngbDropdownMenu>
            <button 
              class="dropdown-item" 
              *ngFor="let option of offsetOptions" 
              (click)="changeOffsetOption(option)">{{option}}</button>
        </div>
      </div>
    </div>
  </div>
  