<div class="row d-flex align-items-center py-4">
  <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-xl-4 offset-xl-4 bg-white align-self-center border border-grey rounded shadow-lg">
    <h3 class="py-1 my-0">Register</h3>
    <div class="row bg-light border-top border-grey">
      <div class="col-12">
        <form class="py-2">
          <div class="alert alert-danger" *ngIf="showFormHasError">
            Some things weren't quite right...
            <div *ngIf="errorMessage">
              {{errorMessage}}
            </div>
          </div>
          <div class="form-group">
            <label for="emailAddress">Email Address</label>
            <input type="email" class="form-control {{emailAddress.iifIsValid('border-success','border-danger')}}" id="emailAddress" placeholder="Enter Email" [(ngModel)]="emailAddress.value" [ngModelOptions]="{standalone: true}">
            <small class="form-text text-danger" *ngIf="emailAddress.showErrorHelp()">Invalid Email Address</small>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control {{password.iifIsValid('border-success','border-danger')}}" id="password" placeholder="Password" [(ngModel)]="password.value" [ngModelOptions]="{standalone: true}">
            <small class="form-text text-muted" *ngIf="!password.showErrorHelp()">Minimum Password Length of 6</small>
            <small class="form-text text-danger" *ngIf="password.showErrorHelp()">Invalid Password</small>
          </div>
          <div class="form-group">
            <label for="confirmPassword">Confirm Password</label>
            <input type="password" class="form-control {{passwordVerification.iifIsValid('border-success','border-danger')}}" id="confirmPassword" placeholder="Confirm Password" [(ngModel)]="passwordVerification.value" [ngModelOptions]="{standalone: true}">
            <small class="form-text text-muted" *ngIf="!passwordVerification.showErrorHelp()">Verify your password by entering it again.</small>
            <small class="form-text text-danger" *ngIf="passwordVerification.showErrorHelp()">Invalid Password Verification</small>
          </div>
          <div class="row">
            <a class="btn btn-danger col-4 offset-1" [routerLink]="'/login'">Cancel</a>
            <button class="btn btn-success col-4 offset-2" (click)="performRegister()">Register</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
