import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {Session} from "../../service/util/Session";
import {FormField} from "../../model/Form";
import { Select2Component } from 'ng2-select2';
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import {CategoryService} from "../../service/CategoryService";
import {ICategoryOutputModel} from "../../../common/contracts/category";
import {FormCategory} from "../../../model/Category";
import { map } from 'rxjs/operators';

type ExIdTextPair = IdTextPair & {selected?: boolean};

@Component({
  selector: 'form-category-select',
  template: `<select2
    #select2Component
    class="form-control select2-form-control {{disabled?'bg-grey':''}} {{showValidation && (!disabled && field && field.iifIsValid('border-success','border-danger'))}}"
    [data]="formCategorySelectData"
    [options]="formCategorySelectOptions"
    (valueChanged)="valueChanged($event.data)"
    [value]="defaultValue"
    [disabled]="disabled"
  ></select2>`
})
export class FormCategorySelectComponent implements OnInit {
  
  private _value: string = '';
  
  @Input('value')
  get value() {
    return this._value;
  }
  set value(newValue: string) {
    this._value = newValue;
    if (!this.field) {
      this.defaultValue = this._value;
    }
  }
  
  // Implies a read only state
  @Input()
  disabled: boolean = false;
  
  @Input()
  placeholder: string;

  @Input()
  showValidation: boolean = true;
  
  public defaultValue: string = '';
  
  @Input()
  field: FormField<any>;
  
  public formCategorySelectOptions: Select2Options;
  public formCategorySelectData: ExIdTextPair[] = [];
  
  // Reference firstNameInput variable inside Component
  @ViewChild('select2Component') select2ComponentRef: Select2Component;
  
  @Output()
  change: EventEmitter<ExIdTextPair[]> = new EventEmitter<IdTextPair[]>();
  
  constructor(
    public categoryService: CategoryService,
    public session: Session,
    private errorHandler: ErrorHandlerService,
  ) {
  }
  
  ngOnInit() {
    
    this.formCategorySelectOptions = { allowClear: true, placeholder: this.placeholder || "Form Type"};
    
    this.session.lockInputRx(this.categoryService.getCategories().pipe(map(categories => categories.filter(cat => cat.name !== 'DRM-Task'))))
    .subscribe( (items: ICategoryOutputModel[]) => {
      let newSelectOptions = [{id:"",text:""}];
      
      items.forEach( (category: FormCategory) => newSelectOptions.push({ id: (category.id as number).toString(), text: category.name === 'Report' ? 'Feedback' : category.name }));
      
      this.formCategorySelectData = newSelectOptions;
      
      //Force the change detection to cycle again to prevent race
      if( this.field ) {
        if( String(this.field.value) !== this.defaultValue )
          this.defaultValue = String(this.field.value);
      } else {
        //If the default value was manually set we need to re-trigger the process
        if( this._value !== '') {
          this.defaultValue = this.value;
          const option = this.formCategorySelectData.find(o => o.id === this._value);
          if (option) {
            option.selected = true; // this is only way to select a group after page reload
          }
        }
      }
    }, err => this.errorHandler.handleHttpError(err));
  }
  
  valueChanged(selectedOpts: ExIdTextPair[]) {
    if( selectedOpts.length === 0 || (
      selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0
    )) {
      if( this.field )
        this.field.value = null;
    }
    
    if( selectedOpts.length > 1 )
      throw("Selected options unexpectedly contained multiple results");
    
    if (selectedOpts.length === 1) {
      if (this.field) {
        this.field.value = selectedOpts[0].id;
      } else {
        if (this._value !== selectedOpts[0].id) { // if the value has been changed - emit event
          this._value = selectedOpts[0].id;
          this.change.emit(selectedOpts);
        }
      }
    }
  }
}