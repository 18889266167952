import { Component, Input, EventEmitter, Output } from '@angular/core';
import { IFormRecordOutputModel } from "../../../../../common/contracts/form";

@Component({
	selector: 'footer',
	template: `
    <submission-detail [formRecord]="formRecord"></submission-detail>
    <div class="row" *ngIf="!readOnly">
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-2">
            <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit(false)"><span class="capitalize">Submit</span></button>
        </div>
        <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-5 col-xl-3 col-xxl-2" *ngIf="showDraft">
            <a class="btn btn-block btn-lg btn-primary rounded-0 text-center text-white" (click)="submit(true)"><span class="capitalize">Save Draft</span></a>
        </div>
    </div>
    `
})
export class FooterComponent {

	@Input() public readOnly: boolean = true;
	@Input() public formRecord: IFormRecordOutputModel | null;
	@Input() public showDraft: boolean = true;

	@Output()
	onSubmit = new EventEmitter<boolean>();

	constructor() { }

	submit(isDraft: boolean = false) {
		this.onSubmit.emit(isDraft);
	}
}