<div class="row">
    <div class="col-8 text-lg-left">
        <h1 class="text-uppercase text-orange">Document Collections</h1>
    </div>
    <div class="col-4 text-md-right">
        <button class="btn btn-md btn-orange mr-2" (click)="handleImportClick()"
            [disabled]='isAdmin ? null: true'>Import</button>
        <button class="btn btn-md btn-orange" (click)="handleDocumentCollectionClick('new')"
            [disabled]='isAdmin ? null: true'>New Document Collection</button>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th scope="col">
                        <span class="sofrtable-col-name" (click)="handleSortChange('name')"
                            [ngClass]="{active: sortBy === 'name', reverse: sortOrder !== 'asc'}">
                            Name<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('createdAt')"
                            [ngClass]="{active: sortBy === 'createdAt', reverse: sortOrder !== 'asc'}">
                            Created At<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="hover-cursor" *ngFor="let documentCollection of documentCollections"
                    (click)="handleDocumentCollectionClick(documentCollection.id)">
                    <td>{{documentCollection.name}}</td>
                    <td>{{documentCollection.createdAt | date : 'mediumDate'}}</td>
                </tr>
                <tr *ngIf="documentCollections.length === 0">
                    <td [colSpan]="7" class="text-center">
                        No Collections
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <pagination [collectionSize]="totalItems" [(page)]="currentPage" [(pageSize)]="pageSize"
            (pageChange)="handlePageChange()"></pagination>
    </div>
</div>