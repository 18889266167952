import { Injectable } from '@angular/core';
import {GenericService} from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  TagPaginationResponse,
  ICreateTagInputModel,
  ITagSortedField,
  IUpdateTagInputModel,
  ITagOutputModel
} from "../../../common/contracts/tag";
import { ISortOrderValue } from "../../../common/contracts/common";
import { Observable, of } from "rxjs";
import { share, map, tap } from "rxjs/operators";

@Injectable()
export class TagsService extends GenericService {

  static readonly apiPrefix = '/api/tag';

  private cachedTags: ITagOutputModel[] | null = null;
  private readonly getTagsRequest: Observable<ITagOutputModel[]>;

  constructor(private http: HttpClient) {
    super();

    const queryParams = new HttpParams({
      fromObject: {
        skip: '0',
        limit: '50',
        sortBy: 'name',
        order: 'asc',
      }
    });

    this.getTagsRequest = this.http.get<TagPaginationResponse>(TagsService.apiPrefix, { params: queryParams}).pipe(
      map(data => {
        this.cachedTags = data.items;
        setTimeout(() => {
          this.cachedTags = null; // invalidate tags cache in 20 minutes
        }, 20 * 60 * 1000);
        return data.items;
      }),
      share() //share results between multiple subscriptions
    );
  }

  public getTags(): Observable<ITagOutputModel[]> {
    return this.cachedTags ? of(this.cachedTags) : this.getTagsRequest;
  }

  public getPagedTags(offset: number = 0, pageSize: number = 20, sortBy: ITagSortedField = 'name', order: ISortOrderValue = 'asc', counts: boolean = true) {
    const queryParams = new HttpParams({
      fromObject: {
        skip: offset.toString(),
        limit: pageSize.toString(),
        sortBy,
        order,
        // counts: counts ? '1' : '0',
      }
    });
    return this.http.get<TagPaginationResponse>(TagsService.apiPrefix, { params: queryParams});
  }

  public getTagById(id: number) {
    return this.http.get<ITagOutputModel>(`${TagsService.apiPrefix}/${id}`);
  }

  public createTag(tagData: ICreateTagInputModel) {
    return this.http.post(TagsService.apiPrefix, tagData).pipe(tap(() => {
      this.cachedTags = null;
    }));
  }

  public updateTag(tagChanges: IUpdateTagInputModel) {
    return this.http.put(TagsService.apiPrefix, tagChanges).pipe(tap(() => {
      this.cachedTags = null;
    }));
  }

  public archiveTag(id: number) {
    return this.http.delete(`${TagsService.apiPrefix}/${id}`).pipe(tap(() => {
      this.cachedTags = null;
    }));
  }

}
