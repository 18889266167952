import * as $ from "jquery";
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { Session } from "../../service/util/Session";
import { FormField } from "../../model/Form";
import { Select2Component } from "ng2-select2";
import { UsersService } from "../../service/admin/UsersService";
import { UsersPaginationResponse } from "../../../common/contracts/users";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";

@Component({
  selector: "user-select",
  template: `<select2
    #select2Component
    class="form-control select2-form-control {{disabled?'bg-grey':''}} {{!disabled && field && field.iifIsValid('border-success','border-danger')}}"
    [options]="userSelectOptions"
    (valueChanged)="valueChanged($event.data)"
    [value]="defaultValue"
    [disabled]="disabled"
    ></select2>`
})
export class UserSelectComponent implements OnInit {

  public userSelectOptions: Select2Options;

  public selectedUserId: string;

  @Input()
  public defaultValue: string = "";

  @Input()
  field: FormField<any>;

  @Input()
  placeholder: string;

  @Input()
  disabled: boolean = false;

  // Reference firstNameInput variable inside Component
  @ViewChild("select2Component") select2ComponentRef: Select2Component;

  @Output()
  change: EventEmitter<IdTextPair[]> = new EventEmitter<IdTextPair[]>();

  @Output()
  init: EventEmitter<null> = new EventEmitter<null>();

  // For the purposes of tracking wether or not the select2 element has run through
  // its first initSelection
  elementInit: boolean = false;

  constructor(
    private usersService: UsersService,
    private errorHandler: ErrorHandlerService,
    public session: Session
  ) { }

  ngOnInit() {

    this.selectedUserId = this.defaultValue;

    const pageSize = 10;

    this.userSelectOptions = {
      allowClear: true,
      placeholder: this.placeholder || "Select User",
      // @ts-ignore
      ajax: {
        delay: 300,
        dataType: 'json',
        data: (query: any) => {
          const page = query.page || 1;
          return query.term ?
            {
              limit: pageSize,
              skip: (page - 1) * pageSize,
              search: query.term,
            }
            :
            {
              limit: pageSize,
              skip: (page - 1) * pageSize,
            };
        },
        // @ts-ignore
        transport: (params, success, failure) => {
          this.usersService.getUsers({
            limit: params.data.limit,
            skip: params.data.skip,
            sortBy: 'name',
            order: 'asc',
            search: params.data.search || undefined
          }).subscribe((usersData: UsersPaginationResponse) => {
            success && success(usersData);
          }, (err) => {
            console.error('Error loading users in userSelect component', err);
            this.errorHandler.handleHttpError(err);
            failure && failure();
          });
        },
        processResults: (data: UsersPaginationResponse, params: any) => {
          params.page = params.page || 1;
          return {
            results: data.items.map(user => ({
              id: user.id.toString(),
              text: `${user.firstName} ${user.lastName}`,
            })),
            pagination: {
              more: (params.page * pageSize) < data.totalCount,
            }
          };
        },
      },
      /*
        Defaulting needs to be done differently on this element
        as it uses ajax to populate its data
       */
      initSelection: (element, callback) => {
        if (!this.elementInit) {
          // It must be a string as that is the type of the value used by select2
          let defaultValInput: string | number = this.field ? (this.field.value || this.defaultValue) : this.defaultValue;
          let defaultVal: string | null = defaultValInput ? defaultValInput.toString() : null;

          if (defaultVal && defaultVal.length) {
            //Get the user
            this.usersService.getUserById(Number(defaultVal), true)
              .subscribe(user => {
                callback({
                  id: defaultVal,
                  text: `${user.firstName} ${user.lastName}`,
                });
              }, err => {
                console.error('Cannot load user details', err);
                this.errorHandler.handleHttpError(err);
              });
          } else {
            callback({
              id: '',
              text: this.userSelectOptions.placeholder
            });
          }

          this.elementInit = true;
          this.init.emit(null);

          return;
        }
      },
    };
  }

  valueChanged(selectedOpts: IdTextPair[]) {
    if (selectedOpts.length === 0 || (
      selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0
    )) {
      if (this.field)
        this.field.value = null;
    }

    if (selectedOpts.length > 1)
      throw ("Selected options unexpectedly contained multiple results");

    if (selectedOpts.length === 1 && this.field)
      this.field.value = selectedOpts[0].id;

    if (!this.field && selectedOpts.length === 1) {
      if (this.selectedUserId !== selectedOpts[0].id) {
        this.selectedUserId = selectedOpts[0].id;
        this.change.emit(selectedOpts);
      }
    } else if (!this.field && (!selectedOpts || selectedOpts.length === 0)) {
      if (this.selectedUserId) {
        this.selectedUserId = '';
        this.change.emit([]);
      }
    }
  }

  /**
   * this method is made for manual selecting  of a user
   */
  public changeSelectedUser(userId: number) {
    this.usersService.getUserById(userId).subscribe(user => {
      if (this.select2ComponentRef && this.select2ComponentRef.selector) {

        this.selectedUserId = user.id.toString();

        $(this.select2ComponentRef.selector.nativeElement).append(
          new Option(`${user.firstName} ${user.lastName}`, user.id.toString(), true, true)
        ).trigger('change');

        $(this.select2ComponentRef.selector.nativeElement).trigger('select2:select', {
          params: {
            data: { id: user.id.toString(), text: `${user.firstName} ${user.lastName}` },
          }
        });

      }
    }, err => {
      console.error('Cannot load user details', err);
      this.errorHandler.handleHttpError(err);
    });
  }
}
