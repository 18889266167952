/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/ng2-select2/ng2-select2.component.ngfactory";
import * as i2 from "ng2-select2/ng2-select2.component";
import * as i3 from "./userGroupSelect.component";
import * as i4 from "../../service/admin/GroupsService";
import * as i5 from "../../service/util/Session";
import * as i6 from "../../service/ErrorHandlerService";
import * as i7 from "../../service/currentUser/CurrentUserService";
var styles_UserGroupSelectComponent = [];
var RenderType_UserGroupSelectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserGroupSelectComponent, data: {} });
export { RenderType_UserGroupSelectComponent as RenderType_UserGroupSelectComponent };
export function View_UserGroupSelectComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { select2ComponentRef: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "select2", [], [[8, "className", 0]], [[null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanged" === en)) {
        var pd_0 = (_co.valueChanged($event.data) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Select2Component_0, i1.RenderType_Select2Component)), i0.ɵdid(2, 4964352, [[1, 4], ["select2Component", 4]], 0, i2.Select2Component, [i0.Renderer], { data: [0, "data"], value: [1, "value"], disabled: [2, "disabled"], options: [3, "options"] }, { valueChanged: "valueChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.userGroupSelectData; var currVal_2 = _co.defaultValue; var currVal_3 = _co.disabled; var currVal_4 = _co.userGroupSelectOptions; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(2, "form-control select2-form-control ", (_co.disabled ? "bg-grey" : ""), " ", ((!_co.disabled && _co.field) && _co.field.iifIsValid("border-success", "border-danger")), ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_UserGroupSelectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "user-group-select", [], null, null, null, View_UserGroupSelectComponent_0, RenderType_UserGroupSelectComponent)), i0.ɵdid(1, 114688, null, 0, i3.UserGroupSelectComponent, [i4.GroupsService, i5.Session, i6.ErrorHandlerService, i7.CurrentUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserGroupSelectComponentNgFactory = i0.ɵccf("user-group-select", i3.UserGroupSelectComponent, View_UserGroupSelectComponent_Host_0, { value: "value", disabled: "disabled", placeholder: "placeholder", restrictGroups: "restrictGroups", multiple: "multiple", field: "field" }, { change: "change" }, []);
export { UserGroupSelectComponentNgFactory as UserGroupSelectComponentNgFactory };
