import { Component } from "@angular/core";
import { DateSelectModalOptions, DateSelectModalState } from "../../../service/ModalService";

@Component({
  selector: 'date-select-modal',
  templateUrl: './dateSelectModal.component.html',
})
export class DateSelectModalComponent {
  options: DateSelectModalOptions;

  public value: string;

  constructor(private state: DateSelectModalState) {
    this.options = state.options;
    this.value = state.options.defaultValue;
  }

  submit() {
    this.state.modal.close(this.value);
  }

  cancel() {
    this.state.modal.dismiss('canceled');
  }

}
