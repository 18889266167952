/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./pageTitle.component";
var styles_PageTitleComponent = [];
var RenderType_PageTitleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageTitleComponent, data: {} });
export { RenderType_PageTitleComponent as RenderType_PageTitleComponent };
function View_PageTitleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "pr-2 text-center text-lg-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h1", [["class", "text-uppercase text-orange"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "flex-grow-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "hr", [["class", "border border-grey border-5 line bw-4 my-4"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 4, 0, currVal_0); }); }
function View_PageTitleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["class", "text-uppercase text-white bg-teal p-2 px-3 my-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 2, 0, currVal_0); }); }
export function View_PageTitleComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "row pt-2"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTitleComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTitleComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.titleType === "1"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.titleType === "2"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PageTitleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "page-title", [], null, null, null, View_PageTitleComponent_0, RenderType_PageTitleComponent)), i0.ɵdid(1, 49152, null, 0, i2.PageTitleComponent, [], null, null)], null, null); }
var PageTitleComponentNgFactory = i0.ɵccf("page-title", i2.PageTitleComponent, View_PageTitleComponent_Host_0, { text: "text", titleType: "titleType" }, {}, []);
export { PageTitleComponentNgFactory as PageTitleComponentNgFactory };
