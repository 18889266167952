import { Component, OnInit, ViewChild } from '@angular/core';
import { FormService } from "../../../../service/FormService";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Session } from "../../../../service/util/Session";
import has = Reflect.has;
import { ChildFormTreeNode, IFormOutputModel } from "../../../../../common/contracts/form";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { ComponentCanDeactivate } from "../../../shared/canDeactivate/ComponentCanDeactivate";
import { DocumentReviewFormStage0Component } from "./stage0/documentReviewFormStage0.component";
import { DocumentReviewFormStage1Component } from "./stage1/documentReviewFormStage1.component";
import { DocumentReviewFormStage2Component } from "./stage2/documentReviewFormStage2.component";
import { DocumentReviewFormStage3Component } from "./stage3/documentReviewFormStage3.component";
import { DocumentReviewFormStage5Component } from "./stage5/documentReviewFormStage5.component";
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";


@Component({
	selector: 'app-admin',
	styleUrls: ['./documentReviewForm.component.scss'],
	templateUrl: './documentReviewForm.component.html',
})
export class DocumentReviewFormComponent extends ComponentCanDeactivate implements OnInit {

	public targetStage: number;
	public showForm: boolean = true;

	public formData: IFormOutputModel | null = null;
	public childForms: ChildFormTreeNode[] | null;

	public departmentId: string;
	public documentId: string;

	@ViewChild('reportStage0') reportStage0Form: DocumentReviewFormStage0Component;
	@ViewChild('reportStage1') reportStage1Form: DocumentReviewFormStage1Component;
	@ViewChild('reportStage2') reportStage2Form: DocumentReviewFormStage2Component;
	@ViewChild('reportStage3') reportStage3Form: DocumentReviewFormStage3Component;
	@ViewChild('reportStage5') reportStage5Form: DocumentReviewFormStage5Component;

	constructor(
		public formService: FormService,
		public activatedRoute: ActivatedRoute,
		public session: Session,
		private router: Router,
		private errorHandler: ErrorHandlerService,
	) {
		super();
	}

	canDeactivate(): boolean {
		// if( !this.showForm) return true;

		// if (this.formData && this.formData.isDeleted) {
		//   return true;
		// }

		// if( this.targetStage === 0 )
		//   return this.reportStage0Form.canDeactivate();

		// if( this.targetStage === 1 )
		//   return this.reportStage1Form.canDeactivate();

		// if( this.targetStage === 2 )
		//   return this.reportStage2Form.canDeactivate();

		// if( this.targetStage === 3 )
		//   return this.reportStage3Form.canDeactivate();

		return true;
	}

	ngOnInit() {
		this.activatedRoute.params.pipe(switchMap((params: Params) => {
			if (!has(params, "taskId")) {
				this.showForm = true;
				this.targetStage = 0;
				return of(null);
			} else {
				return this.formService.getFormById(params["taskId"]);
			}
		})).subscribe((existingForm: IFormOutputModel | null) => {

			this.departmentId = this.activatedRoute.snapshot.queryParams.departmentId ? this.activatedRoute.snapshot.queryParams.departmentId : "";
			this.documentId = this.activatedRoute.snapshot.queryParams.documentId ? this.activatedRoute.snapshot.queryParams.documentId : "";

			if (!existingForm) {
				return;
			}

			/**
			 *  fix for 'Parent Form' link, if form's category is 'Follow-Up' redirect to /followUps/:reportId
			 **/
			if (existingForm.category.name === 'Follow-Up') {
				this.router.navigate(['/followUp', existingForm.id], { replaceUrl: true });
				return;
			}

			/**
			 *  fix for 'Parent Form' link, if form's category is 'Report' redirect to /report/:reportId
			 **/
			if (existingForm.category.name === 'Audit') {
				this.router.navigate(['/audit', existingForm.id], { replaceUrl: true });
				return;
			}

			this.formData = existingForm;

			if (this.formData.records.length === 0) {
				this.errorHandler.raiseError("Record did not contain valid data. Please contact a system administrator for assistance.", "Invalid Form Data");
			}

			this.targetStage = this.formData["stage"] || 0;
			this.showForm = true;

			if (this.formData.childForms && this.formData.childForms.length) {
				this.childForms = this.formData.childForms;
			}

		}, (err) => {
			console.error("Cannot get form by id", err);
			this.errorHandler.handleHttpError(err);
		});
	}
}
