import { Component, Input, ViewEncapsulation } from "@angular/core";
import { DocumentsService } from "service/admin/DocumentsService";
import { IDocumentOutputModel } from "../../../../../../common/contracts/documentDRM";

@Component({
	selector: 'document-review-form-header',
	templateUrl: './documentReviewFormHeader.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class DocumentReviewFormHeaderComponent {

	@Input()
	issueNumber: string = 'TBA';

	@Input()
	documentId: string = 'TBA';

	@Input()
	dateString: string = '';

	document: IDocumentOutputModel | null = null;

	constructor(
		private documentsService: DocumentsService
	) { }

	ngOnInit() {
		const documentId = this.getDocumentId();
		if (documentId) {
			this.documentsService.getDocumentById(documentId).subscribe(document => {
				this.document = document;
			});
		}
	}

	/**
	 * @description Reliably returns a numeric documentId from the supplied value or null
	 * @returns {number | null}
	 */
	getDocumentId(): number | null {
		if (!this.documentId || this.documentId.toLowerCase() === 'TBA') {
			return null;
		}

		try {
			return parseInt(this.documentId);
		} catch (e) {
			return null;
		}
	}
}