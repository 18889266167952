/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "ngx-autosize";
import * as i3 from "@angular/common";
import * as i4 from "./formActions.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../service/FormService";
import * as i7 from "../../service/ErrorHandlerService";
import * as i8 from "../../service/currentUser/CurrentUserService";
import * as i9 from "../../service/util/Session";
import * as i10 from "@angular/router";
var styles_FormActionsComponent = ["i.fa-circle-o-notch[_ngcontent-%COMP%] {\n        margin-right: 10px;\n    }"];
var RenderType_FormActionsComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_FormActionsComponent, data: {} });
export { RenderType_FormActionsComponent as RenderType_FormActionsComponent };
function View_FormActionsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary send-report mr-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleOpenSendFormReport(i0.ɵnov(_v.parent, 13)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-envelope"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0\u00A0Send Email Report"]))], null, null); }
function View_FormActionsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-warning pull-right mr-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleFormRevert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-alert"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0\u00A0Revert Form"]))], null, null); }
function View_FormActionsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-danger pull-right mr-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleDeleteForm(i0.ɵnov(_v.parent, 12)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-trash"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0\u00A0Delete Form"]))], null, null); }
function View_FormActionsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-success pull-right mr-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleDuplicateForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-trash"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0\u00A0Duplicate Form"]))], null, null); }
function View_FormActionsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-12 pb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["This form has been deleted. Deletion reason: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formData.deleteReason; _ck(_v, 3, 0, currVal_0); }); }
function View_FormActionsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Warning"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 14, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "label", [["for", "deleteReason"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Enter deletion reason:"])), (_l()(), i0.ɵeld(9, 0, null, null, 11, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 8, "textarea", [["autosize", ""], ["class", "form-control"], ["id", "deleteReason"], ["ngbAutofocus", ""], ["required", ""]], [[8, "maxLength", 0], [1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 11)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 11)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 18).onInput($event.target) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.deleteReason = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(12, 16384, null, 0, i1.RequiredValidator, [], { required: [0, "required"] }, null), i0.ɵprd(1024, null, i1.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i1.RequiredValidator]), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(15, 671744, null, 0, i1.NgModel, [[8, null], [6, i1.NG_VALIDATORS], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(17, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), i0.ɵdid(18, 2768896, null, 0, i2.AutosizeDirective, [i0.ElementRef, i0.NgZone], { minRows: [0, "minRows"] }, null), (_l()(), i0.ɵeld(19, 0, null, null, 1, "small", [["class", "form-text text-secondary"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The text should no be longer than 255 characters"])), (_l()(), i0.ɵeld(21, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 1, "button", [["class", "btn btn-danger"], ["ngbAutofocus", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.close("confirmed") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete"])), (_l()(), i0.ɵeld(24, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = ""; _ck(_v, 12, 0, currVal_9); var currVal_10 = _co.deleteReason; _ck(_v, 15, 0, currVal_10); var currVal_11 = 2; _ck(_v, 18, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = 255; var currVal_1 = (i0.ɵnov(_v, 12).required ? "" : null); var currVal_2 = i0.ɵnov(_v, 17).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 17).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 17).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 17).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 17).ngClassValid; var currVal_7 = i0.ɵnov(_v, 17).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 17).ngClassPending; _ck(_v, 10, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_12 = (_co.deleteReason.trim().length === 0); _ck(_v, 22, 0, currVal_12); }); }
function View_FormActionsComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-circle-o-notch fa-spin"]], null, null, null, null, null))], null, null); }
function View_FormActionsComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Send Email Report"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 16, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "label", [["for", "deleteReason"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Enter list of emails:"])), (_l()(), i0.ɵeld(9, 0, null, null, 13, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 8, "textarea", [["autosize", ""], ["class", "form-control"], ["id", "deleteReason"], ["ngbAutofocus", ""], ["required", ""]], [[8, "maxLength", 0], [1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 11)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 11)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 18).onInput($event.target) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.emailsList = $event) !== false);
        ad = (pd_5 && ad);
    } if (("input" === en)) {
        var pd_6 = (_co.validateEmails() !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(12, 16384, null, 0, i1.RequiredValidator, [], { required: [0, "required"] }, null), i0.ɵprd(1024, null, i1.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i1.RequiredValidator]), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(15, 671744, null, 0, i1.NgModel, [[8, null], [6, i1.NG_VALIDATORS], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(17, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), i0.ɵdid(18, 2768896, null, 0, i2.AutosizeDirective, [i0.ElementRef, i0.NgZone], { minRows: [0, "minRows"] }, null), (_l()(), i0.ɵeld(19, 0, null, null, 3, "small", [["class", "form-text"]], null, null, null, null, null)), i0.ɵdid(20, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(21, { "text-secondary": 0, "text-danger": 1 }), (_l()(), i0.ɵted(-1, null, ["Should contain list of valid comma-separated emails."])), (_l()(), i0.ɵeld(23, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 3, "button", [["class", "btn btn-primary"], ["ngbAutofocus", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendFormReport(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormActionsComponent_8)), i0.ɵdid(26, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(27, null, ["", ""])), (_l()(), i0.ɵeld(28, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = ""; _ck(_v, 12, 0, currVal_9); var currVal_10 = _co.isSendingReport; var currVal_11 = _co.emailsList; _ck(_v, 15, 0, currVal_10, currVal_11); var currVal_12 = 2; _ck(_v, 18, 0, currVal_12); var currVal_13 = "form-text"; var currVal_14 = _ck(_v, 21, 0, _co.isEmailsValid, !_co.isEmailsValid); _ck(_v, 20, 0, currVal_13, currVal_14); var currVal_16 = _co.isSendingReport; _ck(_v, 26, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = 1024; var currVal_1 = (i0.ɵnov(_v, 12).required ? "" : null); var currVal_2 = i0.ɵnov(_v, 17).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 17).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 17).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 17).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 17).ngClassValid; var currVal_7 = i0.ɵnov(_v, 17).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 17).ngClassPending; _ck(_v, 10, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_15 = ((_co.isSendingReport || (_co.emailsList.trim().length === 0)) || !_co.isEmailsValid); _ck(_v, 24, 0, currVal_15); var currVal_17 = (_co.isSendingReport ? "Sending" : "Send"); _ck(_v, 27, 0, currVal_17); }); }
export function View_FormActionsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "col-12 pb-3"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormActionsComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormActionsComponent_2)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormActionsComponent_3)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormActionsComponent_4)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormActionsComponent_5)), i0.ɵdid(11, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, [["deleteFormContent", 2]], null, 0, null, View_FormActionsComponent_6)), (_l()(), i0.ɵand(0, [["sendReportContent", 2]], null, 0, null, View_FormActionsComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.isAdminOrManager && !!_co.formData) && !_co.formData.isDeleted) && !!_co.formData.archivedAt); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.isAdministrator && !_co.isProduction); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.isAdministrator && !!_co.formData) && !_co.formData.isDeleted); _ck(_v, 7, 0, currVal_2); var currVal_3 = ((_co.isAdminOrManager && !!_co.formData) && _co.showDuplicateButton); _ck(_v, 9, 0, currVal_3); var currVal_4 = (!!_co.formData && _co.formData.isDeleted); _ck(_v, 11, 0, currVal_4); }, null); }
export function View_FormActionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "form-actions", [], null, null, null, View_FormActionsComponent_0, RenderType_FormActionsComponent)), i0.ɵdid(1, 114688, null, 0, i4.FormActionsComponent, [i5.NgbModal, i6.FormService, i3.Location, i7.ErrorHandlerService, i8.CurrentUserService, i9.Session, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormActionsComponentNgFactory = i0.ɵccf("form-actions", i4.FormActionsComponent, View_FormActionsComponent_Host_0, { formData: "formData", showDuplicateButton: "showDuplicateButton" }, {}, []);
export { FormActionsComponentNgFactory as FormActionsComponentNgFactory };
