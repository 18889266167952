/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./buttonsGroupToggle.component";
var styles_ButtonsGroupToggleComponent = ["div.btn-group-toggle[_ngcontent-%COMP%] {\n          display: flex;\n          flex-direction: row;\n          flex-wrap: nowrap;\n          justify-content: flex-end;\n      }\n      \n      button.btn-secondary.active[_ngcontent-%COMP%] {\n          box-shadow: none !important;\n      }"];
var RenderType_ButtonsGroupToggleComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ButtonsGroupToggleComponent, data: {} });
export { RenderType_ButtonsGroupToggleComponent as RenderType_ButtonsGroupToggleComponent };
function View_ButtonsGroupToggleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [], [[8, "className", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "btn btn-secondary ", ((_co.value === _v.context.$implicit.value) ? "active" : ""), ""); var currVal_1 = _co.disabled; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.text; _ck(_v, 1, 0, currVal_2); }); }
export function View_ButtonsGroupToggleComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "btn-group btn-group-toggle"], ["data-toggle", "buttons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ButtonsGroupToggleComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ButtonsGroupToggleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button-group-toggle", [], null, null, null, View_ButtonsGroupToggleComponent_0, RenderType_ButtonsGroupToggleComponent)), i0.ɵdid(1, 49152, null, 0, i2.ButtonsGroupToggleComponent, [], null, null)], null, null); }
var ButtonsGroupToggleComponentNgFactory = i0.ɵccf("button-group-toggle", i2.ButtonsGroupToggleComponent, View_ButtonsGroupToggleComponent_Host_0, { value: "value", disabled: "disabled", options: "options" }, { valueChange: "valueChange" }, []);
export { ButtonsGroupToggleComponentNgFactory as ButtonsGroupToggleComponentNgFactory };
