import { DocumentReviewFormStage5Component } from 'views/authenticated/admin/documentReviewForm/stage5/documentReviewFormStage5.component';
import { DocumentReviewFormComponent } from './../views/authenticated/admin/documentReviewForm/documentReviewForm.component';
import { DocumentReviewFormStage4Component } from './../views/authenticated/admin/documentReviewForm/stage4/documentReviewFormStage4.component';
import { DRMDashboardComponent } from './../views/authenticated/admin/drm-dashboard/drm-dashboard.component';
import { EditTagComponent } from './../views/authenticated/admin/tags/edit/editTag.component';
import { TagsComponent } from './../views/authenticated/admin/tags/list/tags.component';
import { TagsService } from './../service/admin/TagsService';
import { EditDocumentCollectionComponent } from './../views/authenticated/admin/documentCollections/edit/editDocumentCollection.component';
import { DocumentsComponent } from './../views/authenticated/admin/documents/list/documents.component';
import { EditDocumentComponent } from '../views/authenticated/admin/documents/edit/editDocument.component';
import { DocumentCollectionsComponent } from './../views/authenticated/admin/documentCollections/list/documentCollections.component';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskModule } from 'ngx-mask'
import { AutosizeModule } from 'ngx-autosize';

import { AppComponent } from './app.component';
import { Routing } from './app.routes';
import { SplashComponent } from '../views/splash/splash.component';
import { CompanyBoxComponent } from '../views/company-box/company-box.component';
import { DialogModule } from '../views/dialog/dialog.component';
import { AuthenticatedComponent } from '../views/authenticated/_main/authenticated.component';
import { AdminComponent } from '../views/authenticated/admin/template/admin.component';
import { HeaderComponent } from '../views/authenticated/admin/template/header.component';
import { FooterComponent } from '../views/authenticated/admin/template/footer.component';
import { PageTitleComponent } from '../views/authenticated/admin/template/pageTitle.component';
import { ProfileComponent } from '../views/authenticated/profile/profile.component';
import { GroupsComponent } from "../views/authenticated/admin/groups/list/groups.component";
import { MainComponent } from '../views/_main/main.component';
import { Session } from '../service/util/Session';
import { ArbitraryValueCache } from '../service/util/ArbitraryValueCache';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Select2Module } from 'ng2-select2';
import {
  AuthenticationIconComponent,
} from '../views/authentication-icon/authentication-icon.component';
import { LoginComponent } from "../views/auth/login/login.component";
import { ResetComponent } from "../views/auth/reset/reset.component";
import { ForgotComponent } from "../views/auth/forgot/forgot.component";
import { RegisterComponent } from "../views/auth/register/register.component";
import { AuthService } from "../service/security/AuthService";
import { CurrentUserService } from "../service/currentUser/CurrentUserService";

import { DashboardComponent } from "../views/authenticated/admin/dashboard/dashboard.component";
import { DashboardFilterComponent } from "../views/authenticated/admin/dashboard-filter/dashboard-filter.component";
import { ReportFormComponent } from "../views/authenticated/admin/reportForm/reportForm.component";

import { DocumentCollectionsService } from "../service/admin/DocumentCollectionsService";
import { DocumentsService } from "../service/admin/DocumentsService";
import { GroupsService } from "../service/admin/GroupsService";
import { UsersService } from "../service/admin/UsersService";
import { UsersComponent } from "../views/authenticated/admin/users/list/users.component";
import { LocationService } from "../service/LocationService";
import { EnumService } from "../service/EnumService";
import { FormService } from "../service/FormService";
import { CategoryService } from "../service/CategoryService";
import { FormRecordService } from "../service/FormRecordService";
import { ReportFormStage0Component } from "../views/authenticated/admin/reportForm/stage0/reportFormStage0.component";
import { ReportFormStage1Component } from "../views/authenticated/admin/reportForm/stage1/reportFormStage1.component";
import { ReportFormStage2Component } from "../views/authenticated/admin/reportForm/stage2/reportFormStage2.component";
import { ReportFormStage4Component } from "../views/authenticated/admin/reportForm/stage4/reportFormStage4.component";
import { ReportFormStage3Component } from "../views/authenticated/admin/reportForm/stage3/reportFormStage3.component";
import { FollowUpFormComponent } from "../views/authenticated/admin/followUpForm/followUpForm.component";
import { FollowUpFormStage0Component } from "../views/authenticated/admin/followUpForm/stage0/followUpFormStage0.component";
import { FollowUpFormStage1Component } from "../views/authenticated/admin/followUpForm/stage1/followUpFormStage1.component";
import { FollowUpFormStage2Component } from "../views/authenticated/admin/followUpForm/stage2/followUpFormStage2.component";
import { FollowUpFormStage3Component } from "../views/authenticated/admin/followUpForm/stage3/followUpFormStage3.component";
import { LocationSelectComponent } from "../views/shared/locationSelect.component";
import { DocumentCollectionSelectComponent } from "../views/shared/documentCollectionSelect.component";
import { EnumSelectComponent } from "../views/shared/enumSelect.component";
import { UserGroupSelectComponent } from "../views/shared/userGroupSelect.component";
import { MetaTagSelectComponent } from "../views/shared/metaTagSelect.component";
import { DocumentService } from "../service/DocumentService";
import { UserSelectComponent } from "../views/shared/userSelect.component";
import { UserSelect2Component } from "../views/shared/userSelect2.component";
import { FollowUpWidgetComponent } from "../views/shared/followUpWidget.component";
import { EditUserComponent } from "../views/authenticated/admin/users/edit/editUser.component";
import { NotFoundComponent } from "../views/not-found/notFound.component";
import { EditGroupComponent } from "../views/authenticated/admin/groups/edit/editGroup.component";
import { RxFormsService } from "../service/util/reactiveForms";
import { EnumsComponent } from "../views/authenticated/admin/enums/list/enums.component";
import { EditEnumsSetComponent } from "../views/authenticated/admin/enums/edit/editEnumsSet.component";
import { SortablejsModule } from "angular-sortablejs";
import { TagSelectComponent } from "../views/shared/tagSelect.component";
import { RiskSelectComponent } from "../views/shared/riskSelect.component";
import {
  ModalService,
  ConfirmModalState,
  ConfirmTemplateDirective,
  AlertTemplateDirective,
  AlertModalState,
  DateSelectModalState,
  DateSelectModalTemplateDirective,
  CronModalState,
  CronTemplateDirective,
  RiskModalState,
  UploadModalState,
  RiskModalTemplateDirective,
  UploadModalTemplateDirective
} from "../service/ModalService";
import { ConfirmModalComponent } from "../views/modal/confirm/confirmModal.component";
import { RiskModalComponent } from "../views/modal/risk/riskModal.component";
import { UploadModalComponent } from "../views/modal/upload/uploadModal.component";
import { CronModalComponent } from "../views/modal/cron/cronModal.component";
import { AlertModalComponent } from "../views/modal/alert/alertModal.component";
import { ErrorHandlerService } from "../service/ErrorHandlerService";
import { PaginationComponent } from "../views/shared/pagination.component";
import { AdminRouterGuard } from "../service/routerGuards/admin.guard";
import { CurrentUserRouterGuard } from "../service/routerGuards/currentUser.guard";
import { ButtonToggleComponent } from "../views/shared/buttonToggle.component";
import { RxControlClassDirective } from "../directives/rxControlClass.directive";
import { UploadsComponent } from "../views/shared/uploads.component";
import { DRMUploadsComponent } from "../views/shared/drm-uploads.component";
import { BethanyHttpInterceptor } from "../service/security/HttpInterceptor";
import { PreloaderWrapperComponent } from "../views/shared/preloaderWrapper.component";
import { ReportService } from "../service/ReportService";
import { ButtonsSwitchComponent } from "../views/shared/buttonsSwitch.component";
import { DownloadReportLinkComponent } from "../views/shared/downloadReportLink.component";
import {
  DateParserFormatter,
  DatePickerComponent,
  NgbDateStringFormatAdapter
} from "../views/shared/datePicker.component";
import { DateOffsetPicker } from "../views/shared/dateOffsetPicker.component";
import { CanDeactivateGuard } from "../views/shared/canDeactivate/CanDeactivateGuard";
import { ForbiddenComponent } from "../views/forbidden/forbidden.component";
import { SubmissionDetailComponent } from "../views/shared/submissionDetail.component";
import { PersistentStorageService } from "../service/PersistentStorageService";
import { TruncatePipe } from "../pipes/truncate.pipe";
import { LocationsComponent } from "../views/authenticated/admin/locations/list/locations.component";
import { EditLocationComponent } from "../views/authenticated/admin/locations/edit/editLocation.component";
import { ButtonsGroupToggleComponent } from "../views/shared/buttonsGroupToggle.component";
import { DateSelectModalComponent } from "../views/modal/dateSelect/dateSelectModal.component";
import { FormDueAtComponent } from "../views/shared/formDueAt.component";
import { FormActionsComponent } from "../views/shared/formActions.component";
import { ChildFormsTreeComponent, ChildFormsTreeNode } from "../views/shared/childFormsTree.component";
import { ReplaceAmpersandDirective } from "../directives/replaceAmpersand.directive";
import { NumbersOnlyDirective } from "../directives/numbersOnly.directive";
import { EditOptionsComponent } from "../views/authenticated/admin/options/editOptions.component";
import { SettingsService } from "../service/admin/SettingsService";
import {
  AuditFormComponent,
  AuditFormStage0Component,
  AuditFormStage1Component,
  AuditFormStage2Component,
  AuditFormStage3Component
} from "../views/authenticated/admin/auditForm/auditForm.component";
import { SentryErrorHandler } from "../service/util/SentryErrorHandler";
import { FormCategorySelectComponent } from "../views/shared/formCategorySelect.component";
import { FollowUpService } from "../service/FollowUpService";
import { ResourcePacksComponent } from 'views/resource-packs/resource-packs.component';
import { ResourcePackCreateComponent } from 'views/resource-packs/resource-pack-create/resource-pack-create.component';
import { ResourcePackDocumentsComponent } from 'views/resource-packs/resource-pack-documents/resource-pack-documents.component';
import { ResourcePackService } from '../service/ResourcePackService';
import { FormTemplatesComponent } from 'views/authenticated/admin/formTemplate/formTemplates.component';
import { CronEditorModule } from '../../../vendor/cron-editor/src/lib/cron-editor.module';
import { DocumentTagsService } from 'service/admin/DocumentTagService';
import { DocumentReviewFormHeaderComponent } from 'views/authenticated/admin/documentReviewForm/header/documentReviewFormHeader.component';
import { DocumentReviewFormStage3Component } from 'views/authenticated/admin/documentReviewForm/stage3/documentReviewFormStage3.component';
import { DocumentReviewFormStage2Component } from 'views/authenticated/admin/documentReviewForm/stage2/documentReviewFormStage2.component';
import { DocumentReviewFormStage1Component } from 'views/authenticated/admin/documentReviewForm/stage1/documentReviewFormStage1.component';
import { DocumentReviewFormStage0Component } from 'views/authenticated/admin/documentReviewForm/stage0/documentReviewFormStage0.component';
import { DocumentMetaService } from 'service/admin/DocumentMetaService';
import { DocumentIntanceService } from 'service/admin/DocumentInstanceService';
import { DocumentStandardSelectComponent } from "../views/shared/documentStandardSelect.component";
import { RAMDashboardComponent } from '../views/authenticated/admin/ram-dashboard/ram-dashboard.component';
import { RiskAssessmentFormComponent } from 'views/authenticated/admin/riskAssessmentForm/riskAssessmentForm.component'
import { RiskAssessmentFormStage0Component } from 'views/authenticated/admin/riskAssessmentForm/stage0/riskAssessmentFormStage0.component';
import { RiskAssessmentFormStage1Component } from 'views/authenticated/admin/riskAssessmentForm/stage1/riskAssessmentFormStage1.component';
import { RiskAssessmentFormStage2Component } from 'views/authenticated/admin/riskAssessmentForm/stage2/riskAssessmentFormStage2.component';
import { RiskAssessmentFormStage3Component } from 'views/authenticated/admin/riskAssessmentForm/stage3/riskAssessmentFormStage3.component';
import { RiskAssessmentFormStage4Component } from 'views/authenticated/admin/riskAssessmentForm/stage4/riskAssessmentFormStage4.component';
import { RiskComponent } from 'views/authenticated/admin/risks/list/risks.component';
import { RiskService } from '../service/RiskService';
import { EditRiskComponent } from 'views/authenticated/admin/risks/edit/editRisk.component'
@NgModule({
  declarations: [
    AppComponent,
    SplashComponent,
    NotFoundComponent,
    ForbiddenComponent,
    // ResultsComponent,
    AuthenticatedComponent,
    AdminComponent,
    ProfileComponent,
    GroupsComponent,
    LocationsComponent,
    UsersComponent,
    EnumsComponent,
    EditUserComponent,
    EditGroupComponent,
    EditLocationComponent,
    EditEnumsSetComponent,
    AuthenticationIconComponent,
    CompanyBoxComponent,
    MainComponent,
    DashboardComponent,
    DashboardFilterComponent,
    EditOptionsComponent,
    ResourcePacksComponent,
    ResourcePackCreateComponent,
    ResourcePackDocumentsComponent,

    /* Forms */
    ReportFormComponent,
    ReportFormStage0Component,
    ReportFormStage1Component,
    ReportFormStage2Component,
    ReportFormStage3Component,
    ReportFormStage4Component,

    FollowUpFormComponent,
    FollowUpFormStage0Component,
    FollowUpFormStage1Component,
    FollowUpFormStage2Component,
    FollowUpFormStage3Component,

    AuditFormComponent,
    AuditFormStage0Component,
    AuditFormStage1Component,
    AuditFormStage2Component,
    AuditFormStage3Component,

    FormTemplatesComponent,

    DocumentReviewFormComponent,
    DocumentReviewFormHeaderComponent,
    DocumentReviewFormStage0Component,
    DocumentReviewFormStage1Component,
    DocumentReviewFormStage2Component,
    DocumentReviewFormStage3Component,
    DocumentReviewFormStage4Component,
    DocumentReviewFormStage5Component,

    RiskAssessmentFormComponent,
    RiskAssessmentFormStage0Component,
    RiskAssessmentFormStage1Component,
    RiskAssessmentFormStage2Component,
    RiskAssessmentFormStage3Component,
    RiskAssessmentFormStage4Component,

    /* UI Components */
    HeaderComponent,
    PageTitleComponent,
    FooterComponent,

    LocationSelectComponent,
    DocumentCollectionSelectComponent,
    EnumSelectComponent,
    DocumentStandardSelectComponent,
    FormCategorySelectComponent,
    UserGroupSelectComponent,
    UserSelectComponent,
    UserSelect2Component,
    TagSelectComponent,
    MetaTagSelectComponent,
    FollowUpWidgetComponent,
    ConfirmTemplateDirective,
    RiskModalTemplateDirective,
    UploadModalTemplateDirective,
    RiskModalComponent,
    UploadModalComponent,
    AlertTemplateDirective,
    CronTemplateDirective,
    ConfirmModalComponent,
    CronModalComponent,
    AlertModalComponent,
    DateSelectModalComponent,
    DateSelectModalTemplateDirective,
    FormActionsComponent,
    PaginationComponent,
    ButtonToggleComponent,
    ButtonsGroupToggleComponent,
    ButtonsSwitchComponent,
    DownloadReportLinkComponent,
    FormDueAtComponent,
    UploadsComponent,
    DRMUploadsComponent,
    SubmissionDetailComponent,
    RxControlClassDirective,
    ReplaceAmpersandDirective,
    NumbersOnlyDirective,
    PreloaderWrapperComponent,
    DatePickerComponent,
    DateOffsetPicker,
    ChildFormsTreeComponent,
    ChildFormsTreeNode,
    RiskSelectComponent,

    LoginComponent,
    ResetComponent,
    ForgotComponent,
    RegisterComponent,

    DocumentCollectionsComponent,
    EditDocumentCollectionComponent,
    DocumentsComponent,
    EditDocumentComponent,
    TagsComponent,
    EditTagComponent,
    DRMDashboardComponent,
    RAMDashboardComponent,
    RiskComponent,
    EditRiskComponent,

    /* Pipes */
    TruncatePipe,
  ],
  imports: [
    CronEditorModule,
    BrowserModule,
    DialogModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AutosizeModule,
    NgxMaskModule.forRoot(),
    SortablejsModule.forRoot({ animation: 150 }),

    Select2Module,
    NgbModule.forRoot(),
    Routing,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BethanyHttpInterceptor,
      multi: true
    },
    {
      provide: NgbDateAdapter,
      useClass: NgbDateStringFormatAdapter,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: DateParserFormatter,
    },
    {
      provide: TruncatePipe,
      useClass: TruncatePipe
    },
    AuthService,
    CurrentUserService,
    GroupsService,
    UsersService,
    LocationService,
    EnumService,
    FormService,
    FormRecordService,
    CategoryService,
    DocumentService,
    SettingsService,
    RxFormsService,
    ReportService,
    ModalService,
    ConfirmModalState,
    AlertModalState,
    DateSelectModalState,
    RiskModalState,
    UploadModalState,
    CronModalState,
    ErrorHandlerService,
    PersistentStorageService,
    AdminRouterGuard,
    CurrentUserRouterGuard,
    CanDeactivateGuard,
    FollowUpService,
    ResourcePackService,
    Session,
    ArbitraryValueCache,
    DocumentCollectionsService,
    DocumentsService,
    TagsService,
    DocumentTagsService,
    DocumentMetaService,
    DocumentIntanceService,
    RiskService
  ],
  /* Dynamic Components */
  entryComponents: [
    ReportFormStage0Component,
    ReportFormStage1Component,
    ReportFormStage2Component,
    ReportFormStage3Component,
    ReportFormStage4Component,

    FollowUpFormStage0Component,
    FollowUpFormStage1Component,
    FollowUpFormStage2Component,
    FollowUpFormStage3Component,

    AuditFormStage0Component,
    AuditFormStage1Component,
    AuditFormStage2Component,
    AuditFormStage3Component,

    DocumentReviewFormStage0Component,
    DocumentReviewFormStage1Component,
    DocumentReviewFormStage2Component,
    DocumentReviewFormStage3Component,
    DocumentReviewFormStage4Component,
    DocumentReviewFormStage5Component,

    RiskAssessmentFormStage0Component,
    RiskAssessmentFormStage1Component,
    RiskAssessmentFormStage2Component,
    RiskAssessmentFormStage3Component,
    RiskAssessmentFormStage4Component,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
