import { OnInit, EventEmitter } from '@angular/core';
import { Session } from "../../service/util/Session";
import { FormField } from "../../model/Form";
import { Select2Component } from 'ng2-select2';
import { EnumService } from "../../service/EnumService";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
var EnumSelectComponent = /** @class */ (function () {
    function EnumSelectComponent(enumService, session, errorHandler) {
        this.enumService = enumService;
        this.session = session;
        this.errorHandler = errorHandler;
        this.placeholder = "";
        this.allowClear = true;
        this.disabled = false;
        this._value = '';
        this.change = new EventEmitter();
        this.enumSelectOptions = {
            allowClear: true,
            placeholder: ''
        };
        this.enumSelectData = [];
        this.defaultValue = '';
    }
    Object.defineProperty(EnumSelectComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (newValue) {
            this._value = newValue;
        },
        enumerable: true,
        configurable: true
    });
    EnumSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.enum)
            throw ("EnumSelectComponent must have enum defined");
        if (this.placeholder)
            this.enumSelectOptions.placeholder = this.placeholder;
        if (this.allowClear !== null)
            this.enumSelectOptions.allowClear = this.allowClear;
        this.session.lockInputRx(this.enumService.getEnumsByName(this.enum))
            .subscribe(function (data) {
            var newSelectOptions = [];
            if (_this.allowClear)
                newSelectOptions.push({ id: "", text: "" });
            data.forEach(function (_enum) { return newSelectOptions.push({
                id: String(_enum.id), text: _enum.value
            }); });
            _this.enumSelectData = newSelectOptions;
            //Force the change detection to cycle again to prevent race
            if (_this.field && String(_this.field.value) !== _this.defaultValue)
                _this.defaultValue = String(_this.field.value);
            if (!_this.field && _this._value)
                _this.defaultValue = _this._value;
        }, function (err) {
            console.error('Error getting enum by name', err);
            _this.errorHandler.handleHttpError(err);
        });
    };
    EnumSelectComponent.prototype.valueChanged = function (selectedOpts) {
        if (selectedOpts.length === 0 || (selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0)) {
            if (this.field)
                this.field.value = null;
            this.change.emit(selectedOpts);
            return;
        }
        if (selectedOpts.length > 1)
            throw ("Selected options unexpectedly contained multiple results");
        if (this.field)
            this.field.value = selectedOpts[0].id;
        this.change.emit(selectedOpts);
    };
    return EnumSelectComponent;
}());
export { EnumSelectComponent };
