import {
  Component,
  Input,
  Output,
  EventEmitter,
  NgModule,
} from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition,
} from '@angular/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100),
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' })),
      ]),
    ]),
  ],
})
export class DialogComponent {
  @Input() closable = true;
  @Input() visible: boolean;
  @Input() small = false;
  @Input() allowScroll = true;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
  ],
  exports: [DialogComponent],
  declarations: [DialogComponent],
})
export class DialogModule {
}
