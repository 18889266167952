import { Injectable } from '@angular/core';
import {GenericService} from "./GenericService";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {IFormRecordParams} from "../../common/contracts/form";
import { IUpdateRecordDateParams } from "../../common/contracts/record";

@Injectable()
export class FormRecordService extends GenericService {

  static readonly apiPrefix = '/api/record';

  constructor(private http: HttpClient) {
    super();
  }

  public createRecord(record: IFormRecordParams): Observable<any> {
    return this.http.post<any>(FormRecordService.apiPrefix , record);
  }

  public updateRecordDate(params: IUpdateRecordDateParams) {
    return this.http.put(FormRecordService.apiPrefix, params);
  }
}
