import { Component } from "@angular/core";
import { Risk } from "../../../../common/Risk";
import { RiskModalState } from "../../../service/ModalService";

@Component({
  selector: 'risk-modal',
  templateUrl: './riskModal.component.html',
  styles: [`
	.rotate {
		/* FF3.5+ */
		-moz-transform: rotate(-90.0deg);
		/* Opera 10.5 */
		-o-transform: rotate(-90.0deg);
		/* Saf3.1+, Chrome */
		-webkit-transform: rotate(-90.0deg);
		/* IE6,IE7 */
		filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.083);
		/* IE8 */
		-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)";
		/* Standard */
		transform: rotate(-90.0deg);
	}
  `]
})
export class RiskModalComponent {
  public value: string = '';

  public likelihoodOptions = Risk.LikelihoodOptions.reverse();
  public likelihoodOptionCount = Risk.LikelihoodOptions.length;

  public severityOptions = Risk.SeverityOptions.reverse();
  public severityOptionCount = Risk.SeverityOptions.length;

  constructor(private state: RiskModalState) {
  }

  ok() {
    this.state.modal.close();
  }

  /**
   * @description Returns the risk text for any given combination of severity and likelihood
   * @param {number} likelihoodId 
   * @param {number} severityId 
   * @returns {string}
   */
  riskText(likelihoodId: number, severityId: number):string {
	const riskData = Risk.calculateRisk(likelihoodId, severityId);
	return Risk.getEnumKeyById(riskData.id, Risk.Risk) || '';
  }
}
