import { AbstractControl } from "@angular/forms";
import { EmailRegex } from "../../common/constants";

export function emailValidator(control: AbstractControl): {[key: string]: any} | null {
  if (isEmptyInputValue(control.value)) { // if nothing has been passed then let other validator catch it
    return null;
  }
  const isValidEmail = EmailRegex.test(control.value.trim());
  return isValidEmail ? null : {'email': {value: control.value}};
}

export function trimRequiredValidator(control: AbstractControl): {[key: string]: any} | null {
  const isValid = !!(control.value && typeof control.value === 'string' && control.value.trim().length > 0);
  return isValid ? null : {'required': {value: control.value}};
}

function isEmptyInputValue(value: any): boolean {
  return value == null || value.length === 0 || (typeof value === 'string' && value.trim().length === 0);
}

export function validateConfirmPassword(control: AbstractControl): {[key: string]: any} | null {
  const passwordControl = control.root.get('password');
  if (!passwordControl) {
    return null;
  }
  if (passwordControl.value !== control.value) {
    return {
      confirmError: true,
    }
  }
  return null;
}
