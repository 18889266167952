import { OnInit, EventEmitter, OnChanges } from '@angular/core';
import { Session } from "../../service/util/Session";
import { FormField } from "../../model/Form";
import { Select2Component } from 'ng2-select2';
import { TagsService } from "../../service/admin/TagsService";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import { CurrentUserService } from '../../service/currentUser/CurrentUserService';
import { logger } from "../../service/util/Logger";
var TagSelectComponent = /** @class */ (function () {
    function TagSelectComponent(TagsService, session, errorHandler, currentUserService) {
        this.TagsService = TagsService;
        this.session = session;
        this.errorHandler = errorHandler;
        this.currentUserService = currentUserService;
        this._value = '';
        // Implies a read only state
        this.disabled = false;
        // /**
        //  * Restrict available tags to the tags available to the current user
        //  */
        // @Input()
        // restrictTags: boolean = false;
        this.isMeta = true;
        this.multiple = false;
        this.defaultValue = '';
        this.tagSelectData = [];
        this.change = new EventEmitter();
    }
    Object.defineProperty(TagSelectComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        /**
         * By preventing assigning non-unique values during the set
         * we can prevent a loop of observable subscribers
         */
        set: function (newValue) {
            this._value = newValue;
            if (!this.field && !this.multiple) {
                this.defaultValue = this._value;
            }
        },
        enumerable: true,
        configurable: true
    });
    TagSelectComponent.prototype.ngOnInit = function () {
        this.init();
    };
    TagSelectComponent.prototype.ngOnChanges = function () {
        this.init();
    };
    TagSelectComponent.prototype.init = function () {
        var _this = this;
        this.tagSelectOptions = { allowClear: !this.multiple, placeholder: this.placeholder || "Select tag(s)", multiple: this.multiple };
        this.session.lockInputRx(this.TagsService.getTags())
            .subscribe(function (items) {
            var newSelectOptions = [{ id: "", text: "" }];
            items = items.filter(function (tag) {
                return (tag.isMeta === _this.isMeta);
            });
            items.forEach(function (tag) { return newSelectOptions.push({ id: String(tag.id), text: tag.name }); });
            _this.tagSelectData = newSelectOptions;
            //Force the change detection to cycle again to prevent race
            if (_this.field) {
                if (String(_this.field.value) !== _this.defaultValue)
                    _this.defaultValue = String(_this.field.value);
            }
            else {
                //If the default value was manually set we need to re-trigger the process
                if (_this._value !== '') {
                    var valueArr_1 = _this.multiple && _this._value && _this._value.length ? _this._value.split(",") : undefined;
                    //this.defaultValue = (this.value);
                    var options = _this.tagSelectData.filter(function (o) { return (_this.multiple ? (valueArr_1 ? valueArr_1.find(function (selectedValue) { return selectedValue === o.id; }) : false) : o.id === _this._value); });
                    if (options && options.length) {
                        options.map(function (opt) { return opt.selected = true; });
                    }
                }
            }
        }, function (err) { return _this.errorHandler.handleHttpError(err); });
    };
    TagSelectComponent.prototype.valueChanged = function (selectedOpts) {
        if (selectedOpts.length === 0 || (selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0)) {
            if (this.field)
                this.field.value = null;
        }
        if (selectedOpts.length > 1 && !this.multiple)
            throw ("Selected options unexpectedly contained multiple results");
        if (selectedOpts.length === 1 && !this.multiple) {
            if (this.field) {
                this.field.value = selectedOpts[0].id;
            }
            else {
                if (this._value !== selectedOpts[0].id) { // if the value has been changed - emit event
                    this._value = selectedOpts[0].id;
                    this.change.emit(selectedOpts);
                    return;
                }
            }
        }
        if (this.multiple) {
            var actualSelected = selectedOpts.filter(function (opt) { return opt.id && opt.id.length; });
            var newValue = actualSelected.map(function (opt) { return opt.id; }).join(",");
            if (this.field) {
                this.field.value = newValue;
            }
            else {
                logger.silly("TagSelectComponent: Checking internalValue change Value[(" + typeof newValue + ") " + newValue + "] !== _value[(" + typeof this._value + ") " + this._value + "]");
                if (this._value !== newValue) { // if the value has been changed - emit event
                    logger.silly("TagSelectComponent: Updating Internal Value");
                    this._value = newValue;
                    this.change.emit(actualSelected);
                }
            }
        }
    };
    return TagSelectComponent;
}());
export { TagSelectComponent };
