var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import * as moment from 'moment';
import "moment-timezone";
import { FormField, RecordParamType } from "../../../../../model/Form";
import { FormComponent } from "../../../../../model/FormComponent";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormService } from "../../../../../service/FormService";
import { CategoryService } from "../../../../../service/CategoryService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { Router } from "@angular/router";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { environment } from '../../../../../environments/environment';
var AuditFormStage1Component = /** @class */ (function (_super) {
    __extends(AuditFormStage1Component, _super);
    function AuditFormStage1Component(session, currentUserService, formService, categoryService, formRecordService, groupsService, errorHandler, router) {
        var _this = _super.call(this, router) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formService = formService;
        _this.categoryService = categoryService;
        _this.formRecordService = formRecordService;
        _this.groupsService = groupsService;
        _this.errorHandler = errorHandler;
        _this.router = router;
        _this.thisStage = 1;
        // Existing Form Data
        _this.readOnly = false;
        _this.hideHeader = false;
        _this.facilityOptions = [
            {
                text: 'Facility Wide',
                value: 'facilitywide',
            },
            {
                text: 'Part of Facility',
                value: 'partoffacility'
            }
        ];
        _this.form = {
            facilityOption: new FormField(_this.facilityOptions[0].value, {
                recordParamType: RecordParamType.String
            }),
            facilityDetail: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.String
            }),
            summary: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank,
                recordParamType: RecordParamType.String
            }),
            actionSummary: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank,
                recordParamType: RecordParamType.String
            }),
            anyActions: new FormField(true, {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Boolean
            }),
            actionsResolved: new FormField(true, {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Boolean
            }),
            followUps: new FormField('[]', {
                nullEquivilent: "[]",
                validation: function (value) {
                    return _this.followUpWidgetRef ? _this.followUpWidgetRef.validate() : true;
                },
                recordParamType: RecordParamType.JSON
            }),
            reassign: new FormField(false, {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Boolean
            }),
            reassignToUserId: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Number
            })
        };
        _this.documents = [];
        return _this;
    }
    AuditFormStage1Component.prototype.registerFormFields = function () {
        var _this = this;
        var _a;
        (_a = this.formFields).push.apply(_a, Object.keys(this.form).map(function (k) { return _this.form[k]; }));
    };
    AuditFormStage1Component.prototype.onSubmit = function (isDraft) {
        var _this = this;
        this.session.lockInput(function () {
            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var success, fail, stage, assignedUserId, userGroupId, currentUserId, lastSubmission, assignBack, reassignProperty, groups, adminGroup, formFunc;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            success = function () {
                                resolve(null);
                                _this.router.navigate(['/dashboard']);
                            };
                            fail = function (msg, err) {
                                console.error(msg, err);
                                _this.errorHandler.handleHttpError(err);
                                reject();
                            };
                            stage = this.thisStage;
                            assignedUserId = this.formData.assignedUserId;
                            userGroupId = this.formData.userGroupId;
                            currentUserId = this.currentUserService.getCurrentUserIdOrFail();
                            if (!isDraft) return [3 /*break*/, 1];
                            assignedUserId = currentUserId;
                            return [3 /*break*/, 5];
                        case 1:
                            // The audit form is now going to stage 1 for action
                            stage = this.thisStage + 1;
                            if (!(this.form.reassign.value && this.form.reassignToUserId.value)) return [3 /*break*/, 2];
                            stage = this.thisStage;
                            assignedUserId = Number(this.form.reassignToUserId.value);
                            return [3 /*break*/, 5];
                        case 2:
                            lastSubmission = this.getMostRecentSubmission(this.formData);
                            assignBack = false;
                            if (lastSubmission && lastSubmission.stage === this.thisStage
                                && lastSubmission.createdById !== assignedUserId
                                && lastSubmission.createdById !== currentUserId) {
                                reassignProperty = lastSubmission.properties.find(function (property) { return property.property.name === 'reassignToUserId'; });
                                if (reassignProperty && reassignProperty.intData === assignedUserId)
                                    assignBack = true;
                            }
                            if (!(lastSubmission && assignBack)) return [3 /*break*/, 3];
                            //Assign the submission back to that user, leaving the form in stage 1
                            stage = this.thisStage;
                            assignedUserId = lastSubmission.createdById;
                            return [3 /*break*/, 5];
                        case 3:
                            stage = this.thisStage + 1;
                            // Assign it to senior management
                            assignedUserId = null;
                            return [4 /*yield*/, this.groupsService.getGroups().toPromise()];
                        case 4:
                            groups = _a.sent();
                            adminGroup = groups.find(function (group) { return group.groupName.match(/^senior management/gi) !== null; });
                            if (adminGroup)
                                userGroupId = adminGroup.id;
                            else
                                userGroupId = null;
                            _a.label = 5;
                        case 5:
                            formFunc = this.formService.updateForm({
                                id: this.formData.id,
                                stage: stage,
                                userGroupId: userGroupId,
                                assignedUserId: assignedUserId
                            });
                            formFunc.subscribe(function (data) {
                                var formId = _this.formData.id || data['id'];
                                var properties = _this.toRecordParams(_this.form);
                                _this.formRecordService.createRecord({
                                    formId: formId,
                                    // Intentionally cast the properties object since we know its correct
                                    properties: properties,
                                    stage: _this.thisStage,
                                    documents: _this.documents.map(function (doc) { return ({ id: doc.id, isTicked: !!doc.isTicked }); }),
                                    isComplete: !isDraft
                                })
                                    .subscribe(success, function (err) { return fail('Error while creating a record', err); });
                            }, function (err) { return fail('Error while create/update form', err); });
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    AuditFormStage1Component.prototype.ngOnInit = function () {
        this.dateString = moment().tz(environment.timeZone).format("DD-MM-YYYY");
        this.registerFormFields();
        this.loadEnums();
        this.repopulateFormFromData();
    };
    AuditFormStage1Component.prototype.repopulateFormFromData = function () {
        var _this = this;
        if (!this.formData || !this.formData.records || !this.formData.records.length)
            return;
        var stageRecords = this.formData.records.filter(function (record) { return record.stage === _this.thisStage; });
        if (!this.sequence) {
            /**
             * Get the most recent submission (including drafts)
             *
             * if the most recent submission is completed
             *  get the follow ups from the competed submission
             *  return
             *
             * If the most recent submission is a draft
             *  get the most recent completed submission
             *    update the follow ups from the completed submission
             *  if the most recent submission is for a different stage
             *    return
             *  use the most recent submission as the target record
             *  end
             */
            var lastSubmission = this.getMostRecentSubmission(this.formData, true);
            if (!lastSubmission)
                return;
            if (lastSubmission.isComplete) {
                var followUps = this.getJsonData(lastSubmission, "followUps");
                if (followUps) {
                    this.form.followUps.value = followUps;
                    this.form.actionsResolved.value = false;
                }
                return;
            }
            var lastCompletedSubmission = this.getMostRecentSubmission(this.formData);
            if (lastCompletedSubmission) {
                var followUps = this.getJsonData(lastCompletedSubmission, "followUps");
                if (followUps) {
                    this.form.followUps.value = followUps;
                    this.form.actionsResolved.value = false;
                }
            }
            if (lastSubmission.stage !== this.thisStage)
                return;
            this.formRecord = lastSubmission;
        }
        else {
            var targetRecord = stageRecords.find(function (record) { return record.sequence === _this.sequence; });
            if (!targetRecord)
                throw new Error("Invalid Record Sequence");
            this.formRecord = targetRecord;
        }
        this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);
        this.updateFromRecordParams(this.form, this.formRecord);
        // get attached documents
        this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
        this.showReassignOptions();
        this.updateActionOptions();
    };
    // Audit Stage 0 Functions
    AuditFormStage1Component.prototype.loadEnums = function () {
        var _this = this;
        this.session.lockInputRx(this.categoryService.getCategories())
            .subscribe(function (data) {
            var reportCategory = data.find(function (data) { return !!data.name.match(/^Audit/); });
            if (reportCategory)
                _this.formCategoryId = reportCategory.id;
            //else
            //Notify the user an error has occurred
        }, function (err) {
            console.error('Error while getting categories', err);
            _this.errorHandler.handleHttpError(err);
        });
    };
    AuditFormStage1Component.prototype.updateActionOptions = function (state) {
        if (state !== undefined && state !== null)
            this.form.anyActions.value = state;
        else
            state = this.form.anyActions.value;
        if (state) {
            this.setFieldValidation(this.form.actionSummary, FormField.ValidationMethods.IsNotBlank);
        }
        else {
            this.setFieldValidation(this.form.actionSummary, FormField.ValidationMethods.None);
        }
    };
    AuditFormStage1Component.prototype.showReassignOptions = function (state) {
        if (state !== undefined && state !== null)
            this.form.reassign.value = state;
        else
            state = this.form.reassign.value;
        if (state) {
            this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.IsNotBlank);
            this.setFieldValidation(this.form.summary, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.actionSummary, FormField.ValidationMethods.None);
        }
        else {
            this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.summary, FormField.ValidationMethods.IsNotBlank);
            this.setFieldValidation(this.form.actionSummary, FormField.ValidationMethods.IsNotBlank);
        }
    };
    return AuditFormStage1Component;
}(FormComponent));
export { AuditFormStage1Component };
