import { logger } from './Logger';
/**
 * Due to observed data matching the below formats, a consistent function is required to output a reliable format.
 * The input value may contain any of the following formats
 * 1,2,3
 * ["1","2","3"]
 * 1
 * (blank string)
 * 
 * @param {string} The unknown format number string
 * @returns number[]
 */
 export const numStrToArr = (input:string):number[] => {
     const signature = "converter.numStrToArr: ";
     if( !input ) return [];
     if( typeof input === 'number' ) return [input];
     if( input.length === 0 ) return [];
     let tmpNum:number, i:number, tmpVal:unknown, numArr:unknown[];
     if( input.match(/\["/) ) {
        numArr = JSON.parse(input);
     } else if( input.match(/[0-9]+,/) ) {
        numArr = input.split(",");
     } else {
         numArr = [input];
     }
     const result:number[] = [];
     for(i = 0; i < numArr.length; i++) {
        tmpVal = numArr[i];
        try {
            tmpNum = Number(tmpVal);
            if( isNaN(tmpNum) ) {
                const msg = `Could not convert value[${tmpVal}] to a Number`;
                logger.info(signature+msg);
                throw new Error(msg);
            } else {
                result.push(tmpNum);
            }
        } catch (e) {
            logger.error(signature+`Error converting value[${tmpVal}] to a Number`);
            throw e;
        }
    }
    return result;
 }