<div class="modal-header">
    <h4 class="modal-title">{{options.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="ok()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p [innerHTML]="options.message"></p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="ok()" ngbAutofocus>Okay</button>
</div>
