// @dynamic
var Utils = /** @class */ (function () {
    function Utils() {
    }
    /** This returns a range of numbers. Starts from 0 if 'startFrom' is not set */
    Utils.getRange = function (startFrom, until) {
        return Array.from({ length: (until + 1 - startFrom) }, function (_, k) { return k + startFrom; });
    };
    return Utils;
}());
export default Utils;
