var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import "moment-timezone";
import { FormService } from "../../../../service/FormService";
import { Session } from "../../../../service/util/Session";
import { CurrentUserService } from "../../../../service/currentUser/CurrentUserService";
import { PersistentStorageService } from "../../../../service/PersistentStorageService";
import { TruncatePipe } from "../../../../pipes/truncate.pipe";
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
import { stringifyObjectValues } from "../../../../service/util/stringifyObjectValues";
import { UserSelectComponent } from "../../../shared/userSelect.component";
import { ReportService } from "../../../../service/ReportService";
import { CategoryService } from "../../../../service/CategoryService";
import { logger } from "service/util/Logger";
import { DocumentIntanceService } from "service/admin/DocumentInstanceService";
import { FormField } from "model/Form";
import { environment } from "../../../../environments/environment";
import { NgbTabset } from "@ng-bootstrap/ng-bootstrap";
/**
 * Filtering Requirements:
 *  only able to see reassigned items when clear name. More detail in email sent 10 June.
 * seeing reassigned tasks in department when on own user filter
 *
 * Administrator
 *
 * Default View:
 * All items assigned to their name
 * All items assigned to their department(s) and location(s) that have not been assigned to anyone
 *
 * Unfiltered View:
 * All items assigned to their location(s) (all departments), regardless of whether or not they have been assigned to anyone
 *
 * Manager:
 * Default View:
 * All items assigned to their name
 * All items assigned to their department(s) AND location(s) that have not been assigned to anyone
 *
 * Unfiltered View:
 * All items assigned to their department(s) AND location(s), regardless of whether or not they have been assigned to anyone
 *
 * User:
 * Default View:
 * All items assigned to their name
 *
 * Unfiltered View:
 * All items assigned to their name
 */
var DashboardRow = /** @class */ (function () {
    function DashboardRow() {
    }
    return DashboardRow;
}());
var AprovedDocumentRow = /** @class */ (function () {
    function AprovedDocumentRow() {
    }
    return AprovedDocumentRow;
}());
var className = "DRMDashboardComponent";
var DRMDashboardComponent = /** @class */ (function () {
    function DRMDashboardComponent(formService, categoryService, session, router, currentUserService, route, pss, truncatePipe, errorHandler, reportService, documentService) {
        this.formService = formService;
        this.categoryService = categoryService;
        this.session = session;
        this.router = router;
        this.currentUserService = currentUserService;
        this.route = route;
        this.pss = pss;
        this.truncatePipe = truncatePipe;
        this.errorHandler = errorHandler;
        this.reportService = reportService;
        this.documentService = documentService;
        this.dashboardRecords = [];
        this.aprovedDocuments = [];
        this.alertCount = 0;
        this.warningCount = 0;
        this.taskCount = 0;
        this.notificationCount = 0;
        this.tab = 'Tasks';
        this.formsQueryParams = {
            skip: 0,
            limit: 10,
            sortBy: 'dueAt',
            order: 'asc',
            assignedUserId: undefined,
            locationId: undefined,
            showAll: 'active',
            dueFilter: undefined,
            createdAtStart: undefined,
            createdAtEnd: undefined,
            dueAtStart: undefined,
            dueAtEnd: undefined,
            search: undefined,
            category: undefined,
        };
        this.documentInstanceQueryParams = {
            skip: 0,
            limit: 10,
            sortBy: 'dueAt',
            order: 'asc',
            locationId: undefined,
            lastUpdatedAtStart: undefined,
            lastUpdatedAtEnd: undefined,
            search: undefined,
            category: undefined,
            standard: undefined,
            tags: undefined,
            metaTags: '',
            metaTagValue: ''
        };
        this.documentStandard = new FormField('', {});
        this.currentPage = 1;
        this.pageSize = this.pss.pageSize;
        this.showAllOptions = [
            { value: 'all', text: 'All' },
            { value: 'complete', text: 'Complete' },
            { value: 'active', text: 'Active' },
        ];
        this.searchFilter = '';
        this.originatorFilter = '';
        this.dateFilterMap = {
            none: 'Filter By Date',
            createdAt: 'Created At',
            dueAt: 'Due Date',
        };
        this.metaTagValueModel = '';
        this.selectedDateFilter = 'none';
        this.selectedCategoryFilter = null;
        this.filterStartDate = '';
        this.filterEndDate = '';
        this.currentMetaTag = { id: '', value: '', name: '' };
        this.metaTags = [];
        this.includeArchived = false;
        var signature = className + ".constructor: ";
        logger.silly(signature + 'Started');
    }
    DRMDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        var signature = className + ".ngOnInit: ";
        logger.silly("Dashboard Component Init");
        this.isAdmin = this.currentUserService.isAdministrator.getValue();
        this.isAdminOrManager = this.isAdmin || this.currentUserService.userData.role === 'manager';
        logger.silly(signature + ("isAdminOrManager[" + this.isAdminOrManager + "]"));
        if (this.isAdminOrManager) {
            this.currentUserId = this.currentUserService.currentUserId.getValue();
            this.defaultAssignedUserId = this.currentUserId.toString();
            this.defaultAssignedGroupId =
                (this.currentUserService.currentUserData.value.groups || [])
                    .map(function (group) { return group.id.toString(); })
                    .join(",");
            this.defaultAssignedLocationId =
                (this.currentUserService.currentUserData.value.locations || [])
                    .map(function (location) { return location.id.toString(); })
                    .join(",");
            logger.silly(signature + ("defaultAssignedLocationId[" + this.defaultAssignedLocationId + "] defaultAssignedGroupId[" + this.defaultAssignedGroupId + "] defaultAssignedUserId[" + this.defaultAssignedUserId + "]"));
        }
        if (this.isAdmin) {
            this.showAllOptions.splice(2, 0, { value: 'deleted', text: 'Deleted' });
        }
        var allowedShowAllValues = this.showAllOptions.map(function (v) { return v.value; });
        /**
         * all filtering, pagination should go through query params
         */
        this.route.queryParams.subscribe(function (queryParams) {
            logger.silly(signature + ("QueryParams[" + JSON.stringify(queryParams) + "] Changed"));
            /**
             * if page param is undefined push default query params
             */
            if (!queryParams.page || !queryParams.category) {
                _this.pushDefaultQueryParams();
                return;
            }
            if (queryParams.category) {
                _this.formsQueryParams.category = queryParams.category;
            }
            _this.currentPage = parseInt(queryParams.page, 10);
            logger.silly(signature + ("Set currentPage[" + _this.currentPage + "]"));
            // Defer setting the current page due to an observed race condition occurring occasionally
            setTimeout(function () {
                _this.currentPage = parseInt(queryParams.page, 10);
                logger.silly(signature + ("(Deferred) Set currentPage[" + _this.currentPage + "]"));
            }, 200);
            if (isNaN(_this.currentPage) || _this.currentPage <= 0) {
                _this.pushDefaultQueryParams();
                return;
            }
            if (_this.isAdminOrManager) {
                if (queryParams.assignedUser) {
                    _this.formsQueryParams.assignedUserId = parseInt(queryParams.assignedUser, 10) || undefined;
                    if (!_this.userSelectorRef && _this.formsQueryParams.assignedUserId) { // fix for recovering user filter after page refresh
                        _this.defaultAssignedUserId = _this.formsQueryParams.assignedUserId.toString();
                    }
                    else if (_this.formsQueryParams.assignedUserId &&
                        (_this.userSelectorRef.selectedUserId ? _this.userSelectorRef.selectedUserId.toString() : '') !== (_this.formsQueryParams.assignedUserId ? _this.formsQueryParams.assignedUserId.toString() : '')) {
                        _this.userSelectorRef.changeSelectedUser(_this.formsQueryParams.assignedUserId);
                    }
                }
                logger.silly("Processing params.assignedUser[(" + typeof queryParams.assignedUser + ") " + queryParams.assignedUser + "] into assignedUserId[" + _this.formsQueryParams.assignedUserId + "]");
                /**
                 * This cannot rely on a truthy assertion as params.group
                 * will return false when it is a blank string
                 */
                if (typeof queryParams.group === 'string') {
                    _this.formsQueryParams.groupId = queryParams.group;
                }
                else {
                    logger.silly('Setting queryParams.groupId to defaultAssignedGroupId');
                    _this.formsQueryParams.groupId = _this.defaultAssignedGroupId;
                }
                logger.silly("Processing params.group[(" + typeof queryParams.group + ") " + queryParams.group + "] into groupId[" + _this.formsQueryParams.groupId + "]");
                if (queryParams.showAll && allowedShowAllValues.includes(queryParams.showAll)) {
                    _this.formsQueryParams.showAll = queryParams.showAll;
                }
                else {
                    _this.formsQueryParams.showAll = 'active';
                }
            }
            if (queryParams.sortBy) {
                _this.formsQueryParams.sortBy = queryParams.sortBy;
            }
            else {
                _this.formsQueryParams.sortBy = 'dueAt';
            }
            if (queryParams.order) {
                _this.formsQueryParams.order = queryParams.order;
            }
            else {
                _this.formsQueryParams.order = 'asc';
            }
            var startDateFound = false;
            var endDateFound = false;
            ['createdAtStart', 'createdAtEnd', 'dueAtStart', 'dueAtEnd'].forEach(function (dateParam) {
                _this.formsQueryParams[dateParam] = queryParams[dateParam] || undefined;
                if (queryParams[dateParam]) {
                    if (dateParam.startsWith('createdAt')) {
                        _this.selectedDateFilter = 'createdAt';
                    }
                    else if (dateParam.startsWith('dueAt')) {
                        _this.selectedDateFilter = 'dueAt';
                    }
                    if (dateParam.endsWith('Start')) {
                        startDateFound = true;
                        _this.filterStartDate = queryParams[dateParam].replace(/-/g, '/');
                    }
                    else if (dateParam.endsWith('End')) {
                        endDateFound = true;
                        _this.filterEndDate = queryParams[dateParam].replace(/-/g, '/');
                    }
                }
            });
            if (!startDateFound) {
                _this.filterStartDate = '';
            }
            if (!endDateFound) {
                _this.filterEndDate = '';
            }
            if (!startDateFound && !endDateFound) {
                _this.selectedDateFilter = 'none';
            }
            if (queryParams.showOnly && ['overdue', 'alert', 'remaining'].includes(queryParams.showOnly)) {
                _this.formsQueryParams.dueFilter = queryParams.showOnly;
            }
            else {
                delete _this.formsQueryParams.dueFilter;
            }
            if (queryParams.search && queryParams.search.trim()) {
                _this.formsQueryParams.search = queryParams.search.trim();
                _this.searchFilter = queryParams.search.trim();
            }
            if (queryParams.originatorName && queryParams.originatorName.trim()) {
                _this.formsQueryParams.originatorName = queryParams.originatorName.trim();
                _this.originatorFilter = queryParams.originatorName.trim();
            }
            if (queryParams.reportFormType) {
                _this.formsQueryParams.reportFormType = parseInt(queryParams.reportFormType, 10) || undefined;
            }
            else {
                delete _this.formsQueryParams.reportFormType;
            }
            if (queryParams.includeArchived) {
                _this.documentInstanceQueryParams.includeArchived = queryParams.includeArchived === 'true' ? true : false;
            }
            _this.formsQueryParams.skip = (_this.currentPage - 1) * _this.pageSize;
            _this.formsQueryParams.limit = _this.pageSize;
            _this.documentInstanceQueryParams.skip = (_this.currentPage - 1) * _this.pageSize;
            _this.documentInstanceQueryParams.limit = _this.pageSize;
            _this.loadForms();
            _this.loadDocuments();
        });
    };
    DRMDashboardComponent.prototype.handlePageChange = function () {
        var signature = className + '.handlePageChange: ';
        logger.silly(signature + 'Started');
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.handlePageChangeDocumentSearch = function () {
        this.pushDocumentInstanceQueryParams();
    };
    DRMDashboardComponent.prototype.handlePageSizeChange = function (pageSize) {
        var signature = className + '.handlePageSizeChange: ';
        this.pageSize = pageSize;
        if (this.currentPage === 1) {
            this.formsQueryParams.skip = 0;
            this.formsQueryParams.limit = this.pageSize;
            this.loadForms();
        }
        else {
            logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
            this.currentPage = 1;
            this.handlePageChange();
        }
    };
    DRMDashboardComponent.prototype.handlePageSizeChangeDocumentSearch = function (pageSize) {
        var signature = className + '.handlePageSizeChangeDocumentSearch: ';
        this.pageSize = pageSize;
        if (this.currentPage === 1) {
            this.documentInstanceQueryParams.skip = 0;
            this.documentInstanceQueryParams.limit = this.pageSize;
            this.loadDocuments();
        }
        else {
            logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
            this.currentPage = 1;
            this.handlePageChangeDocumentSearch();
        }
    };
    DRMDashboardComponent.prototype.pushQueryParams = function () {
        var signature = className + ".pushQueryParams: ";
        if (this.tab !== 'Tasks') {
            logger.info(signature + ("Ignoring due to Tab[" + this.tab + "]"));
            return;
        }
        var queryParams = stringifyObjectValues({
            page: this.currentPage,
            assignedUser: this.isAdminOrManager ? this.formsQueryParams.assignedUserId : undefined,
            group: this.isAdminOrManager ? (this.formsQueryParams.groupId || undefined) : undefined,
            showAll: this.isAdminOrManager ? (this.formsQueryParams.showAll || undefined) : undefined,
            showOnly: this.formsQueryParams.dueFilter || undefined,
            sortBy: this.formsQueryParams.sortBy,
            order: this.formsQueryParams.order,
            createdAtStart: this.formsQueryParams.createdAtStart || undefined,
            createdAtEnd: this.formsQueryParams.createdAtEnd || undefined,
            dueAtStart: this.formsQueryParams.dueAtStart || undefined,
            dueAtEnd: this.formsQueryParams.dueAtEnd || undefined,
            search: this.formsQueryParams.search || undefined,
            category: this.formsQueryParams.category || undefined,
        });
        if (this.isAdminOrManager &&
            typeof this.formsQueryParams.groupId === 'string' &&
            this.formsQueryParams.groupId.length === 0)
            queryParams.group = '';
        logger.silly("Pushing QueryParams", queryParams);
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: '',
        });
    };
    DRMDashboardComponent.prototype.pushDefaultQueryParams = function () {
        var _this = this;
        var signature = className + ".pushDefaultQueryParams: ";
        logger.silly(signature + "Started");
        // Setting DRM-Task as default category
        this.categoryService.getCategories().subscribe(function (categories) { return __awaiter(_this, void 0, void 0, function () {
            var drmTaskCategory, paramValues, queryParams;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        drmTaskCategory = categories.find(function (cat) { return cat.name === 'DRM-Task'; });
                        paramValues = {
                            page: 1,
                            assignedUser: this.defaultAssignedUserId,
                            showAll: this.isAdminOrManager ? 'active' : undefined,
                            sortBy: 'dueAt',
                            order: 'asc',
                            category: drmTaskCategory && drmTaskCategory.id
                        };
                        logger.silly(signature + ("Appending Default QueryParams[" + JSON.stringify(paramValues) + "] to Url"));
                        queryParams = stringifyObjectValues(paramValues);
                        return [4 /*yield*/, this.router.navigate(['.'], {
                                relativeTo: this.route,
                                replaceUrl: true,
                                queryParams: queryParams,
                                queryParamsHandling: '',
                            })];
                    case 1:
                        _a.sent();
                        logger.silly(signature + "Completed");
                        return [2 /*return*/];
                }
            });
        }); });
    };
    DRMDashboardComponent.prototype.handleUserFilterChange = function (selected) {
        var signature = className + ".handleUserFilterChange: ";
        if (!selected || selected.length === 0) {
            delete this.formsQueryParams.assignedUserId;
        }
        else {
            this.formsQueryParams.assignedUserId = selected[0].id;
        }
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.handleShowAllFilterChange = function (option) {
        var signature = className + ".handleShowAllFilterChange: ";
        this.formsQueryParams.showAll = option;
        delete this.formsQueryParams.dueFilter;
        /**
         * if sorting by archived date was active and a user switched to active filter, turn sort by dueAt as default
         */
        if (this.formsQueryParams.sortBy === 'archivedAt' && option === 'active') {
            this.formsQueryParams.sortBy = 'dueAt';
            this.formsQueryParams.order = 'asc';
        }
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.handleGroupFilterChange = function (selected) {
        var signature = className + ".handleGroupFilterChange: ";
        console.log('event: handleGroupFilterChange');
        if (!selected || selected.length === 0) {
            logger.silly("Updated QueryParam: groupId: [blank string]");
            this.formsQueryParams.groupId = '';
        }
        else {
            var oldValue = this.formsQueryParams.groupId;
            var newValue = selected.map(function (item) { return item.id; }).filter(function (id) { return id && id.length; }).join(",");
            logger.silly("Updated QueryParam: groupId: Old[" + oldValue + "] New[" + newValue + "]");
            this.formsQueryParams.groupId = newValue;
        }
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.handleCategoryFilterChange = function (selected) {
        var signature = className + ".handleCategoryFilterChange: ";
        if (!selected || selected.length === 0) {
            this.formsQueryParams.category = undefined;
        }
        else {
            this.formsQueryParams.category = selected[0].id;
        }
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.handleReportFormTypeChange = function (selected) {
        if (!selected || selected.length === 0) {
            this.formsQueryParams.reportFormType = undefined;
        }
        else {
            this.formsQueryParams.reportFormType = selected[0].id;
        }
        this.currentPage = 1;
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.loadForms = function () {
        var _this = this;
        this.session.lockInputRx(this.formService.getForms(this.formsQueryParams))
            .subscribe(function (data) {
            var dashboardRecords = [];
            _this.totalForms = data.totalCount;
            data.items.forEach(function (formItem) {
                var formType = formItem["category"]["name"];
                if (formType === 'Report')
                    formType = 'Feedback';
                var createdDate = new Date(formItem["createdAt"]);
                var dueDate = formItem["dueAt"] ? new Date(formItem["dueAt"]) : null;
                var title = "" + _this.truncatePipe.transform(formItem.summary || 'unknown');
                dashboardRecords.push({
                    title: title,
                    formType: formType,
                    createdDate: createdDate,
                    dueDate: dueDate,
                    type: formType,
                    isAlert: !!formItem.isOverdue,
                    isWarning: !!formItem.isAlertOverdue,
                    isFinalized: !!formItem.archivedAt,
                    id: formItem["id"],
                    stage: formItem["stage"],
                    archivedAtDateString: formItem["archivedAt"] ? moment(formItem["archivedAt"]).tz(environment.timeZone).format("DD-MM-YY") : '',
                    createdAtDateString: moment(createdDate).tz(environment.timeZone).format("DD-MM-YY"),
                    dueDateString: dueDate ? moment(dueDate).tz(environment.timeZone).format("DD-MM-YY") : '',
                    assignedUser: formItem.assignedUser ? formItem.assignedUser.firstName + " " + formItem.assignedUser.lastName + " " : null,
                    assignedGroup: formItem.userGroup ? "" + formItem.userGroup.groupName : null,
                    location: formItem.formLocation ? formItem.formLocation.name : 'All Locations',
                    documentName: formItem.document && formItem.document.documentCode || ''
                });
            });
            _this.dashboardRecords = dashboardRecords;
            _this.taskCount = data.remainingTasks;
            _this.warningCount = data.alertTasks;
            _this.alertCount = data.overdueTasks;
        }, function (err) {
            console.error('Error while getting forms in dashboard', err);
            _this.errorHandler.handleHttpError(err);
        });
    };
    DRMDashboardComponent.prototype.progressForm = function (row) {
        if (row.formType === 'Report' || row.formType === 'Feedback') {
            this.router.navigateByUrl("/report/" + row.id);
        }
        else if (row.formType === 'Follow-Up') {
            this.router.navigateByUrl("/followUp/" + row.id);
        }
        else if (row.formType === 'Audit') {
            this.router.navigateByUrl("/audit/" + row.id);
        }
        else if (row.formType === 'DRM-Task') {
            this.router.navigateByUrl("/document-review-task/" + row.id);
        }
        else {
            console.error("Unknown Form Type " + row.formType + ". Unable to navigate");
        }
    };
    DRMDashboardComponent.prototype.toggleTopCardFilter = function (filter) {
        var signature = className + ".toggleTopCardFilter: ";
        if (this.formsQueryParams.dueFilter === filter) {
            this.formsQueryParams.dueFilter = undefined;
        }
        else {
            this.formsQueryParams.dueFilter = filter;
        }
        this.formsQueryParams.showAll = 'active';
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.handleSortChange = function (sortField) {
        if (sortField !== this.formsQueryParams.sortBy) {
            this.formsQueryParams.sortBy = sortField;
            this.formsQueryParams.order = 'asc';
        }
        else {
            this.formsQueryParams.order = this.formsQueryParams.order === 'asc' ? 'desc' : 'asc';
        }
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.handleDateFilterChange = function (value) {
        var signature = className + ".handleDateFilterChange: ";
        this.selectedDateFilter = value;
        this.filterStartDate = '';
        this.filterEndDate = '';
        this.formsQueryParams.createdAtStart = undefined;
        this.formsQueryParams.createdAtEnd = undefined;
        this.formsQueryParams.dueAtStart = undefined;
        this.formsQueryParams.dueAtEnd = undefined;
        if (value === 'none') {
            this.currentPage = 1;
            logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
            this.pushQueryParams();
        }
    };
    DRMDashboardComponent.prototype.handleStartDateChanged = function () {
        var signature = className + ".handleStartDateChanged: ";
        this.formsQueryParams[this.selectedDateFilter + 'Start'] = this.filterStartDate.replace(/\//g, '-');
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.handleEndDateChanged = function () {
        var signature = className + ".handleEndDateChanged: ";
        this.formsQueryParams[this.selectedDateFilter + 'End'] = this.filterEndDate.replace(/\//g, '-');
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.performSearch = function () {
        var signature = className + ".performSearch: ";
        if (this.searchFilter && this.searchFilter.trim()) {
            this.formsQueryParams.search = this.searchFilter.trim();
        }
        else {
            this.formsQueryParams.search = undefined;
        }
        if (this.originatorFilter && this.originatorFilter.trim()) {
            this.formsQueryParams.originatorName = this.originatorFilter.trim();
        }
        else {
            this.formsQueryParams.originatorName = undefined;
        }
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.clearSearch = function () {
        var signature = className + ".clearSearch: ";
        this.searchFilter = '';
        this.formsQueryParams.search = undefined;
        this.documentInstanceQueryParams.search = undefined;
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.handleCsvExport = function () {
        var _this = this;
        this.reportService.downloadDRMDashboardCsvReport(this.formsQueryParams).subscribe(function (data) {
            saveAs(data, "drm-dashboard-report-" + moment().format('YYYY-MMM-DD') + ".csv");
        }, function (err) { return _this.errorHandler.handleHttpError(err); });
    };
    DRMDashboardComponent.prototype.handleCategoryFilter = function (category) {
        this.selectedCategoryFilter = category;
        this.formsQueryParams.category = category ? category.id : undefined;
        this.pushQueryParams();
    };
    DRMDashboardComponent.prototype.mapCategoryName = function (name) {
        if (name.toLowerCase() === 'report')
            return "Feedback";
        return name;
    };
    DRMDashboardComponent.prototype.loadDocuments = function () {
        var _this = this;
        this.documentService.getAllDocuments(this.documentInstanceQueryParams).subscribe(function (data) {
            var aprovedDocuments = [];
            _this.totalDocuments = data.length;
            data.forEach(function (documentInstance) {
                var id = documentInstance.id, documentName = documentInstance.document.name, documentCode = (documentInstance.document.documentCode), documentIdentity = (documentInstance.document.documentIdentity), documentId = Number(documentInstance.document.id), documentType = documentInstance.document.type, documentCollectionName = documentInstance.document.documentCollection.name, documentCollectionId = Number(documentInstance.document.documentCollectionId), aprovedAt = documentInstance.aprovedAt, path = documentInstance.path, version = documentInstance.version;
                aprovedDocuments.push({
                    id: id,
                    documentName: documentName,
                    documentCode: documentCode,
                    documentIdentity: documentIdentity,
                    documentId: documentId,
                    documentType: documentType,
                    documentCollectionName: documentCollectionName,
                    documentCollectionId: documentCollectionId,
                    aprovedAt: aprovedAt,
                    path: path,
                    version: version
                });
            });
            _this.aprovedDocuments = aprovedDocuments;
        });
    };
    DRMDashboardComponent.prototype.downloadDocument = function (id, formName) {
        this.documentService.downloadDocument(id).subscribe(function (res) {
            saveAs(res, formName);
        });
    };
    DRMDashboardComponent.prototype.onTabChange = function (event) {
        var signature = className + ".onTabChange: ";
        logger.silly(signature + "Started");
        if (event && event.nextId === 'Search') {
            this.clearSearch();
            this.tab = 'Search';
            this.pushDocumentInstanceQueryParams();
        }
        else {
            this.clearSearch();
            this.tab = 'Tasks';
            this.pushQueryParams();
        }
    };
    DRMDashboardComponent.prototype.performDocumentSearch = function () {
        var signature = className + ".performDocumentSearch: ";
        if (this.searchFilter && this.searchFilter.trim()) {
            this.documentInstanceQueryParams.search = this.searchFilter.trim();
        }
        else {
            this.documentInstanceQueryParams.search = undefined;
        }
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushDocumentInstanceQueryParams();
    };
    DRMDashboardComponent.prototype.pushDocumentInstanceQueryParams = function () {
        var signature = className + ".pushDocumentInstanceQueryParams: ";
        if (!this.isCurrentTab('Search')) {
            logger.info(signature + ("Ignoring due to Tab[" + this.tab + "]"));
            return;
        }
        var queryParamData = {
            page: this.currentPage,
            sortBy: this.formsQueryParams.sortBy,
            order: this.formsQueryParams.order,
            lastUpdatedAtStart: this.documentInstanceQueryParams.lastUpdatedAtStart || undefined,
            lastUpdatedAtEnd: this.documentInstanceQueryParams.lastUpdatedAtEnd || undefined,
            search: this.documentInstanceQueryParams.search || undefined,
            standard: this.documentInstanceQueryParams.standard || undefined,
            category: this.formsQueryParams.category || undefined,
            tags: this.documentInstanceQueryParams.tags || undefined,
            groupId: this.documentInstanceQueryParams.groupId || undefined,
            metaTags: this.documentInstanceQueryParams.metaTags || undefined,
            metaTagValue: this.documentInstanceQueryParams.metaTagValue || undefined,
            includeArchived: this.documentInstanceQueryParams.includeArchived,
            tab: 'Search'
        };
        var queryParams = stringifyObjectValues(queryParamData);
        logger.silly(signature + ("Pushing DocumentInstanceQueryParams[" + JSON.stringify(queryParamData) + "]"));
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: '',
        });
        logger.silly(signature + 'Completed');
    };
    DRMDashboardComponent.prototype.handleStandardFilterChange = function (event) {
        var signature = className + ".handleStandardFilterChange: ";
        logger.silly(signature + "Started");
        if (!event || event.length === 0) {
            this.documentInstanceQueryParams.standard = '';
        }
        else {
            this.documentInstanceQueryParams.standard = event[0].id;
        }
        this.pushDocumentInstanceQueryParams();
    };
    DRMDashboardComponent.prototype.handleTagFilterChange = function (selected) {
        var signature = className + ".handleTagFilterChange: ";
        if (!this.isCurrentTab('Search')) {
            logger.info(signature + ("Ignoring due to Tab[" + this.tab + "]"));
            return;
        }
        logger.silly(signature + "Started");
        if (!selected || selected.length === 0) {
            logger.silly("Updated QueryParam: tags: [blank string]");
            this.documentInstanceQueryParams.tags = '';
        }
        else {
            var oldValue = this.documentInstanceQueryParams.tags;
            var newValue = selected.map(function (item) { return item.id; }).filter(function (id) { return id && id.length; }).join(",");
            logger.silly("Updated QueryParam: tags: Old[" + oldValue + "] New[" + newValue + "]");
            this.documentInstanceQueryParams.tags = newValue;
        }
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushDocumentInstanceQueryParams();
    };
    DRMDashboardComponent.prototype.handleDocumentGroupFilterChange = function (selected) {
        var signature = className + ".handleDocumentGroupFilterChange: ";
        logger.silly(signature + "Started");
        if (!selected || selected.length === 0) {
            logger.silly("Updated QueryParam: userGroup: [blank string]");
            this.documentInstanceQueryParams.groupId = '';
        }
        else {
            var oldValue = this.documentInstanceQueryParams.groupId;
            var newValue = selected.map(function (item) { return item.id; }).filter(function (id) { return id && id.length; }).join(",");
            logger.silly("Updated QueryParam: userGroup: Old[" + oldValue + "] New[" + newValue + "]");
            this.documentInstanceQueryParams.groupId = newValue;
        }
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushDocumentInstanceQueryParams();
    };
    DRMDashboardComponent.prototype.lastUpdatedAtFromFilterChange = function (event) {
        var signature = className + ".lastUpdatedAtFromFilterChange: ";
        logger.silly(signature + "Started");
        if (event === "") {
            return;
        }
        this.documentInstanceQueryParams.lastUpdatedAtStart = event;
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushDocumentInstanceQueryParams();
    };
    DRMDashboardComponent.prototype.lastUpdatedAtEndFilterChange = function (event) {
        var signature = className + ".lastUpdatedAtEndFilterChange: ";
        logger.silly(signature + "Started");
        if (event === "") {
            return;
        }
        this.documentInstanceQueryParams.lastUpdatedAtEnd = event;
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushDocumentInstanceQueryParams();
    };
    DRMDashboardComponent.prototype.handleMetaChange = function (event) {
        this.currentMetaTag.id = event[0].id;
        this.currentMetaTag.name = event[0].text;
    };
    DRMDashboardComponent.prototype.addNewMeta = function () {
        var signature = className + ".addNewMeta: ";
        logger.silly(signature + "Started");
        this.currentMetaTag.value = this.metaTagValueModel;
        this.metaTags.push({ id: this.currentMetaTag.id, value: this.metaTagValueModel, name: this.currentMetaTag.name });
        this.metaTagValueModel = '';
        this.metaTag = '';
        this.documentInstanceQueryParams.metaTags = !!this.metaTags.length ? this.metaTags.map(function (tag) { return tag.id; }).join() : undefined,
            this.documentInstanceQueryParams.metaTagValue = !!this.metaTags.length ? this.metaTags.map(function (tag) { return tag.value; }).join() : undefined;
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushDocumentInstanceQueryParams();
    };
    DRMDashboardComponent.prototype.removeMeta = function (index) {
        var signature = className + ".removeMeta: ";
        logger.silly(signature + "Started");
        this.metaTags.splice(index, 1);
        this.documentInstanceQueryParams.metaTags = !!this.metaTags.length ? this.metaTags.map(function (tag) { return tag.id; }).join() : undefined,
            this.documentInstanceQueryParams.metaTagValue = !!this.metaTags.length ? this.metaTags.map(function (tag) { return tag.value; }).join() : undefined;
        this.currentPage = 1;
        logger.silly(signature + ("Set currentPage[" + this.currentPage + "]"));
        this.pushDocumentInstanceQueryParams();
    };
    DRMDashboardComponent.prototype.handleDocumentRowClick = function (document) {
        this.router.navigateByUrl("/document-collections/" + document.documentCollectionId + "/documents/" + document.documentId);
    };
    DRMDashboardComponent.prototype.handleIncludeArchivedToggle = function () {
        var signature = className + ".handleIncludeArchivedToggle: ";
        logger.silly(signature + "Started");
        this.includeArchived = !this.includeArchived;
        this.documentInstanceQueryParams.includeArchived = this.includeArchived;
        this.pushDocumentInstanceQueryParams();
    };
    DRMDashboardComponent.prototype.isCurrentTab = function (currentTab) {
        var signature = className + ".isCurrentTab: ";
        logger.silly(signature + "Started");
        return this.tab === currentTab;
    };
    return DRMDashboardComponent;
}());
export { DRMDashboardComponent };
