import {Component, Input, OnInit} from "@angular/core";
import * as moment from 'moment';
import "moment-timezone";
import {IFormRecordOutputModel} from "../../../common/contracts/form";
import {CurrentUserService} from "../../service/currentUser/CurrentUserService";
import { FormRecordService } from "../../service/FormRecordService";
import { ModalService } from "../../service/ModalService";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import { environment } from "../../environments/environment";

@Component({
  selector: 'submission-detail',
  styles: [],
  template: `
    <div class="row bg-default my-3 py-4">
        <div class="col-12 offset-0 col-lg-4 offset-lg-2 offset-xl-0 p-1 text-center mt-lg-2 mt-xl-0">
            <span>Updated By: <span class="text-primary">{{ userString }}</span></span>
        </div>
        <div class="col-12 col-lg-5 p-1 text-center mt-lg-2 mt-xl-0">
            <span>Date Updated: <span class="text-primary">{{ dateString }}</span></span>
            <i class="fa fa-pencil text-primary hover-cursor ml-3" *ngIf="isDateEditable" (click)="updateRecordDate()"></i>
        </div>
    </div>
  `
})
export class SubmissionDetailComponent implements OnInit {

  _formRecord: IFormRecordOutputModel | null;

  //TODO: Move this to a config somewhere
  dateFormat: string = "DD-MM-YYYY, h:mm a";

  @Input() set formRecord(val: IFormRecordOutputModel | null) {
    this._formRecord = val;

    this.setOutputStrings();
  }

  get formRecord():IFormRecordOutputModel | null {
    return this._formRecord
  }

  public userString: string = "";
  public dateString: string = "";
  public isDateEditable: boolean = false;

  constructor(
    public currentUserService: CurrentUserService,
    private formRecordService: FormRecordService,
    private modalService: ModalService,
    private errorHandler: ErrorHandlerService,
  ) {
  }

  ngOnInit() {
    this.setOutputStrings();
  }

  private setOutputStrings():void {
    if( this.formRecord && this.formRecord.isComplete ) {
      this.isDateEditable = this.currentUserService.isAdministrator.getValue();
      this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format( this.dateFormat );
      this.userString = this.formRecord.createdByUser ? this.formRecord.createdByUser.firstName + ' ' + this.formRecord.createdByUser.lastName : '';
    } else {
      this.isDateEditable = false;
      this.dateString = moment().tz(environment.timeZone).format( this.dateFormat );
      this.userString = this.currentUserService.currentUserFullName.getValue();
    }
  }

  public updateRecordDate() {

    this.modalService.selectDate({
      title: 'Record Submission Date',
      label: 'Submission date',
      submitText: 'Update',
      defaultValue: moment(this.formRecord!.createdAt).tz(environment.timeZone).format('DD/MM/YYYY'),
    }).then((date) => {
      const newCreatedAt = moment(date, "DD/MM/YYYY").tz(environment.timeZone).set({
        'hour': 12,
        'minute': 0,
        'second': 0
      }).toISOString();

      this.formRecordService.updateRecordDate({
        recordId: this._formRecord!.id,
        date: newCreatedAt,
      }).subscribe(() => {
        this.formRecord!.createdAt = newCreatedAt;
        this.setOutputStrings();
      }, err => this.errorHandler.handleHttpError(err));

    }).catch(() => {});

  }
}
