import { IDocumentOutputModel, IDocumentSortedField } from './../../../../../../common/contracts/documentDRM';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { TagsService } from "../../../../../service/admin/TagsService";
import { DocumentsService } from "../../../../../service/admin/DocumentsService";
import { ITagOutputModel } from "../../../../../../common/contracts/tag";
import { RxFormsService } from "../../../../../service/util/reactiveForms";
import { ModalService } from "../../../../../service/ModalService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { trimRequiredValidator } from "../../../../../validators/reactiveFormValidators";
import { ISortOrderValue } from '../../../../../../common/contracts/common';
import { PersistentStorageService } from 'service/PersistentStorageService';

@Component({
	selector: 'app-tags-component',
	templateUrl: './editTag.component.html',
})
export class EditTagComponent implements OnInit {

	public tag: ITagOutputModel;
	public isNew: boolean = false;
	private tagId: number | null = null;

	public tagForm = new FormGroup({
		name: new FormControl('', [trimRequiredValidator]),
		isMeta: new FormControl(false)
	});

	public tags: IDocumentOutputModel[] = [];
	public totalItems = 0;
	public currentPage = 1;
	public pageSize = this.pss.pageSize;
	public sortBy: IDocumentSortedField = 'name';
	public sortOrder: ISortOrderValue = 'asc';

	constructor(
		private tagService: TagsService,
		private tagsService: DocumentsService,
		private pss: PersistentStorageService,
		private router: Router,
		private route: ActivatedRoute,
		private activatedRoute: ActivatedRoute,
		private modalService: ModalService,
		private errorHandlerService: ErrorHandlerService) {

	}

	ngOnInit() {
		this.activatedRoute.params.subscribe((params: Params) => {
			if (params.tagId) {
				if (/^\d+$/.test(params.tagId) !== true) {
					console.error('Invalid tag router param');
					this.router.navigate(['/404'], { replaceUrl: true });
					return;
				}

				this.tagId = parseInt(params.tagId, 10);
				this.handlePageChange()
				this.tagService.getTagById(this.tagId).subscribe((tag) => {
					this.tag = tag;

					this.tagForm.setValue({
						name: tag.name,
						isMeta: tag.isMeta
					});

				}, (err) => this.errorHandlerService.handleHttpError(err));
			} else {
				this.isNew = true;
			}
		});
	}

	public submit() {
		if (this.tagForm.invalid) {
			RxFormsService.touchAllFormControls(this.tagForm);
			return;
		}

		const observer = {
			next: () => this.goBack(),
			error: error => this.errorHandlerService.handleHttpError(error),
		};

		if (!this.tagId) {
			this.tagService.createTag({
				name: this.tagForm.controls.name.value.trim(),
				isMeta: this.tagForm.controls.isMeta.value
			}).subscribe(observer);

		} else {
			this.tagService.updateTag({
				id: this.tagId,
				name: this.tagForm.controls.name.value.trim(),
				isMeta: this.tagForm.controls.isMeta.value
			}).subscribe(observer);
		}
	}

	public archiveTag() {
		this.modalService.confirmRx({
			title: 'Warning',
			message: 'Are you sure that you want to delete this document collection?'
		}).subscribe((isConfirmed) => {
			if (isConfirmed && this.tagId) {
				this.tagService.archiveTag(this.tagId).subscribe(
					() => this.goBack(),
					(err) => this.errorHandlerService.handleHttpError(err)
				);
			}
		});
	}

	private goBack() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}
	public handleTagClick(groupId: number | string) {
		this.router.navigate([`${groupId}`], { relativeTo: this.route });
	}

	public handlePageChange() {
		this.tagsService.getPagedDocuments(
			// documentCollectionId: number | null, 
			this.tagId,
			// includeArchived: number | null, 
			1,
			// offset: number = 0, 
			this.pageSize * (this.currentPage - 1),
			// pageSize: number = 20, 
			this.pageSize,
			// sortBy: IDocumentSortedField = 'name',
			this.sortBy,
			// order: ISortOrderValue = 'asc', 
			this.sortOrder,
			// counts: boolean = true
		).subscribe((data) => {
			this.tags = data.items;
			this.totalItems = data.totalCount;
		}, (err) => this.errorHandlerService.handleHttpError(err));
	}

	public handleSortChange(sortField: IDocumentSortedField) {
		if (sortField === this.sortBy) {
			this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			this.sortBy = sortField;
			this.sortOrder = 'asc';
		}

		this.handlePageChange();
	}
	public handleDocumentClick(documentId: number | string) {
		this.router.navigate([`tags/${documentId}`], { relativeTo: this.route });
	}
}
