var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericService } from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import { of } from "rxjs";
import { share, map, tap } from "rxjs/operators";
var DocumentMetaService = /** @class */ (function (_super) {
    __extends(DocumentMetaService, _super);
    function DocumentMetaService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.cachedDocumentMeta = null;
        var queryParams = new HttpParams({
            fromObject: {
                skip: '0',
                limit: '50',
                sortBy: 'name',
                order: 'asc',
            }
        });
        _this.getDocumentMetaRequest = _this.http.get(DocumentMetaService.apiPrefix, { params: queryParams }).pipe(map(function (data) {
            _this.cachedDocumentMeta = data.items;
            setTimeout(function () {
                _this.cachedDocumentMeta = null; // invalidate tags cache in 20 minutes
            }, 20 * 60 * 1000);
            return data.items;
        }), share() //share results between multiple subscriptions
        );
        return _this;
    }
    DocumentMetaService.prototype.getMeta = function () {
        return this.cachedDocumentMeta ? of(this.cachedDocumentMeta) : this.getDocumentMetaRequest;
    };
    DocumentMetaService.prototype.getPagedMeta = function (offset, pageSize, sortBy, order, counts) {
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 20; }
        if (sortBy === void 0) { sortBy = 'name'; }
        if (order === void 0) { order = 'asc'; }
        if (counts === void 0) { counts = true; }
        var queryParams = new HttpParams({
            fromObject: {
                skip: offset.toString(),
                limit: pageSize.toString(),
                sortBy: sortBy,
                order: order,
            }
        });
        return this.http.get(DocumentMetaService.apiPrefix, { params: queryParams });
    };
    DocumentMetaService.prototype.getMetaByDocumentId = function (id) {
        return this.http.get(DocumentMetaService.apiPrefix + "/document/" + id);
    };
    DocumentMetaService.prototype.syncMeta = function (documentId, tags) {
        var _this = this;
        return this.http.put(DocumentMetaService.apiPrefix + "/document/" + documentId, tags).pipe(tap(function () {
            _this.cachedDocumentMeta = null;
        }));
    };
    DocumentMetaService.apiPrefix = '/api/document-meta';
    return DocumentMetaService;
}(GenericService));
export { DocumentMetaService };
