import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { EventEmitter, Injectable } from "@angular/core";

@Injectable()
export class RxFormsService {

  public static touchAllFormControls(form: FormGroup) {
    for(let key in form.controls) {
      if (form.controls.hasOwnProperty(key)) {
        if (form.controls[key] instanceof FormGroup) {
          RxFormsService.touchAllFormControls(form.controls[key] as FormGroup);
        } else if (form.controls[key] instanceof FormControl) {
          form.controls[key].markAsTouched();
          (form.controls[key].statusChanges as EventEmitter<string>).emit(form.controls[key].status);
        } else if (form.controls[key] instanceof FormArray) {
          const arrayControls: AbstractControl[] = (form.controls[key] as FormArray).controls;
          for (let control of arrayControls) {
            if (control instanceof FormGroup) {
              RxFormsService.touchAllFormControls(form.controls[key] as FormGroup);
            } else if (control instanceof FormControl) {
              form.controls[key].markAsTouched();
              (form.controls[key].statusChanges as EventEmitter<string>).emit(form.controls[key].status);
            }
          }
        }
      }
    }
  }

}
