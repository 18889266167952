var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericService } from "./GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import { stringifyObjectValues } from "./util/stringifyObjectValues";
var FormService = /** @class */ (function (_super) {
    __extends(FormService, _super);
    function FormService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    FormService.prototype.getForms = function (params) {
        var queryParams = new HttpParams({
            fromObject: stringifyObjectValues(params)
        });
        return this.http.get(FormService.apiPrefix, { params: queryParams });
    };
    FormService.prototype.runSchedules = function () {
        return this.http.get(FormService.apiPrefix + "/schedule/run/all");
    };
    FormService.prototype.runTemplate = function (id) {
        return this.http.get(FormService.apiPrefix + "/" + id + "/execute");
    };
    FormService.prototype.getFormById = function (id) {
        return this.http.get(FormService.apiPrefix + "/" + id.toString());
    };
    FormService.prototype.finalizeForm = function (id) {
        return this.http.get(FormService.apiPrefix + "/finalize/" + id.toString());
    };
    FormService.prototype.createForm = function (form) {
        return this.http.post(FormService.apiPrefix, form);
    };
    FormService.prototype.updateFormSchedule = function (form) {
        return this.http.put(FormService.apiPrefix + "/schedule", form);
    };
    FormService.prototype.updateForm = function (form) {
        return this.http.put(FormService.apiPrefix, form);
    };
    FormService.prototype.deleteForm = function (params) {
        return this.http.put(FormService.apiPrefix + "/delete", params);
    };
    FormService.prototype.revertForm = function (params) {
        return this.http.put(FormService.apiPrefix + "/revert", params);
    };
    FormService.prototype.sendFormReport = function (params) {
        return this.http.post(FormService.apiPrefix + "/sendReport", params);
    };
    FormService.prototype.duplicateFollowUp = function (id) {
        var params = {
            id: id,
            propertiesToClear: ['dueAt']
        };
        return this.http.post(FormService.apiPrefix + "/duplicate", params);
    };
    FormService.prototype.getFormsFromDocumentId = function (id) {
        return this.http.get(FormService.apiPrefix + "/document/" + id);
    };
    FormService.prototype.sendReviewFormNotifications = function (params) {
        return this.http.post(FormService.apiPrefix + "/reviewNotification", params);
    };
    FormService.apiPrefix = '/api/form';
    return FormService;
}(GenericService));
export { FormService };
