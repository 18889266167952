var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { GenericService } from "./GenericService";
var DocumentService = /** @class */ (function (_super) {
    __extends(DocumentService, _super);
    function DocumentService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    DocumentService.prototype.uploadDocument = function (file) {
        var formData = new FormData();
        formData.append("document", file);
        return this.http.post(DocumentService.apiPrefix, formData);
    };
    DocumentService.prototype.downloadDocument = function (id) {
        return this.http.get(DocumentService.apiPrefix + "/" + id, {
            responseType: 'blob',
        });
    };
    DocumentService.prototype.deleteDocument = function (id) {
        return this.http.delete(DocumentService.apiPrefix + "/" + id);
    };
    DocumentService.apiPrefix = '/api/document';
    return DocumentService;
}(GenericService));
export { DocumentService };
