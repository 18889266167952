import { Injectable } from '@angular/core';
import {GenericService} from "./GenericService";
import { HttpClient } from "@angular/common/http";
import {
  IEnumSetCreateModel,
  IEnumSetUpdateModel,
  IEnumsListOutputModel,
  IEnumsOutputModel
} from "../../common/contracts/enums";
import { Observable, of } from "rxjs";
import { share, tap } from "rxjs/operators";
import { CurrentUserService } from "./currentUser/CurrentUserService";

@Injectable()
export class EnumService extends GenericService {

  static apiPrefix = '/api/enum';

  private cachedEnumsMap: {[enumName: string]: IEnumsOutputModel[]} = {};
  private requestsMap: {[enumName: string]: Observable<IEnumsOutputModel[]>} = {};

  constructor(private http: HttpClient, private currentUserService: CurrentUserService) {
    super();

    /**
     * Invalidate cache on logout
     */
    this.currentUserService.logoutEvents.subscribe(() => {
      this.cachedEnumsMap = {};
      this.requestsMap = {};
    });

  }

  public getEnums() {
    return this.http.get<IEnumsListOutputModel>(EnumService.apiPrefix);
  }

  public getEnumsByName(enumName: string, useCachedEnums: boolean = true): Observable<IEnumsOutputModel[]> {
    const cacheKey = enumName.toLowerCase();
    
    if (!useCachedEnums) {
      return this.getGetEnumsRequest(enumName);
    }
    if (this.cachedEnumsMap[cacheKey]) {
      return of(this.cachedEnumsMap[cacheKey]);
    }
    if (this.requestsMap[cacheKey]) {
      return this.requestsMap[cacheKey];
    }
    this.requestsMap[cacheKey] = this.getGetEnumsRequest(enumName);
    return this.requestsMap[cacheKey];
  }

  private getGetEnumsRequest(enumName: string) {
    const cacheKey = enumName.toLowerCase();

    return this.http.get<IEnumsOutputModel[]>( `${EnumService.apiPrefix}/${enumName}`).pipe(
      tap(enums => {
        this.cachedEnumsMap[cacheKey] = enums;
      }),
      share()
    );
  }

  public createEnumsSet(data: IEnumSetCreateModel) {
    return this.http.post(EnumService.apiPrefix, data);
  }

  public updateEnumsSet(data: IEnumSetUpdateModel) {
    const cacheKey = data.name.toLowerCase();

    delete this.cachedEnumsMap[cacheKey]; // clear cached enums
    delete this.requestsMap[cacheKey];
    return this.http.put(EnumService.apiPrefix, data);
  }
}
