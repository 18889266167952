import { Directive, Input, OnInit, Optional, Host, SkipSelf, OnDestroy, HostBinding, HostListener, } from "@angular/core";
import { ControlContainer, FormControl, FormGroupDirective, FormGroupName } from "@angular/forms";
import { Subscription } from "rxjs";

/**
 * this directive adds bootstrap classes 'border-success' or 'border-danger' on reactive form control depending on validation status
 * example <input type="text" formControlName="email" rxControl>
 *   if an element doesn't have formControlName attribute then control name can be provided in rxControl attribute
 *   <select2 ......
 *    rxControl="role"
 *   >
 */

@Directive({
  selector: '[rxControl]'
})
export class RxControlClassDirective implements OnInit, OnDestroy {

  @Input('formControlName') formControlName: string;

  @Input('rxControl') optionalControlName: string;

  private formControl: FormControl | null;

  private subscription: Subscription | null = null;

  private prevStatus: string;

  @Input('class')
  @HostBinding('class')
  private elementClass;

  constructor(@Optional() @Host() @SkipSelf() private parent: ControlContainer) { }

  @HostListener('blur')
  onBlur() {
    if (this.formControl) {
      this.handleStatusChange(this.formControl.status);
    }
  }

  ngOnInit() {

    if (!this.formControlName && !this.optionalControlName) {
      console.error('rxControl requires formControlName attribute or explicit control name passed to rxControl');
    }

    this.formControl = this.findFormControl(this.formControlName || this.optionalControlName);

    if (this.formControl) {
      this.subscription = this.formControl.statusChanges.subscribe(this.handleStatusChange.bind(this));
    }

  }

  private findFormControl(name: string): FormControl | null {

    if (this.parent instanceof FormGroupDirective || this.parent instanceof FormGroupName) {

      const control = this.parent.control.get(name);

      if (control && control instanceof FormControl) {

        return control;

      } else {

        console.error('requested control is not instance of FormControl');

        return null;
      }
    } else {

      console.error('RxControl parent is not valid', this.parent);

      return null;
    }

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private handleStatusChange(status: string) {

    if (!this.formControl || this.formControl.disabled) return;

    const fullStatus = (this.formControl.touched ? 'TOUCHED' : 'UNTOUCHED') + ' ' + status;

    if (fullStatus === this.prevStatus) {
      return;
    }

    this.prevStatus = fullStatus;

    const cleanedClasses = this.elementClass.replace(/\s(border-success|border-danger)/, '');

    this.elementClass = this.formControl.untouched ? cleanedClasses : (cleanedClasses + (status === 'VALID' ?  ' border-success' : ' border-danger'));

  }

}