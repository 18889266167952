import { environment } from '../../environments/environment';
/**
 * @description Rudimentary logger
 */
var logLevels = {
    none: 0,
    silly: 1,
    debug: 2,
    info: 3,
    error: 4,
    all: 5
};
var logLevelName = environment.logLevel || "debug";
var logLevel = logLevels[logLevelName] || logLevels.debug;
export var logger;
(function (logger) {
    function none() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // do nothing
    }
    logger.none = none;
    function silly() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a;
        if (logLevel <= logLevels.silly) {
            (_a = console.log).call.apply(_a, [{}, 'silly:'].concat(args));
        }
    }
    logger.silly = silly;
    function debug() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a;
        if (logLevel <= logLevels.debug) {
            (_a = console.log).call.apply(_a, [{}, 'debug:'].concat(args));
        }
    }
    logger.debug = debug;
    function info() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a;
        if (logLevel <= logLevels.info) {
            (_a = console.log).call.apply(_a, [{}, 'info:'].concat(args));
        }
    }
    logger.info = info;
    function error() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a;
        if (logLevel <= logLevels.error) {
            console.log.call({}, 'error:');
            (_a = console.error).call.apply(_a, [{}].concat(args));
        }
    }
    logger.error = error;
    function all() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a;
        if (logLevel <= logLevels.all) {
            (_a = console.log).call.apply(_a, [{}, 'all:'].concat(args));
        }
    }
    logger.all = all;
})(logger || (logger = {}));
logger.info("Initalized Log at " + logLevelName + " : " + logLevel);
