import { Component, Input, OnInit } from "@angular/core";
import { ChildFormTreeNode } from "../../../common/contracts/form";
import { CategoryService } from "service/CategoryService";

@Component({
  selector: 'child-forms-tree-node',
  styles: [`
    .tree-node {
        line-height: 2;
    }
    .children-container {
        padding-left: 20px;
    }
  `],
  template: `
    <div *ngIf="treeNode" class="tree-node">
        <a [routerLink]="[routerLinkBase, treeNode.id]">{{displayText}}:&nbsp;{{treeNode.summary | truncate : 80}}&nbsp;&nbsp;
            <i class="fa" [ngClass]="{'fa-pencil': !treeNode.archivedAt, 'fa-check': !!treeNode.archivedAt}"></i>
        </a>
        <div *ngIf="treeNode.childForms && treeNode.childForms.length" class="children-container">
            <child-forms-tree [childForms]="treeNode.childForms"></child-forms-tree>
        </div>
    </div>
  `
})
export class ChildFormsTreeNode implements OnInit {

  public routerLinkBase:string = "/followUp";
  public displayText:string = "Follow-Up";

  @Input()
  treeNode: ChildFormTreeNode;

  constructor(
    private categoryService: CategoryService
  ) {}

  ngOnInit() {
    this.categoryService.getCategories().subscribe( categories => {
      const formCategory = categories.find( category => category.id === this.treeNode.categoryId );

      if( formCategory ) {
        this.displayText = formCategory.name;

        if( this.displayText === 'Report' ) {
          this.routerLinkBase = "/report";
        }

        if( this.displayText === 'Audit' ) {
          this.routerLinkBase = "/audit";
        }
      }
    })
  }
}

@Component({
  selector: 'child-forms-tree',
  styles: [``],
  template: `
    <div *ngIf="childForms">
        <child-forms-tree-node *ngFor="let node of childForms" [treeNode]="node"></child-forms-tree-node>
    </div>
  `
})
export class ChildFormsTreeComponent {

  @Input()
  childForms: ChildFormTreeNode[];

}
