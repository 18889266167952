import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { CurrentUserService } from "../currentUser/CurrentUserService";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class CurrentUserRouterGuard implements CanActivate {

  constructor(public currentUserService: CurrentUserService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean  {

    if (this.currentUserService.userData) {
      return true;
    } else {
      if (this.currentUserService.token) {
        this.currentUserService.recoverTokenFromLocalStorage();
        return this.currentUserService.getCurrentUser().pipe(
          map(() => true),
          catchError(err => {
            console.log('Error getting current user. Redirecting to login.', err);
            this.router.navigate(['/login']);
            return of(false);
          }),
        );
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    }

  }

}

