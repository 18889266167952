/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./alertModal.component";
import * as i2 from "../../../service/ModalService";
var styles_AlertModalComponent = [];
var RenderType_AlertModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertModalComponent, data: {} });
export { RenderType_AlertModalComponent as RenderType_AlertModalComponent };
export function View_AlertModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["ngbAutofocus", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Okay"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.options.message; _ck(_v, 7, 0, currVal_1); }); }
export function View_AlertModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alert-modal-component", [], null, null, null, View_AlertModalComponent_0, RenderType_AlertModalComponent)), i0.ɵdid(1, 49152, null, 0, i1.AlertModalComponent, [i2.AlertModalState], null, null)], null, null); }
var AlertModalComponentNgFactory = i0.ɵccf("alert-modal-component", i1.AlertModalComponent, View_AlertModalComponent_Host_0, {}, {}, []);
export { AlertModalComponentNgFactory as AlertModalComponentNgFactory };
