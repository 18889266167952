var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter } from "@angular/core";
import { timer } from 'rxjs';
import { take, finalize, switchMap } from "rxjs/operators";
import * as Sentry from "@sentry/browser";
import { quickGuid } from "./QuickGuid";
var Session = /** @class */ (function () {
    function Session() {
        var _this = this;
        /* Global isLoading property will cause the loading overlay to show */
        this.isLoading = false;
        this.isLoadingChange = new EventEmitter();
        /**
         * @description Broadcasts intent to return to the previous page in the app, defaulting to the supplied string
         */
        this.requestPrevPage = new EventEmitter();
        /*
          isReady indicates the app has loaded everything needed (including credentials) and is ready to
          proceed. If this is not set, the application should not start.
        */
        this.isReady = false;
        this.init = function () {
            _this.isReady = false;
            _this.flash = {};
            _this.credentials = null;
            _this.lockInput(function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.isReady = true;
                    return [2 /*return*/];
                });
            }); });
        };
        /*
          The flash scope should be cleared by the next init. It should be used for low-key transitions
          only. It should never be used for long term storage
         */
        this.flash = {};
        /*
          For the purpose of tracking threads which are locking the input and showing a loading screen
         */
        this.threads = [];
        this.lockInput = function (func) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                            var threadId, error_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.isLoading = true;
                                        if (this.threads.length === 0) {
                                            this.isLoadingChange.emit(true);
                                        }
                                        threadId = quickGuid();
                                        this.threads.push(threadId);
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, 4, 5]);
                                        return [4 /*yield*/, func()];
                                    case 2:
                                        _a.sent();
                                        return [3 /*break*/, 5];
                                    case 3:
                                        error_1 = _a.sent();
                                        // Die Silently
                                        // throw e;
                                        console.error(error_1);
                                        Sentry.captureException(error_1);
                                        return [3 /*break*/, 5];
                                    case 4:
                                        this.threads.splice(this.threads.indexOf(threadId), 1);
                                        if (this.threads.length === 0) {
                                            this.isLoading = false;
                                            this.isLoadingChange.emit(false);
                                        }
                                        resolve({});
                                        return [7 /*endfinally*/];
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); }, 0);
                    })];
            });
        }); };
        this.lockInputRx = function (asyncEntity) {
            _this.isLoading = true;
            if (_this.threads.length === 0) {
                _this.isLoadingChange.emit(true);
            }
            var threadId = quickGuid();
            _this.threads.push(threadId);
            return timer(0).pipe(switchMap(function () { return asyncEntity.pipe(take(1), finalize(function () {
                var index = _this.threads.indexOf(threadId);
                if (index !== -1) {
                    _this.threads.splice(index, 1);
                }
                if (_this.threads.length === 0) {
                    _this.isLoading = false;
                    _this.isLoadingChange.emit(false);
                }
            })); }));
        };
    }
    return Session;
}());
export { Session };
