var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericService } from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import { stringifyObjectValues } from "../util/stringifyObjectValues";
var defaultUsersQueryParams = {
    skip: 0,
    limit: 10,
    sortBy: 'name',
    order: 'asc',
    search: undefined,
};
var UsersService = /** @class */ (function (_super) {
    __extends(UsersService, _super);
    function UsersService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    UsersService.prototype.getUsers = function (params) {
        if (params === void 0) { params = defaultUsersQueryParams; }
        var queryParams = new HttpParams({
            fromObject: stringifyObjectValues(params)
        });
        return this.http.get(UsersService.apiPrefix, { params: queryParams });
    };
    UsersService.prototype.getUserById = function (id, allowArchived) {
        if (allowArchived === void 0) { allowArchived = false; }
        return this.http.get(UsersService.apiPrefix + "/" + id, {
            params: {
                allowArchived: allowArchived.toString()
            }
        });
    };
    UsersService.prototype.createUser = function (userData) {
        return this.http.post(UsersService.apiPrefix, userData);
    };
    UsersService.prototype.updateUser = function (userData) {
        return this.http.put(UsersService.apiPrefix, userData);
    };
    UsersService.prototype.archiveUser = function (id) {
        return this.http.delete(UsersService.apiPrefix + "/" + id);
    };
    UsersService.prototype.restoreUser = function (id) {
        return this.http.put(UsersService.apiPrefix + "/" + id + "/restore", {});
    };
    UsersService.apiPrefix = '/api/users';
    return UsersService;
}(GenericService));
export { UsersService };
