import { OnInit } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import { environment } from '../../../../environments/environment';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent() {
        //TODO: Move this to a config somewhere
        this.dateFormat = "DD-MM-YYYY";
    }
    Object.defineProperty(HeaderComponent.prototype, "formRecord", {
        get: function () {
            return this._formRecord;
        },
        set: function (val) {
            this._formRecord = val;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.ngOnInit = function () {
        this.updateDateString();
    };
    HeaderComponent.prototype.updateDateString = function () {
        var theMoment = this.formRecord ? moment(this.formRecord.createdAt) : moment();
        this.dateString = theMoment.tz(environment.timeZone).format(this.dateFormat);
    };
    return HeaderComponent;
}());
export { HeaderComponent };
