var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpParams } from "@angular/common/http";
import { GenericService } from "../GenericService";
import { stringifyObjectValues } from 'service/util/stringifyObjectValues';
var DocumentIntanceService = /** @class */ (function (_super) {
    __extends(DocumentIntanceService, _super);
    function DocumentIntanceService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    DocumentIntanceService.prototype.upload = function (params) {
        return this.http.post(DocumentIntanceService.apiPrefix, params);
    };
    DocumentIntanceService.prototype.downloadDocument = function (id) {
        return this.http.get(DocumentIntanceService.apiPrefix + "/" + id, {
            responseType: 'blob',
        });
    };
    DocumentIntanceService.prototype.getAllDocuments = function (params) {
        var queryParams = new HttpParams({
            fromObject: stringifyObjectValues(params)
        });
        return this.http.get("" + DocumentIntanceService.apiPrefix, { params: queryParams });
    };
    DocumentIntanceService.prototype.getDraftDocument = function (id) {
        return this.http.get(DocumentIntanceService.apiPrefix + "/" + id + "/draft");
    };
    DocumentIntanceService.prototype.downloadDraftDocument = function (id) {
        return this.http.get(DocumentIntanceService.apiPrefix + "/" + id + "/downloadDraft", {
            responseType: 'blob',
        });
    };
    DocumentIntanceService.apiPrefix = '/api/document-instance';
    return DocumentIntanceService;
}(GenericService));
export { DocumentIntanceService };
