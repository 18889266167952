import { Component, Input, ComponentFactoryResolver, ViewContainerRef, ViewChild, OnInit } from '@angular/core';
import { IFormOutputModel, IFormRecordOutputModel } from "../../../../../../common/contracts/form";
import { DocumentReviewFormStage0Component } from "../stage0/documentReviewFormStage0.component";
import { DocumentReviewFormStage1Component } from "../stage1/documentReviewFormStage1.component";
import { DocumentReviewFormStage2Component } from "../stage2/documentReviewFormStage2.component";
import { DocumentReviewFormStage3Component } from "../stage3/documentReviewFormStage3.component";
import { DocumentReviewFormStage5Component } from "../stage5/documentReviewFormStage5.component";

const stageFormMap = {
	0: DocumentReviewFormStage0Component,
	1: DocumentReviewFormStage1Component,
	2: DocumentReviewFormStage2Component,
	3: DocumentReviewFormStage3Component,
	5: DocumentReviewFormStage5Component
};

/*
  This is the final, read only state for all the stages
*/

@Component({
	selector: 'document-review-form-4',
	templateUrl: './documentReviewFormStage4.component.html'
})
export class DocumentReviewFormStage4Component implements OnInit {
	// Existing Form Data
	@Input() public formData: IFormOutputModel;

	@ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef;

	public isReportEnabled = false;

	constructor(
		private factoryResolver: ComponentFactoryResolver,
	) { }

	ngOnInit() {
		this.addReadOnlyForms();
	}

	public addReadOnlyForms() {
		if (!this.formData || !this.formData.records)
			return;

		//Get completed Records
		this.formData.records
			.filter(record => record.isComplete)
			.sort( (a,b) => a.sequence - b.sequence )
			.forEach(record => this.addReadOnlyForm(record))
	}

	public addReadOnlyForm(record: IFormRecordOutputModel) {
		const factory = this.factoryResolver.resolveComponentFactory(stageFormMap[record.stage]);
		const component = this.vc.createComponent(factory);

		// @ts-ignore
		component.instance.readOnly = true;
		// @ts-ignore
		component.instance.formData = this.formData;
		// @ts-ignore
		component.instance.hideHeader = !(record.stage === 0 && this.formData.archivedAt);
		// @ts-ignore
		component.instance.sequence = record.sequence;

		this.vc.insert(component.hostView);

		this.isReportEnabled = true;
	}
}
