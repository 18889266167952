import { HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import lscache from 'lscache';
import { throwError } from 'rxjs';
import * as has from 'has';
import * as Sentry from "@sentry/browser";

const defaultHeaders = {
  'Content-Type':  'application/json',
  'Accept' : "application/json"
};

export abstract class GenericService {

  static _token: string | null;

  static httpOptions = {
    headers: new HttpHeaders(defaultHeaders)
  };
  
  protected handleError(error: HttpErrorResponse) {
    
    let errorMessage: string | null = null;
    
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      
      errorMessage = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      
      if( error.error !== null ) {
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${JSON.stringify(error.error)}`);
  
        if( has(error.error, 'message') ) {
          Sentry.captureException( new Error(error.error.message));
          
          // return an observable with a user-facing error message
          return throwError({
            message: error.error.message,
            status: error.status
          });
        }
      } else {
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: [empty String]`);
      }
    }
    
    if( !errorMessage )
      errorMessage = error.message;
  
    Sentry.captureException( new Error(errorMessage));

    // return an observable with a user-facing error message
    return throwError({
      message: 'Something bad happened; please try again later.',
      status: error.status
    });
  };

  get token() {
    return GenericService._token || lscache.get('token');
  }

  set token(newToken) {
    GenericService._token = newToken;
    const headers = {
      ...defaultHeaders,
    };
    if (newToken) {
      headers['Authorization'] = 'Bearer ' + newToken;
    }
    GenericService.httpOptions = {
      headers: new HttpHeaders(headers),
    };
    lscache.set('token', newToken);
  }

}
