import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import * as moment from 'moment';
import "moment-timezone";
import {
	IFormOutputModel,
	IFormRecordOutputModel,
	IFormRecordPropertyParam
} from "../../../../../../common/contracts/form";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { IDocumentType } from "../../../../../../common/contracts/document";
import { FollowUpService } from "../../../../../service/FollowUpService";
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
	selector: 'follow-up-form-2',
	templateUrl: './followUpFormStage2.component.html'
})
export class FollowUpFormStage2Component extends FormComponent implements OnInit {

	// Existing Form Data
	@Input() readOnly: boolean = false;
	@Input() hideHeader: boolean = false;

	@Input() formData: IFormOutputModel;
	@Input() sequence: number;

	public formRecord: IFormRecordOutputModel;

	@ViewChild('followUpWidget') followUpWidgetRef: FollowUpWidgetComponent;

	/*
	  This should have been done properly using something that implements FormControl but its
	  too late now
	 */
	public form: { [key: string]: FormField<any> } = {
		summary: new FormField<String>('', {
			validation: FormField.ValidationMethods.IsNotBlank
		}),
		notes: new FormField<String>('', {
			validation: FormField.ValidationMethods.None
		}),
		status: new FormField<String>('inProgress', {
			validation: FormField.ValidationMethods.IsNotBlank,
		}),
		//Since this will be assigned to a select it must be a string data - Conversion where appropriate
		reassignToUserId: new FormField<string>('', {
			validation: FormField.ValidationMethods.None
		}),
		reassign: new FormField<boolean>(false, {
			validation: FormField.ValidationMethods.None
		}),
		followUps: new FormField<string>('[]', {
			nullEquivilent: "[]",
			validation: (value: string) => {
				return this.followUpWidgetRef ? this.followUpWidgetRef.validate() : true;
			}
		}),
	};

	public dateString: string;

	public documents: Array<IDocumentType> = [];

	constructor(
		public session: Session,
		public currentUserService: CurrentUserService,
		public formRecordService: FormRecordService,
		public formService: FormService,
		private errorHandler: ErrorHandlerService,
		private followUpService: FollowUpService,
		public router: Router
	) {
    super(router);
	}

	registerFormFields() {
		this.formFields.push(...Object.keys(this.form).map((k: string) => this.form[k]));
	}

	ngOnInit() {
		this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);

		this.registerFormFields();

		this.repopulateFormFromData();
	}

	onSubmit(isDraft: boolean) {
		this.session.lockInput(() => {
			return new Promise((resolve, reject) => {

				let success = () => {
					resolve(null);

					this.closeForm();
				};

				let fail = (msg: string, err: any) => {
					console.error(msg, err);
					this.errorHandler.handleHttpError(err);
					reject();
				};

				let stage: number = isDraft ? 2 : 3;
				let assignedUserId: number | null = null;

				if (this.currentUserService.userData)
					assignedUserId = this.currentUserService.userData.id;

				if (!isDraft && this.form.reassign.value && this.form.reassignToUserId.value) {
					stage = 1;

					assignedUserId = Number(this.form.reassignToUserId.value);
				}

				let properties: Partial<IFormRecordPropertyParam>[] = [];

				this.formService.updateForm({
					id: this.formData.id,
					stage,
					assignedUserId
				})
					.subscribe(() => {
						properties.push({
							name: "reassign",
							intData: this.form.reassign.value ? 1 : 0
						});

						if (this.form.reassign.value) {
							properties.push({
								name: "reassignToUserId",
								intData: this.form.reassign.value ? Number(this.form.reassignToUserId.value) : null,
							});
						}

						properties.push({
							name: "followUps",
							jsonData: this.form.followUps.value
						});

						if (this.form.summary.value.length > 0)
							properties.push({
								name: "summary",
								stringData: this.form.summary.value
							});

						if (this.form.notes.value.length > 0)
							properties.push({
								name: "notes",
								stringData: this.form.notes.value
							});

						let followUps: { userGroupId: string, description: string, dueDate: string }[] = [];
						if (this.form.followUps.value.length) {
							followUps = JSON.parse(this.form.followUps.value);
						}

						this.formRecordService.createRecord({
							formId: this.formData.id,
							// Intentionally cast the properties object since we know its correct
							properties: properties as any,
							stage: 2,
							documents: this.documents.map(doc => ({ id: doc.id, isTicked: !!doc.isTicked })),
							isComplete: !isDraft
						})
							.subscribe((data: any) => {
								//Done creating the form and appending its properties

								if (isDraft || stage !== 3) {
									success();
								} else {
									this.formService.finalizeForm(this.formData.id)
										.subscribe(() => {
											if (followUps.length === 0)
												return success();

											this.followUpService.generateFollowUps(this.formData.id, this.formData.formLocationId, followUps, this.getFirstSubmissionType(), success);
										},
											err => fail('Error while finalizing a form', err)
										);
								}
							},
								err => fail('Error while creating a record', err)
							);
					},
						err => fail('Error while updating a form', err)
					);
			});
		});
	}

	private repopulateFormFromData() {
		if (!this.formData || !this.formData.records || !this.formData.records.length)
			return;

		let stageRecords = this.formData.records.filter(record => record.stage === 2);

		if (stageRecords.length === 0) {
			/*
				At this point, we want to acknowledge the possibility that the
				previous stage specified followups that need to be completed
			 */
			stageRecords = this.formData.records.filter(record => record.stage === 1);

			let mostRecentRecord = stageRecords.sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

			if (!mostRecentRecord)
				return;

			let followUpRecord =
				mostRecentRecord.properties.find(recordProperty => recordProperty.property.name === 'followUps');

			if (followUpRecord) {
				this.form.followUps.value = followUpRecord.jsonData;
			}

			return;
		}

		if (!this.sequence) {
			let mostRecentRecord = stageRecords.sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

			if (!mostRecentRecord)
				throw new Error("internal error");

			this.formRecord = mostRecentRecord;
		} else {
			let targetRecord = stageRecords.find(record => record.sequence === this.sequence);

			if (!targetRecord)
				throw new Error(`Missing Record At Sequence ${this.sequence}`);

			this.formRecord = targetRecord;
		}

		//Convert the properties into easily accessible IFormRecordPropertyParam
		if (!this.formRecord.properties)
			return;

		this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);

		let simpleProperties: { [key: string]: IFormRecordPropertyParam } = {};

		this.formRecord.properties.forEach(recordProperty => {
			//eject invalid property
			if (!recordProperty.property)
				return;

			let result: Partial<IFormRecordPropertyParam> = {
				name: recordProperty.property.name
			};

			if (recordProperty.stringData)
				result.stringData = recordProperty.stringData;

			if (recordProperty.intData)
				result.intData = recordProperty.intData;

			if (recordProperty.jsonData)
				result.jsonData = recordProperty.jsonData;

			if (recordProperty.enumId)
				result.enumId = recordProperty.enumId;

			simpleProperties[result.name as string] = result as IFormRecordPropertyParam;
		});

		/*
		  If the previous record was not from the current user,
		  or if a sequence was specified, we only want the followUps
		*/

		if (!this.formRecord.isComplete || this.sequence) {
			if (simpleProperties['reassignToUserId'])
				this.form.reassignToUserId.value = String(simpleProperties['reassignToUserId'].intData);

			if (simpleProperties['reassign']
				&& simpleProperties['reassign'].intData !== null
				&& simpleProperties['reassign'].intData !== undefined) {
				this.form.reassign.value = (simpleProperties['reassign'].intData > 0);
				this.showReassignOptions(this.form.reassign.value);
			}

			if (simpleProperties['hasContactedOriginator']
				&& simpleProperties['hasContactedOriginator'].intData !== null
				&& simpleProperties['hasContactedOriginator'].intData !== undefined)
				this.form.hasContactedOriginator.value = (simpleProperties['hasContactedOriginator'].intData > 0);

			if (simpleProperties['summary'])
				this.form.summary.value = simpleProperties['summary'].stringData;

			if (simpleProperties['notes'])
				this.form.notes.value = simpleProperties['notes'].stringData;

			this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
		}

		if (simpleProperties['followUps'])
			this.form.followUps.value = simpleProperties['followUps'].jsonData;
	}

	public showReassignOptions(state: boolean): void {
		this.form.reassign.value = state;
		if (state) {
			this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.IsNotBlank);
			this.setFieldValidation(this.form.summary, FormField.ValidationMethods.None);
		} else {
			this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.summary, FormField.ValidationMethods.IsNotBlank);
		}
	}

	private getFirstSubmissionType(): number | null {
		let firstSubmission = this.getFirstSubmission();

		if (firstSubmission) {
			let formType = firstSubmission.properties.find(recordProperty => recordProperty.property.name === "reportFormType");
			if (formType) {
				return formType.enumId;
			}
		}

		return null;
	}

	private getFirstSubmission(): IFormRecordOutputModel | null {
		if (!this.formData || !this.formData.records || !this.formData.records.length) {
			return null;
		}
		return this.formData.records.find(record => record.stage === 0 && record.isComplete) || null;
	}

}
