<preloader-wrapper [isLoaded]="isLoaded">

    <div class="row">
        <div class="col-9 text-lg-left">
            <h1 class="text-uppercase text-orange">{{isNew ? 'New' : 'Edit'}} User</h1>
        </div>
        <div class="col-3 text-md-right" *ngIf="!isNew && isAdmin && (!user || !user.isArchived)">
            <button class="btn btn-md btn-orange" (click)="emulateUser()">Emulate User</button>
        </div>
    </div>

    <form [formGroup]="userForm" autocomplete="off" role="presentation">
        <input autocomplete="false" name="hidden" type="text" style="display:none;">
        
        <div class="row">
            <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
                <div class="form-group">
                    <label for="usernameInput">E-mail:</label>
                    <input type="text" id="usernameInput" class="form-control input-lg" formControlName="username" rxControl>
                    <small class="form-text text-danger" *ngIf="userForm.controls.username.touched && userForm.controls.username.hasError('required')">This field is required.</small>
                    <small class="form-text text-danger" *ngIf="userForm.controls.username.touched && userForm.controls.username.hasError('email')">Must be a valid E-mail.</small>
                </div>
            </div>
            <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
                <div class="form-group">
                    <label for="phoneInput">Phone:</label>
                    <input type="text" id="phoneInput" class="form-control input-lg" formControlName="phone" mask="(00) 0000 0000" rxControl>
                    <small class="form-text text-danger" *ngIf="userForm.controls.phone.touched && userForm.controls.phone.hasError('pattern')">Must have 10 digits.</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
                <div class="form-group">
                    <label for="firstNameInput">First Name:</label>
                    <input type="text" id="firstNameInput" class="form-control input-lg" formControlName="firstName" rxControl>
                    <small class="form-text text-danger" *ngIf="userForm.controls.firstName.touched && userForm.controls.firstName.hasError('required')">This field is required.</small>
                </div>
            </div>
            <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
                <div class="form-group">
                    <label for="lastNameInput">Last Name:</label>
                    <input type="text" id="lastNameInput" class="form-control  input-lg"  formControlName="lastName" rxControl>
                    <small class="form-text text-danger" *ngIf="userForm.controls.lastName.touched && userForm.controls.lastName.hasError('required')">This field is required.</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
                <div class="form-group">
                    <label for="firstNameInput">User Role:</label>
                    <select2
                        id="roleSelector"
                        class="form-control select2-form-control"
                        rxControl="role"
                        [width]="'100%'"
                        [options]="{placeholder: 'Select role'}"
                        [data]="rolesOptions"
                        [value]="userForm.controls.role.value"
                        [disabled]="userForm.controls.role.disabled"
                        (valueChanged)="handleSelectorChange('role', $event)"></select2>
                    <small class="form-text text-danger" *ngIf="userForm.controls.role.touched && userForm.controls.role.hasError('required')">This field is required.</small>
                </div>
            </div>
            <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
                <div class="form-group">
                    <label for="groupsSelector">Departments:</label>
                    <select2
                        id="groupsSelector"
                        class="form-control select2-form-control"
                        rxControl="selectedGroups"
                        [width]="'100%'"
                        [options]="{multiple: true, placeholder: 'Select groups'}"
                        [data]="groupsOptions"
                        [value]="userForm.controls.selectedGroups.value"
                        [disabled]="userForm.controls.selectedGroups.disabled"
                        (valueChanged)="handleSelectorChange('selectedGroups', $event)"></select2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
                <div class="form-group">
                    <label for="locationsSelector">Locations:</label>
                    <select2
                        id="locationsSelector"
                        class="form-control select2-form-control"
                        rxControl="selectedLocations"
                        [width]="'100%'"
                        [options]="{multiple: true, placeholder: 'Select locations'}"
                        [data]="locationsOptions"
                        [value]="userForm.controls.selectedLocations.value"
                        [disabled]="userForm.controls.selectedLocations.disabled"
                        (valueChanged)="handleSelectorChange('selectedLocations', $event)"
                    ></select2>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!isNew && (!user || !user.isArchived)">
            <div class="col-12 offset-0 col-sm-12 col-lg-12 offset-lg-0 col-xl-12 offset-xl-0 text-center">
                <button class="btn btn-link" (click)="toggleChangePassword()">{{setPassword ? 'Cancel changing password': 'Change password'}}</button>
            </div>
        </div>
        <div class="row" *ngIf="setPassword">
            <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
                <div class="form-group">
                    <label for="passwordInput">Password:</label>
                    <input type="password" id="passwordInput" class="form-control input-lg"  formControlName="password" rxControl>
                    <small class="form-text text-danger" *ngIf="userForm.controls.password.touched && userForm.controls.password.hasError('required')">This field is required.</small>
                    <small class="form-text text-danger" *ngIf="userForm.controls.password.touched && userForm.controls.password.hasError('minlength')">Must have at least 6 characters.</small>
                </div>
            </div>
            <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
                <div class="form-group">
                    <label for="confirmPasswordInput">Confirm Password:</label>
                    <input type="password" id="confirmPasswordInput" class="form-control  input-lg" formControlName="confirmPassword" rxControl>
                    <small class="form-text text-danger" *ngIf="userForm.controls.confirmPassword.touched && userForm.controls.confirmPassword.hasError('confirmError')">Password doesn't match.</small>
                </div>
            </div>
        </div>
        <div class="row bg-default mx-1 my-3 py-4">
            <div class="col-12 col-lg-4 p-1 text-center mt-lg-2 mt-xl-0">
                <span *ngIf="!!user">Date Created: <span class="text-primary">{{ user.createdAt | date: 'mediumDate' }}</span></span>
            </div>
            <div class="col-12 col-lg-4 p-1 text-center mt-lg-2 mt-xl-0">
                <span *ngIf="!!user">Date Updated: <span class="text-primary">{{ user.updatedAt | date: 'mediumDate' }}</span></span>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1" *ngIf="!user || !user.isArchived">
                <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit()" [disabled]="userForm.pristine"><span class="capitalize">save</span></button>
            </div>
            <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-4 col-xl-2 col-xxl-1">
                <a class="btn btn-block btn-lg btn-secondary rounded-0 text-center" [routerLink]="['/admin/users']"><span class="capitalize">cancel</span></a>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1" *ngIf="!isNew && (!user || !user.isArchived)">
                <button class="btn btn-block btn-lg btn-danger rounded-0" (click)="archiveUser()"><span class="capitalize">delete</span></button>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1" *ngIf="user && user.isArchived && isAdmin">
                <button class="btn btn-block btn-lg btn-danger rounded-0" (click)="restoreUser()"><span class="capitalize">restore</span></button>
            </div>
        </div>
    </form>

</preloader-wrapper>
