<div class="row">
    <div class="col-12 text-lg-left">
        <h1 class="text-uppercase text-orange">{{isNew ? 'New' : 'Edit'}} Location</h1>
    </div>
</div>


<form [formGroup]="locationForm">
   <div class="row">
        <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
            <div class="form-group">
                <label for="usernameInput">Location Name:</label>
                <input
                    type="text"
                    id="usernameInput"
                    autofocus
                    class="form-control input-lg"
                    rxControl
                    formControlName="name"
                >
                <small class="form-text text-danger" *ngIf="locationForm.controls.name.touched && locationForm.controls.name.hasError('required')">This field is required.</small>
            </div>
        </div>
    </div>
    <div class="row bg-default mx-1 my-3 py-4">
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
            <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit()" [disabled]="locationForm.pristine">
                <span class="capitalize">Save</span>
            </button>
        </div>
        <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-4 col-xl-2 col-xxl-1">
            <a class="btn btn-block btn-lg btn-secondary rounded-0 text-center" [routerLink]="['..']"><span class="capitalize">Cancel</span></a>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1" *ngIf="!isNew">
            <button class="btn btn-block btn-lg btn-danger rounded-0" (click)="archiveLocation()"><span class="capitalize">Delete</span></button>
        </div>
    </div>
</form>
