import { OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Session } from '../../../service/util/Session';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RxFormsService } from "../../../service/util/reactiveForms";
import { AuthService } from "../../../service/security/AuthService";
import { ErrorHandlerService } from "../../../service/ErrorHandlerService";
import { ModalService } from "../../../service/ModalService";
import { emailValidator, trimRequiredValidator, validateConfirmPassword } from "../../../validators/reactiveFormValidators";
var ResetComponent = /** @class */ (function () {
    function ResetComponent(session, router, activatedRoute, authService, errorHandler, modalService) {
        var _this = this;
        this.session = session;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.authService = authService;
        this.errorHandler = errorHandler;
        this.modalService = modalService;
        this.resetForm = new FormGroup({
            username: new FormControl('', [trimRequiredValidator, emailValidator]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
            confirmPassword: new FormControl('', validateConfirmPassword),
        });
        this.resetForm.controls.password.valueChanges.subscribe(function (value) {
            _this.resetForm.controls.confirmPassword.updateValueAndValidity();
            if (value) {
                _this.resetForm.controls.confirmPassword.markAsTouched();
            }
        });
    }
    ResetComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.activatedRoute.queryParams, this.activatedRoute.params).subscribe(function (_a) {
            var queryParams = _a[0], routeParams = _a[1];
            if (queryParams.email) {
                _this.resetForm.controls.username.setValue(queryParams.email);
            }
            _this.resetToken = routeParams.resetToken;
        });
    };
    ResetComponent.prototype.submitReset = function () {
        var _this = this;
        if (this.resetForm.invalid) {
            RxFormsService.touchAllFormControls(this.resetForm);
            return;
        }
        this.authService.resetPassword({
            resetToken: this.resetToken,
            username: this.resetForm.controls.username.value.trim(),
            password: this.resetForm.controls.password.value,
        }).subscribe(function (data) {
            /**
             * auto authorization after password reset, /api/reset returns JwtPayload
             */
            _this.authService.token = data.accessToken;
            _this.session.credentials = data;
            _this.modalService.alert({
                title: 'Success',
                message: 'Your password has been changed successfully.'
            }).then(function () {
                _this.router.navigate(['/dashboard']);
            });
        }, function (err) { return _this.errorHandler.handleHttpError(err); });
    };
    return ResetComponent;
}());
export { ResetComponent };
