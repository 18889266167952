import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { GenericService } from "./GenericService";
import { IDocumentType } from "../../common/contracts/document";
import { Observable } from "rxjs";

@Injectable()
export class DocumentService extends GenericService {

  static apiPrefix = '/api/document';

  constructor(private http: HttpClient) {
    super();
  }

  public uploadDocument(file: File): Observable<IDocumentType> {
    const formData = new FormData();
    formData.append("document", file);
    return this.http.post<IDocumentType>(DocumentService.apiPrefix, formData);
  }

  public downloadDocument(id: number) {
    return this.http.get(`${DocumentService.apiPrefix}/${id}` , {
      responseType: 'blob',
    });
  }

  public deleteDocument(id: number) {
    return this.http.delete(`${DocumentService.apiPrefix}/${id}`);
  }

}
