<h1 class="text-uppercase text-orange">Resource Packs</h1>

<app-resource-pack-create (onCreate)="fetchData()"></app-resource-pack-create>
<br/>

<preloader-wrapper [isLoaded]="isLoaded">
<div class="row" *ngIf="resourcePacks.length;else empty">
  <div class="col">

    <table class="table table-striped table-inverse">
      <thead class="thead-inverse">
        <tr>
          <th>Name</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rp of resourcePacks">
            <td scope="row">{{rp.name}}</td>
            <td width="150">
              <div class="btn-group">
                <a [routerLink]="['documents' , rp.id]" class="btn btn-primary btn-sm text-white">View / Edit</a>
                <a class="btn btn-danger btn-sm text-white" (click)="deleteResourcePack(rp.id)">Delete</a>
              </div>
            </td>
          </tr>
        </tbody>
    </table>

  </div>
</div>

</preloader-wrapper>
<ng-template #empty>
  <div class="text-center">No Resource Packs Available</div>
</ng-template>