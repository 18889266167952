var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
// import { IrisksortedField, IDocumentCollectionOutputModel } from "../../../../../../common/contracts/documentCollection";
import { ActivatedRoute, Router } from "@angular/router";
import { PersistentStorageService } from "../../../../../service/PersistentStorageService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { CurrentUserService } from '../../../../../service/currentUser/CurrentUserService';
import { RiskService } from 'service/RiskService';
import { Risk } from '../../../../../../common/Risk';
import { riskTypeMap, riskTypeOptions } from 'model/RiskType';
import { logger } from 'service/util/Logger';
import { ReportService } from 'service/ReportService';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ModalService } from 'service/ModalService';
import { DocumentCollectionsService } from 'service/admin/DocumentCollectionsService';
import { Session } from 'service/util/Session';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
var RiskComponent = /** @class */ (function () {
    function RiskComponent(groupsService, router, route, pss, errorHandler, reportService, userService, modalService, collectionsService, session) {
        var _this = this;
        this.groupsService = groupsService;
        this.router = router;
        this.route = route;
        this.pss = pss;
        this.errorHandler = errorHandler;
        this.reportService = reportService;
        this.userService = userService;
        this.modalService = modalService;
        this.collectionsService = collectionsService;
        this.session = session;
        this.riskTypeOptions = [{ id: '', text: '' }].concat(riskTypeOptions);
        this.risks = [];
        this.totalItems = 0;
        this.currentPage = 1;
        this.pageSize = this.pss.pageSize;
        this.sortBy = 'summary';
        this.sortOrder = 'asc';
        this.isAdmin = false;
        this.searchFilter = '';
        this.locationFilter = '';
        this.riskLevelFilter = "";
        this.riskStatusFilter = "";
        this.riskTypeId = "";
        this.riskSelectOptions = [{ id: '', text: '' }].concat(Risk.RiskOptions);
        this.riskStatus = [{ id: '', text: '' }, { id: "1", text: "Open" }, { id: "2", text: "Close" }];
        this.riskDataUpdateSubject = new BehaviorSubject(false);
        this.handleChangeRiskLevel = function (event) {
            logger.silly("handleChangeRiskLevel");
            var value = event.data[0].text;
            _this.riskLevelFilter = value;
            _this.currentPage = 1;
            _this.handlePageChange();
        };
        this.handleChangeRiskStatus = function (event) {
            logger.silly("handleChangeRiskStatus");
            var value = event.data[0].text;
            _this.riskStatusFilter = value;
            _this.currentPage = 1;
            _this.handlePageChange();
        };
        this.handleImportClick = function () {
            _this.modalService.upload({ accept: ".csv" }).subscribe(function (importFile) {
                if (!importFile)
                    return;
                console.log("import file", importFile);
                _this.session.lockInputRx(_this.groupsService.importRisks(importFile.id)).subscribe(function (resp) {
                    _this.collectionsService.clearCache();
                    _this.handlePageChange();
                    _this.modalService.alert({
                        title: "Import Complete",
                        message: resp.message
                    });
                }, function (err) {
                    _this.errorHandler.handleHttpError(err);
                });
            });
        };
    }
    RiskComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isAdmin = this.userService.isAdministrator.getValue();
        this.handlePageChange();
        this.riskDataUpdateSubject.pipe(filter(function (val) { return !!val; }), debounceTime(200)).subscribe(function () {
            _this.fetchRiskData();
        });
    };
    RiskComponent.prototype.handleRiskClick = function (groupId) {
        this.router.navigate(["" + groupId], { relativeTo: this.route });
    };
    RiskComponent.prototype.handlePageChange = function () {
        logger.silly("handlePageChange");
        this.riskDataUpdateSubject.next(true);
    };
    RiskComponent.prototype.fetchRiskData = function () {
        var _this = this;
        logger.silly("fetchRiskData");
        this.risks = [];
        var search = this.searchFilter.length > 0 ? this.searchFilter : '';
        this.groupsService.getRisks(this.pageSize * (this.currentPage - 1), this.pageSize, this.sortBy, this.sortOrder, search, this.locationFilter, this.riskLevelFilter, this.riskStatusFilter, this.riskTypeId).subscribe(function (data) {
            data.items.forEach(function (item) {
                _this.risks.push(__assign({}, item, { riskType: riskTypeMap[item.riskTypeId] }));
            });
            _this.totalItems = data.totalCount;
        }, function (err) { return _this.errorHandler.handleHttpError(err); });
    };
    RiskComponent.prototype.handleSortChange = function (sortField) {
        logger.silly("HandleSortChange");
        if (sortField === this.sortBy) {
            this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        }
        else {
            this.sortBy = sortField;
            this.sortOrder = 'asc';
        }
        this.handlePageChange();
    };
    RiskComponent.prototype.performSearch = function () {
        logger.silly("performSearch");
        this.searchFilter = this.searchFilter.trim();
        this.currentPage = 1;
        this.handlePageChange();
    };
    RiskComponent.prototype.clearSearch = function () {
        logger.silly("clearSearch");
        this.searchFilter = '';
        this.currentPage = 1;
        this.handlePageChange();
    };
    RiskComponent.prototype.handleChangeRiskTypeFilter = function (id) {
        logger.silly("handleChangeRiskTypeFilter");
        if (!id) {
            this.riskTypeId = "";
        }
        else {
            this.riskTypeId = id;
        }
        this.currentPage = 1;
        this.handlePageChange();
    };
    RiskComponent.prototype.handleLocationFilterChange = function (selected) {
        logger.silly("handleLocationFilterChange");
        if (!selected || selected.length === 0) {
            logger.silly("Updated QueryParam: locationId: [blank string]");
        }
        else {
            var oldValue = this.locationFilter;
            var newValue = selected.map(function (item) { return item.id; }).filter(function (id) { return id && id.length; }).join(",");
            logger.silly("Updated QueryParam: locationId: Old[" + oldValue + "] New[" + newValue + "]");
            this.locationFilter = newValue;
        }
        this.currentPage = 1;
        this.handlePageChange();
    };
    RiskComponent.prototype.handleCsvExport = function () {
        var _this = this;
        var search = this.searchFilter.length > 0 ? this.searchFilter : '';
        this.reportService.downloadRISKcsvReport(this.pageSize * (this.currentPage - 1), this.pageSize, this.sortBy, this.sortOrder, search, this.locationFilter, this.riskLevelFilter, this.riskStatusFilter, this.riskTypeId).subscribe(function (data) {
            saveAs(data, "risk-report-" + moment().format('YYYY-MMM-DD') + ".csv");
        }, function (err) { return _this.errorHandler.handleHttpError(err); });
    };
    return RiskComponent;
}());
export { RiskComponent };
