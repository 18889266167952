import { Directive, OnInit, ElementRef, OnDestroy } from "@angular/core";

/**
 * this directive replaces & character with 'and' in inputs or textareas
 */

function handleKeyDown(event) {
  if (event.key === '&') {
    event.stopPropagation();
    event.preventDefault();

    // @ts-ignore
    insertAtCursor(this, ' and ');

    let inputEvent;
    if (typeof (Event) === "function") { // not IE
      inputEvent = new Event("input", {
        'bubbles': true,
        'cancelable': true,
      });
    } else { // IE
      inputEvent = document.createEvent("Event");
      inputEvent.initEvent("input", true, true);
    }

    // @ts-ignore
    this.dispatchEvent(inputEvent);
  }
}

function insertAtCursor(inputEl, textToInsert: string) { // first character of textToInsert should be space
  if (inputEl.selectionStart || inputEl.selectionStart == '0') {
    let startPos = inputEl.selectionStart;
    let endPos = inputEl.selectionEnd;
    const _text = textToInsert.slice(startPos === 0 ? 1 : inputEl.value[startPos - 1] === ' ' ? 1 : 0);
    inputEl.value = inputEl.value.substring(0, startPos)
      + _text
      + inputEl.value.substring(endPos, inputEl.value.length);
    const caretPos = startPos + _text.length;
    inputEl.setSelectionRange(caretPos, caretPos);
  } else {
    inputEl.value += textToInsert;
  }
}

@Directive({
  selector: '[replaceAmpersand]'
})
export class ReplaceAmpersandDirective implements OnInit, OnDestroy {

  constructor(private element: ElementRef) { }

  ngOnInit(): void {
    this.element.nativeElement.addEventListener('keydown', handleKeyDown);
  }

  ngOnDestroy(): void {
    this.element.nativeElement.removeEventListener('keydown', handleKeyDown);
  }

}
