import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField, RecordParamType } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { IFormRecordOutputModel } from "../../../../../../common/contracts/form";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { IFormOutputModel } from "../../../../../../common/contracts/form";
import { CategoryService } from "../../../../../service/CategoryService";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { IDocumentType } from "../../../../../../common/contracts/document";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { Router } from '@angular/router';
import { logger } from 'service/util/Logger';
import { riskTypeMap } from '../../../../../model/RiskType';
import { Risk } from "../../../../../../common/Risk";
import { EnumService } from 'service/EnumService';
import { FollowUpService } from 'service/FollowUpService';
import { IEnumsOutputModel } from '../../../../../../common/contracts/enums';

@Component({
	selector: 'risk-assessment-form-2',
	templateUrl: './riskAssessmentFormStage2.component.html'
})
export class RiskAssessmentFormStage2Component extends FormComponent implements OnInit {
	public currentFormStage: number = 2;
	public className = "RiskAssessmentFormStage2Component";

	// Existing Form Data
	@Input() public formData: IFormOutputModel;
	@Input() sequence: number;

	public formRecord: IFormRecordOutputModel;

	@Input() readOnly: boolean = false;
	@Input() hideHeader: boolean = false;

	@ViewChild('followUpWidget') followUpWidgetRef: FollowUpWidgetComponent;

	public form: { [key: string]: FormField<any> } = {
		reassign: new FormField<boolean>(false, {
			validation: FormField.ValidationMethods.None,
			recordParamType: RecordParamType.Boolean
		}),
		//Since this will be assigned to a select it must be a string data - Conversion where appropriate
		reassignToUserId: new FormField<string>('', {
			validation: FormField.ValidationMethods.None
		}),
		followUps: new FormField<string>('[]', {
			nullEquivilent: "[]",
			recordParamType: RecordParamType.JSON,
			validation: (value: string) => {
				return this.followUpWidgetRef ? this.followUpWidgetRef.validate() : true;
			}
		}),
		isAlarp: new FormField<boolean>(false, {
			validation: FormField.ValidationMethods.None,
			recordParamType: RecordParamType.Boolean
		}),
		riskRemoved: new FormField<boolean>(false, {
			validation: FormField.ValidationMethods.None,
			recordParamType: RecordParamType.Boolean
		}),
		summary: new FormField<String>('', {
			validation: FormField.ValidationMethods.None
		}),
	};

	public documents: Array<IDocumentType> = [];
	public isExistingRisk: boolean = false;
	public isRiskRemoved: boolean = false;
	public isRiskIncreased: boolean = false;
	public riskType: string = 'General Risk';
	public followUpFormTypeId: number | null = null;
	public isRamQuesShow = true;

	constructor(
		public session: Session,
		public currentUserService: CurrentUserService,
		public formRecordService: FormRecordService,
		public formService: FormService,
		public categoryService: CategoryService,
		private groupsService: GroupsService,
		public errorHandler: ErrorHandlerService,
		public router: Router,
		public enumService: EnumService,
		public followUpService: FollowUpService
	) {
		super(router);
	}

	registerFormFields() {
		this.formFields.push(...Object.keys(this.form).map((k: string) => this.form[k]));
	}

	ngOnInit() {
		this.loadEnums();
		this.registerFormFields();
		this.repopulateFormFromData();
		this.isRamQuesShow = this.getRiskType() === '1' ? true : false;
	}

	private getRiskType(): (keyof typeof riskTypeMap) | null {
		const firstSubmission = this.getFirstSubmission();

		if (firstSubmission) {
			const riskTypeInt = this.getIntData(firstSubmission, 'riskType');
			if (riskTypeInt) {
				const riskTypeKey = riskTypeInt.toString();
				if (Object.prototype.hasOwnProperty.call(riskTypeMap, riskTypeKey)) {
					return riskTypeKey as keyof typeof riskTypeMap;
				}
			}
		}

		return null;
	}

	private getFirstSubmission(): IFormRecordOutputModel | null {
		if (!this.formData || !this.formData.records || !this.formData.records.length)
			return null;

		return this.formData.records.find(record => record.stage === 0 && record.isComplete) || null;
	}

	onSubmit(isDraft: boolean = false) {
		const signature = this.className + ".onSubmit:";
		this.session.lockInput(() => {
			return new Promise(async (resolve, reject) => {
				let assignedUserId: number | null = this.currentUserService.userData && isDraft ? this.currentUserService.userData.id : null;

				let stage: number = this.currentFormStage;
				let userGroupId: number | null = null;
				if (!isDraft) {
					if (this.form.reassign.value && Number(this.form.reassignToUserId.value)) {
						// The user is intending to reassign this form to another user
						stage = 1;
						userGroupId = this.formData.userGroupId;


						assignedUserId = Number(this.form.reassignToUserId.value);
						logger.silly(signature + `Assigning form to User[${assignedUserId}] and Group[${userGroupId}]`);
					} else if (!this.isExistingRisk || this.form.riskRemoved.value || this.isRiskIncreased || this.form.isAlarp.value) {
						stage = 3;
						userGroupId = await this.groupsService.getGroupIdByName("Senior Management");
					} else {
						stage = 4;
						userGroupId = await this.getGroupForRiskType();
					}
				}

				this.formService.updateForm({
					id: this.formData.id,
					formLocationId: this.formData.formLocationId,
					userGroupId,
					notifyOnComplete: this.formData.notifyOnComplete,
					stage,
					assignedUserId
				}).subscribe((data: any) => {
					const properties = this.toRecordParams(this.form);

					this.formRecordService.createRecord({
						formId: this.formData.id,
						properties: properties as any,
						stage: this.currentFormStage,
						documents: this.documents.map(doc => ({ id: doc.id, isTicked: !!doc.isTicked })), // send list of attached documents
						isComplete: !isDraft
					})
						.subscribe((data: any) => {
							const success = () => {
								resolve(data);

								this.session.requestPrevPage.next({ defaultUrl: '/ram-dashboard', filter: 'dashboard' });
							}

							//Done creating the form and appending its properties
							if (stage === 4) {
								this.formService.finalizeForm(this.formData.id).subscribe(() => {
									let followUps: { userGroupId: string, description: string, dueDate: string }[] = [];

									if (this.form.followUps.value && this.form.followUps.value.length) {
										followUps = JSON.parse(this.form.followUps.value);
									}

									if (followUps.length === 0)
										return success();

									this.followUpService.generateFollowUps(this.formData.id, this.formData.formLocationId, followUps, this.followUpFormTypeId, success);
								});
							} else {
								success();
							}
						}, err => {
							this.errorHandler.handleHttpError(err);
							reject("Error creating new record");
						});
				}, (err) => {
					this.errorHandler.handleHttpError(err);
					reject("Error updating form");
				});
			});
		});
	}

	/**
	 * @description Fetches the groupId of the group that should recieve the ticket based on the riskType
	 */
	private async getGroupForRiskType(): Promise<number | null> {
		switch (this.riskType.toLowerCase()) {
			case "resident clinical risk":
				if (this.isExistingRisk && !this.isRiskIncreased) {
					return this.groupsService.getGroupIdByName("Clinical Care");
				}

				return this.groupsService.getGroupIdByName("Clinical Governance");
			case "resident non-clinical risk":
				return this.groupsService.getGroupIdByName("Risk Management");
			default:
				return this.groupsService.getGroupIdByName("OHSC Management");
		}
	}

	/**
	 * @description Fetches the original data, and historical data that will not change by modification fo this form
	 */
	private handleInitialData(): void {
		const lastStage0Submission = this.getMostRecentSubmission(this.formData, false, 0);
		const firstAssessment = this.getOldestSubmission(this.formData, false, 1, undefined);
		const lastAssessment = this.getMostRecentSubmission(this.formData, false, 1, undefined);

		if (!lastStage0Submission) {
			throw new Error("There should always be a stage 0 submission");
		}

		if (!firstAssessment) {
			throw new Error("There should always be a first 1 submission");
		}

		if (!lastAssessment) {
			throw new Error("There should always be a last 1 submission");
		}

		const propsOfInterest = {
			riskType: new FormField<string>('1', {
				recordParamType: RecordParamType.Number
			}),
			isExistingRisk: new FormField<boolean>(false, {
				recordParamType: RecordParamType.Boolean
			}),
			existingRisk: new FormField<number>(null, {
				validation: FormField.ValidationMethods.None
			})
		};

		this.updateFromRecordParams(propsOfInterest, lastStage0Submission);

		this.riskType = riskTypeMap[(propsOfInterest.riskType.value || '1').toString()];
		this.isExistingRisk = !!(propsOfInterest.isExistingRisk.value && propsOfInterest.existingRisk.value);
		this.isRiskRemoved = this.getBoolData(lastAssessment, 'riskRemoved') || false;

		if (!this.isRiskRemoved) {
			const initialRisk = this.getStringData(firstAssessment, 'riskLevel');
			const newRisk = this.getStringData(lastAssessment, 'postMitigationRiskLevel');

			if (initialRisk && newRisk) {
				this.isRiskIncreased = Risk.isHigherRisk(initialRisk, newRisk);
			}
		} else {
			this.form.riskRemoved.value = true;
			this.form.riskRemoved.disabled = true;
		}
	}

	private async repopulateFormFromData() {
		this.handleInitialData();

		const lastSubmission = this.getMostRecentSubmission(this.formData, true, this.currentFormStage, this.sequence);

		// Do not recall the last submission if it is complete but not specifically requested
		if (!lastSubmission || (!this.sequence && lastSubmission.isComplete)) {
			return this.loadFollowUpData();
		}

		this.formRecord = lastSubmission;
		this.updateFromRecordParams(this.form, this.formRecord);

		// get attached documents
		this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);

		return;
	}

	/**
	 * @description Loads follow up data from the most recent submission of any type
	 */
	private loadFollowUpData() {
		if (!this.form.followUps.valueIsNull) return;

		const mostRecentSubmission = this.getMostRecentSubmission(this.formData, true);

		if (mostRecentSubmission) {
			const followUpData = this.getJsonData(mostRecentSubmission, 'followUps');
			this.form.followUps.value = followUpData;
		}
	}

	private loadEnums() {
		this.session.lockInputRx(this.enumService.getEnumsByName('reportFormType'))
			.subscribe((data: IEnumsOutputModel[]) => {
				const followUpFormType = data.find(typeEnum => typeEnum.value.toLowerCase() === 'other');
				if (followUpFormType) {
					this.followUpFormTypeId = followUpFormType.id;
				}
			});
	}
}
