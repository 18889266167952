var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpParams } from "@angular/common/http";
import { GenericService } from "./GenericService";
import { stringifyObjectValues } from "./util/stringifyObjectValues";
var ReportService = /** @class */ (function (_super) {
    __extends(ReportService, _super);
    function ReportService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    ReportService.prototype.downloadReadOnlyFormsReport = function (formId) {
        return this.http.get(ReportService.apiPrefix + "/" + formId, {
            responseType: 'blob',
        });
    };
    ReportService.prototype.downloadDashboardCsvReport = function (params) {
        var queryParams = new HttpParams({
            fromObject: stringifyObjectValues(params)
        });
        return this.http.get(ReportService.apiPrefix + "/dashboardCsv", {
            params: queryParams,
            responseType: 'blob',
        });
    };
    ReportService.prototype.downloadDRMDashboardCsvReport = function (params) {
        var queryParams = new HttpParams({
            fromObject: stringifyObjectValues(params)
        });
        return this.http.get(ReportService.apiPrefix + "/drmDashboardCsv", {
            params: queryParams,
            responseType: 'blob',
        });
    };
    ReportService.prototype.downloadRISKcsvReport = function (offset, pageSize, sortBy, order, searchFilter, locationFilter, riskLevelFilter, riskStatusFilter, riskTypeId) {
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 20; }
        if (sortBy === void 0) { sortBy = 'id'; }
        if (order === void 0) { order = 'asc'; }
        var queryParams = new HttpParams({
            fromObject: {
                skip: offset.toString(),
                limit: pageSize.toString(),
                sortBy: sortBy,
                order: order,
                searchFilter: searchFilter,
                locationFilter: locationFilter,
                riskLevelFilter: riskLevelFilter,
                riskStatusFilter: riskStatusFilter,
                riskTypeId: riskTypeId
            }
        });
        console.log("downloadRISKcsvReport");
        return this.http.get(ReportService.apiPrefix + "/downloadRiskCSV", {
            params: queryParams,
            responseType: 'blob',
        });
    };
    ReportService.apiPrefix = '/api/report';
    return ReportService;
}(GenericService));
export { ReportService };
