<div class="row">
    <div class="col-12 text-lg-left">
        <h1 class="text-uppercase text-orange">{{isNew ? 'New' : 'Edit'}} Tag</h1>
    </div>
</div>


<form [formGroup]="tagForm">
   <div class="row">
        <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
            <div class="form-group">
                <label for="usernameInput">Tag Name:</label>
                <input
                    type="text"
                    id="usernameInput"
                    autofocus 
                    class="form-control input-lg"
                    rxControl
                    formControlName="name"
                >
                <small class="form-text text-danger" *ngIf="tagForm.controls.name.touched && tagForm.controls.name.hasError('required')">This field is required.</small>
            </div>
        </div>
        <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
            <div class="form-group">
                <label for="usernameInput">Is Meta Data:</label>
                <button-toggle [control]="tagForm.controls.isMeta"></button-toggle>
            </div>
        </div>
        
    </div>
    <div class="row bg-default mx-1 my-3 py-4">
        <div class="col-12 col-lg-4 p-1 text-center mt-lg-2 mt-xl-0">
            <span *ngIf="!!tag">Date Created: <span class="text-primary">{{ tag.createdAt | date: 'mediumDate' }}</span></span>
        </div>
        <div class="col-12 col-lg-4 p-1 text-center mt-lg-2 mt-xl-0">
            <span *ngIf="!!tag">Date Updated: <span class="text-primary">{{ tag.updatedAt | date: 'mediumDate' }}</span></span>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
            <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit()" [disabled]="tagForm.invalid">
                <span class="capitalize">save</span>
            </button>
        </div>
        <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-4 col-xl-2 col-xxl-1">
            <a class="btn btn-block btn-lg btn-secondary rounded-0 text-center" [routerLink]="['/admin/tags']"><span class="capitalize">cancel</span></a>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1" *ngIf="!isNew && false">
            <button class="btn btn-block btn-lg btn-danger rounded-0" (click)="archiveTag()"><span class="capitalize">delete</span></button>
        </div>
    </div>
</form>
