import { Injectable } from '@angular/core';
import {GenericService} from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  DocumentTagPaginationResponse,
  ICreateDocumentTagInputModel,
  IDocumentTagSortedField,
  IUpdateDocumentTagInputModel,
  IDocumentTagOutputModel
} from "../../../common/contracts/documentTag";
import { ISortOrderValue } from "../../../common/contracts/common";
import { Observable, of } from "rxjs";
import { share, map, tap } from "rxjs/operators";

@Injectable()
export class DocumentTagsService extends GenericService {

  static readonly apiPrefix = '/api/document-tag';

  private cachedDocumentTags: IDocumentTagOutputModel[] | null = null;
  private readonly getDocumentTagsRequest: Observable<IDocumentTagOutputModel[]>;

  constructor(private http: HttpClient) {
    super();

    const queryParams = new HttpParams({
      fromObject: {
        skip: '0',
        limit: '50',
        sortBy: 'name',
        order: 'asc',
      }
    });

    this.getDocumentTagsRequest = this.http.get<DocumentTagPaginationResponse>(DocumentTagsService.apiPrefix, { params: queryParams}).pipe(
      map(data => {
        this.cachedDocumentTags = data.items;
        setTimeout(() => {
          this.cachedDocumentTags = null; // invalidate tags cache in 20 minutes
        }, 20 * 60 * 1000);
        return data.items;
      }),
      share() //share results between multiple subscriptions
    );
  }

  public getTags(): Observable<IDocumentTagOutputModel[]> {
    return this.cachedDocumentTags ? of(this.cachedDocumentTags) : this.getDocumentTagsRequest;
  }

  public getPagedTags(offset: number = 0, pageSize: number = 20, sortBy: IDocumentTagSortedField = 'name', order: ISortOrderValue = 'asc', counts: boolean = true) {
    const queryParams = new HttpParams({
      fromObject: {
        skip: offset.toString(),
        limit: pageSize.toString(),
        sortBy,
        order,
        // counts: counts ? '1' : '0',
      }
    });
    return this.http.get<DocumentTagPaginationResponse>(DocumentTagsService.apiPrefix, { params: queryParams});
  }

  public getTagByDocumentId(id: number) {
    return this.http.get(`${DocumentTagsService.apiPrefix}/document/${id}`);
  }

  public createTag(tagData: ICreateDocumentTagInputModel) {
    return this.http.post(DocumentTagsService.apiPrefix, tagData).pipe(tap(() => {
      this.cachedDocumentTags = null;
    }));
  }

  public updateTag(tagChanges: IUpdateDocumentTagInputModel) {
    return this.http.put(DocumentTagsService.apiPrefix, tagChanges).pipe(tap(() => {
      this.cachedDocumentTags = null;
    }));
  }

  public archiveTag(id: number) {
    return this.http.delete(`${DocumentTagsService.apiPrefix}/${id}`).pipe(tap(() => {
      this.cachedDocumentTags = null;
    }));
  }

}
