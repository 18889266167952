import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import "moment-timezone";
import { FormField, RecordParamType } from "../../../../../model/Form";
import { FormComponent } from "../../../../../model/FormComponent";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormService } from "../../../../../service/FormService";
import { FormCategory } from "../../../../../../model/Category";
import { CategoryService } from "../../../../../service/CategoryService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import {
  IFormOutputModel,
  IFormRecordOutputModel
} from "../../../../../../common/contracts/form";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { Router } from "@angular/router";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { IDocumentType } from "../../../../../../common/contracts/document";
import { FollowUpService } from 'service/FollowUpService';
import { EnumService } from 'service/EnumService';
import { IEnumsOutputModel } from '../../../../../../common/contracts/enums';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'audit-form-2',
  templateUrl: './auditFormStage2.component.html',
})
export class AuditFormStage2Component extends FormComponent implements OnInit {

  protected thisStage: number = 2;

  // Existing Form Data
  @Input() readOnly: boolean = false;
  @Input() hideHeader: boolean = false;

  @Input() formData: IFormOutputModel;
  @Input() sequence: number;

  public formRecord: IFormRecordOutputModel;
  public reportFormTypes: IEnumsOutputModel[] = [];

  @ViewChild('followUpWidget') followUpWidgetRef: FollowUpWidgetComponent;

  public dateString: string;
  public formCategoryId: number;
  public form: { [key: string]: FormField<any> } = {
    summary: new FormField<String>('', {
      validation: FormField.ValidationMethods.IsNotBlank,
      recordParamType: RecordParamType.String
    }),
    notes: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.String
    }),
    followUps: new FormField<string>('[]', {
      nullEquivilent: "[]",
      validation: (value: string) => {
        return this.followUpWidgetRef ? this.followUpWidgetRef.validate() : true;
      },
      recordParamType: RecordParamType.JSON
    }),
    reassign: new FormField<boolean>(false, {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.Boolean
    }),
    reassignToUserId: new FormField<string>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.Number
    })
  };

  public documents: Array<IDocumentType> = [];

  constructor(
    public session: Session,
    public currentUserService: CurrentUserService,
    public formService: FormService,
    public categoryService: CategoryService,
    public formRecordService: FormRecordService,
    public groupsService: GroupsService,
    private errorHandler: ErrorHandlerService,
    public router: Router,
    public followUpService: FollowUpService,
    public enumService: EnumService
  ) {
    super(router);
  }

  registerFormFields() {
    this.formFields.push(...Object.keys(this.form).map((k: string) => this.form[k]));
  }

  private getLastSubmission(): IFormRecordOutputModel | null {
    if (!this.formData || !this.formData.records || !this.formData.records.length)
      return null;

    let submissions = this.formData.records.filter(record => record.isComplete);

    if (!submissions || !submissions.length)
      return null;

    return submissions[submissions.length - 1];
  }

  onSubmit(isDraft: boolean) {

    this.session.lockInput(() => {

      return new Promise(async (resolve, reject) => {

        let success = () => {
          resolve();

          this.router.navigate(['/dashboard']);
        };

        let fail = (msg: string, err: any) => {
          console.error(msg, err);
          this.errorHandler.handleHttpError(err);
          reject();
        };

        // This will be equal to the current stage. Drafts should not increment this value
        let stage: number = this.thisStage;

        let assignedUserId: number | null = this.formData.assignedUserId;
        let userGroupId: number | null = this.formData.userGroupId;

        // If its a draft assign it to the current user
        const currentUserId = this.currentUserService.getCurrentUserIdOrFail();
        if (isDraft) {
          assignedUserId = currentUserId;
        } else {
          // The audit form is now going to stage 1 for action
          stage = this.thisStage + 1;

          if (this.form.reassign.value && this.form.reassignToUserId.value) {
            stage = this.thisStage - 1;

            assignedUserId = Number(this.form.reassignToUserId.value);
          } else {
            let lastSubmission = this.getLastSubmission();

            /*
              If:
               There is a last submission
               AND that last submission is a stage 1 submission
               AND that last submission was made by another user
               AND that last submission assigned the form to the current user
            */
            let assignBack: boolean = false;

            if (lastSubmission && lastSubmission.stage === this.thisStage
              && lastSubmission.createdById !== assignedUserId
              && lastSubmission.createdById !== currentUserId
            ) {
              let reassignProperty = lastSubmission.properties.find(property => property.property.name === 'reassignToUserId');

              if (reassignProperty && reassignProperty.intData === assignedUserId)
                assignBack = true;
            }

            if (lastSubmission && assignBack) {
              //Assign the submission back to that user, leaving the form in stage 1
              stage = this.thisStage;

              assignedUserId = lastSubmission.createdById;
            } else {
              stage = this.thisStage + 1;

              // Assign it to senior management
              assignedUserId = null;

              let groups = await this.groupsService.getGroups().toPromise();
              let adminGroup = groups.find(group => group.groupName.match(/^senior management/gi) !== null);

              if (adminGroup)
                userGroupId = adminGroup.id;
              else
                userGroupId = null;
            }
          }
        }

        const formFunc = this.formService.updateForm({
          id: this.formData.id,
          stage,
          userGroupId,
          assignedUserId
        });

        formFunc.subscribe((data: any) => {

          let formId: number = this.formData.id || data['id'];

          let properties = this.toRecordParams(this.form);

          this.formRecordService.createRecord({
            formId: formId,
            // Intentionally cast the properties object since we know its correct
            properties: properties as any,
            stage: this.thisStage,
            documents: this.documents.map(doc => ({ id: doc.id, isTicked: !!doc.isTicked })),
            isComplete: !isDraft
          })
            .subscribe((data: any) => {
              //Done creating the form and appending its properties

              if (stage === this.thisStage + 1) {
                this.formService.finalizeForm(this.formData.id)
                  .subscribe(() => {

                    // Generate the follows
                    let followUps: { userGroupId: string, description: string, dueDate: string }[] = [];
                    if (this.form.followUps.value.length) {
                      followUps = JSON.parse(this.form.followUps.value);
                    }

                    if (followUps.length === 0)
                      return success();

                    let formTypeId: number | null = null;
                    const reportFormTypeEnum = this.reportFormTypes.find(typeEnum => typeEnum.name.toLowerCase() === 'other');

                    if (reportFormTypeEnum)
                      formTypeId = reportFormTypeEnum.id

                    this.followUpService.generateFollowUps(this.formData.id, this.formData.formLocationId, followUps, formTypeId, success);
                  },
                    err => fail('Error while finalizing a form', err)
                  );
              } else {
                success();
              }

              this.router.navigate(['/dashboard']);

            }, err => {
              console.error('Error while creating a record', err);
              this.errorHandler.handleHttpError(err);
              reject();
            });
        },
          err => {
            console.error('Error while create/update form', err);
            this.errorHandler.handleHttpError(err);
            reject();
          });
      })
    });
  }

  ngOnInit() {

    this.dateString = moment().tz(environment.timeZone).format("DD-MM-YYYY");

    this.registerFormFields();

    this.loadEnums();

    this.repopulateFormFromData();
  }

  private repopulateFormFromData() {
    if (!this.formData || !this.formData.records || !this.formData.records.length)
      return;

    let stageRecords = this.formData.records.filter(record => record.stage === this.thisStage);

    const followUps = this.loadStringField(this.thisStage - 1, this.formData, 'followUps');

    if (followUps)
      this.form.followUps.value = followUps.jsonData;

    if (stageRecords.length === 0)
      return;

    if (!this.sequence) {
      let mostRecentRecord = stageRecords.sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

      if (!mostRecentRecord)
        throw new Error("internal error");

      // If the most recent record was a submission, we are not going to use it
      if (mostRecentRecord.isComplete) {
        // It is likely the client will require that some fields are always loaded
        return;
      }

      this.formRecord = mostRecentRecord;
    } else {
      let targetRecord = stageRecords.find(record => record.sequence === this.sequence);

      if (!targetRecord)
        throw new Error("internal error");

      this.formRecord = targetRecord;
    }

    this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);

    this.updateFromRecordParams(this.form, this.formRecord);

    // get attached documents
    this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);

    this.showReassignOptions();
  }

  // Audit Stage 0 Functions
  private loadEnums() {
    this.session.lockInputRx(this.categoryService.getCategories())
      .subscribe((data: FormCategory[]) => {

        let reportCategory: FormCategory | undefined = data.find((data: FormCategory) => !!data.name.match(/^Audit/));

        if (reportCategory)
          this.formCategoryId = reportCategory.id as number;
        //else
        //Notify the user an error has occurred
      },
        err => {
          console.error('Error while getting categories', err);
          this.errorHandler.handleHttpError(err);
        });

    this.session.lockInputRx(this.enumService.getEnumsByName('reportFormType'))
      .subscribe((data: IEnumsOutputModel[]) => {
        this.reportFormTypes = data;
      });
  }

  public showReassignOptions(state?: boolean | null): void {
    if (state !== undefined && state !== null)
      this.form.reassign.value = state;
    else
      state = this.form.reassign.value;
    if (state) {
      this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.IsNotBlank);
      this.setFieldValidation(this.form.summary, FormField.ValidationMethods.None);
    } else {
      this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.None);
      this.setFieldValidation(this.form.summary, FormField.ValidationMethods.IsNotBlank);
    }
  }
}