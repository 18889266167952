var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as t from 'io-ts';
import { IntFromString } from 'io-ts-types/lib/IntFromString';
import { EmailRegex, timeZone } from '../constants';
import * as moment from 'moment';
import 'moment-timezone';
export var GetByIdParams = t.exact(t.type({ id: t.refinement(IntFromString, function (v) { return v > 0; }, 'valid id') }));
/*
  * This fails compilation after an unknown change in package (likely io-ts) which has resulted
  * in a discrepency between the result of t.literal and the expected class Mixed, to be returned
  * by t.union
  */
// @ts-ignore
export var SortOrderValues = t.union(['asc', 'desc'].map(function (f) { return t.literal(f); }));
export var PhoneValidationModel = t.union([t.refinement(t.string, function (s) { return /^\d{10}$/.test(s); }, '10 digits'), t.null]);
export var EmailValidationModel = t.refinement(t.string, function (v) { return EmailRegex.test(v.toLowerCase()); }, 'valid email');
/**
 * this io-ts type convert date from a string in format DD-MM-YYYY into JS Date object
 * it sets time at start of the day in timezone Australia/Brisbane
 */
var DateFromQueryParamType = /** @class */ (function (_super) {
    __extends(DateFromQueryParamType, _super);
    function DateFromQueryParamType() {
        var _this = _super.call(this, 'DateFromQueryParam', function (u) { return u instanceof Date; }, function (u, c) {
            var validation = t.string.validate(u, c);
            if (validation.isLeft()) {
                return validation;
            }
            else {
                var s = validation.value;
                if (!/^\d{1,2}-\d{1,2}-\d{4}$/.test(s)) {
                    return t.failure(s, c);
                }
                var momentDate = moment(s, 'DD-MM-YYYY').tz(timeZone);
                if (!momentDate.isValid()) {
                    return t.failure(s, c);
                }
                momentDate.startOf('day');
                return t.success(momentDate.toDate());
            }
        }, function (a) { return a.toISOString(); }) || this;
        _this._tag = 'DateFromQueryParamType';
        return _this;
    }
    return DateFromQueryParamType;
}(t.Type));
export var DateFromQueryParam = new DateFromQueryParamType();
