var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { RxFormsService } from "../../../../../service/util/reactiveForms";
import { EnumService } from "../../../../../service/EnumService";
import { ModalService } from "../../../../../service/ModalService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { trimRequiredValidator } from "../../../../../validators/reactiveFormValidators";
import { ResourcePackService } from 'service/ResourcePackService';
var EditEnumsSetComponent = /** @class */ (function () {
    function EditEnumsSetComponent(enumService, router, route, activatedRoute, errorService, modalService, resourcePackService) {
        var _this = this;
        this.enumService = enumService;
        this.router = router;
        this.route = route;
        this.activatedRoute = activatedRoute;
        this.errorService = errorService;
        this.modalService = modalService;
        this.resourcePackService = resourcePackService;
        this.enums = [];
        this.isNew = false;
        // To prevent "Object Possibly Null" in Template
        this.sortableOptions = {};
        this.enumForm = new FormGroup({
            name: new FormControl(''),
            values: new FormArray([])
        });
        this.sortableOptions = {
            animation: 150,
            onUpdate: function () {
                _this.enumForm.markAsDirty();
            }
        };
    }
    EditEnumsSetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.resourcePackService.fetch().toPromise()];
                    case 1:
                        _a.resourcePacks = _b.sent();
                        if (params.enumName) {
                            this.enumService.getEnumsByName(params.enumName, false).subscribe(function (enums) {
                                _this.enums = enums;
                                _this.enumForm.setValue({
                                    name: params.enumName,
                                    values: []
                                });
                                _this.enumForm.controls.name.disable();
                                for (var _i = 0, enums_1 = enums; _i < enums_1.length; _i++) {
                                    var enumEntry = enums_1[_i];
                                    _this.enumForm.get('values').push(new FormGroup({
                                        id: new FormControl(enumEntry.id),
                                        value: new FormControl(enumEntry.value, trimRequiredValidator),
                                        resourcePack: new FormControl(enumEntry.resourcePackId ? enumEntry.resourcePackId.toString() : '')
                                    }));
                                }
                            }, function (err) { return _this.errorService.handleHttpError(err); });
                        }
                        else {
                            this.isNew = true;
                            this.valuesFormArray.push(new FormGroup({
                                id: new FormControl('new'),
                                value: new FormControl('', trimRequiredValidator),
                                resourcePack: new FormControl()
                            }));
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
    Object.defineProperty(EditEnumsSetComponent.prototype, "valuesFormArray", {
        get: function () {
            return this.enumForm.get('values');
        },
        enumerable: true,
        configurable: true
    });
    EditEnumsSetComponent.prototype.handleRemoveValue = function (index) {
        var _this = this;
        // if it's new item - remove without confirmation
        var idValue = this.valuesFormArray.at(index).controls.id.value;
        if (idValue === 'new') {
            this.valuesFormArray.removeAt(index);
            this.enumForm.markAsDirty();
            return;
        }
        this.modalService.confirmRx({
            title: 'Warning',
            message: 'Are you sure that you want to remove this enum entry?'
        }).subscribe(function (isConfirmed) {
            if (isConfirmed) {
                _this.valuesFormArray.removeAt(index);
                _this.enumForm.markAsDirty();
            }
        });
    };
    EditEnumsSetComponent.prototype.handleAddEntry = function () {
        this.valuesFormArray.push(new FormGroup({
            id: new FormControl('new'),
            value: new FormControl('', trimRequiredValidator),
            resourcePack: new FormControl()
        }));
        this.enumForm.markAsDirty();
    };
    EditEnumsSetComponent.prototype.submit = function () {
        var _this = this;
        if (this.enumForm.invalid) {
            RxFormsService.touchAllFormControls(this.enumForm);
            return;
        }
        var formData = this.enumForm.getRawValue();
        formData.name = formData.name.trim();
        for (var i = 0; i < formData.values.length; i++) {
            formData.values[i].value = formData.values[i].value.trim();
            if (!formData.values[i].resourcePack || !formData.values[i].resourcePack.length)
                delete formData.values[i].resourcePack;
        }
        var request = this.isNew ? this.enumService.createEnumsSet(formData) : this.enumService.updateEnumsSet(formData);
        request.subscribe(function () { return _this.goBack(); }, function (err) { return _this.errorService.handleHttpError(err); });
    };
    EditEnumsSetComponent.prototype.goBack = function () {
        this.router.navigate(['..'], { relativeTo: this.route });
    };
    return EditEnumsSetComponent;
}());
export { EditEnumsSetComponent };
