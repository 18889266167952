import { Component } from "@angular/core";
import { DocumentService } from "service/DocumentService";
import { IDocumentType } from "../../../../common/contracts/document";
import { UploadModalState } from "../../../service/ModalService";

@Component({
	selector: 'upload-modal',
	templateUrl: './uploadModal.component.html',
	styles: [`
	.rotate {
		/* FF3.5+ */
		-moz-transform: rotate(-90.0deg);
		/* Opera 10.5 */
		-o-transform: rotate(-90.0deg);
		/* Saf3.1+, Chrome */
		-webkit-transform: rotate(-90.0deg);
		/* IE6,IE7 */
		filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.083);
		/* IE8 */
		-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)";
		/* Standard */
		transform: rotate(-90.0deg);
	}

	.hidden-input {
		display: none;
	}
  `]
})
export class UploadModalComponent {

	constructor(
		public state: UploadModalState,
		private documentService: DocumentService,
	) {
	}

	cancel() {
		this.state.modal.close();
	}

	/**
	 * uploads handlers
	 */

	public handleFileSelected(event) {
		if (event.target.files.length !== 1) {
			return;
		}

		const file: File = event.target.files[0];
		this.state.isUploading.next(true);
		this.documentService.uploadDocument(file).subscribe((newDocument: IDocumentType) => {
			this.state.documentUploaded.next(newDocument);
			this.state.isUploading.next(false);
			this.state.modal.close(newDocument);
		}, err => {
			this.state.isUploading.next(false);
			this.state.uploadError.next(err);
		});
	}
}
