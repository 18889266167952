import { OnInit } from "@angular/core";
import { FormField } from "./Form";
import { ComponentCanDeactivate } from "../views/shared/canDeactivate/ComponentCanDeactivate";
import { IDocumentType } from "../../common/contracts/document";
import "moment-timezone";
import * as moment from 'moment';
import { environment } from "../environments/environment";
import { IFormOutputModel, IFormRecordPropertyParam, IFormRecordOutputModel } from "../../common/contracts/form";
import { IRecordPropertyType } from "../../common/contracts/recordProperty";
import { logger } from "service/util/Logger";
import { isNullOrUndefined } from "util";
import { Router } from "@angular/router";

export abstract class FormComponent extends ComponentCanDeactivate implements OnInit {

	public blockUnload: boolean = true;
	public className = "FormComponent";

	public currentFormStage = 0;

	//TODO: Move this to a config somewhere
	public dateFormat: string = "DD-MM-YYYY, h:mm a";

	/* Generic Select2 Options */
	select2Options: Select2Options = {};

	// List of Custom FormFields which are on the page
	formFields: Array<FormField<any>> = [];

	// Indicate that the form was invalid after an attempt to submit
	showFormHasError: boolean = false;
	errorMessage: string | null;

	private disableDirtyCheck: boolean = false;

	protected constructor(
		public router: Router
	) {
		super();
	}

	/* TODO: Can we please ensure that we use location.back only when the previous url was dashboard, otherwise go to /dashboard */
	protected closeForm() {
		this.router.navigateByUrl('/dashboard');
	}

	abstract registerFormFields(): void;
	abstract ngOnInit(): void;
	abstract onSubmit(isDraft: boolean): void;

	public canDeactivate(): boolean {
		const signature = this.className + ".canDeactivate:";

		// This is automatically set to false when a submission is attempted, and then back to true
		if (this.disableDirtyCheck) {
			logger.silly(signature + `Bypassing dirty form check`);
			return true;
		}

		// Find any dirty form fields
		if (this.formFields.find(field => field.isDirty)) {
			logger.silly(signature + `Form contains dirty data`);
			return false;
		}

		return true;
	}

	/**
	 * @description Provides access to the private disableDirtyCheck property
	 */
	public getDirtyCheckState(): boolean {
		return this.disableDirtyCheck;
	}

	validateForm(): boolean {
		const signature = this.className + ".validateForm:";
		this.disableDirtyCheck = false;

		let hasError: boolean = false;

		this.formFields.forEach((field, idx) => {
			if (!field.isValid) {
				field.showError = true;
				hasError = true;

				if (!environment.production) {
					console.error(`Field at idx [${idx}] is not valid`);
					console.error(field);
				}
			}
		});

		this.showFormHasError = hasError;

		logger.silly(signature + `Validating Form ${hasError ? 'failed' : 'passed'}`);

		return !hasError;
	}

	/**
	 * @description Updates the field validation of any field, setting it to the new alidation method
	 * @param {FormField<any>} field 
	 * @param validationMethod 
	 */
	setFieldValidation(field: FormField<any>, validationMethod: (value: String | null) => boolean): void {
		const signature = this.className + '.setFieldValidation: ';
		this.disableDirtyCheck = false;

		if (field) {
			if (validationMethod === field.validation) {
				logger.debug(signature + `Ignored Validation Change Field[${field.name || 'with value ' + (isNullOrUndefined(field.value) ? 'null' : field.value).toString()}]`);
				return;
			}

			if (!field.originalValidation) {
				field.originalValidation = field.validation;
			}

			field.validation = validationMethod;
			field.validate();

			logger.silly(signature + `Updated Field Validation for Field[${field.name || 'with value ' + (isNullOrUndefined(field.value) ? 'null' : field.value).toString()}]`);
		} else {
			logger.info(signature + "Attempted to set FieldValidation on Null Field");
		}
	}

	/**
	 * @description Returns the validation of any field that has been updated at runtime to its original value
	 */
	resetAllValidation(skipValidation: boolean = false) {
		const signature = this.className + ".resetAllValidation: ";
		this.formFields.forEach(field => {
			if (field.originalValidation) {
				field.validation = field.originalValidation;
				if (!skipValidation) field.validate();
				field.originalValidation = null;

				logger.silly(signature + `Updated Field Validation for Field[${field.name || 'with value ' + (isNullOrUndefined(field.value) ? 'null' : field.value).toString()}]`);
			}
		});
	}

	submit(isDraft: boolean = false): void {
		const signature = this.className + ".submit:";
		logger.silly(signature + `Handling Submit with IsDraft[${isDraft}]`);

		this.errorMessage = null;

		if (!this.validateForm()) {
			this.errorMessage = ' ';

			// Scroll to the top and ask them to start again
			if (!this.validateForm()) {
				this.errorMessage = ' ';
				let ngComponents: HTMLCollectionOf<Element> = window.document.getElementsByClassName("main-container");
				console.log("OnScroll");
				for (let i = 0; i < ngComponents.length; i++) {
					//@ts-ignore
					if (ngComponents[i].scrollTo)
						ngComponents[i].scrollTo(0, 0);
					//@ts-ignore
					else if (ngComponents[i].scroll)
						ngComponents[i].scroll(0, 0);
				}

				logger.silly(signature + `Failed 2nd Pass Form Validation`);
				return;
			}
			logger.silly(signature + `Failed Form Validation`);
			return;
		}

		this.disableDirtyCheck = true;
		logger.silly(signature + `Form Validation Passed. Disabling Dirty Checks.`);

		this.onSubmit(isDraft);
	}

	select2Changed(selectedOpts: IdTextPair[], target: FormField<any>, allowMultiple: boolean = false) {
		if (selectedOpts.length === 0 || (
			selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0
		)) {
			target.value = null;

			return;
		}

		if (allowMultiple) {
			let val: Array<any> = selectedOpts.map((opt: IdTextPair) => opt.id);

			target.value = JSON.stringify(val);
		} else {
			if (selectedOpts.length > 1)
				throw ("Selected options unexpectedly contained multiple results");

			target.value = selectedOpts[0].id;
		}
	}

	protected initTickedDocuments(documents: IDocumentType[] | undefined, tickedDocuments: Array<{ id: number }> | undefined): Array<IDocumentType> {
		if (!documents || documents.length === 0) {
			return [];
		}
		if (!tickedDocuments || tickedDocuments.length === 0) {
			return this.guaranteeUniqueDocuments(documents);
		}

		return this.guaranteeUniqueDocuments(documents.map(doc => ({
			...doc,
			isTicked: !!tickedDocuments.find(d => d.id === doc.id)
		})));
	}

	/**
	 * FIX: If documents somehow contains a duplicate entry, there will be a key violation, so this needs
	 * to be rectified by guaranteeing there are no duplicates
	 */
	protected guaranteeUniqueDocuments(docs: Array<IDocumentType>): Array<IDocumentType> {
		let docData = docs.map(predicate => JSON.stringify(predicate));

		let result = docs.filter(
			(predicate, idx) => docData.indexOf(JSON.stringify(predicate)) === idx
		);

		return result;
	}

	/**
	 * Reusable processing methods for data
	 */
	positiveValidInt(field: FormField<any>): number | null {
		let result: number | null = field.isValid && field.value !== null ? Number(field.value) : null;

		return (result === 0 ? null : result);
	}

	/**
	 * Parses a local due date and calculates an appropriate alertDate
	 * 
	 * @param localDueDate 
	 * @param startDate 
	 */
	protected parseDueDate(localDueDate: string, startDate?: moment.Moment, isTemplate: boolean = false): { dueAt: string | null, alertAt: string | null } {

		if (isTemplate) {
			return {
				dueAt: null,
				alertAt: null
			};
		}

		const start = startDate ? startDate.clone() : moment().tz(environment.timeZone).set({
			'hour': 0,
			'minute': 0,
			'second': 0,
			'millisecond': 0
		});

		let dueAt = moment(localDueDate, 'DD-MM-YYYY').tz(environment.timeZone);

		let dueAtOffset: number = (dueAt.diff(start, 'days') / 2);

		let alertAt = start.add(dueAtOffset, 'days');

		return {
			dueAt: dueAt.toISOString(false) as string,
			alertAt: alertAt.toISOString(false) as string
		}
	}

	/**
	 * Determines if the passed form data
	 * 
	 * @param formData 
	 */
	protected isNewForm(formData?: IFormOutputModel | null): boolean {
		return !(formData && formData.id);
	}

	/**
	 * Converts a form and its constituent form fields into an array of formRecordParams
	 * 
	 * @param form 
	 */
	protected toRecordParams(form: { [key: string]: FormField<any> }): (Partial<IFormRecordPropertyParam> | undefined)[] {
		return Object.keys(form).map(key =>
			form[key].toRecordParam(key)
		).filter(val => val !== undefined);
	}

	/**
	 * Loads data from a formRecord into an editable formObject
	 * 
	 * @param form 
	 * @param record 
	 */
	protected updateFromRecordParams(form: { [key: string]: FormField<any> }, record: IFormRecordOutputModel): void {
		Object.keys(form).forEach(key => {
			const recordProperty = record.properties.find(prop => prop.property && prop.property.name === key);

			if (recordProperty)
				form[key].fromRecordParam(key, recordProperty);
		})
	}

	/**
	 * @description Returns the first submission that matches the filters, assuming the inverse of the order in which they were submitted
	 * @param {IFormOutputModel} formData 
	 * @param {boolean} include_incomplete
	 * @returns {IFormRecordOutputModel | null}
	 */
	protected getMostRecentSubmission(
		formData: IFormOutputModel | null,
		include_incomplete: boolean = false,
		stage?: number,
		sequence?: number,
		filter?: (record: IFormRecordOutputModel) => boolean
	): IFormRecordOutputModel | null {
		const submissions = this.filterSubmissions(formData, include_incomplete, stage, sequence, filter);

		if (!submissions || !submissions.length)
			return null;

		return submissions[submissions.length - 1];
	}

	/**
	 * @description Returns the first submission that matches the filters, assuming the order they were submitted
	 * 
	 * @param {IFormOutputModel} formData 
	 * @param {boolean} include_incomplete
	 * @returns {IFormRecordOutputModel | null}
	 */
	protected getOldestSubmission(
		formData: IFormOutputModel | null,
		include_incomplete: boolean = false,
		stage?: number,
		sequence?: number,
		filter?: (record: IFormRecordOutputModel) => boolean
	): IFormRecordOutputModel | null {
		const submissions = this.filterSubmissions(formData, include_incomplete, stage, sequence, filter);

		if (!submissions || !submissions.length)
			return null;

		return submissions[0];
	}

	/**
	 * @description Filters the submissions in the supplied formData for further processing
	 * @param formData 
	 * @param include_incomplete 
	 * @param stage 
	 * @param sequence 
	 * @param filter 
	 * @returns 
	 */
	protected filterSubmissions(
		formData: IFormOutputModel | null,
		include_incomplete: boolean = false,
		stage?: number,
		sequence?: number,
		filter?: (record: IFormRecordOutputModel) => boolean
	): IFormRecordOutputModel[] {
		if (!formData || !formData.records || !formData.records.length)
			return [];

		const submissions = include_incomplete ? formData.records : formData.records.filter(record => record.isComplete);
		const stageSubmissions = stage !== undefined ? submissions.filter(record => record.stage === stage) : submissions;
		const sequenceSubmissions = sequence ? stageSubmissions.filter(record => record.sequence === sequence) : stageSubmissions;
		const filteredSequenceSubmissions = filter ? sequenceSubmissions.filter(filter) : sequenceSubmissions;
		
		return filteredSequenceSubmissions;
	}

	/**
	 * Handles all the repeated validation and verification associated with retrieving json data
	 * from a form record by fieldname
	 * 
	 * @param {IFormRecordOutputModel} record 
	 * @param {string} fieldName 
	 * @returns {string|null}
	 */
	protected getJsonData(record: IFormRecordOutputModel, fieldName: string): string | null {
		const jsonProperty = record.properties.find(recordProperty => recordProperty.property.name === fieldName);

		if (jsonProperty && jsonProperty.jsonData && jsonProperty.jsonData.length) {
			return jsonProperty.jsonData;
		}

		return null;
	}

	/**
	 * Handles all the repeated validation and verification associated with retrieving string data
	 * from a form record by fieldname
	 * 
	 * @param {IFormRecordOutputModel} record 
	 * @param {string} fieldName 
	 * @returns {string|null}
	 */
	protected getStringData(record: IFormRecordOutputModel, fieldName: string): string | null {
		const stringProperty = record.properties.find(recordProperty => recordProperty.property.name === fieldName);

		if (stringProperty && stringProperty.stringData && stringProperty.stringData.length) {
			return stringProperty.stringData;
		}

		return null;
	}

	/**
	 * Handles all the repeated validation and verification associated with retrieving int data
	 * from a form record by fieldname
	 * 
	 * @param {IFormRecordOutputModel} record 
	 * @param {string} fieldName 
	 * @returns {string|null}
	 */
	protected getIntData(record: IFormRecordOutputModel, fieldName: string): number | null {
		const stringProperty = record.properties.find(recordProperty => recordProperty.property.name === fieldName);

		if (stringProperty && stringProperty.intData !== null) {
			return stringProperty.intData;
		}

		return null;
	}

	/**
	 * Handles all the repeated validation and verification associated with retrieving int data
	 * from a form record by fieldname
	 * 
	 * @param {IFormRecordOutputModel} record 
	 * @param {string} fieldName 
	 * @returns {string|null}
	 */
	protected getBoolData(record: IFormRecordOutputModel, fieldName: string): boolean | null {
		const stringProperty = record.properties.find(recordProperty => recordProperty.property.name === fieldName);

		if (stringProperty && stringProperty.intData) {
			return true;
		}

		return false;
	}

	protected loadStringField(stage: number, formData: IFormOutputModel, fieldName: string): IRecordPropertyType | null | undefined {
		const stageRecords = formData.records.filter(record => record.stage === stage);

		if (stageRecords) {
			let mostRecentRecord = stageRecords.sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

			if (!mostRecentRecord)
				return null;

			return mostRecentRecord.properties.find(recordProperty => recordProperty.property.name === fieldName);
		}

		return null;
	}
}