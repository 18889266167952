var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentCanDeactivate } from "../views/shared/canDeactivate/ComponentCanDeactivate";
import "moment-timezone";
import * as moment from 'moment';
import { environment } from "../environments/environment";
import { logger } from "service/util/Logger";
import { isNullOrUndefined } from "util";
var FormComponent = /** @class */ (function (_super) {
    __extends(FormComponent, _super);
    function FormComponent(router) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.blockUnload = true;
        _this.className = "FormComponent";
        _this.currentFormStage = 0;
        //TODO: Move this to a config somewhere
        _this.dateFormat = "DD-MM-YYYY, h:mm a";
        /* Generic Select2 Options */
        _this.select2Options = {};
        // List of Custom FormFields which are on the page
        _this.formFields = [];
        // Indicate that the form was invalid after an attempt to submit
        _this.showFormHasError = false;
        _this.disableDirtyCheck = false;
        return _this;
    }
    /* TODO: Can we please ensure that we use location.back only when the previous url was dashboard, otherwise go to /dashboard */
    FormComponent.prototype.closeForm = function () {
        this.router.navigateByUrl('/dashboard');
    };
    FormComponent.prototype.canDeactivate = function () {
        var signature = this.className + ".canDeactivate:";
        // This is automatically set to false when a submission is attempted, and then back to true
        if (this.disableDirtyCheck) {
            logger.silly(signature + "Bypassing dirty form check");
            return true;
        }
        // Find any dirty form fields
        if (this.formFields.find(function (field) { return field.isDirty; })) {
            logger.silly(signature + "Form contains dirty data");
            return false;
        }
        return true;
    };
    /**
     * @description Provides access to the private disableDirtyCheck property
     */
    FormComponent.prototype.getDirtyCheckState = function () {
        return this.disableDirtyCheck;
    };
    FormComponent.prototype.validateForm = function () {
        var signature = this.className + ".validateForm:";
        this.disableDirtyCheck = false;
        var hasError = false;
        this.formFields.forEach(function (field, idx) {
            if (!field.isValid) {
                field.showError = true;
                hasError = true;
                if (!environment.production) {
                    console.error("Field at idx [" + idx + "] is not valid");
                    console.error(field);
                }
            }
        });
        this.showFormHasError = hasError;
        logger.silly(signature + ("Validating Form " + (hasError ? 'failed' : 'passed')));
        return !hasError;
    };
    /**
     * @description Updates the field validation of any field, setting it to the new alidation method
     * @param {FormField<any>} field
     * @param validationMethod
     */
    FormComponent.prototype.setFieldValidation = function (field, validationMethod) {
        var signature = this.className + '.setFieldValidation: ';
        this.disableDirtyCheck = false;
        if (field) {
            if (validationMethod === field.validation) {
                logger.debug(signature + ("Ignored Validation Change Field[" + (field.name || 'with value ' + (isNullOrUndefined(field.value) ? 'null' : field.value).toString()) + "]"));
                return;
            }
            if (!field.originalValidation) {
                field.originalValidation = field.validation;
            }
            field.validation = validationMethod;
            field.validate();
            logger.silly(signature + ("Updated Field Validation for Field[" + (field.name || 'with value ' + (isNullOrUndefined(field.value) ? 'null' : field.value).toString()) + "]"));
        }
        else {
            logger.info(signature + "Attempted to set FieldValidation on Null Field");
        }
    };
    /**
     * @description Returns the validation of any field that has been updated at runtime to its original value
     */
    FormComponent.prototype.resetAllValidation = function (skipValidation) {
        if (skipValidation === void 0) { skipValidation = false; }
        var signature = this.className + ".resetAllValidation: ";
        this.formFields.forEach(function (field) {
            if (field.originalValidation) {
                field.validation = field.originalValidation;
                if (!skipValidation)
                    field.validate();
                field.originalValidation = null;
                logger.silly(signature + ("Updated Field Validation for Field[" + (field.name || 'with value ' + (isNullOrUndefined(field.value) ? 'null' : field.value).toString()) + "]"));
            }
        });
    };
    FormComponent.prototype.submit = function (isDraft) {
        if (isDraft === void 0) { isDraft = false; }
        var signature = this.className + ".submit:";
        logger.silly(signature + ("Handling Submit with IsDraft[" + isDraft + "]"));
        this.errorMessage = null;
        if (!this.validateForm()) {
            this.errorMessage = ' ';
            // Scroll to the top and ask them to start again
            if (!this.validateForm()) {
                this.errorMessage = ' ';
                var ngComponents = window.document.getElementsByClassName("main-container");
                console.log("OnScroll");
                for (var i = 0; i < ngComponents.length; i++) {
                    //@ts-ignore
                    if (ngComponents[i].scrollTo)
                        ngComponents[i].scrollTo(0, 0);
                    //@ts-ignore
                    else if (ngComponents[i].scroll)
                        ngComponents[i].scroll(0, 0);
                }
                logger.silly(signature + "Failed 2nd Pass Form Validation");
                return;
            }
            logger.silly(signature + "Failed Form Validation");
            return;
        }
        this.disableDirtyCheck = true;
        logger.silly(signature + "Form Validation Passed. Disabling Dirty Checks.");
        this.onSubmit(isDraft);
    };
    FormComponent.prototype.select2Changed = function (selectedOpts, target, allowMultiple) {
        if (allowMultiple === void 0) { allowMultiple = false; }
        if (selectedOpts.length === 0 || (selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0)) {
            target.value = null;
            return;
        }
        if (allowMultiple) {
            var val = selectedOpts.map(function (opt) { return opt.id; });
            target.value = JSON.stringify(val);
        }
        else {
            if (selectedOpts.length > 1)
                throw ("Selected options unexpectedly contained multiple results");
            target.value = selectedOpts[0].id;
        }
    };
    FormComponent.prototype.initTickedDocuments = function (documents, tickedDocuments) {
        if (!documents || documents.length === 0) {
            return [];
        }
        if (!tickedDocuments || tickedDocuments.length === 0) {
            return this.guaranteeUniqueDocuments(documents);
        }
        return this.guaranteeUniqueDocuments(documents.map(function (doc) { return (__assign({}, doc, { isTicked: !!tickedDocuments.find(function (d) { return d.id === doc.id; }) })); }));
    };
    /**
     * FIX: If documents somehow contains a duplicate entry, there will be a key violation, so this needs
     * to be rectified by guaranteeing there are no duplicates
     */
    FormComponent.prototype.guaranteeUniqueDocuments = function (docs) {
        var docData = docs.map(function (predicate) { return JSON.stringify(predicate); });
        var result = docs.filter(function (predicate, idx) { return docData.indexOf(JSON.stringify(predicate)) === idx; });
        return result;
    };
    /**
     * Reusable processing methods for data
     */
    FormComponent.prototype.positiveValidInt = function (field) {
        var result = field.isValid && field.value !== null ? Number(field.value) : null;
        return (result === 0 ? null : result);
    };
    /**
     * Parses a local due date and calculates an appropriate alertDate
     *
     * @param localDueDate
     * @param startDate
     */
    FormComponent.prototype.parseDueDate = function (localDueDate, startDate, isTemplate) {
        if (isTemplate === void 0) { isTemplate = false; }
        if (isTemplate) {
            return {
                dueAt: null,
                alertAt: null
            };
        }
        var start = startDate ? startDate.clone() : moment().tz(environment.timeZone).set({
            'hour': 0,
            'minute': 0,
            'second': 0,
            'millisecond': 0
        });
        var dueAt = moment(localDueDate, 'DD-MM-YYYY').tz(environment.timeZone);
        var dueAtOffset = (dueAt.diff(start, 'days') / 2);
        var alertAt = start.add(dueAtOffset, 'days');
        return {
            dueAt: dueAt.toISOString(false),
            alertAt: alertAt.toISOString(false)
        };
    };
    /**
     * Determines if the passed form data
     *
     * @param formData
     */
    FormComponent.prototype.isNewForm = function (formData) {
        return !(formData && formData.id);
    };
    /**
     * Converts a form and its constituent form fields into an array of formRecordParams
     *
     * @param form
     */
    FormComponent.prototype.toRecordParams = function (form) {
        return Object.keys(form).map(function (key) {
            return form[key].toRecordParam(key);
        }).filter(function (val) { return val !== undefined; });
    };
    /**
     * Loads data from a formRecord into an editable formObject
     *
     * @param form
     * @param record
     */
    FormComponent.prototype.updateFromRecordParams = function (form, record) {
        Object.keys(form).forEach(function (key) {
            var recordProperty = record.properties.find(function (prop) { return prop.property && prop.property.name === key; });
            if (recordProperty)
                form[key].fromRecordParam(key, recordProperty);
        });
    };
    /**
     * @description Returns the first submission that matches the filters, assuming the inverse of the order in which they were submitted
     * @param {IFormOutputModel} formData
     * @param {boolean} include_incomplete
     * @returns {IFormRecordOutputModel | null}
     */
    FormComponent.prototype.getMostRecentSubmission = function (formData, include_incomplete, stage, sequence, filter) {
        if (include_incomplete === void 0) { include_incomplete = false; }
        var submissions = this.filterSubmissions(formData, include_incomplete, stage, sequence, filter);
        if (!submissions || !submissions.length)
            return null;
        return submissions[submissions.length - 1];
    };
    /**
     * @description Returns the first submission that matches the filters, assuming the order they were submitted
     *
     * @param {IFormOutputModel} formData
     * @param {boolean} include_incomplete
     * @returns {IFormRecordOutputModel | null}
     */
    FormComponent.prototype.getOldestSubmission = function (formData, include_incomplete, stage, sequence, filter) {
        if (include_incomplete === void 0) { include_incomplete = false; }
        var submissions = this.filterSubmissions(formData, include_incomplete, stage, sequence, filter);
        if (!submissions || !submissions.length)
            return null;
        return submissions[0];
    };
    /**
     * @description Filters the submissions in the supplied formData for further processing
     * @param formData
     * @param include_incomplete
     * @param stage
     * @param sequence
     * @param filter
     * @returns
     */
    FormComponent.prototype.filterSubmissions = function (formData, include_incomplete, stage, sequence, filter) {
        if (include_incomplete === void 0) { include_incomplete = false; }
        if (!formData || !formData.records || !formData.records.length)
            return [];
        var submissions = include_incomplete ? formData.records : formData.records.filter(function (record) { return record.isComplete; });
        var stageSubmissions = stage !== undefined ? submissions.filter(function (record) { return record.stage === stage; }) : submissions;
        var sequenceSubmissions = sequence ? stageSubmissions.filter(function (record) { return record.sequence === sequence; }) : stageSubmissions;
        var filteredSequenceSubmissions = filter ? sequenceSubmissions.filter(filter) : sequenceSubmissions;
        return filteredSequenceSubmissions;
    };
    /**
     * Handles all the repeated validation and verification associated with retrieving json data
     * from a form record by fieldname
     *
     * @param {IFormRecordOutputModel} record
     * @param {string} fieldName
     * @returns {string|null}
     */
    FormComponent.prototype.getJsonData = function (record, fieldName) {
        var jsonProperty = record.properties.find(function (recordProperty) { return recordProperty.property.name === fieldName; });
        if (jsonProperty && jsonProperty.jsonData && jsonProperty.jsonData.length) {
            return jsonProperty.jsonData;
        }
        return null;
    };
    /**
     * Handles all the repeated validation and verification associated with retrieving string data
     * from a form record by fieldname
     *
     * @param {IFormRecordOutputModel} record
     * @param {string} fieldName
     * @returns {string|null}
     */
    FormComponent.prototype.getStringData = function (record, fieldName) {
        var stringProperty = record.properties.find(function (recordProperty) { return recordProperty.property.name === fieldName; });
        if (stringProperty && stringProperty.stringData && stringProperty.stringData.length) {
            return stringProperty.stringData;
        }
        return null;
    };
    /**
     * Handles all the repeated validation and verification associated with retrieving int data
     * from a form record by fieldname
     *
     * @param {IFormRecordOutputModel} record
     * @param {string} fieldName
     * @returns {string|null}
     */
    FormComponent.prototype.getIntData = function (record, fieldName) {
        var stringProperty = record.properties.find(function (recordProperty) { return recordProperty.property.name === fieldName; });
        if (stringProperty && stringProperty.intData !== null) {
            return stringProperty.intData;
        }
        return null;
    };
    /**
     * Handles all the repeated validation and verification associated with retrieving int data
     * from a form record by fieldname
     *
     * @param {IFormRecordOutputModel} record
     * @param {string} fieldName
     * @returns {string|null}
     */
    FormComponent.prototype.getBoolData = function (record, fieldName) {
        var stringProperty = record.properties.find(function (recordProperty) { return recordProperty.property.name === fieldName; });
        if (stringProperty && stringProperty.intData) {
            return true;
        }
        return false;
    };
    FormComponent.prototype.loadStringField = function (stage, formData, fieldName) {
        var stageRecords = formData.records.filter(function (record) { return record.stage === stage; });
        if (stageRecords) {
            var mostRecentRecord = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
            if (!mostRecentRecord)
                return null;
            return mostRecentRecord.properties.find(function (recordProperty) { return recordProperty.property.name === fieldName; });
        }
        return null;
    };
    return FormComponent;
}(ComponentCanDeactivate));
export { FormComponent };
