import { Component } from "@angular/core";
import { AlertModalState, ConfirmOptions } from "../../../service/ModalService";

/**
 * The component displayed in the alert modal opened by the ModalService.
 */
@Component({
  selector: 'alert-modal-component',
  templateUrl: './alertModal.component.html',
})
export class AlertModalComponent {
  options: ConfirmOptions;
  constructor(private state: AlertModalState) {
    this.options = state.options;
  }

  ok() {
    this.state.modal.close('ok');
  }
}
