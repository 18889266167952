<div class="row">
    <div class="col-9 text-lg-left">
        <h1 class="text-uppercase text-orange">Tag Management</h1>
    </div>
    <div class="col-3 text-md-right">
        <button class="btn btn-md btn-orange" (click)="handleTagClick('new')">New Tag</button>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('name')"
                            [ngClass]="{active: sortBy === 'name', reverse: sortOrder !== 'asc'}">
                            Name<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('isMeta')"
                            [ngClass]="{active: sortBy === 'isMeta', reverse: sortOrder !== 'asc'}">
                            Is Meta Data<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('createdAt')"
                            [ngClass]="{active: sortBy === 'createdAt', reverse: sortOrder !== 'asc'}">
                            Created At<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="hover-cursor" *ngFor="let tag of tags" (click)="handleTagClick(tag.id)">
                    <td>{{tag.name}}</td>
                    <td>{{tag.isMeta ? 'Yes' : 'No'}}</td>
                    <td>{{tag.createdAt | date : 'mediumDate'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <pagination [collectionSize]="totalItems" [(page)]="currentPage" [(pageSize)]="pageSize"
            (pageChange)="handlePageChange()"></pagination>
    </div>
</div>