import { EventEmitter, OnInit } from '@angular/core';
import { timer } from "rxjs";
var FollowUpTemplate = /** @class */ (function () {
    function FollowUpTemplate() {
        this.userGroupId = '';
        this.description = '';
        this.dueDate = '';
        this.isValid = true;
    }
    return FollowUpTemplate;
}());
var FollowUpWidgetComponent = /** @class */ (function () {
    function FollowUpWidgetComponent() {
        this.skipChanges = false;
        this.updateSubscription = null;
        this.hideHeader = false;
        this.modelChange = new EventEmitter();
        this.followUps = [
            new FollowUpTemplate()
        ];
        this.isNotEmpty = false;
    }
    Object.defineProperty(FollowUpWidgetComponent.prototype, "model", {
        get: function () {
            return this._model;
        },
        set: function (value) {
            if (this.skipChanges) {
                this.skipChanges = false;
                return;
            }
            if (value && value !== '[]') {
                this._model = value;
                this.followUps = JSON.parse(value);
                this.isNotEmpty = true;
            }
            else {
                this._model = '[]';
                this.followUps = [
                    new FollowUpTemplate(),
                ];
                this.isNotEmpty = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    FollowUpWidgetComponent.prototype.ngOnInit = function () {
    };
    FollowUpWidgetComponent.prototype.setUserGroupId = function (data, idx) {
        if (data.length === 0)
            this.followUps[idx].userGroupId = '';
        else
            this.followUps[idx].userGroupId = data[0].id;
        this.emit();
    };
    FollowUpWidgetComponent.prototype.addFollowUp = function () {
        this.followUps.push(new FollowUpTemplate());
        this.emit();
    };
    FollowUpWidgetComponent.prototype.deleteFollowUp = function (idx) {
        this.followUps.splice(idx, 1);
        if (this.followUps.length === 0) {
            this.addFollowUp();
            return;
        }
        this.emit();
    };
    FollowUpWidgetComponent.prototype.emit = function () {
        var _this = this;
        if (this.updateSubscription) {
            this.updateSubscription.unsubscribe();
        }
        this.updateSubscription = timer(300).subscribe(function () {
            var notEmptyFollowUps = _this.followUps.filter(function (followUp) {
                return followUp.userGroupId.length > 0 || followUp.description.length > 0 || followUp.dueDate.length > 0;
            });
            _this.isNotEmpty = notEmptyFollowUps.length > 0;
            _this.skipChanges = true;
            _this.modelChange.emit(JSON.stringify(notEmptyFollowUps));
            _this.updateSubscription = null;
        });
        // Do not emit empty followUps
    };
    /*
     * Crude validation method for simple followUp form items
     */
    FollowUpWidgetComponent.prototype.validate = function () {
        var foundError = false;
        var notEmptyFollowUps = this.followUps.filter(function (followUp) {
            return followUp.userGroupId.length > 0 || followUp.description.length > 0 || followUp.dueDate.length > 0;
        });
        notEmptyFollowUps.forEach(function (followUp) {
            followUp.isValid = true;
            if (followUp.description.length === 0
                || followUp.userGroupId.length === 0
                || followUp.dueDate.length === 0) {
                foundError = true;
                followUp.isValid = false;
            }
        });
        return !foundError;
    };
    return FollowUpWidgetComponent;
}());
export { FollowUpWidgetComponent };
