import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from "./AuthService";
import { environment } from '../../environments/environment';
import * as Sentry from "@sentry/browser";

@Injectable()
export class BethanyHttpInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if( request.method === "POST" ) {
      Sentry.addBreadcrumb({
        category: "XHR Data",
        data: request.body
      });
    }
    
    const headers: {[name: string]: string} = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '-1',
    };

    if (this.auth.token) {
      headers['Authorization'] = `Bearer ${this.auth.token}`;
    }

    request = request.clone({
      url: environment.apiPrefix ? (environment.apiPrefix + request.url) : request.url,
      setHeaders: headers,
    });

    return next.handle(request);
  }

}
