import { OnInit, EventEmitter, OnChanges } from "@angular/core";
import { Session } from "../../service/util/Session";
import { FormField } from "../../model/Form";
import { Select2Component } from "ng2-select2";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import { DocumentCollectionsService } from "service/admin/DocumentCollectionsService";
var DocumentCollectionSelectComponent = /** @class */ (function () {
    function DocumentCollectionSelectComponent(documentCollectionsService, session, errorHandler) {
        this.documentCollectionsService = documentCollectionsService;
        this.session = session;
        this.errorHandler = errorHandler;
        this.placeholder = "";
        this.allowClear = true;
        this.disabled = false;
        this._value = '';
        this.change = new EventEmitter();
        this.selectOptions = {
            allowClear: true,
            placeholder: ''
        };
        this.selectData = [];
        this.defaultValue = '';
    }
    Object.defineProperty(DocumentCollectionSelectComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (newValue) {
            this._value = newValue;
        },
        enumerable: true,
        configurable: true
    });
    DocumentCollectionSelectComponent.prototype.ngOnChanges = function () {
        //Force the change detection to cycle again to prevent race
        if (this.selectData.length > 0 && this.field && String(this.field.value) !== this.defaultValue) {
            this.defaultValue = String(this.field.value);
        }
    };
    DocumentCollectionSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.placeholder)
            this.selectOptions.placeholder = this.placeholder;
        if (this.allowClear !== null)
            this.selectOptions.allowClear = this.allowClear;
        this.session.lockInputRx(this.documentCollectionsService.getDocumentCollections())
            .subscribe(function (data) {
            var newSelectOptions = [];
            if (_this.allowClear)
                newSelectOptions.push({ id: "", text: "" });
            data.forEach(function (item) { return newSelectOptions.push({
                id: String(item.id),
                text: item.name
            }); });
            _this.selectData = newSelectOptions;
            //Force the change detection to cycle again to prevent race
            if (_this.field && String(_this.field.value) !== _this.defaultValue)
                _this.defaultValue = String(_this.field.value);
            if (!_this.field && _this._value)
                _this.defaultValue = _this._value;
        }, function (err) {
            console.error('Error getting document collections', err);
            _this.errorHandler.handleHttpError(err);
        });
    };
    DocumentCollectionSelectComponent.prototype.valueChanged = function (selectedOpts) {
        if (selectedOpts.length === 0 || (selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0)) {
            if (this.field)
                this.field.value = null;
            this.change.emit(selectedOpts);
            return;
        }
        if (selectedOpts.length > 1)
            throw ("Selected options unexpectedly contained multiple results");
        if (this.field)
            this.field.value = selectedOpts[0].id;
        this.change.emit(selectedOpts);
    };
    return DocumentCollectionSelectComponent;
}());
export { DocumentCollectionSelectComponent };
