import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from "../../../service/currentUser/CurrentUserService";

/**
 * This component should always be authenticated - it's protected by CurrentUserRouterGuard
 */
@Component({
	styles: [],
	templateUrl: './authenticated.component.html',
})
export class AuthenticatedComponent implements OnInit {

	public navOptions = {
	};

	constructor(
		public currentUserService: CurrentUserService,
	) { }

	ngOnInit() {}
}
