import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Session } from "../../service/util/Session";
import { FormField } from "../../model/Form";
import { Select2Component } from 'ng2-select2';
import { TagsService } from "../../service/admin/TagsService";
import { CurrentUserService } from '../../service/currentUser/CurrentUserService';
import { logger } from "../../service/util/Logger";
import { FormService } from "../../service/FormService";
import { RiskService } from 'service/RiskService';


type ExIdTextPair = IdTextPair & { selected?: boolean };
type RiskItem = { id: number, summary: string };
type RiskData = { items: RiskItem[] };

@Component({
	selector: 'risk-select',
	template: `<select2
    #select2Component
    class="form-control select2-form-control {{disabled?'bg-grey':''}} {{!disabled && field && field.iifIsValid('border-success','border-danger')}}"
    [data]="selectData"
    [options]="selectOptions"
    (valueChanged)="valueChanged($event.data)"
    [value]="defaultValue"
    [disabled]="disabled"
  ></select2>`,
})
export class RiskSelectComponent implements OnInit {

	private _value: string = '';

	@Input('value')
	get value() {
		return this._value;
	}
	/**
	 * By preventing assigning non-unique values during the set
	 * we can prevent a loop of observable subscribers
	 */
	set value(newValue: string) {
		this._value = newValue;

		if (!this.field && !this.multiple) {
			this.defaultValue = this._value;
		}
	}

	// Implies a read only state
	@Input()
	disabled: boolean = false;

	@Input()
	placeholder: string;

	// /**
	//  * Restrict available tags to the tags available to the current user
	//  */
	// @Input()
	// restrictTags: boolean = false;
	@Input()
	isMeta: boolean = true;

	@Input()
	multiple: boolean = false;

	public defaultValue: string = '';

	@Input()
	field: FormField<number> | FormField<string>;

	@Input()
	currentRiskId: number | null = null;

	public selectOptions: Select2Options;
	public selectData: ExIdTextPair[] = [];

	// Reference firstNameInput variable inside Component
	@ViewChild('select2Component') select2ComponentRef: Select2Component;

	@Output()
	change: EventEmitter<ExIdTextPair[]> = new EventEmitter<IdTextPair[]>();

	constructor(
		public TagsService: TagsService,
		public session: Session,
		public currentUserService: CurrentUserService,
		public formService: FormService,
		public riskService: RiskService
	) {
	}

	ngOnInit() {
		this.init();
	}

	init() {
		this.selectOptions = {
			allowClear: !this.multiple,
			placeholder: this.placeholder || "Select a risk",
			multiple: this.multiple
		};

		this.session.lockInputRx(this.riskService.getRisks(0, 2000)).subscribe((riskData: RiskData) => {
			this.selectData = riskData.items.map(item => ({
				id: item.id.toString(),
				text: item.summary,
				selected: this.selectedByDefault(item.id)
			}));
		});
	}

	private selectedByDefault(id: number | string) {
		if( this.field ) {
			if( typeof this.field.value === 'number' ) {
				return this.field.value === id;
			}

			logger.info(`Unknown DataType[${typeof this.field.value}] for Field Value[${this.field.value}]`);
		}

		return false;
	}

	valueChanged(selectedOpts: ExIdTextPair[]) {
		if (selectedOpts.length > 1 && !this.multiple)
			throw ("Selected options unexpectedly contained multiple results");

		if (selectedOpts.length === 1 && !this.multiple && !this.disabled) {
			if (this.field) {
				this.field.value = selectedOpts[0].id;
			} else {
				if (this._value !== selectedOpts[0].id) { // if the value has been changed - emit event
					this._value = selectedOpts[0].id;
					this.change.emit(selectedOpts);
					return;
				}
			}
		}

		if (this.multiple) {
			const actualSelected = selectedOpts.filter(opt => opt.id && opt.id.length);
			const newValue = actualSelected.map(opt => opt.id).join(",");


			if (this.field) {
				this.field.value = newValue;
			} else {
				logger.silly(`Checking internalValue change Value[(${typeof newValue}) ${newValue}] !== _value[(${typeof this._value}) ${this._value}]`);
				if (this._value !== newValue) { // if the value has been changed - emit event
					logger.silly("RiskSelectComponent: Updating Internal Value");
					this._value = newValue;
					this.change.emit(actualSelected);
				}
			}
		}
	}

}
