import { Component, Input, ComponentFactoryResolver, ViewContainerRef, OnInit, ViewChild } from "@angular/core";
import { IFormOutputModel, IFormRecordOutputModel } from "../../../../../../common/contracts/form";
import {AuditFormStage0Component} from "../stage0/auditFormStage0.component";
import {AuditFormStage1Component} from "../stage1/auditFormStage1.component";
import {AuditFormStage2Component} from "../stage2/auditFormStage2.component";

const stageFormMap = {
  0: AuditFormStage0Component,
  1: AuditFormStage1Component,
  2: AuditFormStage2Component
};

/*
  This is the final, read only state for all the stages
*/

@Component({
  selector: 'audit-form-3',
  templateUrl: './auditFormStage3.component.html'
})
export class AuditFormStage3Component implements OnInit {

  private _formData: IFormOutputModel;

  @Input('formData')
  get formData() {
    return this._formData;
  }
  /** this is fix for navigation via child forms tree
   *  since this component might not be unmounted we need to clear all dynamic (read-only) forms
   **/
  set formData(newFormData: IFormOutputModel) {
    this._formData = newFormData;
    this.isReportEnabled = false;
    this.vc.clear();
    this.addReadOnlyForms();
  }

  @ViewChild('vc', {read: ViewContainerRef}) vc: ViewContainerRef;

  public isReportEnabled = false;

  constructor(private factoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    // VC Clear added so that if this occurs after formdata is set, the output doesn't double up
    this.vc.clear();
    this.addReadOnlyForms();
  }

  public addReadOnlyForms() {
    if( !this.formData || !this.formData.records ) {
      console.error('form data is not defined');
      return;
    }
  
    /*
      var err = new Error();
      console.log( err.stack );
      console.log(this.formData.records.filter( record => record.isComplete));
    */

    //Get completed Records
    this.formData.records
    .filter( record => record.isComplete)
    .forEach( record => this.addReadOnlyForm(record));
  }

  public addReadOnlyForm(record:IFormRecordOutputModel) {
    const _component = stageFormMap[record.stage];
    if (!_component) {
      console.error('Cannot find follow-up form component for stage ', record.stage);
      return;
    }
    const factory = this.factoryResolver.resolveComponentFactory(_component);
    const component = this.vc.createComponent(factory);

    // @ts-ignore
    component.instance.readOnly = true;
    // @ts-ignore
    component.instance.formData = this.formData;
    // @ts-ignore
    component.instance.hideHeader = !( record.stage === 0 && this.formData.archivedAt );
    // @ts-ignore
    component.instance.sequence = record.sequence;

    this.vc.insert(component.hostView);

    this.isReportEnabled = true;
  }
}
