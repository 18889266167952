import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ResourcePackService } from 'service/ResourcePackService';
import { ResourcePack } from 'model/ResourcePack';
import { ErrorHandlerService } from 'service/ErrorHandlerService';

@Component({
  selector: 'app-resource-pack-create',
  templateUrl: './resource-pack-create.component.html',
  styleUrls: ['./resource-pack-create.component.css']
})
export class ResourcePackCreateComponent implements OnInit {
  name: string;
  @Output() onCreate: EventEmitter<ResourcePack>;

  public validationClasses = {};
  public isNameInvalid = false;

  constructor(private service: ResourcePackService, private errorHandlerService: ErrorHandlerService) {
    this.onCreate = new EventEmitter<ResourcePack>();
   }

  ngOnInit() {
  }

  validate() {
    const result = (this.name && this.name.length);
    if( result ) {
      this.validationClasses = {'border-success': true};
      this.isNameInvalid = false;
    } else {
      this.validationClasses = {'border-danger': true};
      this.isNameInvalid = true;
    }

    return result;
  }

  resetValidation() {
    this.name = "";
    this.isNameInvalid = false;
    this.validationClasses = {};
  }


  submit() {
    if( !this.validate() )
      return;

    this.service.create(this.name).subscribe(res => {
      this.onCreate.emit(res);
      this.resetValidation();
    }, err => {
      this.errorHandlerService.handleHttpError(err);
    });
  }
}
