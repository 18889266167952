import { Component, EventEmitter, Input, Output } from "@angular/core";

export interface IButtonGroupOption {
  value: string;
  text: string;
}

@Component({
  selector: 'button-group-toggle',
  styles: [`
      div.btn-group-toggle {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
      }
      
      button.btn-secondary.active {
          box-shadow: none !important;
      }
  `],
  template: `
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <button
            *ngFor="let option of options"
            class="btn btn-secondary {{value === option.value ? 'active' : ''}}"
            [disabled]="disabled"
            (click)="handleClick(option.value)">
            {{ option.text }}
        </button>
    </div>
  `
})
export class ButtonsGroupToggleComponent {

  @Input()
  value: string = '';
  @Output()
  valueChange = new EventEmitter<string>();

  @Input()
  disabled: boolean = false;

  @Input()
  options: IButtonGroupOption[] = [];

  public handleClick(val: string) {
    if (!this.disabled) {
      if (val !== this.value) {
        this.value = val;
        this.valueChange.emit(val);
      }
    }
  }
}
