<form class="form">
  <div class="form-group">
    <label for="name">Name</label>
    <input 
      type="text" 
      name="name" 
      id="name" 
      [(ngModel)]="name" 
      class="form-control" 
      placeholder="Name" 
      [ngClass]="validationClasses"
      (keyup)="validate()"
    >
    <small class="text-muted">Resource pack name</small>
    <small class="form-text text-danger" *ngIf="isNameInvalid">Invalid Name</small>
  </div>
  <button class="btn btn-success rounded-0" (click)="submit()">
      <span class="capitalize">Create</span>
  </button>
</form>