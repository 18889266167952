/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./forbidden.component";
var styles_ForbiddenComponent = [];
var RenderType_ForbiddenComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ForbiddenComponent, data: {} });
export { RenderType_ForbiddenComponent as RenderType_ForbiddenComponent };
export function View_ForbiddenComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Forbidden!"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sorry, you don't have access to the requested page. Please contact an administrator."]))], null, null); }
export function View_ForbiddenComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "forbidden-page", [], null, null, null, View_ForbiddenComponent_0, RenderType_ForbiddenComponent)), i0.ɵdid(1, 49152, null, 0, i1.ForbiddenComponent, [], null, null)], null, null); }
var ForbiddenComponentNgFactory = i0.ɵccf("forbidden-page", i1.ForbiddenComponent, View_ForbiddenComponent_Host_0, {}, {}, []);
export { ForbiddenComponentNgFactory as ForbiddenComponentNgFactory };
