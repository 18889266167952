import { Component, Input, OnInit } from "@angular/core";
import * as moment from 'moment';
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import { IFormOutputModel } from "../../../common/contracts/form";
import { ModalService } from "../../service/ModalService";
import { FormService } from "../../service/FormService";
import { CurrentUserService } from "../../service/currentUser/CurrentUserService";
import { environment } from "../../environments/environment";

@Component({
  selector: 'form-due-at',
  template: `
    <div class="{{ inline ? 'd-inline' : 'row' }}">
        <div class="{{ inline ? 'd-inline' : 'col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6' }}">
            <span class="ml-3">Form Due At: <span class="text-primary">{{ dueAtString }}</span></span>
            <i class="fa fa-pencil text-primary hover-cursor ml-3" *ngIf="isDueDateEditable" (click)="updateRecordDate()"></i>
        </div>
    </div>
  `
})
export class FormDueAtComponent implements OnInit {

  private readonly dateFormat: string = "DD-MM-YYYY, h:mm a";

  @Input()
  formData: IFormOutputModel;

  @Input()
  isDueAtEditable: boolean = false;

  @Input()
  inline: boolean = false;

  public isDueDateEditable: boolean;
  public dueAtString: string;

  constructor(
    private currentUserService: CurrentUserService,
    private modalService: ModalService,
    private formService: FormService,
    private errorHandler: ErrorHandlerService) {
  }

  ngOnInit() {
    this.isDueDateEditable = this.currentUserService.isAdministrator.getValue();
    this.dueAtString = this.formData.dueAt ? moment(this.formData.dueAt).tz(environment.timeZone).format(this.dateFormat) : 'DD-MM-YYYY';
  }

  public updateRecordDate() {

    this.modalService.selectDate({
      title: 'Form Due Date',
      label: 'Due date',
      submitText: 'Update',
      defaultValue: moment(this.formData.dueAt || new Date().toISOString()).tz(environment.timeZone).format('DD/MM/YYYY'),
    }).then((date) => {
      const newDueAt = moment(date, "DD/MM/YYYY").tz(environment.timeZone).set({
        'hour': 12,
        'minute': 0,
        'second': 0
      }).toISOString();

      this.formService.updateForm({
        id: this.formData.id,
        stage: this.formData.stage,
        dueAt: newDueAt,
      }).subscribe(() => {
        this.formData.dueAt = newDueAt;
        this.dueAtString = moment(newDueAt).tz(environment.timeZone).format(this.dateFormat);
      }, err => this.errorHandler.handleHttpError(err));

    }).catch(() => {});

  }

}
