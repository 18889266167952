<header [formData]="formData" *ngIf="!hideHeader"></header>
<page-title text="{{isTemplate?'Audit Template':'Processing of Completed Audit'}}"></page-title>

<form>

    <div class="row">
        <div class="col-12 col-lg-8">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <label>Audit Type:</label>
                        <enum-select
                            [field]="form.type"
                            placeholder="Select Type"
                            enum="AuditFormType"
                            [disabled]="readOnly"
                            (change)="updateAuditType($event)"
                        ></enum-select>
                        <small class="form-text text-danger" *ngIf="form.type.showErrorHelp()">Invalid Type</small>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <label>Due Date</label>
                        <date-picker 
                            [disabled]="readOnly" 
                            [formField]="form.dueAt" 
                            [disablePastDates]="true"
                            *ngIf="!isTemplate"
                        ></date-picker>
                        <date-offset-picker
                            *ngIf="isTemplate"
                            [periodField]="form.templateDueAtPeriod"
                            [offsetField]="form.templateDueAtOffset"
                        ></date-offset-picker>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <label>Location:</label>
                        <location-select
                            *ngIf="isTemplate"
                            [field]="form.templateLocation"
                            [disabled]="readOnly"
                            [multiple]="isTemplate"
                        ></location-select>
                        <location-select
                            *ngIf="!isTemplate"
                            [field]="form.location"
                            [disabled]="readOnly"
                            [multiple]="isTemplate"
                        ></location-select>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <label>Department:</label>
                        <user-group-select
                            [field]="form.department"
                            [disabled]="readOnly"
                        ></user-group-select>
                        <small class="form-text text-danger" *ngIf="form.department.showErrorHelp()">Invalid Department</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Period Covered By Audit</label>
                        <div class="row">
                            <div class="col-6">
                                <small *ngIf="isTemplate">Period From</small>
                                <date-picker [disabled]="readOnly" [formField]="form.periodFrom" *ngIf="!isTemplate"></date-picker>
                                <date-offset-picker
                                    *ngIf="isTemplate"
                                    [periodField]="form.templatePeriodFromPeriod"
                                    [offsetField]="form.templatePeriodFromOffset"
                                ></date-offset-picker>
                            </div>
                            <div class="col-6">
                                <small *ngIf="isTemplate">Period To</small>
                                <date-picker [disabled]="readOnly" [formField]="form.periodTo" *ngIf="!isTemplate"></date-picker>
                                <date-offset-picker
                                    *ngIf="isTemplate"
                                    [periodField]="form.templatePeriodToPeriod"
                                    [offsetField]="form.templatePeriodToOffset"
                                ></date-offset-picker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="row">
                <div class="col-12 offset-0 col-xl-10 offset-xl-1">
                    <document-uploads [(documents)]="documents" [readOnly]="readOnly" [disableTicking]="true"></document-uploads>
                </div>
            </div>
            <div class="row">
                <div class="col-12 offset-0 col-xl-10 offset-xl-1">
                    <div class="row mt-2 mt-lg-3">
                        <div class="col-12">
                            <span>Resources:</span>
                        </div>
                    </div>
                    <div *ngIf="resourcePacks.length === 0" class="mt-1 pt-1 pl-3 border-top">
                        <span>No Resources</span>
                    </div>
                    
                    <ng-container *ngFor="let pack of resourcePacks; let p = index">
                        <div class="col-12 pt-2" [ngClass]="{'border-top':!p}">
                            <span>{{pack.name}}</span>
                        </div>
                        <div *ngFor="let doc of pack.documents; let i = index" class="mt-1 pt-1 border-top">
                            <span class="px-3 download-document-reference" (click)="download(doc)">{{doc.path}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <footer 
        [formRecord]="formRecord" 
        [readOnly]="readOnly" 
        (onSubmit)="submit($event)"
        [showDraft]="!isTemplate"
    ></footer>
</form>