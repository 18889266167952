
    <div class="row">
        <div class="col-12 col-lg-6">
            <img src="assets/images/logo.png" class="logoImg">
        </div>
        <div class="col-12 pt-2 pt-lg-0 col-lg-6 text-center">
            <div class="border border-dark rounded border-dashed d-inline-block float-lg-right py-3 px-5">
                <div class="row">
                    <div class="col-12">
                        <strong class="font-weight-heavy">Issue Number:</strong>
                        {{ formData ? formData.issueNumber || "TBA" : "TBA" }}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <strong class="font-weight-heavy">Date Created:</strong> {{dateString}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    