<div class="modal-header">
    <h4 class="modal-title">Risk Matrix</h4>
    <button type="button" class="close" aria-label="Close" (click)="ok()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <table class="table table-striped">
		<thead>
		  <tr>
			<th [colSpan]="2"></th>
			<th [colSpan]="likelihoodOptionCount" class="text-center">Likelihood</th>
		  </tr>
		  <tr>
			<th [colSpan]="2"></th>
			<th *ngFor="let likelihood of likelihoodOptions">{{likelihood.text}}</th>
		  </tr>
		</thead>
		<tbody>
		  <tr *ngFor="let severity of severityOptions; let i = index;">
			<th *ngIf="i === 0" [rowSpan]="severityOptionCount"><span class="rotate">Severity</span></th>
			<th scope="row">{{severity.text}}</th>
			<td *ngFor="let likelihood of likelihoodOptions">{{riskText(likelihood.id, severity.id)}}</td>
		  </tr>
		</tbody>
	  </table>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="ok()">OK</button>
</div>
