<div class="row" *ngIf="!hideHeader">
	<div class="col-12 col-lg-6">
		<img src="assets/images/logo.png" class="logoImg">
	</div>
	<div class="col-12 pt-2 pt-lg-0 col-lg-6 text-center">
		<div class="w-100 border border-dark rounded border-dashed d-inline-block float-lg-right py-3 px-5">
			<div class="row">
				<div class="col-12">
					<strong class="font-weight-heavy">Document:</strong>
					{{ document ? document.documentCode || "TBA" : "TBA" }}
				</div>
			</div>
			<div class="row mt-2">
				<div class="col-12">
					<strong class="font-weight-heavy">Date Created:</strong>
					{{documentCreatedAt ? documentCreatedAt : "TBA"}}
				</div>
			</div>
		</div>
	</div>
</div>

<div class="row">
	<div class="col-12 text-lg-left">
		<h1 class="text-uppercase text-orange">{{isNew ? 'New' : 'Edit'}} Document</h1>
	</div>
</div>
<form [formGroup]="documentForm" class="w-100">
	<div class="row">
		<div class="col-12 offset-0 col-sm-6">
			<div class="form-group">
				<label for="usernameInput">Document Name:</label>
				<input type="text" id="usernameInput" autofocus class="form-control input-lg" rxControl
					formControlName="documentName" [readOnly]="readOnly">
				<small class="form-text text-danger"
					*ngIf="documentForm.controls.documentName.touched && documentForm.controls.documentName.hasError('required')">This
					field is required.</small>
			</div>
		</div>
		<div class="col-6 offset-0 col-sm-4">
			<div class="form-group">
				<label>Type:</label>
				<select class="form-control" formControlName="type" [attr.disabled]="readOnly ? 'disabled' : null">
					<option value="" selected disabled>Select document type</option>
					<option value="FORM">Form</option>
					<option value="POLICY">Policy</option>
					<option value="PROCEDURE">Procedure</option>
					<option value="AUDIT">Audit</option>
				</select>
			</div>
		</div>
		<div class="col-6 offset-0 col-sm-2">
			<div class="form-group">
				<label for="documentIdentityInput">Document Id (Code):</label>
				<input type="text" id="documentIdentityInput" class="form-control input-lg" rxControl
					formControlName="documentIdentity" [readOnly]="readOnly">
				<small class="form-text text-danger"
					*ngIf="documentForm.controls.documentIdentity.touched && documentForm.controls.documentIdentity.hasError('required')">This
					field is required.</small>
			</div>
		</div>
		<div class="col-12 offset-0 col-sm-6">
			<div class="form-group">
				<label for="reviewDateInput">Date of next review:</label>
				<date-picker [formField]="documentForm.controls.nextReviewDate" [required]="true" [disablePastDates]="true"
					[disabled]="isDocAdmin ? readOnly : true">
				</date-picker>
			</div>
		</div>
		<div class="col-12 offset-0 col-sm-6">
			<label>Department:</label>
			<user-group-select [value]="departmentId" (change)="handleDepartmentChange($event)"
				[disabled]="isDocAdmin ? readOnly: true"></user-group-select>
		</div>
	</div>
</form>

<div class="row">
	<div class="container p-0">
		<div class="row pb-2">
			<div class="col-12 offset-0 col-sm-6">
				<label>Standard:</label>
				<standard-select [field]="documentStandard" placeholder="Select standard" enum="DocumentStandard"
					[disabled]="isDocAdmin ? readOnly: true" (change)="handleSelectChange($event)"></standard-select>
			</div>
			<div class="col-12 offset-0 col-sm-6">
				<label>Tags:</label>
				<tag-select [value]="selectedTags" [isMeta]="false" multiple="true" (change)="handleTagChange($event)"
					placeholder="Select a tag" [disabled]="isDocAdmin ? readOnly: true">
				</tag-select>
			</div>
		</div>
		<div class="row">
			<div class="col-12 offset-0 col-sm-6">
				<label>Meta:</label>
				<div class="row">
					<div class="col-12">
						<div class="d-flex flex-wrap">
							<div *ngFor="let tag of metaIdValuePairArray; let t = index" class="flex-shrink-0 badge badge-light mr-1 mt-1">
								<div class="d-flex align-items-center">
									<div>{{tag.name}} : {{tag.value}}&nbsp;&nbsp;</div>
									<button class="close" (click)="removeMeta(t)" [hidden]="isDocAdmin ? readOnly: true">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group d-flex">
					<tag-select [multiple]="false" (change)="handleMetaChange($event)"
						class="form-control" [disabled]="isDocAdmin ? readOnly: true"></tag-select>
					<input type="text" autofocus class="form-control input-lg ml-2" placeholder="Value"
						[(ngModel)]="metaTagValueModel" [disabled]="isDocAdmin ? readOnly: true">
					<button class="btn btn-outline-success ml-2" (click)="addNewDocumentMeta()"
						[disabled]="isDocAdmin ? readOnly: true">Add</button>
				</div>
			</div>
			<div class="col-12 offset-0 col-sm-6">
				<label>Collection:</label>
				<document-collection-select [allowClear]="false" [field]="documentCollection"
					placeholder="Select document collection" [disabled]="isDocAdmin ? readOnly: true"
					(change)="handleSelectChange($event)"></document-collection-select>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
				<button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit()"
					[disabled]="shouldDisableSaveButton()">
					<span class="capitalize">save</span>
				</button>
			</div>
			<a class="btn btn-lg btn-secondary rounded-0 text-center text-white" (click)="cancel()"><span
					class="capitalize">back to collection</span></a>
		</div>
	</div>
</div>
<div *ngIf="!isNew" class="row">
	<div class="d-flex justify-content-end w-100 my-3">
		<button class="btn btn-orange rounded-0 mx-1" (click)="downloadLatestAprovedVersion()"
			*ngIf="!!latestAprovedVersion && !readOnly">
			<i class="fa fa-download mx-1"></i>
			<strong>Latest Approved Version</strong>
		</button>
		<button class="btn btn-orange rounded-0 mx-1" (click)="downloadLatestDraft()"
			*ngIf="(!!latestAprovedVersion || !!latestDraftRecord) && !readOnly">
			<i class="fa fa-download mx-1"></i>
			<strong>Latest Draft Version</strong>
		</button>
	</div>
	<div class="col-12 text-lg-left mt-4">
		<div class="d-flex justify-content-between align-items-center">
			<h3 class="text-uppercase text-orange">Tasks</h3>
			<button class="btn btn-success" *ngIf="isAdminOrManager && !readOnly" [routerLink]="'/document-review-task/'"
				[queryParams]="{documentId:documentId,departmentId:departmentId}">Create a new review
				task</button>
		</div>
		<div class="col-12 my-2">
			<div class="show-all-filter-block" *ngIf="isAdminOrManager">
				<button-group-toggle [options]="showAllOptions" [value]="formsQueryParams.showAll"
					(valueChange)="handleShowAllFilterChange($event)"></button-group-toggle>
			</div>
		</div>
		<div class="col-12 my-2">
			<table class="table table-hover table-striped">
				<thead>
					<tr class="hover-cursor row">
						<th scope="col" class="col-md-3">
							<span class="sortable-col-name" (click)="handleSortChange('name')"
								[ngClass]="{active: sortBy === 'name', reverse: sortOrder !== 'asc'}">
								Name<i class="fa fa-caret-up"></i>
							</span>
						</th>
						<th scope="col" class="col-md-3">
							<span class="sortable-col-name" (click)="handleSortChange('assignedTo')"
								[ngClass]="{active: sortBy === 'AssignedTo', reverse: sortOrder !== 'asc'}">
								Assigned to<i class="fa fa-caret-up"></i>
							</span>
						</th>
						<th scope="col" class="col-md-3">
							<span class="sortable-col-name" (click)="handleSortChange('createdAt')"
								[ngClass]="{active: sortBy === 'CreatedAt', reverse: sortOrder !== 'asc'}">
								Created at<i class="fa fa-caret-up"></i>
							</span>
						</th>
						<th scope="col" class="col-md-3">
							<span class="sortable-col-name" (click)="handleSortChange('dueDate')"
								[ngClass]="{active: sortBy === 'DueDate', reverse: sortOrder !== 'asc'}">
								Due date<i class="fa fa-caret-up"></i>
							</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr class="hover-cursor row" *ngFor="let task of tasks" (click)="progressForm(task)">
						<td class="col-md-3">{{task.summary}}</td>
						<td class="col-md-3">
							<span *ngIf="task.assignedUser">
								{{ task.assignedUser ? ( task.assignedUser.firstName + ' ' + task.assignedUser.lastName ) : '' }}
							</span>
							<span *ngIf="!task.assignedUser && task.userGroup">
								{{ task.userGroup.groupName }} Department
							</span>
						</td>
						<td class="col-md-3">{{task.createdAt | date : 'mediumDate'}}</td>
						<td class="col-md-3">{{task.dueAt | date : 'mediumDate'}}</td>
					</tr>
					<tr *ngIf="tasks.length === 0" class="row d-flex justify-content-center">
						<td class="text-center">
							No Tasks
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="col-12">
			<pagination [collectionSize]="totalItems" [(page)]="currentPage" [(pageSize)]="pageSize"
				(pageChange)="handlePageChange()" (pageSizeChange)="handlePageSizeChange($event)"></pagination>
		</div>
	</div>
</div>