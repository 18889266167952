var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericService } from "./GenericService";
import { HttpClient } from "@angular/common/http";
var FormRecordService = /** @class */ (function (_super) {
    __extends(FormRecordService, _super);
    function FormRecordService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    FormRecordService.prototype.createRecord = function (record) {
        return this.http.post(FormRecordService.apiPrefix, record);
    };
    FormRecordService.prototype.updateRecordDate = function (params) {
        return this.http.put(FormRecordService.apiPrefix, params);
    };
    FormRecordService.apiPrefix = '/api/record';
    return FormRecordService;
}(GenericService));
export { FormRecordService };
