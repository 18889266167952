/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../views/modal/confirm/confirmModal.component.ngfactory";
import * as i4 from "../views/modal/confirm/confirmModal.component";
import * as i5 from "../service/ModalService";
import * as i6 from "../views/modal/alert/alertModal.component.ngfactory";
import * as i7 from "../views/modal/alert/alertModal.component";
import * as i8 from "../views/modal/dateSelect/dateSelectModal.component.ngfactory";
import * as i9 from "../views/modal/dateSelect/dateSelectModal.component";
import * as i10 from "../views/modal/cron/cronModal.component.ngfactory";
import * as i11 from "../views/modal/cron/cronModal.component";
import * as i12 from "../views/modal/risk/riskModal.component.ngfactory";
import * as i13 from "../views/modal/risk/riskModal.component";
import * as i14 from "../views/modal/upload/uploadModal.component.ngfactory";
import * as i15 from "../views/modal/upload/uploadModal.component";
import * as i16 from "../service/DocumentService";
import * as i17 from "@angular/common";
import * as i18 from "./app.component";
import * as i19 from "../service/util/Session";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.onActivate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, { activateEvents: "activate" })], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "w-100 h-100 ", (_co.isLoading ? "t-40" : ""), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "row d-flex h-100 fixed-top t-50 bg-dark"]], null, null, null, null, null))], null, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row d-flex h-100 fixed-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-xl-4 offset-xl-4 align-self-center text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "assets/images/logo.png"]], null, null, null, null, null))], null, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "confirm-modal-component", [], null, null, null, i3.View_ConfirmModalComponent_0, i3.RenderType_ConfirmModalComponent)), i1.ɵdid(1, 49152, null, 0, i4.ConfirmModalComponent, [i5.ConfirmModalState], null, null)], null, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alert-modal-component", [], null, null, null, i6.View_AlertModalComponent_0, i6.RenderType_AlertModalComponent)), i1.ɵdid(1, 49152, null, 0, i7.AlertModalComponent, [i5.AlertModalState], null, null)], null, null); }
function View_AppComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "date-select-modal", [], null, null, null, i8.View_DateSelectModalComponent_0, i8.RenderType_DateSelectModalComponent)), i1.ɵdid(1, 49152, null, 0, i9.DateSelectModalComponent, [i5.DateSelectModalState], null, null)], null, null); }
function View_AppComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cron-modal", [], null, null, null, i10.View_CronModalComponent_0, i10.RenderType_CronModalComponent)), i1.ɵdid(1, 49152, null, 0, i11.CronModalComponent, [i5.CronModalState], null, null)], null, null); }
function View_AppComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "risk-modal", [], null, null, null, i12.View_RiskModalComponent_0, i12.RenderType_RiskModalComponent)), i1.ɵdid(1, 49152, null, 0, i13.RiskModalComponent, [i5.RiskModalState], null, null)], null, null); }
function View_AppComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "upload-modal", [], null, null, null, i14.View_UploadModalComponent_0, i14.RenderType_UploadModalComponent)), i1.ɵdid(1, 49152, null, 0, i15.UploadModalComponent, [i5.UploadModalState, i16.DocumentService], null, null)], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(3, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(5, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(7, 16384, null, 0, i5.ConfirmTemplateDirective, [i1.TemplateRef, i5.ConfirmModalState], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_AppComponent_5)), i1.ɵdid(9, 16384, null, 0, i5.AlertTemplateDirective, [i1.TemplateRef, i5.AlertModalState], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_AppComponent_6)), i1.ɵdid(11, 16384, null, 0, i5.DateSelectModalTemplateDirective, [i1.TemplateRef, i5.DateSelectModalState], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_AppComponent_7)), i1.ɵdid(13, 16384, null, 0, i5.CronTemplateDirective, [i1.TemplateRef, i5.CronModalState], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_AppComponent_8)), i1.ɵdid(15, 16384, null, 0, i5.RiskModalTemplateDirective, [i1.TemplateRef, i5.RiskModalState], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_AppComponent_9)), i1.ɵdid(17, 16384, null, 0, i5.UploadModalTemplateDirective, [i1.TemplateRef, i5.UploadModalState], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.session.isReady; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isLoading; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.isLoading; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i18.AppComponent, [i19.Session, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i18.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
