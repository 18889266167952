import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  template: '<router-outlet></router-outlet>'
})
export class AdminComponent implements OnInit {

  constructor() {}

  ngOnInit() {
    /**
     * this component is protected by AdminRouterGuard thus we can be sure that current user has administrator role
     */
    /*if( !this.currentUserService.isAdministrator.getValue() ) {
      console.log("User was rejected due to attempt to access admin page without admin access");
      
      this.router.navigateByUrl('/');
    }*/
  }
}
