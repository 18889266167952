import { Component, OnInit, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';
import "moment-timezone";
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { IFormRecordOutputModel, IFormRecordPropertyParam } from "../../../../../../common/contracts/form";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { IFormOutputModel } from "../../../../../../common/contracts/form";
import { CategoryService } from "../../../../../service/CategoryService";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { IDocumentType } from "../../../../../../common/contracts/document";
import { SettingsService } from "../../../../../service/admin/SettingsService";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { FollowUpService } from "../../../../../service/FollowUpService";
import { environment } from '../../../../../environments/environment';
import { logger } from 'service/util/Logger';
import { Router } from '@angular/router';

enum CommunicationSelectOption {
  RESPONSE_REQUIRED_EMAIL = 'RESPONSE_REQUIRED_EMAIL',
  RESPONSE_REQUIRED_MANUAL = 'RESPONSE_REQUIRED_MANUAL',
  RESPONSE_NOT_REQUIRED = 'RESPONSE_NOT_REQUIRED'
}

@Component({
  selector: 'report-form-2',
  templateUrl: './reportFormStage2.component.html'
})
export class ReportFormStage2Component extends FormComponent implements OnInit {
  private defaultCommunicationOption = CommunicationSelectOption.RESPONSE_REQUIRED_EMAIL;
  public emailsEnabled: boolean = true;

  public followUpFormCategory: number;
  public communicationSelectOption = CommunicationSelectOption;

  public communicationSelectOptions = [
    { id: CommunicationSelectOption.RESPONSE_REQUIRED_EMAIL, text: 'Response Required - Send emails', disabled: false },
    { id: CommunicationSelectOption.RESPONSE_REQUIRED_MANUAL, text: 'Response Required - Admin processing', disabled: false },
    { id: CommunicationSelectOption.RESPONSE_NOT_REQUIRED, text: 'Response Not Required - Do not send emails', disabled: false }
  ];

  // Existing Form Data
  @Input() public formData: IFormOutputModel;
  @Input() sequence: number;

  public formRecord: IFormRecordOutputModel;

  @Input() readOnly: boolean = false;
  @Input() hideHeader: boolean = false;

  @ViewChild('followUpWidget') followUpWidgetRef: FollowUpWidgetComponent;

  /*
    This should have been done properly using something that implements FormControl but its
    too late now
   */
  public form: { [key: string]: FormField<any> } = {
    reassign: new FormField<boolean>(false, {
      validation: FormField.ValidationMethods.None
    }),
    //Since this will be assigned to a select it must be a string data - Conversion where appropriate
    reassignToUserId: new FormField<string>('', {
      validation: FormField.ValidationMethods.None
    }),
    followUps: new FormField<string>('[]', {
      nullEquivilent: "[]",
      validation: (value: string) => {
        return this.followUpWidgetRef ? this.followUpWidgetRef.validate() : true;
      }
    }),
    summary: new FormField<String>('', {
      validation: FormField.ValidationMethods.None
    }),
    notes: new FormField<String>('', {
      validation: FormField.ValidationMethods.None
    }),
    emailOther: new FormField<boolean>(false, {
      validation: FormField.ValidationMethods.None
    }),
    otherEmail: new FormField<String>('', {
      validation: FormField.ValidationMethods.None
    }),
    furtherCommunication: new FormField<string>(this.defaultCommunicationOption, {
      validation: FormField.ValidationMethods.IsNotBlank,
      onChange: () => {
      }
    }),
  };

  public dateString: string;

  public documents: Array<IDocumentType> = [];

  constructor(
    public session: Session,
    public currentUserService: CurrentUserService,
    public formRecordService: FormRecordService,
    public formService: FormService,
    public categoryService: CategoryService,
    private groupsService: GroupsService,
    public errorHandler: ErrorHandlerService,
    public router: Router,
    private settingsService: SettingsService,
    private followUpService: FollowUpService
  ) {
    super(router);
  }

  registerFormFields() {
    this.formFields.push(...Object.keys(this.form).map((k: string) => this.form[k]));
  }

  ngOnInit() {
    this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);

    this.registerFormFields();

    this.repopulateFormFromData();

    // Load the category name
    this.categoryService.getCategoryIdByName('Follow-Up').subscribe(categoryId =>
      this.followUpFormCategory = categoryId
    );

    this.loadSettings();
  }

  loadSettings() {
    this.settingsService.getSettings().subscribe(
      data => {
        this.emailsEnabled = data.isEmailsEnabled;
        if (!this.emailsEnabled) {
          this.communicationSelectOptions[1].text += ' (Default)';
          this.communicationSelectOptions[0].disabled = true;
        } else {
          this.communicationSelectOptions[0].text += ' (Default)';
        }

        this.reloadCommunicationOptions();
      }
    )
  }

  reloadCommunicationOptions() {
    const signature = this.className + ".reloadCommunicationOptions: ";
    const newOptions = JSON.parse(JSON.stringify(this.communicationSelectOptions));

    this.communicationSelectOptions = [];

    setTimeout(() => {
      newOptions.forEach(option => {
        delete option.selected;

        if (option.id === this.defaultCommunicationOption) {
          option.selected = 'selected';
          this.form.furtherCommunication.value = this.defaultCommunicationOption;
        }
      });

      this.communicationSelectOptions = newOptions;
      logger.silly(signature + `Reloaded Communication Options`);
    });
  }

  onSubmit(isDraft: boolean) {
    this.session.lockInput(() => {
      return new Promise<null>(async (resolve, reject) => {

        let success = () => {
          resolve(null);

          this.closeForm();
        };

        let fail = (msg: string, err: any) => {
          console.error(msg, err);
          this.errorHandler.handleHttpError(err);
          reject();
        };

        let stage: number = isDraft ? 2 : 3;
        let assignedUserId: number | null = null;
        let userGroupId = this.formData.userGroupId;

        if (this.currentUserService.userData)
          assignedUserId = this.currentUserService.userData.id;

        /*
         * This second round of notifyOnComplete ocurrs after the determination as to wether or not
         * the form is complete because it is possible an additional email has been selected for notification
         * but is not the originator. This is set before the communication options are considered so that it can
         * be adjusted accordingly.
         */

        let notifyOnComplete = this.formData.notifyOnComplete;

        if (
          // If the form is ready to advance
          stage === 3
        ) {

          if (this.form.reassign.value && this.form.reassignToUserId.value) {
            // We actually want to send it back 1 for more detail
            stage = 1;

            assignedUserId = Number(this.form.reassignToUserId.value);
          } else {

            // If this is destined for the next stage and further admin processing
            // It should go to the admin department
            let groups = await this.groupsService.getGroups().toPromise();

            let adminGroup = groups.find(group => group.groupName.match(/^admin/gi) !== null);

            switch (this.form.furtherCommunication.value as keyof typeof CommunicationSelectOption) {
              case "RESPONSE_REQUIRED_EMAIL":

                if (this.form.otherEmail && this.form.otherEmail.value && this.form.otherEmail.value.length) {
                  if (notifyOnComplete)
                    notifyOnComplete += `,${this.form.otherEmail.value}`;
                  else
                    notifyOnComplete = this.form.otherEmail.value;
                }

                // We are done. The server should send email to the users in notifyOnComplete
                stage = 4;

                assignedUserId = null;
                break;
              case "RESPONSE_REQUIRED_MANUAL":
                notifyOnComplete = null;

                if (adminGroup)
                  userGroupId = adminGroup.id;

                assignedUserId = null;
                break;
              case "RESPONSE_NOT_REQUIRED":
                notifyOnComplete = null;

                // We are done. The server should not send emails
                stage = 4;

                assignedUserId = null;
                break;
              default:
                throw new Error(`Unsupported Communication Option[${this.form.furtherCommunication.value}`);
            }
          }
        }

        let properties: Partial<IFormRecordPropertyParam>[] = [];

        this.formService.updateForm({
          id: this.formData.id,
          stage,
          userGroupId,
          assignedUserId,
          notifyOnComplete
        })
          .subscribe(() => {
            properties.push({
              name: "reassign",
              intData: this.form.reassign.value ? 1 : 0
            });

            properties.push({
              name: "furtherCommunication",
              stringData: this.form.furtherCommunication.value
            });

            if (this.form.reassign.value) {
              // TODO: This fields validation shoudl change from int > 0 to NONE when reassign is true/false
              properties.push({
                name: "reassignToUserId",
                intData: Number(this.form.reassignToUserId.value)
              });
            }

            properties.push({
              name: "followUps",
              jsonData: this.form.followUps.value
            });

            if (this.form.summary.value.length > 0) {
              properties.push({
                name: "summary",
                stringData: this.form.summary.value
              });
            }

            if (this.form.notes.value.length > 0) {
              properties.push({
                name: "notes",
                stringData: this.form.notes.value
              });
            }

            properties.push({
              name: "emailOther",
              intData: this.form.emailOther.value ? 1 : 0
            });

            if (this.form.emailOther.value) {
              properties.push({
                name: "otherEmail",
                stringData: this.form.otherEmail.value
              });
            }

            // Generate the follows
            let followUps: { userGroupId: string, description: string, dueDate: string }[] = [];
            if (this.form.followUps.value.length) {
              followUps = JSON.parse(this.form.followUps.value);
            }

            this.formRecordService.createRecord({
              formId: this.formData.id,
              // Intentionally cast the properties object since we know its correct
              properties: properties as any,
              stage: 2,
              documents: this.documents.map(doc => ({ id: doc.id, isTicked: !!doc.isTicked })),
              isComplete: !isDraft
            })
              .subscribe((data: any) => {
                //Done creating the form and appending its properties
                if (isDraft || followUps.length === 0) {

                  if (stage === 4) {
                    this.formService.finalizeForm(this.formData.id)
                      .subscribe(() => {
                        resolve(null);

                        this.closeForm();
                      }, err => this.errorHandler.handleHttpError(err));
                  } else {
                    success();
                  }
                } else if (this.form.reassign.value && this.form.reassign.value) {
                  success();
                } else {
                  if (stage === 4) {
                    this.formService.finalizeForm(this.formData.id)
                      .subscribe(
                        () => {
                          this.followUpService.generateFollowUps(this.formData.id, this.formData.formLocationId, followUps, this.getFirstSubmissionType(), success);
                        },
                        err => fail('Error while finalizing a form', err)
                      );
                  } else {
                    this.followUpService.generateFollowUps(this.formData.id, this.formData.formLocationId, followUps, this.getFirstSubmissionType(), success);
                  }
                }
              }, err => fail('Error creating a record', err));
          }, err => fail('Error updating a form', err));
      });
    });
  }

  private getFirstSubmissionType(): number | null {
    let firstSubmission = this.getFirstSubmission();

    if (firstSubmission) {
      let formType = firstSubmission.properties.find(recordProperty => recordProperty.property.name === "reportFormType");
      if (formType) {
        return formType.enumId;
      }
    }

    return null;
  }

  private getFirstSubmission(): IFormRecordOutputModel | null {
    if (!this.formData || !this.formData.records || !this.formData.records.length)
      return null;

    return this.formData.records.find(record => record.stage === 0 && record.isComplete) || null;
  }

  private repopulateFormFromData() {
    if (!this.formData || !this.formData.records || !this.formData.records.length)
      return;

    let stageRecords = this.formData.records.filter(record => record.stage === 2);
    let previousRecord = this.formData.records.slice().sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

    /*
      If there are no pre-existing stage records, OR if the previous record is not a stage record,
      AND a sequence has not been specified (IE, not submitted), load followups from that previous record
    */
    if (!this.sequence && (stageRecords.length === 0 || (previousRecord && previousRecord.stage !== 2))) {
      /*
          At this point, we want to acknowledge the possibility that the
          previous stage specified followups that need to be completed
       */
      stageRecords = this.formData.records.filter(record => record.stage === 1);

      let mostRecentRecord = stageRecords.sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

      if (!mostRecentRecord)
        return;

      let followUpRecord =
        mostRecentRecord.properties.find(recordProperty => recordProperty.property.name === 'followUps');

      if (followUpRecord) {
        this.form.followUps.value = followUpRecord.jsonData;
      }

      /**
       * aggregate documents and ticked documents from all previous complete records
       */
      const completeRecords = this.formData.records.filter(record => record.isComplete);

      let allDocuments: IDocumentType[] = [];
      let allTickedDocuments: { id: number }[] = [];

      for (const record of completeRecords) {
        if (record.documents && record.documents.length) {
          allDocuments = allDocuments.concat(record.documents.map(doc => ({ ...doc }))); // copy all documents object
          if (record.tickedDocuments && record.tickedDocuments.length) {
            allTickedDocuments = allTickedDocuments.concat(record.tickedDocuments)
          }
        }
      }

      this.documents = this.initTickedDocuments(allDocuments, allTickedDocuments);

      return;
    }

    if (!this.sequence) {
      let mostRecentRecord = stageRecords.sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

      if (!mostRecentRecord)
        throw new Error("internal error");

      // If the most recent record was a submission, we are not going to use it
      if (mostRecentRecord.isComplete)
        return;

      this.formRecord = mostRecentRecord;
    } else {
      let targetRecord = stageRecords.find(record => record.sequence === this.sequence);

      if (!targetRecord)
        throw new Error("internal error");

      this.formRecord = targetRecord;
    }

    this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);

    //Convert the properties into easily accessible IFormRecordPropertyParam
    if (!this.formRecord.properties)
      return;

    this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);

    let simpleProperties: { [key: string]: IFormRecordPropertyParam } = {};

    this.formRecord.properties.forEach(recordProperty => {
      //eject invalid property
      if (!recordProperty.property)
        return;

      let result: Partial<IFormRecordPropertyParam> = {
        name: recordProperty.property.name
      };

      if (recordProperty.stringData)
        result.stringData = recordProperty.stringData;

      if (recordProperty.intData)
        result.intData = recordProperty.intData;

      if (recordProperty.jsonData)
        result.jsonData = recordProperty.jsonData;

      if (recordProperty.enumId)
        result.enumId = recordProperty.enumId;

      simpleProperties[result.name as string] = result as IFormRecordPropertyParam;
    });

    /*
      If the last record for this stage is not the most recent record for this stage
      then it was passed back before coming forward again, and the reassignment
      should ALWAYS be 0
     */
    let allowReassignPrepopulate: boolean = true;
    /**
     *  FIX: added .slice() that copies an array to prevent mutation of original records
     **/
    let highestOrderRecord = this.formData.records.slice().sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();
    if (!this.sequence && highestOrderRecord && highestOrderRecord.sequence > this.formRecord.sequence)
      allowReassignPrepopulate = false;

    if (simpleProperties['reassign']
      && simpleProperties['reassign'].intData !== null
      && simpleProperties['reassign'].intData !== undefined
      && allowReassignPrepopulate)
      this.form.reassign.value = (simpleProperties['reassign'].intData > 0);

    if (simpleProperties['reassignToUserId'] && allowReassignPrepopulate)
      this.form.reassignToUserId.value = String(simpleProperties['reassignToUserId'].intData);

    if (simpleProperties['followUps'])
      this.form.followUps.value = simpleProperties['followUps'].jsonData;

    if (simpleProperties['summary'])
      this.form.summary.value = simpleProperties['summary'].stringData;

    if (simpleProperties['notes'])
      this.form.notes.value = simpleProperties['notes'].stringData;

    if (simpleProperties['furtherCommunication']) {
      this.defaultCommunicationOption = String(simpleProperties['furtherCommunication'].stringData) as CommunicationSelectOption;
      this.reloadCommunicationOptions();
    }

    if (simpleProperties['emailOther']
      && simpleProperties['emailOther'].intData !== null
      && simpleProperties['emailOther'].intData !== undefined)
      this.form.emailOther.value = (simpleProperties['emailOther'].intData > 0);

    if (simpleProperties['otherEmail'])
      this.form.otherEmail.value = simpleProperties['otherEmail'].stringData;
  }
}
