import {saveAs} from 'file-saver';
import * as moment from 'moment';
import { Component, Input } from "@angular/core";
import { ReportService } from "../../service/ReportService";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";

@Component({
  selector: 'download-report-link',
  template: `
  <div class="{{ inline ? 'd-inline' : 'row' }}">
      <div class="{{ inline ? 'd-inline' : 'col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1' }}">
          <a (click)="downloadPDFReport()" class="btn btn-link text-primary {{ inline ? 'py-0 btn-sm' : ''}}">
              <i class="fa {{isReportInProcess ? 'fa-circle-o-notch fa-spin' : 'fa-download'}}"></i>&nbsp;Download PDF Report
          </a>
      </div>
  </div>
  `
})
export class DownloadReportLinkComponent {

  @Input()
  formId: number;

  @Input()
  filePrefix: string;
  
  @Input()
  inline: boolean = false;

  public isReportInProcess = false;

  constructor(private reportService: ReportService, private errorHandler: ErrorHandlerService,) {
  }

  public downloadPDFReport() {
    if (this.formId && !this.isReportInProcess) {
      this.isReportInProcess = true;
      this.reportService.downloadReadOnlyFormsReport(this.formId).subscribe(
        data => {
          saveAs(data, `${this.filePrefix}-${moment().format('YYYY-MMM-DD')}.pdf`);
        },
        error => {
          this.errorHandler.handleHttpError(error)
        },
        () => {
          this.isReportInProcess =  false;
        });
    }
  }
}
