import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter
} from "@angular/core";
import { LocationService } from "../../service/LocationService";
import { FormLocation } from "../../../model/FormLocation";
import { Session } from "../../service/util/Session";
import { FormField } from "../../model/Form";
import { Select2Component } from "ng2-select2";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import { CurrentUserService } from "service/currentUser/CurrentUserService";
import { logger } from "service/util/Logger";
import { numStrToArr } from "service/util/converter";

type ExIdTextPair = IdTextPair & { selected?: boolean };

@Component({
  selector: "location-select",
  template: `<select2
    #select2Component
    class="form-control select2-form-control {{disabled?'bg-grey':''}} {{field && !disabled && field.iifIsValid('border-success','border-danger')}}"
    [data]="locationSelectData"
    [options]="locationSelectOptions"
    (valueChanged)="valueChanged($event.data)"
    [value]="defaultValue"
    [disabled]="disabled"
  ></select2>`
})
export class LocationSelectComponent implements OnInit {

  /**
   * Restrict available location to the user locations attached to the current user
   */
  @Input()
  restrictLocations: boolean = false;

  public locationSelectOptions: Select2Options;
  public locationSelectData: ExIdTextPair[] = [];

  public defaultValue: string = "";

  private _value: string = '';

  @Input('value')
  get value() {
    return this._value;
  }
  /**
   * By preventing assigning non-unique values during the set
   * we can prevent a loop of observable subscribers
   */
  set value(newValue: string) {
    this._value = newValue;

    if (!this.field && !this.multiple) {
      this.defaultValue = this._value;
    }
  }

  private _multiple: boolean = false;

  @Input("multiple")
  get multiple() {
    return this._multiple;
  }
  set multiple(newValue: boolean) {
    this._multiple = newValue;

    if (this.locationSelectOptions)
      this.locationSelectOptions.multiple = newValue;
  }

  @Input() field: FormField<any>;
  @Input() placeholder: string;
  @Input() disabled: boolean = false;

  // Reference firstNameInput variable inside Component
  @ViewChild("select2Component")
  select2ComponentRef: Select2Component;

  @Output()
  change: EventEmitter<ExIdTextPair[]> = new EventEmitter<IdTextPair[]>();

  constructor(
    public locationService: LocationService,
    public session: Session,
    private errorHandler: ErrorHandlerService,
    public currentUserService: CurrentUserService
  ) { }

  /** My god this is complex. It needs to be rewritten completely */
  ngOnInit() {

    this.locationSelectOptions = { allowClear: !this.multiple, placeholder: this.placeholder || "All Locations", multiple: this.multiple };

    this.session.lockInputRx(this.locationService.getLocations())
      .subscribe((items: FormLocation[]) => {
        let newSelectOptions: ExIdTextPair[] = [{ id: "", text: "" }];

        // remove groups that is not assigned to current user with role manager.
        if (this.restrictLocations) {
          const locations = this.currentUserService.currentUserData.value!.locations;

          if (locations && locations.length > 0) {
            items = items.filter(
              item =>
                !!locations.find(location => location.id === item.id)
            );
          }
        }

        let addedOptions = false;
        if (!this.field || !this.multiple || (this.multiple && this.field && String(this.field.value).length === 0)) {
          items.forEach((location: FormLocation) => newSelectOptions.push({ id: String(location.id), text: location.name }));
          this.locationSelectData = newSelectOptions;
          addedOptions = true;
        }

        //Force the change detection to cycle again to prevent race
        if (this.field) {
          const strVal = String(this.field.value);
          if (strVal !== this.defaultValue) {
            const locationIds = numStrToArr(strVal);

            if (!addedOptions) {
              items.forEach((location: FormLocation) => newSelectOptions.push({
                id: String(location.id),
                text: location.name,
                selected: !!locationIds.find(id => location.id === id)
              }));
              this.locationSelectData = newSelectOptions;
              addedOptions = true;
            } else {
              this.defaultValue = strVal;
            }
          }
        } else {
          //If the default value was manually set we need to re-trigger the process
          if (this._value !== '') {
            const valueArr: string[] | undefined = this.multiple && this._value && this._value.length ? this._value.split(",") : undefined;
            this.defaultValue = this.value;
            const options = this.locationSelectData.filter(o => (this.multiple ? (
              valueArr ? valueArr.find(selectedValue => selectedValue === o.id) : false
            ) : o.id === this._value));
            if (options && options.length) {
              options.map(opt => opt.selected = true);
            }

            logger.silly(`SetDefaultValues[${JSON.stringify(this.locationSelectData)}]`);
          }
        }
      }, err => this.errorHandler.handleHttpError(err));
  }

  valueChanged(selectedOpts: ExIdTextPair[]) {
    if (selectedOpts.length === 0 || (
      selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0
    )) {
      if (this.field)
        this.field.value = null;
    }

    if (selectedOpts.length > 1 && !this.multiple)
      throw ("Selected options unexpectedly contained multiple results");

    if (selectedOpts.length === 1 && !this.multiple) {
      if (this.field) {
        this.field.value = selectedOpts[0].id;
      } else {
        if (this._value !== selectedOpts[0].id) { // if the value has been changed - emit event
          this._value = selectedOpts[0].id;
          this.change.emit(selectedOpts);
          return;
        }
      }
    }

    if (this.multiple) {
      const actualSelected = selectedOpts.filter(opt => opt.id && opt.id.length);
      const selectedIds = actualSelected.map(opt => String(opt.id));
      const newValue = selectedIds.join(",");

      if (this.field) {
        console.log("Setting Field Value ", newValue);
        this.field.value = newValue;
      } else {
        logger.silly(`Checking internalValue change Value[(${typeof newValue}) ${newValue}] !== _value[(${typeof this._value}) ${this._value}]`);
        const currentlySelectedValues = (this._value || '').split(",");
        const hasMismatch = !currentlySelectedValues.every(value => selectedIds.includes(value)) || !selectedIds.every(value => currentlySelectedValues.includes(value));

        if (hasMismatch) { // if the value has been changed - emit event
          logger.silly("userGroupSelectComponent: Updating Internal Value");
          this._value = newValue;
          this.change.emit(actualSelected);
        }
      }
    }
  }
}
