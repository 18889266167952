
    <div class="row pt-2">
        <div class="col-12" *ngIf="titleType==='1'">
            <div class="d-flex">
                <div class="pr-2 text-center text-lg-left">
                    <h1 class="text-uppercase text-orange">{{ text }}</h1>
                </div>
                <div class="flex-grow-1">
                    <hr class="border border-grey border-5 line bw-4 my-4" />
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="titleType==='2'">
            <h1 class="text-uppercase text-white bg-teal p-2 px-3 my-2">{{ text }}</h1>
        </div>
    </div>
    