<div class="w-100 h-100 {{isLoading ? 't-40':''}}" *ngIf="session.isReady">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>

<div *ngIf="isLoading" class="row d-flex h-100 fixed-top t-50 bg-dark"></div>
<div *ngIf="isLoading" class="row d-flex h-100 fixed-top">
  <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-xl-4 offset-xl-4 align-self-center text-center">
    <img src="assets/images/logo.png" />
  </div>
</div>

<ng-template confirmModal>
  <confirm-modal-component></confirm-modal-component>
</ng-template>

<ng-template alertModal>
  <alert-modal-component></alert-modal-component>
</ng-template>

<ng-template dateSelectModal>
  <date-select-modal></date-select-modal>
</ng-template>

<ng-template cronModal>
  <cron-modal></cron-modal>
</ng-template>

<ng-template riskModal>
  <risk-modal></risk-modal>
</ng-template>

<ng-template uploadModal>
  <upload-modal></upload-modal>
</ng-template>
