<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-10 pb-2">
                Welcome {{ currentUserService.currentUserFullName | async }}
            </div>
            <div class="col-2 text-right">
                <div ngbDropdown class="dropdown-link-wrapper" placement="bottom-right">
                    <a class="dropdown-link" ngbDropdownToggle>Export to</a>
                    <div ngbDropdownMenu>
                        <button class="dropdown-item" (click)="handleCsvExport()">CSV file</button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <h1 class="main-top-header">DOCUMENTS DASHBOARD</h1>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card-deck">
            <div class="card text-white bg-danger mb-3 top-card-filter" (click)="toggleTopCardFilter('overdue')"
                [ngClass]="{'active': formsQueryParams.dueFilter === 'overdue'}">
                <div class="card-body">
                    <h1 class="card-title text-center text-xxl">{{alertCount}}</h1>
                </div>
                <div class="card-footer text-center">OVERDUE</div>
            </div>
            <div class="card text-white bg-warning mb-3 top-card-filter" (click)="toggleTopCardFilter('alert')"
                [ngClass]="{'active': formsQueryParams.dueFilter === 'alert'}">
                <div class="card-body">
                    <h1 class="card-title text-center text-xxl">{{warningCount}}</h1>
                </div>
                <div class="card-footer text-center">Requires Attention</div>
            </div>
            <div class="card text-white bg-success mb-3 top-card-filter" (click)="toggleTopCardFilter('remaining')"
                [ngClass]="{'active': formsQueryParams.dueFilter === 'remaining'}">
                <div class="card-body">
                    <h1 class="card-title text-center text-xxl">{{taskCount}}</h1>
                </div>
                <div class="card-footer text-center">Remaining Tasks</div>
            </div>
        </div>
    </div>
    <ngb-tabset [destroyOnHide]="false" class="col-12" (tabChange)="onTabChange($event)" #tabSet="ngbTabset">
        <ngb-tab title="Tasks" id="Tasks">
            <ng-template ngbTabContent>
                <div class="col-12 pt-2">
                    <div class="row">
                        <div class="col-8">
                            <div class="form-group search-input-group">
                                <input class="form-control search-input-control" type="text" placeholder="Search 🔍"
                                    [(ngModel)]="searchFilter" (keyup.enter)="performSearch()"
                                    (keyup.escape)="clearSearch()" (blur)="performSearch()">
                                <span class="search-input-clear" title="Clear" *ngIf="searchFilter.length > 0"
                                    (click)="clearSearch()">&times;</span>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="isAdminOrManager">
                        <div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6 pb-2">
                            <user-select #userSelect (change)="handleUserFilterChange($event)"
                                placeholder="View Queue For User" [defaultValue]="defaultAssignedUserId"></user-select>
                        </div>
                        <div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6 pb-2">
                            <user-group-select (change)="handleGroupFilterChange($event)" placeholder="Department"
                                [value]="formsQueryParams.groupId || ''" [restrictGroups]="!isAdmin" multiple="true">
                            </user-group-select>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="second-line-filter">
                        <div class="date-filter-block">
                            <div ngbDropdown class="dropdown-link-wrapper" placement="bottom-left">
                                <a class="dropdown-link" ngbDropdownToggle>{{dateFilterMap[selectedDateFilter]}}</a>
                                <div ngbDropdownMenu>
                                    <button class="dropdown-item" (click)="handleDateFilterChange('createdAt')"
                                        *ngIf="selectedDateFilter !== 'createdAt'">Created At</button>
                                    <button class="dropdown-item" (click)="handleDateFilterChange('dueAt')"
                                        *ngIf="selectedDateFilter !== 'dueAt'">Due Date</button>
                                    <button class="dropdown-item" (click)="handleDateFilterChange('none')"
                                        *ngIf="selectedDateFilter !== 'none'">Clear Filter</button>
                                </div>
                            </div>
                            <div class="date-picker-container" *ngIf="selectedDateFilter !== 'none'">
                                <date-picker placeholder="Start date" [clearable]="true" [(value)]="filterStartDate"
                                    (valueChange)="handleStartDateChanged()" [disableValidationStyles]="true">
                                </date-picker>
                            </div>
                            <div class="date-picker-container" *ngIf="selectedDateFilter !== 'none'">
                                <date-picker placeholder="End date" [clearable]="true" [(value)]="filterEndDate"
                                    (valueChange)="handleEndDateChanged()" [disableValidationStyles]="true">
                                </date-picker>
                            </div>
                        </div>
                        <div class="show-all-filter-block" *ngIf="isAdminOrManager">
                            <button-group-toggle [options]="showAllOptions" [value]="formsQueryParams.showAll"
                                (valueChange)="handleShowAllFilterChange($event)"></button-group-toggle>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <span class="sortable-column" (click)="handleSortChange('category')"
                                        [ngClass]="{active: formsQueryParams.sortBy === 'category', reverse: formsQueryParams.order !== 'asc'}">
                                        Type<i class="fa fa-caret-up"></i>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span class="sortable-column" (click)="handleSortChange('summary')"
                                        [ngClass]="{active: formsQueryParams.sortBy === 'summary', reverse: formsQueryParams.order !== 'asc'}">
                                        Form<i class="fa fa-caret-up"></i>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span class="sortable-column" (click)="handleSortChange('documentIdentity')"
                                        [ngClass]="{active: formsQueryParams.sortBy === 'documentIdentity', reverse: formsQueryParams.order !== 'asc'}">
                                        Document<i class="fa fa-caret-up"></i>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span class="sortable-column" (click)="handleSortChange('assignedTo')"
                                        [ngClass]="{active: formsQueryParams.sortBy === 'assignedTo', reverse: formsQueryParams.order !== 'asc'}">
                                        Assigned To<i class="fa fa-caret-up"></i>
                                    </span>
                                </th>
                                <th scope="col" class="text-center">
                                    <span class="sortable-column" (click)="handleSortChange('createdAt')"
                                        [ngClass]="{active: formsQueryParams.sortBy === 'createdAt', reverse: formsQueryParams.order !== 'asc'}">
                                        Created At<i class="fa fa-caret-up"></i>
                                    </span>
                                </th>
                                <th scope="col" class="text-center">
                                    <span class="sortable-column" (click)="handleSortChange('dueAt')"
                                        [ngClass]="{active: formsQueryParams.sortBy === 'dueAt', reverse: formsQueryParams.order !== 'asc'}">
                                        Due Date<i class="fa fa-caret-up"></i>
                                    </span>
                                </th>
                                <th scope="col" class="text-center" *ngIf="formsQueryParams.showAll !== 'active'">
                                    <span class="sortable-column" (click)="handleSortChange('archivedAt')"
                                        [ngClass]="{active: formsQueryParams.sortBy === 'archivedAt', reverse: formsQueryParams.order !== 'asc'}">
                                        {{formsQueryParams.showAll === 'deleted' ? 'Deleted On' : 'Completed On'}}<i
                                            class="fa fa-caret-up"></i>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="hover-cursor" [ngClass]="{
                                    'table-danger': record.isAlert, 
                                    'table-warning': record.isWarning, 
                                    'table-success':  !record.isAlert && !record.isWarning, 
                                    'table-secondary': record.isFinalized
                                }" *ngFor="let record of dashboardRecords" (click)="progressForm(record)">
                                <td>{{record.type}}</td>
                                <td>{{record.title}}</td>
                                <td>{{record.documentName}}</td>
                                <td>
                                    <span *ngIf="record.assignedUser">
                                        {{ record.assignedUser }}
                                    </span>
                                    <span *ngIf="!record.assignedUser && record.assignedGroup">
                                        {{ record.assignedGroup }} Department
                                    </span>
                                </td>
                                <td class="text-center">{{record.createdAtDateString}}</td>
                                <td class="text-center">{{record.dueDateString}}</td>
                                <td class="text-center">{{record.archivedAtDateString}}</td>
                            </tr>
                            <tr *ngIf="dashboardRecords.length === 0">
                                <td [colSpan]="formsQueryParams.showAll === 'active' ? 6 : 7" class="text-center">
                                    No Tasks
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12" *ngIf="dashboardRecords.length > 0">
                    <pagination [collectionSize]="totalForms" [(page)]="currentPage" (pageChange)="handlePageChange()"
                        [pageSize]="pageSize" (pageSizeChange)="handlePageSizeChange($event)"></pagination>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="Search" id="Search">
            <ng-template ngbTabContent>
                <div class="col-12 pt-2">
                    <div class="row">
                        <div class="col-12" [ngClass]="{ 'col-sm-6 col-lg-8' : isAdminOrManager }">
                            <div class="form-group search-input-group">
                                <input class="form-control search-input-control" type="text" placeholder="Search 🔍"
                                    [(ngModel)]="searchFilter" (keyup.enter)="performDocumentSearch()"
                                    (keyup.escape)="clearSearch()" (blur)="performDocumentSearch()">
                                <span class="search-input-clear" title="Clear" *ngIf="searchFilter.length > 0"
                                    (click)="clearSearch()">&times;</span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4" *ngIf="isAdminOrManager">
                            <enum-select placeholder="Standard" [field]="documentStandard" enum="documentStandard" 
                            (change)="handleStandardFilterChange($event)"
                            ></enum-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-4">
                            <label>Tags:</label>
                            <tag-select [value]="documentInstanceQueryParams.tags" [isMeta]="false" multiple="true"
                            (change)="handleTagFilterChange($event)" placeholder="Select a tag">
                            </tag-select>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <label>Department:</label>
                            <user-group-select (change)="handleDocumentGroupFilterChange($event)" placeholder="Department"
                            [value]="documentInstanceQueryParams.groupId || ''" [restrictGroups]="!isAdmin" multiple="true">
                        </user-group-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <label>Meta Tags:</label>
                            <div class="row">
                                <div class="col-12 offset-0 col-sm-6">
                                    <div class="d-flex">
                                        <div *ngFor="let tag of metaTags" class="flex-shrink-0 badge badge-light mr-1">
                                            <div class="d-flex align-items-center">
                                                <div>{{tag.id}} : {{tag.value}}&nbsp;&nbsp;</div>
                                                <button class="close"
                                                (click)="removeMeta(metaTags.indexOf(tag))">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group d-flex ">
                                <tag-select [value]="metaTag" [multiple]="false" class='form-control' (change)="handleMetaChange($event)"></tag-select>
                                <input [(ngModel)]="metaTagValueModel" type="text" autofocus class="form-control input-lg ml-2" placeholder="Value">
                                <button class="btn btn-outline-success ml-2" (click)="addNewMeta()">Add</button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="form-group">
                                <label>Last Updated Date</label>
                                <div class="row">
                                    <div class="col-6">
                                        <date-picker (valueChange)="lastUpdatedAtFromFilterChange($event)"></date-picker>
                                    </div>
                                    <div class="col-6">
                                        <date-picker (valueChange)="lastUpdatedAtEndFilterChange($event)"></date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="form-group">
                                <label>Show archived:</label>
                                <button-toggle
                                    [value]="includeArchived"
                                    (valueChange)="handleIncludeArchivedToggle()"
                                    [disabled]="!isAdmin"></button-toggle>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <span class="sortable-column"
                                        [ngClass]="{active: formsQueryParams.sortBy === 'documentIdentity', reverse: formsQueryParams.order !== 'asc'}">
                                        Document ID<i class="fa fa-caret-up"></i>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span class="sortable-column"
                                        [ngClass]="{active: formsQueryParams.sortBy === 'category', reverse: formsQueryParams.order !== 'asc'}">
                                        Collection<i class="fa fa-caret-up"></i>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span class="sortable-column"
                                        [ngClass]="{active: formsQueryParams.sortBy === 'summary', reverse: formsQueryParams.order !== 'asc'}">
                                        Name<i class="fa fa-caret-up"></i>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span class="sortable-column"
                                        [ngClass]="{active: formsQueryParams.sortBy === 'location', reverse: formsQueryParams.order !== 'asc'}">
                                        Type<i class="fa fa-caret-up"></i>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span class="sortable-column"
                                        [ngClass]="{active: formsQueryParams.sortBy === 'assignedTo', reverse: formsQueryParams.order !== 'asc'}">
                                        Last Updated<i class="fa fa-caret-up"></i>
                                    </span>
                                </th>
                                <th>
                                    <span>Download</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="hover-cursor" *ngFor="let document of aprovedDocuments"
                            (click)="handleDocumentRowClick(document)">
                                <td>{{document.documentCode}}</td>
                                <td>{{document.documentCollectionName}}</td>
                                <td>{{document.documentName}}</td>
                                <td>{{document.documentType}}</td>
                                <td>{{document.aprovedAt | date : 'dd-MM-yy'}}</td>
                                <td>
                                    <button type="button" 
                                    class="btn btn-link" 
                                    (click)="downloadDocument(document.id,document.documentName)"
                                    >Download</button>
                                </td>
                            </tr>
                            <tr *ngIf="aprovedDocuments.length === 0">
                                <td [colSpan]="formsQueryParams.showAll === 'active' ? 6 : 7" class="text-center">
                                    No Documents
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12" *ngIf="aprovedDocuments.length > 0">
                    <pagination [collectionSize]="totalDocuments" [(page)]="currentPage" (pageChange)="handlePageChangeDocumentSearch()"
                        [pageSize]="pageSize" (pageSizeChange)="handlePageSizeChangeDocumentSearch($event)"></pagination>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>