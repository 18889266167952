var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormService } from "../../../../service/FormService";
import { ActivatedRoute, Router } from "@angular/router";
import { Session } from "../../../../service/util/Session";
var has = Reflect.has;
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
import { ComponentCanDeactivate } from "../../../shared/canDeactivate/ComponentCanDeactivate";
import { FollowUpFormStage0Component } from "./stage0/followUpFormStage0.component";
import { FollowUpFormStage2Component } from "./stage2/followUpFormStage2.component";
import { FollowUpFormStage1Component } from "./stage1/followUpFormStage1.component";
import { CurrentUserService } from 'service/currentUser/CurrentUserService';
var FollowUpFormComponent = /** @class */ (function (_super) {
    __extends(FollowUpFormComponent, _super);
    function FollowUpFormComponent(formService, activatedRoute, session, errorHandler, router, currentUserService) {
        var _this = _super.call(this) || this;
        _this.formService = formService;
        _this.activatedRoute = activatedRoute;
        _this.session = session;
        _this.errorHandler = errorHandler;
        _this.router = router;
        _this.currentUserService = currentUserService;
        _this.showForm = false;
        _this.isAdmin = false;
        _this.formData = null;
        _this.childForms = null;
        return _this;
    }
    FollowUpFormComponent.prototype.canDeactivate = function () {
        if (!this.showForm)
            return true;
        if (this.formData && this.formData.isDeleted) {
            return true;
        }
        if (this.targetStage === 0)
            return this.followUpStage0Form.canDeactivate();
        if (this.targetStage === 1)
            return this.followUpStage1Form.canDeactivate();
        if (this.targetStage === 2)
            return this.followUpStage2Form.canDeactivate();
        return true;
    };
    FollowUpFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isAdmin = this.currentUserService.isAdministrator.getValue();
        this.activatedRoute.params.subscribe(function (params) {
            if (!has(params, "reportId")) {
                _this.showForm = true;
                _this.targetStage = 0;
                _this.formData = null;
                _this.childForms = null;
                return;
            }
            _this.session.lockInputRx(_this.formService.getFormById(params["reportId"])).subscribe(function (existingForm) {
                if (!existingForm) {
                    return;
                }
                /**
                 *  fix for 'Parent Form' link, if form's category is 'Report' redirect to /report/:reportId
                 **/
                if (existingForm.category.name === 'Report') {
                    _this.router.navigate(['/report', existingForm.id], { replaceUrl: true });
                    return;
                }
                /**
                 *  fix for 'Parent Form' link, if form's category is 'Report' redirect to /report/:reportId
                 **/
                if (existingForm.category.name === 'Audit') {
                    _this.router.navigate(['/audit', existingForm.id], { replaceUrl: true });
                    return;
                }
                /**
                 *  fix for 'Parent Form' link, if form's category is 'DRM-Task' redirect to /document-review-task/:reportId
                 **/
                if (existingForm.category.name === 'DRM-Task') {
                    _this.router.navigate(['/document-review-task', existingForm.id], { replaceUrl: true });
                    return;
                }
                _this.formData = existingForm;
                if (_this.formData) {
                    if (_this.formData.records.length === 0) {
                        _this.errorHandler.raiseError("Record did not contain valid data. Please contact a system administrator for assistance.", "Invalid Form Data");
                        return;
                    }
                    _this.targetStage = _this.formData.stage || 0;
                    _this.showForm = true;
                    if (_this.formData.archivedAt && _this.formData.childForms && _this.formData.childForms.length) {
                        _this.childForms = _this.formData.childForms;
                    }
                    else {
                        _this.childForms = null;
                    }
                }
            }, function (err) {
                console.error('Error while getting follow-up form', err);
                _this.errorHandler.handleHttpError(err);
            });
        });
    };
    return FollowUpFormComponent;
}(ComponentCanDeactivate));
export { FollowUpFormComponent };
