import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import "moment-timezone";
import {isNil} from "lodash";
import {FormComponent} from "../../../../../model/FormComponent";
import {FormField} from "../../../../../model/Form";
import {Session} from "../../../../../service/util/Session";
import {CurrentUserService} from "../../../../../service/currentUser/CurrentUserService";
import {IFormRecordOutputModel, IFormRecordPropertyParam} from "../../../../../../common/contracts/form";
import {FormRecordService} from "../../../../../service/FormRecordService";
import {FormService} from "../../../../../service/FormService";
import { IFormOutputModel } from "../../../../../../common/contracts/form";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'report-form-3',
  templateUrl: './reportFormStage3.component.html'
})
export class ReportFormStage3Component extends FormComponent implements OnInit {

  // Existing Form Data
  @Input() public formData: IFormOutputModel;
  @Input() sequence: number;

  public formRecord: IFormRecordOutputModel;

  @Input() readOnly: boolean = false;
  @Input() hideHeader: boolean = false;

  /*
    This should have been done properly using something that implements FormControl but its
    too late now
   */
  public form:{ [key:string]:FormField<any> } = {
    adminComplete: new FormField<boolean>(false, {
      validation: FormField.ValidationMethods.None
    }),
    notes: new FormField<String>('', {
      validation: FormField.ValidationMethods.None
    }),
  };

  public dateString: string;

  constructor(
    public session: Session,
    public currentUserService: CurrentUserService,
    public formService: FormService,
    public formRecordService: FormRecordService,
    private errorHandler: ErrorHandlerService,
    public router: Router
  ){
    super(router);
  }

  registerFormFields() {
    this.formFields.push( ...Object.keys(this.form).map((k:string) => this.form[k]) );
  }

  ngOnInit() {
    this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);

    this.registerFormFields();

    this.repopulateFormFromData();
  }

  private repopulateFormFromData() {
    if( !this.formData || !this.formData.records || !this.formData.records.length )
      return;

    let stageRecords = this.formData.records.filter( record => record.stage === 3);
    if( stageRecords.length === 0 )
      return;

    if( !this.sequence ) {
      let mostRecentRecord = stageRecords.sort( (a, b) => a.sequence > b.sequence ? 1 : -1 ).pop();

      if (!mostRecentRecord)
        throw new Error("internal error");

      // If the most recent record was a submission, we are not going to use it
      if( mostRecentRecord.isComplete )
        return;

      this.formRecord = mostRecentRecord;
    } else {
      let targetRecord = stageRecords.find( record => record.sequence === this.sequence );

      if( !targetRecord )
        throw new Error("internal error");

      this.formRecord = targetRecord;
    }

    this.dateString = moment( this.formRecord.createdAt ).tz(environment.timeZone).format( this.dateFormat );

    let simpleProperties:{[key:string]: IFormRecordPropertyParam} = {};

    this.formRecord.properties.forEach( recordProperty => {
      //eject invalid property
      if( !recordProperty.property )
        return;

      let result:Partial<IFormRecordPropertyParam> = {
        name: recordProperty.property.name
      };

      if(recordProperty.stringData)
        result.stringData = recordProperty.stringData;

      if(recordProperty.intData)
        result.intData = recordProperty.intData;

      if(recordProperty.jsonData)
        result.jsonData = recordProperty.jsonData;

      if(recordProperty.enumId)
        result.enumId = recordProperty.enumId;

      simpleProperties[result.name as string] = result as IFormRecordPropertyParam;
    });

    if( simpleProperties['adminComplete'] && !isNil(simpleProperties['adminComplete'].intData))
      this.form.adminComplete.value = ( simpleProperties['adminComplete'].intData > 0);

    if( simpleProperties['notes'] )
      this.form.notes.value = simpleProperties['notes'].stringData;
  }

  onSubmit(isDraft: boolean) {
    this.session.lockInput(  () => {
      return new Promise((resolve, reject) => {

        let success = () => {
          resolve();

          this.closeForm();
        };
  
        let fail = (msg:string, err:any) => {
          console.error(msg, err);
          this.errorHandler.handleHttpError(err);
          reject();
        };

        let stage: number = isDraft ? 3 : 4;

        let properties: Partial<IFormRecordPropertyParam>[] = [];

        this.formService.updateForm({
          id: this.formData.id,
          stage
        })
        .subscribe(() => {
          properties.push({
            name: "adminComplete",
            intData: this.form.adminComplete.value ? 1 : 0
          });

          if( this.form.notes.value.length > 0 )
            properties.push({
              name: "notes",
              stringData: this.form.notes.value
            });

          this.formRecordService.createRecord({
            formId: this.formData.id,
            // Intentionally cast the properties object since we know its correct
            properties: properties as any,
            stage: 3,
            documents: [], // this stage doesn't have uploads
            isComplete: !isDraft
          })
          .subscribe( () => {
            if( stage === 4 )
              return this.formService.finalizeForm(this.formData.id).subscribe(success);
            
            return success();
          },
          err => fail('Error while creating a record', err));
        },
        err => fail('Error while updating a form', err));
      });
    });
  }
}
