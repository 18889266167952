
    <div class="border">
      <div class="row p-2">
          <div class="col-12">
              <div class="row" *ngIf="!hideHeader">
                  <div class="col-12">
                      <span class="h5">Follow Up:</span>
                  </div>
              </div>
              <div class="row {{i===0?'pt-1':'pt-3'}}" *ngFor="let followUp of followUps; let i=index">
                  <div class="col-12 {{i===0?'':'pb-1'}}" *ngIf="!readOnly">
                    <span class="fa fa-close pull-right hover-cursor" *ngIf="followUps.length > 1 || isNotEmpty" (click)="deleteFollowUp(i)"></span>
                  </div>
                  <div class="col-12 offset-0 col-md-6">
                      <div class="form-group">
                          <label *ngIf="i===0">Department:</label>
                          <user-group-select
                            [value]="followUps[i].userGroupId"
                            [disabled]="readOnly"
                            (change)="setUserGroupId($event, i);"
                          ></user-group-select>
                      </div>
                  </div>
                  <div class="col-12 offset-0 col-md-6">
                      <div class="form-group">
                          <label *ngIf="i===0">Due Date:</label>
                          <date-picker [disabled]="readOnly" [(value)]="followUps[i].dueDate" (valueChange)="emit()" [disablePastDates]="true"></date-picker>
                      </div>
                  </div>
                  <div class="col-12">
                      <textarea
                        class="form-control"
                        replaceAmpersand
                        autosize
                        [minRows]="2"
                        [placeholder]="readOnly ? '' : 'Description'"
                        [spellcheck]="true"
                        [disabled]="readOnly"
                        [(ngModel)]="followUps[i].description"
                        (input)="emit()"
                        [ngModelOptions]="{standalone: true}"
                      ></textarea>
                  </div>
                  <div class="col-12" *ngIf="!followUps[i].isValid && !readOnly">
                    <small class="form-text text-danger text-right">Please Ensure all fields are filled.</small>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12 pt-2">
                      <div class="border-bottom border-dashed"></div>
                  </div>
              </div>
          </div>
          <div class="col-12 pt-2" *ngIf="!readOnly">
              <div class="d-inline-block hover-cursor" (click)="addFollowUp()">
                  <span class="fa fa-plus text-success"></span> Follow Up (Add one per department)
              </div>
          </div>
      </div>
  </div>
  