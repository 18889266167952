var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericService } from './GenericService';
import { HttpClient } from '@angular/common/http';
var ResourcePackService = /** @class */ (function (_super) {
    __extends(ResourcePackService, _super);
    function ResourcePackService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.apiPrefix = '/api/resource-packs';
        return _this;
    }
    ResourcePackService.prototype.create = function (name) {
        return this.http.post(this.apiPrefix, { name: name });
    };
    ResourcePackService.prototype.delete = function (id) {
        return this.http.delete(this.apiPrefix.concat('/', id.toString()));
    };
    ResourcePackService.prototype.fetch = function () {
        return this.http.get(this.apiPrefix);
    };
    ResourcePackService.prototype.fetchById = function (resourcePackId) {
        return this.http.get(this.apiPrefix.concat('/', resourcePackId));
    };
    ResourcePackService.prototype.updateName = function (id, name) {
        return this.http.put(this.apiPrefix.concat('/', id.toString()), { name: name });
    };
    ResourcePackService.prototype.attachDocument = function (resourcePackId, document) {
        return this.http.post(this.apiPrefix.concat('/', resourcePackId.toString(), '/documents'), document);
    };
    ResourcePackService.prototype.deleteDocument = function (documentId) {
        return this.http.delete(this.apiPrefix.concat('/documents/', documentId.toString()));
    };
    return ResourcePackService;
}(GenericService));
export { ResourcePackService };
