import { EventEmitter } from '@angular/core';
import { ArbitraryValueCache } from 'service/util/ArbitraryValueCache';
import { CurrentUserService } from 'service/currentUser/CurrentUserService';
import { FormField } from 'model/Form';
import { ActivatedRoute, Router } from '@angular/router';
var DashboardFilterComponent = /** @class */ (function () {
    function DashboardFilterComponent(currentUserService, arbitraryValueCache, route, router) {
        var _this = this;
        this.currentUserService = currentUserService;
        this.arbitraryValueCache = arbitraryValueCache;
        this.route = route;
        this.router = router;
        this.cacheKey = DashboardFilterComponent.CACHE_KEY;
        this.showAllOptions = [
            { value: 'all', text: 'All' },
            { value: 'complete', text: 'Complete' },
            { value: 'active', text: 'Active' },
        ];
        this.filter = {
            page: '1',
            search: '',
            originatorName: '',
            sortBy: 'dueAt',
            order: 'asc'
        };
        this.dateFilterMap = {
            none: 'Filter By Date',
            createdAt: 'Created At',
            dueAt: 'Due Date',
        };
        this.filterStartDate = new FormField('', {
            validation: FormField.ValidationMethods.None,
            onChange: function (val) {
                if (val === null) {
                    _this.filter[_this.selectedDateFilter + 'Start'] = '';
                }
                else {
                    _this.filter[_this.selectedDateFilter + 'Start'] = val.replace(/\//g, '-');
                }
                _this.publishFilter();
            }
        });
        this.filterEndDate = new FormField('', {
            validation: FormField.ValidationMethods.None,
            onChange: function (val) {
                if (val === null) {
                    _this.filter[_this.selectedDateFilter + 'End'] = '';
                }
                else {
                    _this.filter[_this.selectedDateFilter + 'End'] = val.replace(/\//g, '-');
                }
                _this.publishFilter();
            }
        });
        this.assignedUserId = new FormField('', {
            validation: FormField.ValidationMethods.None,
            onChange: function (val) {
                if (val) {
                    _this.filter.assignedUserId = val;
                }
                else {
                    _this.filter.assignedUserId = '';
                }
                _this.publishFilter();
            }
        });
        this.locations = new FormField('', {
            validation: FormField.ValidationMethods.None,
            onChange: function (val) {
                if (val) {
                    _this.filter.locationId = val;
                }
                else {
                    _this.filter.locationId = '';
                }
                _this.publishFilter();
            }
        });
        this.groups = new FormField('', {
            validation: FormField.ValidationMethods.None,
            onChange: function (val) {
                if (val) {
                    _this.filter.groupId = val;
                }
                else {
                    _this.filter.groupId = '';
                }
                _this.publishFilter();
            }
        });
        this.category = new FormField('', {
            validation: FormField.ValidationMethods.None,
            onChange: function (val) {
                if (val) {
                    _this.filter.category = val;
                }
                else {
                    _this.filter.category = '';
                }
                _this.publishFilter();
            }
        });
        this.reportFormType = new FormField('', {
            validation: FormField.ValidationMethods.None,
            onChange: function (val) {
                if (val) {
                    _this.filter.reportFormType = val;
                }
                else {
                    _this.filter.reportFormType = '';
                }
                _this.publishFilter();
            }
        });
        this.selectedDateFilter = 'none';
        this.filterChange = new EventEmitter();
    }
    Object.defineProperty(DashboardFilterComponent.prototype, "defaultFilterCategoryText", {
        get: function () {
            return 'All Categories';
        },
        enumerable: true,
        configurable: true
    });
    DashboardFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        var currentQueryParams = this.route.snapshot.queryParams;
        var currentFilterData = this.arbitraryValueCache.get(this.cacheKey) || {};
        Object.assign(this.filter, currentFilterData, currentQueryParams);
        this.isAdmin = this.currentUserService.isAdministrator.getValue();
        this.isAdminOrManager = this.isAdmin || this.currentUserService.userData.role === 'manager';
        if (this.isAdmin) {
            this.showAllOptions.splice(2, 0, { value: 'deleted', text: 'Deleted' });
        }
        if (this.isAdminOrManager) {
            if (!this.filter.showAll) {
                this.filter.showAll = 'active';
            }
            if (this.filter.assignedUserId === undefined) {
                var currentUserId = this.currentUserService.currentUserId.getValue();
                if (currentUserId) {
                    var userIdStr = String(currentUserId);
                    this.assignedUserId.value = userIdStr;
                }
            }
            else {
                this.assignedUserId.patch(this.filter.assignedUserId);
            }
            if (this.filter.locationId === undefined) {
                // All locations for the current user
                this.locations.value = (this.currentUserService.currentUserData.value.locations || [])
                    .map(function (location) { return location.id.toString(); })
                    .join(",");
            }
            else {
                this.locations.patch(this.filter.locationId);
            }
            if (this.filter.groupId === undefined) {
                // All locations for the current user
                this.groups.value = (this.currentUserService.currentUserData.value.groups || [])
                    .map(function (group) { return group.id.toString(); })
                    .join(",");
            }
            else {
                this.groups.patch(this.filter.groupId);
            }
        }
        else {
            delete this.filter.showAll;
            delete this.filter.assignedUserId;
            delete this.filter.locationId;
            delete this.filter.groupId;
        }
        if (this.filter.category) {
            this.category.patch(this.filter.category);
        }
        if (this.filter.reportFormType) {
            this.reportFormType.patch(this.filter.reportFormType);
        }
        if (this.filter.dueAtEnd || this.filter.dueAtStart) {
            this.selectedDateFilter = 'dueAt';
            delete this.filter.createdAtEnd;
            delete this.filter.createdAtStart;
            if (this.filter.dueAtEnd) {
                this.filterEndDate.value = this.filter.dueAtEnd.replace(/\-/g, '/');
            }
            if (this.filter.dueAtStart) {
                this.filterStartDate.value = this.filter.dueAtStart.replace(/\-/g, '/');
            }
        }
        else if (this.filter.createdAtEnd || this.filter.createdAtStart) {
            this.selectedDateFilter = 'createdAt';
            delete this.filter.dueAtEnd;
            delete this.filter.dueAtStart;
            if (this.filter.createdAtEnd) {
                this.filterEndDate.value = this.filter.createdAtEnd.replace(/\-/g, '/');
            }
            if (this.filter.createdAtStart) {
                this.filterStartDate.value = this.filter.createdAtStart.replace(/\-/g, '/');
            }
        }
        // Load from URL on init
        // Write URL to cache
        // Listen to caches in the cache
        this.arbitraryValueCache.onChange(this.cacheKey).subscribe(function (filter) {
            _this.filter = filter;
            _this.filterChange.emit(_this.filter);
        });
        // The filter is ready for interaction
        this.publishFilter();
    };
    DashboardFilterComponent.prototype.setDefaultFilter = function () {
        this.filter = Object.assign({}, DashboardFilterComponent.DEFAULT_FILTER);
        if (this.isAdminOrManager) {
            if (!this.filter.showAll) {
                this.filter.showAll = 'active';
            }
            if (this.filter.assignedUserId === undefined) {
                var currentUserId = this.currentUserService.currentUserId.getValue();
                if (currentUserId) {
                    var userIdStr = String(currentUserId);
                    this.assignedUserId.value = userIdStr;
                }
            }
            else {
                this.assignedUserId.patch(this.filter.assignedUserId);
            }
            if (this.filter.locationId === undefined) {
                // All locations for the current user
                this.locations.value = (this.currentUserService.currentUserData.value.locations || [])
                    .map(function (location) { return location.id.toString(); })
                    .join(",");
            }
            else {
                this.locations.patch(this.filter.locationId || null);
            }
            if (this.filter.groupId === undefined) {
                // All locations for the current user
                this.groups.value = (this.currentUserService.currentUserData.value.groups || [])
                    .map(function (group) { return group.id.toString(); })
                    .join(",");
            }
            else {
                this.groups.patch(this.filter.groupId);
            }
        }
        else {
            delete this.filter.showAll;
            delete this.filter.assignedUserId;
            delete this.filter.locationId;
            delete this.filter.groupId;
        }
        this.category.patch(this.filter.category || null);
        this.reportFormType.patch(this.filter.reportFormType || null);
        if (this.filter.dueAtEnd || this.filter.dueAtStart) {
            this.selectedDateFilter = 'dueAt';
            delete this.filter.createdAtEnd;
            delete this.filter.createdAtStart;
            if (this.filter.dueAtEnd) {
                this.filterEndDate.value = this.filter.dueAtEnd.replace(/\-/g, '/');
            }
            if (this.filter.dueAtStart) {
                this.filterStartDate.value = this.filter.dueAtStart.replace(/\-/g, '/');
            }
        }
        else if (this.filter.createdAtEnd || this.filter.createdAtStart) {
            this.selectedDateFilter = 'createdAt';
            delete this.filter.dueAtEnd;
            delete this.filter.dueAtStart;
            if (this.filter.createdAtEnd) {
                this.filterEndDate.value = this.filter.createdAtEnd.replace(/\-/g, '/');
            }
            if (this.filter.createdAtStart) {
                this.filterStartDate.value = this.filter.createdAtStart.replace(/\-/g, '/');
            }
        }
        this.router.navigate(['.'], {
            relativeTo: this.route,
            replaceUrl: true,
            queryParams: this.filter,
            queryParamsHandling: '',
        }).then(function () {
            window.location.reload();
        });
    };
    DashboardFilterComponent.prototype.publishFilter = function () {
        // Write to URL
        if (!this.filter.createdAtStart)
            delete this.filter.createdAtStart;
        if (!this.filter.createdAtEnd)
            delete this.filter.createdAtEnd;
        if (!this.filter.dueAtStart)
            delete this.filter.dueAtStart;
        if (!this.filter.dueAtEnd)
            delete this.filter.dueAtEnd;
        this.arbitraryValueCache.set(this.cacheKey, this.filter);
        this.writeFilterToUrl();
    };
    DashboardFilterComponent.prototype.writeFilterToUrl = function () {
        this.router.navigate(['.'], {
            relativeTo: this.route,
            replaceUrl: true,
            queryParams: this.filter,
            queryParamsHandling: '',
        });
    };
    DashboardFilterComponent.prototype.clearSearch = function () {
        this.filter.search = '';
        this.publishFilter();
    };
    DashboardFilterComponent.prototype.clearOriginatorSearch = function () {
        this.filter.originatorName = '';
        this.publishFilter();
    };
    DashboardFilterComponent.prototype.mapCategoryName = function (name) {
        if (name.toLowerCase() === 'report')
            return "Feedback";
        return name;
    };
    DashboardFilterComponent.prototype.handleShowAllFilterChange = function (option) {
        this.filter.showAll = option;
        /**
         * if sorting by archived date was active and a user switched to active filter, turn sort by dueAt as default
         */
        if (this.filter.sortBy === 'archivedAt' && option === 'active') {
            this.filter.sortBy = 'dueAt';
            this.filter.order = 'asc';
        }
        this.publishFilter();
    };
    DashboardFilterComponent.prototype.handleDateFilterChange = function (value) {
        this.selectedDateFilter = value;
        this.filterStartDate.value = '';
        this.filterEndDate.value = '';
        delete this.filter.createdAtStart;
        delete this.filter.createdAtEnd;
        delete this.filter.dueAtStart;
        delete this.filter.dueAtEnd;
        this.publishFilter();
    };
    DashboardFilterComponent.CACHE_KEY = 'DashboardFilter';
    DashboardFilterComponent.DEFAULT_FILTER = {
        page: '1',
        search: '',
        originatorName: '',
        sortBy: 'dueAt',
        order: 'asc'
    };
    return DashboardFilterComponent;
}());
export { DashboardFilterComponent };
