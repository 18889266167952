import { Component, OnInit } from '@angular/core';
import { TagsService } from "../../../../../service/admin/TagsService";
import { ITagSortedField, ITagOutputModel } from "../../../../../../common/contracts/tag";
import { ActivatedRoute, Router } from "@angular/router";
import { ISortOrderValue } from "../../../../../../common/contracts/common";
import { PersistentStorageService } from "../../../../../service/PersistentStorageService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";

@Component({
  selector: 'app-groups-component',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  public tags: ITagOutputModel[] = [];
  public totalItems = 0;
  public currentPage = 1;
  public pageSize = this.pss.pageSize;
  public sortBy: ITagSortedField = 'name';
  public sortOrder: ISortOrderValue = 'asc';

  constructor(private groupsService: TagsService,
              private router: Router,
              private route: ActivatedRoute,
              private pss: PersistentStorageService,
              private errorHandler: ErrorHandlerService) { }

  ngOnInit() {
    this.handlePageChange();
  }

  public handleTagClick(groupId: number | string) {
    this.router.navigate([`${groupId}`], {relativeTo: this.route});
  }

  public handlePageChange() {
    this.groupsService.getPagedTags(
      this.pageSize * (this.currentPage - 1),
      this.pageSize,
      this.sortBy,
      this.sortOrder
    ).subscribe((data) => {
      this.tags = data.items;
      this.totalItems = data.totalCount;
    }, (err) => this.errorHandler.handleHttpError(err));
  }

  public handleSortChange(sortField: ITagSortedField) {
    if (sortField === this.sortBy) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = sortField;
      this.sortOrder = 'asc';
    }

    this.handlePageChange();
  }

}
