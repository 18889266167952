<div class="modal-header">
    <h4 class="modal-title">{{options.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="col-12 offset-0 col-md-6">
        <div class="form-group">
            <label>{{options.label || 'Select date'}}:</label>
            <date-picker #datePicker [(value)]="value" [required]="true"></date-picker>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="submit()" ngbAutofocus [disabled]="datePicker.isDateInvalid">
        {{options.submitText || 'Submit'}}
    </button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">{{options.declineText || 'Cancel'}}</button>
</div>
