<div class="container d-flex h-100">
  <div class="row w-100">
    <div class="col-12 align-self-center text-center">
      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3 col-md-8 offset-md-2">
          <img src="assets/images/logo.png" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="profileIcon d-none d-md-block">
  <authentication-icon></authentication-icon>
</div>