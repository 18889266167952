import { Injectable } from '@angular/core';
import { GenericService } from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
	DocumentPaginationResponse,
	ICreateDocumentInputModel,
	IDocumentSortedField,
	IUpdateDocumentInputModel,
	IDocumentOutputModel
} from "../../../common/contracts/documentDRM";
import { ISortOrderValue } from "../../../common/contracts/common";
import { Observable, of } from "rxjs";
import { share, map, tap } from "rxjs/operators";
import { IDocumentType } from '../../../common/contracts/document';

@Injectable()
export class DocumentsService extends GenericService {

	static readonly apiPrefix = '/api/documentDRM';

	private cachedDocuments: IDocumentOutputModel[] | null = null;
	private readonly getDocumentsRequest: Observable<IDocumentOutputModel[]>;

	constructor(private http: HttpClient) {
		super();

		const queryParams = new HttpParams({
			fromObject: {
				skip: '0',
				limit: '50',
				sortBy: 'name',
				order: 'asc',
			}
		});

		this.getDocumentsRequest = this.http.get<DocumentPaginationResponse>(DocumentsService.apiPrefix, { params: queryParams }).pipe(
			map(data => {
				this.cachedDocuments = data.items;
				setTimeout(() => {
					this.cachedDocuments = null; // invalidate documentCollections cache in 20 minutes
				}, 20 * 60 * 1000);
				return data.items;
			}),
			share() //share results between multiple subscriptions
		);
	}

	public getDocuments(): Observable<IDocumentOutputModel[]> {
		return this.cachedDocuments ? of(this.cachedDocuments) : this.getDocumentsRequest;
	}

	public getPagedDocuments(
		documentCollectionId: number | null, 
		includeArchived: number | null, 
		offset: number = 0, 
		pageSize: number = 20, 
		sortBy: IDocumentSortedField = 'name', 
		order: ISortOrderValue = 'asc', 
		counts: boolean = true
	) {
		const filter = {};
		if (documentCollectionId) {
			filter['documentCollectionId'] = documentCollectionId.toString();
		}
		if (includeArchived) {
			filter['includeArchived'] = includeArchived.toString();
		}
		const queryParams = new HttpParams({
			fromObject: {
				skip: offset.toString(),
				limit: pageSize.toString(),
				sortBy,
				order,
				...filter
				// counts: counts ? '1' : '0',
			}
		});
		return this.http.get<DocumentPaginationResponse>(DocumentsService.apiPrefix, { params: queryParams });
	}

	public getDocumentById(id: number) {
		return this.http.get<IDocumentOutputModel>(`${DocumentsService.apiPrefix}/${id}`);
	}

	public createDocument(documentCollectionData: ICreateDocumentInputModel) {
		return this.http.post(DocumentsService.apiPrefix, documentCollectionData).pipe(tap(() => {
			this.cachedDocuments = null;
		}));
	}

	public updateDocument(documentCollectionChanges: IUpdateDocumentInputModel) {
		return this.http.put(DocumentsService.apiPrefix, documentCollectionChanges).pipe(tap(() => {
			this.cachedDocuments = null;
		}));
	}

	public archiveDocument(id: number) {
		return this.http.delete(`${DocumentsService.apiPrefix}/${id}`).pipe(tap(() => {
			this.cachedDocuments = null;
		}));
	}

	public getDocumentInstanceByDocumentId(id: number) {
		return this.http.get(`${DocumentsService.apiPrefix}/${id}/documentInstance`);
	}

	/**
	 * @description Gets the default document, draft or otherwise, for the given DRM task
	 * @param {number} documentId The document that the request is originating from
	 * @param {number} formId The id of the form that the draft is going to be attached to
	 * @returns 
	 */
	public getDefaultDraftDocument(documentId: number, formId: number | null): Observable<IDocumentType | null> {
		const fetcher = formId ? 
			this.http.get<IDocumentType>(`${DocumentsService.apiPrefix}/${documentId}/defaultDraftInstance/${formId}`)
			: this.http.get<IDocumentType>(`${DocumentsService.apiPrefix}/${documentId}/defaultDraftInstance`);

		return fetcher.pipe(
			map( response => {
				if( !Object.prototype.hasOwnProperty.call(response, 'id') ) {
					return null;
				}

				return response;
			})
		)
	}
}
