<div class="row">
  <div class="col-8 text-lg-left">
    <h1 class="text-uppercase text-orange">Risk Register</h1>
  </div>
  <div class="col-2 text-right">
    <button class="btn btn-md btn-orange mr-2" (click)="handleImportClick()"
      [disabled]='isAdmin ? null: true'>Import</button>

  </div>
  <div class="col-2 text-right">
    <div ngbDropdown class="dropdown-link-wrapper" placement="bottom-right">
      <a class="dropdown-link" ngbDropdownToggle>Export to</a>
      <div ngbDropdownMenu>
        <button class="dropdown-item" (click)="handleCsvExport()">CSV file</button>
      </div>
    </div>
  </div>
</div>
<div class="row mb-3">
  <div class="col-8">
    <div class="form-group search-input-group">
      <input class="form-control search-input-control" type="text" placeholder="Search 🔍" [(ngModel)]="searchFilter"
        (keyup.enter)="performSearch()" (keyup.escape)="clearSearch()" (blur)="performSearch()">
    </div>
  </div>
  <div class="col-4">
    <location-select (change)="handleLocationFilterChange($event)" placeholder="Location" [restrictLocations]="true"
      multiple="false"></location-select>
  </div>
  <div class="col-4">
    <select2 class="form-control select2-form-control" [data]="riskSelectOptions"
      [options]="{ placeholder: 'Select Risk level' }" (valueChanged)="handleChangeRiskLevel($event)">
    </select2>
  </div>
  <div class="col-4">
    <select2 class="form-control select2-form-control" [data]="riskStatus"
      [options]="{ placeholder: 'Select Risk Status' }" (valueChanged)="handleChangeRiskStatus($event)">
    </select2>
  </div>
  <div class="col-4">
    <select2 #select2Component class="form-control select2-form-control" [data]="riskTypeOptions"
      [options]="{ placeholder: 'Select Risk Type' }" (valueChanged)="handleChangeRiskTypeFilter($event.value)">
    </select2>
  </div>

</div>

<div class="row">
  <div class="col-12">
    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th scope="col">
            <span class="sortable-col-name" (click)="handleSortChange('summary')"
              [ngClass]="{active: sortBy === 'summary', reverse: sortOrder !== 'asc'}">
              Name<i class="fa fa-caret-up"></i>
            </span>
          </th>
          <th scope="col">
            <span class="sortable-col-name">
              Risk Type
            </span>
          </th>
          <th scope="col">
            <span class="sortable-col-name">
              Location
            </span>
          </th>
          <th scope="col">
            <span class="sortable-col-name" (click)="handleSortChange('residentName')"
              [ngClass]="{active: sortBy === 'residentName', reverse: sortOrder !== 'asc'}">
              Resident Name<i class="fa fa-caret-up"></i>
            </span>
          </th>
          <th scope="col">
            <span class="sortable-col-name" (click)="handleSortChange('riskLevel')"
              [ngClass]="{active: sortBy === 'riskLevel', reverse: sortOrder !== 'asc'}">
              Risk Level<i class="fa fa-caret-up"></i>
            </span>
          </th>
          <th scope="col">
            <span class="sortable-col-name" (click)="handleSortChange('riskStatus')"
              [ngClass]="{active: sortBy === 'riskStatus', reverse: sortOrder !== 'asc'}">
              Risk Status<i class="fa fa-caret-up"></i>
            </span>
          </th>
          <th scope="col">
            <span class="sortable-col-name" (click)="handleSortChange('dateOfNextAssessment')"
              [ngClass]="{active: sortBy === 'dateOfNextAssessment', reverse: sortOrder !== 'asc'}">
              Date of Next Assessment<i class="fa fa-caret-up"></i>
            </span>
          </th>
          <th scope="col">
            <span class="sortable-col-name" (click)="handleSortChange('dateOfLastAssessment')"
              [ngClass]="{active: sortBy === 'dateOfLastAssessment', reverse: sortOrder !== 'asc'}">
              Date of Last Assessment<i class="fa fa-caret-up"></i>
            </span>
          </th>
          <th scope="col">
            <span class="sortable-col-name" (click)="handleSortChange('createdAt')"
              [ngClass]="{active: sortBy === 'createdAt', reverse: sortOrder !== 'asc'}">
              Date of Initial Assessment<i class="fa fa-caret-up"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="hover-cursor" *ngFor="let risk of risks" (click)="handleRiskClick(risk.id)">
          <td>{{risk.summary}}</td>
          <td>{{risk.riskType}}</td>
          <td>{{risk.form_locations.name}}</td>
          <td>{{risk.residentName || 'N/A'}}</td>
          <td>{{risk.riskLevel}}</td>
          <td>{{risk.riskStatus}}</td>
          <td>{{risk.dateOfNextAssessment ? (risk.dateOfNextAssessment | date: 'mediumDate') : 'N/A'}}</td>
          <td>{{risk.dateOfLastAssessment | date: 'mediumDate'}}</td>
          <td>{{risk.createdAt | date: 'mediumDate'}}</td>
        </tr>
        <tr *ngIf="risks.length === 0">
          <td [colSpan]="10" class="text-center">
            No Results Available
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12">
    <pagination [collectionSize]="totalItems" [(page)]="currentPage" [(pageSize)]="pageSize"
      (pageChange)="handlePageChange()"></pagination>
  </div>
</div>