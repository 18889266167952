import {Component, OnInit, ViewChild} from '@angular/core';
import {FormService} from "../../../../service/FormService";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Session} from "../../../../service/util/Session";
import has = Reflect.has;
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
import { ChildFormTreeNode, IFormOutputModel } from "../../../../../common/contracts/form";
import {ComponentCanDeactivate} from "../../../shared/canDeactivate/ComponentCanDeactivate";
import {FollowUpFormStage0Component} from "./stage0/followUpFormStage0.component";
import {FollowUpFormStage2Component} from "./stage2/followUpFormStage2.component";
import {FollowUpFormStage1Component} from "./stage1/followUpFormStage1.component";
import { CurrentUserService } from 'service/currentUser/CurrentUserService';

@Component({
  selector: 'follow-up-form',
  templateUrl: './followUpForm.component.html',
  styleUrls: ['./followUpForm.component.css'],
})
export class FollowUpFormComponent extends ComponentCanDeactivate implements OnInit {

  public targetStage:number;
  public showForm:boolean = false;
  public isAdmin: boolean = false;
  public formData: IFormOutputModel | null = null;

  public childForms: ChildFormTreeNode[] | null = null;

  @ViewChild('followUpStage0') followUpStage0Form: FollowUpFormStage0Component;
  @ViewChild('followUpStage1') followUpStage1Form: FollowUpFormStage1Component;
  @ViewChild('followUpStage2') followUpStage2Form: FollowUpFormStage2Component;

  constructor(
    public formService: FormService,
    public activatedRoute: ActivatedRoute,
    public session: Session,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private currentUserService: CurrentUserService,
  ) {
    super();
  }

  canDeactivate():boolean {
    if( !this.showForm) return true;

    if (this.formData && this.formData.isDeleted) {
      return true;
    }

    if( this.targetStage === 0 )
      return this.followUpStage0Form.canDeactivate();

    if( this.targetStage === 1 )
      return this.followUpStage1Form.canDeactivate();
  
    if( this.targetStage === 2 )
      return this.followUpStage2Form.canDeactivate();

    return true;
  }

  ngOnInit() {
    this.isAdmin = this.currentUserService.isAdministrator.getValue();

    this.activatedRoute.params.subscribe((params: Params) => {

      if (!has(params, "reportId")) {
        this.showForm = true;
        this.targetStage = 0;
        this.formData = null;
        this.childForms = null;
        return;
      }

      this.session.lockInputRx(this.formService.getFormById(params["reportId"])).subscribe((existingForm: IFormOutputModel | null) => {
  
        if (!existingForm) {
          return;
        }
  
        /**
         *  fix for 'Parent Form' link, if form's category is 'Report' redirect to /report/:reportId
         **/
        if (existingForm.category.name === 'Report') {
          this.router.navigate(['/report', existingForm.id], { replaceUrl: true });
          return;
        }
  
        /**
         *  fix for 'Parent Form' link, if form's category is 'Report' redirect to /report/:reportId
         **/
        if (existingForm.category.name === 'Audit') {
          this.router.navigate(['/audit', existingForm.id], { replaceUrl: true });
          return;
        }

        /**
         *  fix for 'Parent Form' link, if form's category is 'DRM-Task' redirect to /document-review-task/:reportId
         **/
        if (existingForm.category.name === 'DRM-Task') {
          this.router.navigate(['/document-review-task', existingForm.id], { replaceUrl: true });
          return;
        }

        this.formData = existingForm;

        if (this.formData) {

          if (this.formData.records.length === 0) {
            this.errorHandler.raiseError("Record did not contain valid data. Please contact a system administrator for assistance.", "Invalid Form Data");
            return;
          }

          this.targetStage = this.formData!.stage || 0;
          this.showForm = true;

          if (this.formData.archivedAt && this.formData.childForms && this.formData.childForms.length) {
            this.childForms = this.formData.childForms;
          } else {
            this.childForms = null;
          }
        }

      }, err => {
        console.error('Error while getting follow-up form', err);
        this.errorHandler.handleHttpError(err);
      });
    });
  }
}
