/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./downloadReportLink.component";
import * as i2 from "../../service/ReportService";
import * as i3 from "../../service/ErrorHandlerService";
var styles_DownloadReportLinkComponent = [];
var RenderType_DownloadReportLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DownloadReportLinkComponent, data: {} });
export { RenderType_DownloadReportLinkComponent as RenderType_DownloadReportLinkComponent };
export function View_DownloadReportLinkComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadPDFReport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0Download PDF Report "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", (_co.inline ? "d-inline" : "row"), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", (_co.inline ? "d-inline" : "col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1"), ""); _ck(_v, 1, 0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "btn btn-link text-primary ", (_co.inline ? "py-0 btn-sm" : ""), ""); _ck(_v, 2, 0, currVal_2); var currVal_3 = i0.ɵinlineInterpolate(1, "fa ", (_co.isReportInProcess ? "fa-circle-o-notch fa-spin" : "fa-download"), ""); _ck(_v, 3, 0, currVal_3); }); }
export function View_DownloadReportLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "download-report-link", [], null, null, null, View_DownloadReportLinkComponent_0, RenderType_DownloadReportLinkComponent)), i0.ɵdid(1, 49152, null, 0, i1.DownloadReportLinkComponent, [i2.ReportService, i3.ErrorHandlerService], null, null)], null, null); }
var DownloadReportLinkComponentNgFactory = i0.ɵccf("download-report-link", i1.DownloadReportLinkComponent, View_DownloadReportLinkComponent_Host_0, { formId: "formId", filePrefix: "filePrefix", inline: "inline" }, {}, []);
export { DownloadReportLinkComponentNgFactory as DownloadReportLinkComponentNgFactory };
