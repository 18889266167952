import { Injectable } from '@angular/core';
import { JWTPayload } from "../../../model/Auth";
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {GenericService} from "../GenericService";
import { HttpClient } from '@angular/common/http';
import { IAuthParams, IForgotPassParams, IResetPassParams } from "../../../common/contracts/auth";

@Injectable()
export class AuthService extends GenericService {

  constructor(private http: HttpClient) {
    super();
  }

  public login(credentials: IAuthParams): Observable<JWTPayload> {
    return this.http.post<JWTPayload>('/api/auth', credentials)
      .pipe( catchError(this.handleError) );
  }

  public forgotPassword(params: IForgotPassParams) {
    return this.http.post('/api/forgot', params)
      .pipe( catchError(this.handleError) );
  }

  public resetPassword(params: IResetPassParams) {
    return this.http.post<JWTPayload>('/api/reset', params)
      .pipe( catchError(this.handleError) );
  }

  public switch(userId: string | number) {
    return this.http.get<JWTPayload>(`/api/switch/${userId}`)
      .pipe( catchError(this.handleError) );
  }
}