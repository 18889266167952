<div class="row">
  <div class="col-12 text-lg-left">
    <h1 class="text-uppercase text-orange">User Profile</h1>
  </div>
</div>


<form [formGroup]="profileForm">
  <div class="row">
    <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
      <div class="form-group">
        <label for="usernameInput">E-mail:</label>
        <input type="text" id="usernameInput" class="form-control input-lg" formControlName="username" rxControl>
        <small class="form-text text-danger" *ngIf="profileForm.controls.username.touched && profileForm.controls.username.hasError('required')">This field is required.</small>
        <small class="form-text text-danger" *ngIf="profileForm.controls.username.touched && profileForm.controls.username.hasError('email')">Must be a valid E-mail.</small>
      </div>
    </div>
    <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
      <div class="form-group">
        <label for="phoneInput">Phone:</label>
        <input type="text" id="phoneInput" class="form-control input-lg" formControlName="phone" mask="(00) 0000 0000" rxControl>
        <small class="form-text text-danger" *ngIf="profileForm.controls.phone.touched && profileForm.controls.phone.hasError('pattern')">Must have 10 digits.</small>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
      <div class="form-group">
        <label for="firstNameInput">First Name:</label>
        <input type="text" id="firstNameInput" class="form-control input-lg"  formControlName="firstName" rxControl>
        <small class="form-text text-danger" *ngIf="profileForm.controls.firstName.touched && profileForm.controls.firstName.hasError('required')">This field is required.</small>
      </div>
    </div>
    <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
      <div class="form-group">
        <label for="lastNameInput">Last Name:</label>
        <input type="text" id="lastNameInput" class="form-control  input-lg"  formControlName="lastName" rxControl>
        <small class="form-text text-danger" *ngIf="profileForm.controls.lastName.touched && profileForm.controls.lastName.hasError('required')">This field is required.</small>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 offset-0 col-sm-12 col-lg-12 offset-lg-0 col-xl-12 offset-xl-0 text-center">
      <button class="btn btn-link" (click)="toggleChangePassword()">{{setPassword ? 'Cancel changing password': 'Change password'}}</button>
    </div>
  </div>
  <div class="row" *ngIf="setPassword">
    <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
      <div class="form-group">
        <label for="passwordInput">Current Password:</label>
        <input type="password" id="oldPasswordInput" class="form-control input-lg"  formControlName="oldPassword" rxControl>
        <small class="form-text text-danger" *ngIf="profileForm.controls.oldPassword.touched && profileForm.controls.oldPassword.hasError('required')">This field is required.</small>
        <small class="form-text text-danger" *ngIf="profileForm.controls.oldPassword.touched && profileForm.controls.oldPassword.hasError('minlength')">Must have at least 6 characters.</small>
      </div>
    </div>
    <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
    </div>
    <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
      <div class="form-group">
        <label for="passwordInput">New Password:</label>
        <input type="password" id="passwordInput" class="form-control input-lg"  formControlName="password" rxControl>
        <small class="form-text text-danger" *ngIf="profileForm.controls.password.touched && profileForm.controls.password.hasError('required')">This field is required.</small>
        <small class="form-text text-danger" *ngIf="profileForm.controls.password.touched && profileForm.controls.password.hasError('minlength')">Must have at least 6 characters.</small>
      </div>
    </div>
    <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
      <div class="form-group">
        <label for="confirmPasswordInput">Confirm New Password:</label>
        <input type="password" id="confirmPasswordInput" class="form-control input-lg" formControlName="confirmPassword" rxControl>
        <small class="form-text text-danger" *ngIf="profileForm.controls.confirmPassword.touched && profileForm.controls.confirmPassword.hasError('confirmError')">Password doesn't match.</small>
      </div>
    </div>
  </div>
  <div class="row bg-default mx-1 my-3 py-4">
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
      <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submitProfile()" [disabled]="profileForm.pristine"><span class="capitalize">save</span></button>
    </div>
    <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-4 col-xl-2 col-xxl-1">
      <a class="btn btn-block btn-lg btn-secondary rounded-0 text-center text-white" (click)="location.back()"><span class="capitalize">cancel</span></a>
    </div>
  </div>
</form>
