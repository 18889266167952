import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { GenericService } from "../GenericService";
import { stringifyObjectValues } from 'service/util/stringifyObjectValues';
import { IDocumentInstanceOutputType } from '../../../common/contracts/documentInstance';


@Injectable()
export class DocumentIntanceService extends GenericService {

  static apiPrefix = '/api/document-instance';

  constructor(private http: HttpClient) {
    super();
  }

  public upload(params) {
     return this.http.post(DocumentIntanceService.apiPrefix,params);
  }

  public downloadDocument(id: number) {
    return this.http.get(`${DocumentIntanceService.apiPrefix}/${id}` , {
      responseType: 'blob',
    });
  }

  public getAllDocuments(params) {
    const queryParams = new HttpParams({
      fromObject: stringifyObjectValues(params)
    });
    return this.http.get<IDocumentInstanceOutputType[]>(`${DocumentIntanceService.apiPrefix}`, { params: queryParams });
  }

  public getDraftDocument(id: number) {
    return this.http.get(`${DocumentIntanceService.apiPrefix}/${id}/draft`);
  }

  public downloadDraftDocument(id: number) {
    return this.http.get(`${DocumentIntanceService.apiPrefix}/${id}/downloadDraft` , {
      responseType: 'blob',
    });
  }
  
}