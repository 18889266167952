import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { GenericService } from "./GenericService";
import { Observable } from "rxjs";
import { ISortOrderValue } from '../../common/contracts/common';
import { IRiskOutputModel } from '../../common/contracts/risk';

@Injectable()
export class RiskService extends GenericService {

  static apiPrefix = '/api/risk';
  static importPrefix = "/api/risk-import"

  constructor(private http: HttpClient) {
    super();
  }

  public createRisk(params): Observable<any> {
    return this.http.post<any>(RiskService.apiPrefix, params);
  }


  public importRisks(id): Observable<any> {
    return this.http.get<any>(RiskService.importPrefix + "/" + id);
  }

  public getRisks(
    offset: number = 0,
    pageSize: number = 20,
    sortBy = 'id',
    order: ISortOrderValue = 'asc',
    searchFilter: string = '',
    locationFilter: string = '',
    riskLevelFilter: string = '',
    riskStatusFilter: string = '',
    riskTypeId: string = '',
  ) {
    const queryParams = new HttpParams({
      fromObject: {
        skip: offset.toString(),
        limit: pageSize.toString(),
        sortBy,
        order,
        searchFilter,
        locationFilter,
        riskLevelFilter,
        riskStatusFilter,
        riskTypeId
      }
    });
    return this.http.get<any>(RiskService.apiPrefix, { params: queryParams });
  }

  public getRiskFromId(id): Observable<IRiskOutputModel> {
    return this.http.get<IRiskOutputModel>(`${RiskService.apiPrefix}/${id.toString()}`);
  }

  public updateRisk(risk): Observable<any> {
    return this.http.put<any>(RiskService.apiPrefix, risk);
  }

  public generateAssessment(id): Observable<any> {
    return this.http.get<IRiskOutputModel>(`${RiskService.apiPrefix}/${id.toString()}/newAssessment`);
  }
}
