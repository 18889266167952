import { OnInit, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Days, MonthWeeks, Months } from './enums';
import Utils from './Utils';
var CronEditorComponent = /** @class */ (function () {
    function CronEditorComponent() {
        // the name is an Angular convention, @Input variable name + "Change" suffix
        this.cronChange = new EventEmitter();
        this.selectOptions = this.getSelectOptions();
    }
    Object.defineProperty(CronEditorComponent.prototype, "cron", {
        get: function () { return this.localCron; },
        set: function (value) {
            this.localCron = value;
            this.cronChange.emit(this.localCron);
        },
        enumerable: true,
        configurable: true
    });
    CronEditorComponent.prototype.ngOnInit = function () {
        if (this.options.removeSeconds) {
            this.options.hideSeconds = true;
        }
        this.state = this.getDefaultState();
        this.handleModelChange(this.cron);
    };
    CronEditorComponent.prototype.ngOnChanges = function (changes) {
        var newCron = changes['cron'];
        if (newCron && !newCron.firstChange) {
            this.handleModelChange(this.cron);
        }
    };
    CronEditorComponent.prototype.setActiveTab = function (tab) {
        if (!this.disabled) {
            this.activeTab = tab;
            this.regenerateCron();
        }
    };
    CronEditorComponent.prototype.dayDisplay = function (day) {
        return Days[day];
    };
    CronEditorComponent.prototype.monthWeekDisplay = function (monthWeekNumber) {
        return MonthWeeks[monthWeekNumber];
    };
    CronEditorComponent.prototype.monthDisplay = function (month) {
        return Months[month];
    };
    CronEditorComponent.prototype.monthDayDisplay = function (month) {
        if (month === 'L') {
            return 'Last Day';
        }
        else if (month === 'LW') {
            return 'Last Weekday';
        }
        else if (month === '1W') {
            return 'First Weekday';
        }
        else {
            return "" + month + this.getOrdinalSuffix(month) + " day";
        }
    };
    CronEditorComponent.prototype.allMonthsSelected = function () {
        var _this = this;
        return !this.selectOptions.monthNames.find(function (month) { return _this.state.monthly[month]; });
    };
    CronEditorComponent.prototype.regenerateCron = function () {
        var _this = this;
        this.isDirty = true;
        switch (this.activeTab) {
            case 'minutes':
                this.cron = "0/" + this.state.minutes.minutes + " * 1/1 * ?";
                if (!this.options.removeSeconds) {
                    this.cron = this.state.minutes.seconds + " " + this.cron;
                }
                if (!this.options.removeYears) {
                    this.cron = this.cron + " *";
                }
                break;
            case 'hourly':
                this.cron = this.state.hourly.minutes + " 0/" + this.state.hourly.hours + " 1/1 * ?";
                if (!this.options.removeSeconds) {
                    this.cron = this.state.hourly.seconds + " " + this.cron;
                }
                if (!this.options.removeYears) {
                    this.cron = this.cron + " *";
                }
                break;
            case 'daily':
                switch (this.state.daily.subTab) {
                    case 'everyDays':
                        // tslint:disable-next-line:max-line-length
                        this.cron = this.state.daily.everyDays.minutes + " " + this.hourToCron(this.state.daily.everyDays.hours, this.state.daily.everyDays.hourType) + " 1/" + this.state.daily.everyDays.days + " * ?";
                        if (!this.options.removeSeconds) {
                            this.cron = this.state.daily.everyDays.seconds + " " + this.cron;
                        }
                        if (!this.options.removeYears) {
                            this.cron = this.cron + " *";
                        }
                        break;
                    case 'everyWeekDay':
                        // tslint:disable-next-line:max-line-length
                        this.cron = this.state.daily.everyWeekDay.minutes + " " + this.hourToCron(this.state.daily.everyWeekDay.hours, this.state.daily.everyWeekDay.hourType) + " ? * MON-FRI";
                        if (!this.options.removeSeconds) {
                            this.cron = this.state.daily.everyWeekDay.seconds + " " + this.cron;
                        }
                        if (!this.options.removeYears) {
                            this.cron = this.cron + " *";
                        }
                        break;
                    default:
                        throw new Error('Invalid cron daily subtab selection');
                }
                break;
            case 'weekly':
                var days = this.selectOptions.days
                    .reduce(function (acc, day) { return _this.state.weekly[day] ? acc.concat([day]) : acc; }, [])
                    .join(',');
                this.cron = this.state.weekly.minutes + " " + this.hourToCron(this.state.weekly.hours, this.state.weekly.hourType) + " ? * " + days;
                if (!this.options.removeSeconds) {
                    this.cron = this.state.weekly.seconds + " " + this.cron;
                }
                if (!this.options.removeYears) {
                    this.cron = this.cron + " *";
                }
                break;
            case 'monthly':
                switch (this.state.monthly.subTab) {
                    case 'specificDay':
                        var day = this.state.monthly.runOnWeekday ? this.state.monthly.specificDay.day + "W" : this.state.monthly.specificDay.day;
                        var months = "";
                        if (this.state.monthly.specificDay.months === 'selected') {
                            months = this.selectOptions.monthNames
                                .reduce(function (acc, month) { return _this.state.monthly[month] ? acc.concat([month]) : acc; }, [])
                                .join(',');
                            if (months.length === 0) {
                                months = "*";
                            }
                        }
                        else {
                            months = "1/" + this.state.monthly.specificDay.months;
                        }
                        // tslint:disable-next-line:max-line-length
                        this.cron = this.state.monthly.specificDay.minutes + " " + this.hourToCron(this.state.monthly.specificDay.hours, this.state.monthly.specificDay.hourType) + " " + day + " " + months + " ?";
                        if (!this.options.removeSeconds) {
                            this.cron = this.state.monthly.specificDay.seconds + " " + this.cron;
                        }
                        if (!this.options.removeYears) {
                            this.cron = this.cron + " *";
                        }
                        break;
                    case 'specificWeekDay':
                        // tslint:disable-next-line:max-line-length
                        this.cron = this.state.monthly.specificWeekDay.minutes + " " + this.hourToCron(this.state.monthly.specificWeekDay.hours, this.state.monthly.specificWeekDay.hourType) + " ? " + this.state.monthly.specificWeekDay.startMonth + "/" + this.state.monthly.specificWeekDay.months + " " + this.state.monthly.specificWeekDay.day + this.state.monthly.specificWeekDay.monthWeek;
                        if (!this.options.removeSeconds) {
                            this.cron = this.state.monthly.specificWeekDay.seconds + " " + this.cron;
                        }
                        if (!this.options.removeYears) {
                            this.cron = this.cron + " *";
                        }
                        break;
                    default:
                        throw new Error('Invalid cron monthly subtab selection');
                }
                break;
            case 'yearly':
                switch (this.state.yearly.subTab) {
                    case 'specificMonthDay':
                        // tslint:disable-next-line:max-line-length
                        var day = this.state.yearly.runOnWeekday ? this.state.yearly.specificMonthDay.day + "W" : this.state.yearly.specificMonthDay.day;
                        // tslint:disable-next-line:max-line-length
                        this.cron = this.state.yearly.specificMonthDay.minutes + " " + this.hourToCron(this.state.yearly.specificMonthDay.hours, this.state.yearly.specificMonthDay.hourType) + " " + day + " " + this.state.yearly.specificMonthDay.month + " ?";
                        if (!this.options.removeSeconds) {
                            this.cron = this.state.yearly.specificMonthDay.seconds + " " + this.cron;
                        }
                        if (!this.options.removeYears) {
                            this.cron = this.cron + " *";
                        }
                        break;
                    case 'specificMonthWeek':
                        // tslint:disable-next-line:max-line-length
                        this.cron = this.state.yearly.specificMonthWeek.minutes + " " + this.hourToCron(this.state.yearly.specificMonthWeek.hours, this.state.yearly.specificMonthWeek.hourType) + " ? " + this.state.yearly.specificMonthWeek.month + " " + this.state.yearly.specificMonthWeek.day + this.state.yearly.specificMonthWeek.monthWeek;
                        if (!this.options.removeSeconds) {
                            this.cron = this.state.yearly.specificMonthWeek.seconds + " " + this.cron;
                        }
                        if (!this.options.removeYears) {
                            this.cron = this.cron + " *";
                        }
                        break;
                    default:
                        throw new Error('Invalid cron yearly subtab selection');
                }
                break;
            case 'advanced':
                this.cron = this.state.advanced.expression;
                break;
            default:
                throw new Error('Invalid cron active tab selection');
        }
    };
    CronEditorComponent.prototype.getAmPmHour = function (hour) {
        return this.options.use24HourTime ? hour : (hour + 11) % 12 + 1;
    };
    CronEditorComponent.prototype.getHourType = function (hour) {
        return this.options.use24HourTime ? undefined : (hour >= 12 ? 'PM' : 'AM');
    };
    CronEditorComponent.prototype.hourToCron = function (hour, hourType) {
        if (this.options.use24HourTime) {
            return hour;
        }
        else {
            return hourType === 'AM' ? (hour === 12 ? 0 : hour) : (hour === 12 ? 12 : hour + 12);
        }
    };
    CronEditorComponent.prototype.handleModelChange = function (cron) {
        var _this = this;
        if (this.isDirty) {
            this.isDirty = false;
            return;
        }
        else {
            this.isDirty = false;
        }
        this.validate(cron);
        var cronSeven = cron;
        if (this.options.removeSeconds) {
            cronSeven = "0 " + cron;
        }
        if (this.options.removeYears) {
            cronSeven = cronSeven + " *";
        }
        var _a = cronSeven.split(' '), seconds = _a[0], minutes = _a[1], hours = _a[2], dayOfMonth = _a[3], month = _a[4], dayOfWeek = _a[5];
        if (cronSeven.match(/\d+ 0\/\d+ \* 1\/1 \* \? \*/)) {
            this.activeTab = 'minutes';
            this.state.minutes.minutes = Number(minutes.substring(2));
            this.state.minutes.seconds = Number(seconds);
        }
        else if (cronSeven.match(/\d+ \d+ 0\/\d+ 1\/1 \* \? \*/)) {
            this.activeTab = 'hourly';
            this.state.hourly.hours = Number(hours.substring(2));
            this.state.hourly.minutes = Number(minutes);
            this.state.hourly.seconds = Number(seconds);
        }
        else if (cronSeven.match(/\d+ \d+ \d+ 1\/\d+ \* \? \*/)) {
            this.activeTab = 'daily';
            this.state.daily.subTab = 'everyDays';
            this.state.daily.everyDays.days = Number(dayOfMonth.substring(2));
            var parsedHours = Number(hours);
            this.state.daily.everyDays.hours = this.getAmPmHour(parsedHours);
            this.state.daily.everyDays.hourType = this.getHourType(parsedHours);
            this.state.daily.everyDays.minutes = Number(minutes);
            this.state.daily.everyDays.seconds = Number(seconds);
        }
        else if (cronSeven.match(/\d+ \d+ \d+ \? \* MON-FRI \*/)) {
            this.activeTab = 'daily';
            this.state.daily.subTab = 'everyWeekDay';
            var parsedHours = Number(hours);
            this.state.daily.everyWeekDay.hours = this.getAmPmHour(parsedHours);
            this.state.daily.everyWeekDay.hourType = this.getHourType(parsedHours);
            this.state.daily.everyWeekDay.minutes = Number(minutes);
            this.state.daily.everyWeekDay.seconds = Number(seconds);
        }
        else if (cronSeven.match(/\d+ \d+ \d+ \? \* (MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))* \*/)) {
            this.activeTab = 'weekly';
            this.selectOptions.days.forEach(function (weekDay) { return _this.state.weekly[weekDay] = false; });
            dayOfWeek.split(',').forEach(function (weekDay) { return _this.state.weekly[weekDay] = true; });
            var parsedHours = Number(hours);
            this.state.weekly.hours = this.getAmPmHour(parsedHours);
            this.state.weekly.hourType = this.getHourType(parsedHours);
            this.state.weekly.minutes = Number(minutes);
            this.state.weekly.seconds = Number(seconds);
        }
        else if (cronSeven.match(/\d+ \d+ \d+ (\d+|L|LW|1W) ((1\/\d+)|((JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(,(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))*)|\*) \? \*/)) {
            this.activeTab = 'monthly';
            this.state.monthly.subTab = 'specificDay';
            if (dayOfMonth.indexOf('W') !== -1) {
                this.state.monthly.specificDay.day = dayOfMonth.charAt(0);
                this.state.monthly.runOnWeekday = true;
            }
            else {
                this.state.monthly.specificDay.day = dayOfMonth;
            }
            var firstChar = month.charAt(0);
            this.state.monthly.specificDay.months = (firstChar === '*' || isNaN(Number(firstChar))) ? this.selectOptions.months[0] : Number(month.substring(2));
            this.selectOptions.monthNames.forEach(function (month) { return _this.state.monthly[month] = false; });
            if (this.state.monthly.specificDay.months === this.selectOptions.months[0]) {
                month.split(',').forEach(function (month) { return _this.state.monthly[month] = true; });
            }
            var parsedHours = Number(hours);
            this.state.monthly.specificDay.hours = this.getAmPmHour(parsedHours);
            this.state.monthly.specificDay.hourType = this.getHourType(parsedHours);
            this.state.monthly.specificDay.minutes = Number(minutes);
            this.state.monthly.specificDay.seconds = Number(seconds);
        }
        else if (cronSeven.match(/\d+ \d+ \d+ \? \d+\/\d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/)) {
            var day = dayOfWeek.substr(0, 3);
            var monthWeek = dayOfWeek.substr(3);
            this.activeTab = 'monthly';
            this.state.monthly.subTab = 'specificWeekDay';
            this.state.monthly.specificWeekDay.monthWeek = monthWeek;
            this.state.monthly.specificWeekDay.day = day;
            if (month.indexOf('/') !== -1) {
                var _b = month.split('/').map(Number), startMonth = _b[0], months = _b[1];
                this.state.monthly.specificWeekDay.months = months;
                this.state.monthly.specificWeekDay.startMonth = startMonth;
            }
            var parsedHours = Number(hours);
            this.state.monthly.specificWeekDay.hours = this.getAmPmHour(parsedHours);
            this.state.monthly.specificWeekDay.hourType = this.getHourType(parsedHours);
            this.state.monthly.specificWeekDay.minutes = Number(minutes);
            this.state.monthly.specificWeekDay.seconds = Number(seconds);
        }
        else if (cronSeven.match(/\d+ \d+ \d+ (\d+|L|LW|1W) \d+ \? \*/)) {
            this.activeTab = 'yearly';
            this.state.yearly.subTab = 'specificMonthDay';
            this.state.yearly.specificMonthDay.month = Number(month);
            if (dayOfMonth.indexOf('W') !== -1) {
                this.state.yearly.specificMonthDay.day = dayOfMonth.charAt(0);
                this.state.yearly.runOnWeekday = true;
            }
            else {
                this.state.yearly.specificMonthDay.day = dayOfMonth;
            }
            var parsedHours = Number(hours);
            this.state.yearly.specificMonthDay.hours = this.getAmPmHour(parsedHours);
            this.state.yearly.specificMonthDay.hourType = this.getHourType(parsedHours);
            this.state.yearly.specificMonthDay.minutes = Number(minutes);
            this.state.yearly.specificMonthDay.seconds = Number(seconds);
        }
        else if (cronSeven.match(/\d+ \d+ \d+ \? \d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/)) {
            var day = dayOfWeek.substr(0, 3);
            var monthWeek = dayOfWeek.substr(3);
            this.activeTab = 'yearly';
            this.state.yearly.subTab = 'specificMonthWeek';
            this.state.yearly.specificMonthWeek.monthWeek = monthWeek;
            this.state.yearly.specificMonthWeek.day = day;
            this.state.yearly.specificMonthWeek.month = Number(month);
            var parsedHours = Number(hours);
            this.state.yearly.specificMonthWeek.hours = this.getAmPmHour(parsedHours);
            this.state.yearly.specificMonthWeek.hourType = this.getHourType(parsedHours);
            this.state.yearly.specificMonthWeek.minutes = Number(minutes);
            this.state.yearly.specificMonthWeek.seconds = Number(seconds);
        }
        else {
            this.activeTab = 'advanced';
            this.state.advanced.expression = cron;
        }
    };
    CronEditorComponent.prototype.validate = function (cron) {
        this.state.validation.isValid = false;
        this.state.validation.errorMessage = '';
        if (!cron) {
            this.state.validation.errorMessage = 'Cron expression cannot be null';
            return;
        }
        var cronParts = cron.split(' ');
        var expected = 5;
        if (!this.options.removeSeconds) {
            expected++;
        }
        if (!this.options.removeYears) {
            expected++;
        }
        if (cronParts.length !== expected) {
            this.state.validation.errorMessage = "Invalid cron expression, there must be " + expected + " segments";
            return;
        }
        this.state.validation.isValid = true;
        return;
    };
    CronEditorComponent.prototype.getDefaultAdvancedCronExpression = function () {
        if (this.options.removeSeconds && !this.options.removeYears) {
            return '15 10 L-2 * ? 2019';
        }
        if (!this.options.removeSeconds && this.options.removeYears) {
            return '0 15 10 L-2 * ?';
        }
        if (this.options.removeSeconds && this.options.removeYears) {
            return '15 10 L-2 * ?';
        }
        return '0 15 10 L-2 * ? 2019';
    };
    CronEditorComponent.prototype.getDefaultState = function () {
        var _a = this.options.defaultTime.split(':').map(Number), defaultHours = _a[0], defaultMinutes = _a[1], defaultSeconds = _a[2];
        return {
            minutes: {
                minutes: 1,
                seconds: 0
            },
            hourly: {
                hours: 1,
                minutes: 0,
                seconds: 0
            },
            daily: {
                subTab: 'everyDays',
                everyDays: {
                    days: 1,
                    hours: this.getAmPmHour(defaultHours),
                    minutes: defaultMinutes,
                    seconds: defaultSeconds,
                    hourType: this.getHourType(defaultHours)
                },
                everyWeekDay: {
                    hours: this.getAmPmHour(defaultHours),
                    minutes: defaultMinutes,
                    seconds: defaultSeconds,
                    hourType: this.getHourType(defaultHours)
                }
            },
            weekly: {
                MON: true,
                TUE: false,
                WED: false,
                THU: false,
                FRI: false,
                SAT: false,
                SUN: false,
                hours: this.getAmPmHour(defaultHours),
                minutes: defaultMinutes,
                seconds: defaultSeconds,
                hourType: this.getHourType(defaultHours)
            },
            monthly: {
                subTab: 'specificDay',
                runOnWeekday: false,
                JAN: false,
                FEB: false,
                MAR: false,
                APR: false,
                MAY: false,
                JUN: false,
                JUL: false,
                AUG: false,
                SEP: false,
                OCT: false,
                NOV: false,
                DEC: false,
                specificDay: {
                    day: '1',
                    months: 1,
                    hours: this.getAmPmHour(defaultHours),
                    minutes: defaultMinutes,
                    seconds: defaultSeconds,
                    hourType: this.getHourType(defaultHours)
                },
                specificWeekDay: {
                    monthWeek: '#1',
                    day: 'MON',
                    startMonth: 1,
                    months: 1,
                    hours: this.getAmPmHour(defaultHours),
                    minutes: defaultMinutes,
                    seconds: defaultSeconds,
                    hourType: this.getHourType(defaultHours)
                }
            },
            yearly: {
                subTab: 'specificMonthDay',
                runOnWeekday: false,
                specificMonthDay: {
                    month: 1,
                    day: '1',
                    hours: this.getAmPmHour(defaultHours),
                    minutes: defaultMinutes,
                    seconds: defaultSeconds,
                    hourType: this.getHourType(defaultHours)
                },
                specificMonthWeek: {
                    monthWeek: '#1',
                    day: 'MON',
                    month: 1,
                    hours: this.getAmPmHour(defaultHours),
                    minutes: defaultMinutes,
                    seconds: defaultSeconds,
                    hourType: this.getHourType(defaultHours)
                }
            },
            advanced: {
                expression: this.getDefaultAdvancedCronExpression()
            },
            validation: {
                isValid: true,
                errorMessage: ''
            }
        };
    };
    CronEditorComponent.prototype.getOrdinalSuffix = function (value) {
        if (value.length > 1) {
            var secondToLastDigit = value.charAt(value.length - 2);
            if (secondToLastDigit === '1') {
                return 'th';
            }
        }
        var lastDigit = value.charAt(value.length - 1);
        switch (lastDigit) {
            case '1':
                return 'st';
            case '2':
                return 'nd';
            case '3':
                return 'rd';
            default:
                return 'th';
        }
    };
    CronEditorComponent.prototype.getSelectOptions = function () {
        return {
            months: ['selected'].concat(Utils.getRange(1, 12)),
            monthWeeks: ['#1', '#2', '#3', '#4', '#5', 'L'],
            days: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
            monthNames: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
            minutes: Utils.getRange(0, 59),
            fullMinutes: Utils.getRange(0, 59),
            seconds: Utils.getRange(0, 59),
            hours: Utils.getRange(1, 23),
            monthDays: Utils.getRange(1, 31),
            monthDaysWithLasts: Utils.getRange(1, 31).map(String).concat(['L']),
            hourTypes: ['AM', 'PM']
        };
    };
    return CronEditorComponent;
}());
export { CronEditorComponent };
