<div class="modal-header">
    <h4 class="modal-title">Select File</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="col-12">
        <button class="btn btn-orange rounded-0 btn-block" (click)="fileInput.click()"
            [disabled]="state.isUploading | async">
            <i class="fa fa-upload" *ngIf="!(state.isUploading | async)"></i>
            <i class="fa fa-circle-o-notch fa-spin" *ngIf="state.isUploading | async"></i>
            <strong class="upload-title">{{ ( state.isUploading | async ) ? 'UPLOADING' : 'UPLOAD FILE'}}</strong>
        </button>
        <input class="hidden-input" type="file" #fileInput (change)="handleFileSelected($event)">
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="cancel()">Cancel</button>
</div>