import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { debounceTime, filter, map, tap } from "rxjs/operators";
import { logger } from "./Logger";

export type ValueChangedEvent = {
	key: string,
	value: unknown
}

const className = "ArbitraryValueCache";

@Injectable()
export class ArbitraryValueCache {

	private valueChangedSubject = new BehaviorSubject<ValueChangedEvent | null>(null);
	private cache: Record<string, unknown> = {};

	public onChange<T = unknown>(key: string, settings?: {
		debounceTime?: number
	}): Observable<T> {
		const signature = className + ".onChange: ";
		const _settings = Object.assign({
			debounceTime: 200
		}, settings);

		return this.valueChangedSubject.pipe(
			filter(evt => evt !== null && evt.key === key),
			debounceTime(_settings.debounceTime),
			tap(evt => logger.silly(signature + `Value Changed at Key[${evt ? evt.key : 'undefined'}] with Value[${evt ? JSON.stringify(evt.value, null, 2) : 'undefined'}]`)),
			map(evt => (evt ? JSON.parse(JSON.stringify(evt.value)) : undefined) as T),
		);
	}

	public get(key: string) {
		const signature = className + ".get: ";
		const hasValue = Object.keys(this.cache).includes(key);

		if (!hasValue) {
			logger.silly(signature + `Cache miss on Key[${key}]`);
			return undefined;
		}

		logger.silly(signature + `Cache hit on Key[${key}]`);
		return this.cache[key];
	}

	public set(key: string, value: unknown) {
		const signature = className + ".set: ";
		const oldValue = Object.keys(this.cache).includes(key) ? this.comparisonValue(this.cache[key]) : undefined;
		const newValue = this.comparisonValue(value);

		if (typeof oldValue === 'undefined' || oldValue !== newValue) {
			logger.silly(signature + `Updating Key[${key}]`);
			this.cache[key] = JSON.parse(newValue);
			this.valueChangedSubject.next({
				key,
				value
			});

			return true;
		}

		logger.silly(signature + `Not updating unchanged Key[${key}]`);
		return false;
	}

	public delete(key: string) {
		const signature = className + ".delete: ";
		const hasValue = Object.keys(this.cache).includes(key);

		if (!hasValue) {
			logger.silly(signature + 'Not deleting undefined key');
			return false;
		}

		delete this.cache[key];
		this.valueChangedSubject.next({
			key,
			value: undefined
		});
		logger.silly(signature + `Deleted Key[${key}]`);
		return true;
	}

	public clear() {
		const signature = className + ".clear: ";
		Object.keys(this.cache).forEach(cacheKey => {
			delete this.cache[cacheKey];
			this.valueChangedSubject.next({
				key: cacheKey,
				value: undefined
			});
		});

		logger.silly(signature + 'Cache Cleared');
	}

	private comparisonValue(value: unknown) {
		return JSON.stringify(value);
	}
}