<div class="container-fluid main-container">
  <div class="row header bg-white shadow-sm border-light border-bottom position-relative">
    <div class="col-3 col-md-4 col-lg-3 text-center d-flex">
      <a [routerLink]="['/']" class="d-none d-md-block align-self-center logoLink"></a>

      <a [routerLink]="['/']" class="d-md-none align-self-center logoLink"></a>
    </div>
    <!--
      <div class="col-9 col-md-7 col-lg-8 align-self-center">
        <input class="form-control shadow-sm" placeholder="" />
      </div>
    -->
    <div class="col-2 d-none d-md-block profileIcon ml-auto text-right">
      <authentication-icon [small]="true"></authentication-icon>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
