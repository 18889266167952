<div class="row">
    <div class="col-9 text-lg-left">
        <h1 class="text-uppercase text-orange">Location Management</h1>
    </div>
    <div class="col-3 text-md-right">
        <button class="btn btn-md btn-orange" (click)="handleLocationClick('new')">New Location</button>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <table class="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">
                    <span class="sortable-col-name"
                        (click)="handleSortChange('name')"
                        [ngClass]="{active: sortBy === 'name', reverse: sortOrder !== 'asc'}">
                            Name<i class="fa fa-caret-up"></i>
                    </span>
                </th>
                <th scope="col">
                    <span class="sortable-col-name"
                          (click)="handleSortChange('usersCount')"
                          [ngClass]="{active: sortBy === 'usersCount', reverse: sortOrder !== 'asc'}">
                            Users<i class="fa fa-caret-up"></i>
                    </span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr class="hover-cursor" *ngFor="let location of locations" (click)="handleLocationClick(location.id)">
                <td>{{location.name}}</td>
                <td>{{location.usersCount}}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <pagination
            [collectionSize]="totalItems"
            [(page)]="currentPage"
            [(pageSize)]="pageSize"
            (pageChange)="handlePageChange()"></pagination>
    </div>
</div>

