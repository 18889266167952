/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/downloadReportLink.component.ngfactory";
import * as i2 from "../../../../shared/downloadReportLink.component";
import * as i3 from "../../../../../service/ReportService";
import * as i4 from "../../../../../service/ErrorHandlerService";
import * as i5 from "../../../../shared/formDueAt.component.ngfactory";
import * as i6 from "../../../../shared/formDueAt.component";
import * as i7 from "../../../../../service/currentUser/CurrentUserService";
import * as i8 from "../../../../../service/ModalService";
import * as i9 from "../../../../../service/FormService";
import * as i10 from "@angular/common";
import * as i11 from "./auditFormStage3.component";
var styles_AuditFormStage3Component = [];
var RenderType_AuditFormStage3Component = i0.ɵcrt({ encapsulation: 2, styles: styles_AuditFormStage3Component, data: {} });
export { RenderType_AuditFormStage3Component as RenderType_AuditFormStage3Component };
function View_AuditFormStage3Component_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "download-report-link", [["filePrefix", "follow-up-report"]], null, null, null, i1.View_DownloadReportLinkComponent_0, i1.RenderType_DownloadReportLinkComponent)), i0.ɵdid(1, 49152, null, 0, i2.DownloadReportLinkComponent, [i3.ReportService, i4.ErrorHandlerService], { formId: [0, "formId"], filePrefix: [1, "filePrefix"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formData.id; var currVal_1 = "follow-up-report"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AuditFormStage3Component_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12 col-md-6 col-lg-6 col-xl-6 pt-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "form-due-at", [], null, null, null, i5.View_FormDueAtComponent_0, i5.RenderType_FormDueAtComponent)), i0.ɵdid(2, 114688, null, 0, i6.FormDueAtComponent, [i7.CurrentUserService, i8.ModalService, i9.FormService, i4.ErrorHandlerService], { formData: [0, "formData"], inline: [1, "inline"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formData; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AuditFormStage3Component_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { vc: 0 }), (_l()(), i0.ɵeld(1, 16777216, [[1, 3], ["vc", 1]], null, 0, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-12 col-md-6 col-lg-6 col-xl-6"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AuditFormStage3Component_1)), i0.ɵdid(5, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AuditFormStage3Component_2)), i0.ɵdid(7, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isReportEnabled; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.isReportEnabled; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_AuditFormStage3Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "audit-form-3", [], null, null, null, View_AuditFormStage3Component_0, RenderType_AuditFormStage3Component)), i0.ɵdid(1, 114688, null, 0, i11.AuditFormStage3Component, [i0.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuditFormStage3ComponentNgFactory = i0.ɵccf("audit-form-3", i11.AuditFormStage3Component, View_AuditFormStage3Component_Host_0, { formData: "formData" }, {}, []);
export { AuditFormStage3ComponentNgFactory as AuditFormStage3ComponentNgFactory };
