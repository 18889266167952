import { Component, OnInit } from '@angular/core';
import { ResourcePack } from '../../model/ResourcePack';
import { ResourcePackService } from 'service/ResourcePackService';
import { ErrorHandlerService } from 'service/ErrorHandlerService';

@Component({
  selector: 'app-resource-packs',
  templateUrl: './resource-packs.component.html',
  styleUrls: ['./resource-packs.component.css']
})
export class ResourcePacksComponent implements OnInit {

  resourcePacks: ResourcePack[] = []
  isLoaded = false;
  constructor(
    private service: ResourcePackService, 
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.isLoaded = false;
    this.service.fetch().subscribe(data => {
      this.resourcePacks = data;
      this.isLoaded = true;
    }, err => {
      this.isLoaded = true;
      this.errorHandlerService.handleHttpError(err);
    });
  }

  deleteResourcePack(id:number) {
    this.service.delete(id).subscribe(data => {
      const idx = this.resourcePacks.findIndex( resourcePack => resourcePack.id === id);
      this.resourcePacks.splice(idx, 1);
    }, err => {
      this.errorHandlerService.handleHttpError(err);
    });
  }
}
