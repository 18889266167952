var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import * as moment from 'moment';
import 'moment-timezone';
import { FormField } from "../../../../../model/Form";
import { FormComponent } from "../../../../../model/FormComponent";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormService } from "../../../../../service/FormService";
import { CategoryService } from "../../../../../service/CategoryService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { environment } from "../../../../../environments/environment";
var ReportFormStage0Component = /** @class */ (function (_super) {
    __extends(ReportFormStage0Component, _super);
    function ReportFormStage0Component(session, currentUserService, formService, categoryService, formRecordService, router, errorHandler) {
        var _this = _super.call(this, router) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formService = formService;
        _this.categoryService = categoryService;
        _this.formRecordService = formRecordService;
        _this.router = router;
        _this.errorHandler = errorHandler;
        // Existing Form Data
        _this.readOnly = false;
        _this.hideHeader = false;
        _this.documents = [];
        /*
          This should have been done properly using something that implements FormControl but its
          too late now
         */
        _this.form = {
            originatorName: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
            location: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            type: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
            department: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
            originatorType: new FormField(null, {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
            residentRoomNumber: new FormField('', {
                placeholder: "Unit/SA Number",
                validation: FormField.ValidationMethods.IsNotBlank
            }),
            residentEmail: new FormField('', {
                validation: [
                    FormField.ValidationMethods.IsEmail,
                    FormField.ValidationMethods.IsBlank
                ]
            }),
            residentName: new FormField('', {
                validation: FormField.ValidationMethods.None,
            }),
            originatorEmail: new FormField('', {
                validation: [
                    FormField.ValidationMethods.IsEmail,
                    FormField.ValidationMethods.IsBlank,
                ]
            }),
            summary: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
        };
        _this.originatorOptions = {
            residentRoomNumber: true,
            residentName: false,
            residentEmail: true,
            originatorEmail: false,
            isFamilyMember: false,
            isStaffMember: false
        };
        return _this;
    }
    ReportFormStage0Component.prototype.registerFormFields = function () {
        var _this = this;
        var _a;
        (_a = this.formFields).push.apply(_a, Object.keys(this.form).map(function (k) { return _this.form[k]; }));
    };
    ReportFormStage0Component.prototype.getEmailToNotifyOnComplete = function () {
        var resultArray = [];
        if (this.originatorOptions.residentEmail && this.form.residentEmail.value.length > 0) {
            resultArray.push(this.form.residentEmail.value);
        }
        if (this.originatorOptions.originatorEmail && this.form.originatorEmail.value.length > 0) {
            resultArray.push(this.form.originatorEmail.value);
        }
        return (resultArray.length > 0 ? resultArray.join(", ") : null);
    };
    ReportFormStage0Component.prototype.onSubmit = function (isDraft) {
        var _this = this;
        if (isDraft === void 0) { isDraft = false; }
        this.session.lockInput(function () {
            return new Promise(function (resolve, reject) {
                var formLocationId = _this.form.location.isValid && _this.form.location.value !== null ? Number(_this.form.location.value) : null;
                if (formLocationId === 0)
                    formLocationId = null;
                var userGroupId = _this.form.department.isValid ? Number(_this.form.department.value) : null;
                var stage = isDraft ? 0 : 1;
                var notifyOnComplete = _this.getEmailToNotifyOnComplete();
                var formFunc;
                // The assigned user should always be reset to null on submission of stage 0
                var assignedUserId = null;
                if (_this.currentUserService.userData && isDraft)
                    assignedUserId = _this.currentUserService.userData.id;
                var isNewForm = !(_this.formData && _this.formData.id);
                /* NOTE : The assigned userID should be set to null if this is not a draft */
                if (!isNewForm) {
                    //FormFunc = update
                    formFunc = _this.formService.updateForm({
                        id: _this.formData.id,
                        formLocationId: formLocationId,
                        userGroupId: userGroupId,
                        notifyOnComplete: notifyOnComplete,
                        stage: stage,
                        assignedUserId: assignedUserId
                    });
                }
                else {
                    // The actual due date is 21 days however we want this to show up red on the due date
                    var dueAt = moment().tz(environment.timeZone).add(10, 'days').set({
                        'hour': 0,
                        'minute': 0,
                        'second': 0
                    }).toISOString(false);
                    // This should be back to the client within 14 days
                    var alertAt = moment().tz(environment.timeZone).add(7, 'days').set({
                        'hour': 0,
                        'minute': 0,
                        'second': 0
                    }).toISOString(false);
                    formFunc = _this.formService.createForm({
                        categoryId: _this.formCategoryId,
                        formLocationId: formLocationId,
                        userGroupId: userGroupId,
                        dueAt: dueAt,
                        alertAt: alertAt,
                        notifyOnComplete: notifyOnComplete,
                        stage: stage,
                        parentFormId: null,
                        assignedUserId: assignedUserId
                    });
                }
                formFunc.subscribe(function (data) {
                    var properties = [];
                    var formId = (_this.formData && _this.formData.id) ? _this.formData.id : data['id'];
                    if (userGroupId)
                        properties.push({
                            name: "userGroupId",
                            intData: userGroupId
                        });
                    // This desperately needs to be refactored but in the interest of brevity...
                    properties.push({
                        name: "reportFormType",
                        enumId: Number(_this.form.type.value)
                    });
                    properties.push({
                        name: "originatorName",
                        stringData: _this.form.originatorName.value
                    });
                    properties.push({
                        name: "originatorTypeId",
                        enumId: Number(_this.form.originatorType.value)
                    });
                    if (_this.originatorOptions.residentRoomNumber && _this.form.residentRoomNumber.value.length > 0)
                        properties.push({
                            name: "residentRoomNumber",
                            stringData: _this.form.residentRoomNumber.value
                        });
                    if (_this.originatorOptions.residentEmail && _this.form.residentEmail.value.length > 0)
                        properties.push({
                            name: "residentEmail",
                            stringData: _this.form.residentEmail.value
                        });
                    if (_this.originatorOptions.residentName && _this.form.residentName.value.length > 0)
                        properties.push({
                            name: "residentName",
                            stringData: _this.form.residentName.value
                        });
                    if (_this.originatorOptions.originatorEmail && _this.form.originatorEmail.value.length > 0)
                        properties.push({
                            name: "originatorEmail",
                            stringData: _this.form.originatorEmail.value
                        });
                    properties.push({
                        name: "summary",
                        stringData: _this.form.summary.value
                    });
                    // TODO: Add issue number
                    _this.formRecordService.createRecord({
                        formId: formId,
                        // Intentionally cast the properties object since we know its correct
                        properties: properties,
                        stage: 0,
                        documents: _this.documents.map(function (doc) { return ({ id: doc.id, isTicked: !!doc.isTicked }); }),
                        isComplete: !isDraft
                    })
                        .subscribe(function (data) {
                        //Done creating the form and appending its properties
                        resolve();
                        if (isNewForm) {
                            // go to dashboard
                            _this.router.navigate(['/dashboard']);
                        }
                        else {
                            _this.closeForm();
                        }
                    }, function (err) {
                        _this.errorHandler.handleHttpError(err);
                        reject("Error creating new record");
                    });
                }, function (err) {
                    _this.errorHandler.handleHttpError(err);
                    reject("Error Creating or updating form");
                });
            });
        });
    };
    ReportFormStage0Component.prototype.ngOnInit = function () {
        this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);
        this.registerFormFields();
        this.loadEnums();
        this.repopulateFormFromData();
    };
    ReportFormStage0Component.prototype.repopulateFormFromData = function () {
        var _this = this;
        if (!this.formData || !this.formData.records || !this.formData.records.length)
            return;
        var stageRecords = this.formData.records.filter(function (record) { return record.stage === 0; });
        if (stageRecords.length === 0)
            return;
        if (!this.sequence) {
            var mostRecentRecord = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
            if (!mostRecentRecord)
                throw new Error("internal error");
            // If the most recent record was a submission, we are not going to use it
            if (mostRecentRecord.isComplete)
                return;
            this.formRecord = mostRecentRecord;
        }
        else {
            var targetRecord = stageRecords.find(function (record) { return record.sequence === _this.sequence; });
            if (!targetRecord)
                throw new Error("internal error");
            this.formRecord = targetRecord;
        }
        /**
         * get attached documents
         */
        this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
        /**
         * show update date from record's createdAt
         */
        this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);
        //Convert the properties into easily accessible IFormRecordPropertyParam
        if (!this.formRecord.properties) {
            return;
        }
        var simpleProperties = {};
        this.formRecord.properties.forEach(function (recordProperty) {
            //eject invalid property
            if (!recordProperty.property)
                return;
            var result = {
                name: recordProperty.property.name
            };
            if (recordProperty.stringData)
                result.stringData = recordProperty.stringData;
            if (recordProperty.intData)
                result.intData = recordProperty.intData;
            if (recordProperty.jsonData)
                result.jsonData = recordProperty.jsonData;
            if (recordProperty.enumId)
                result.enumId = recordProperty.enumId;
            simpleProperties[result.name] = result;
        });
        if (simpleProperties['userGroupId'])
            this.form.department.value = String(simpleProperties['userGroupId'].intData);
        else if (this.formData['userGroupId'])
            this.form.department.value = String(this.formData['userGroupId']);
        if (this.formData['formLocationId'])
            this.form.location.value = String(this.formData['formLocationId']);
        //Repopulate from the simpleProperties
        if (simpleProperties['originatorName'])
            this.form.originatorName.value = simpleProperties['originatorName'].stringData;
        if (simpleProperties['reportFormType'])
            this.form.type.value = String(simpleProperties['reportFormType'].enumId);
        if (simpleProperties['originatorTypeId'])
            this.form.originatorType.value = String(simpleProperties['originatorTypeId'].enumId);
        if (simpleProperties['residentRoomNumber'])
            this.form.residentRoomNumber.value = simpleProperties['residentRoomNumber'].stringData;
        if (simpleProperties['residentEmail'])
            this.form.residentEmail.value = simpleProperties['residentEmail'].stringData;
        if (simpleProperties['residentName'])
            this.form.residentName.value = simpleProperties['residentName'].stringData;
        if (simpleProperties['originatorEmail'])
            this.form.originatorEmail.value = simpleProperties['originatorEmail'].stringData;
        if (simpleProperties['summary'])
            this.form.summary.value = simpleProperties['summary'].stringData;
    };
    ReportFormStage0Component.prototype.loadEnums = function () {
        var _this = this;
        this.session.lockInputRx(this.categoryService.getCategories())
            .subscribe(function (data) {
            var reportCategory = data.find(function (data) { return !!data.name.match(/^Report/); });
            if (reportCategory)
                _this.formCategoryId = reportCategory.id;
            //else
            //Notify the user an error has occurred, as we can't find the right form category
        }, function (err) {
            console.error('Error getting enums', err);
            _this.errorHandler.handleHttpError(err);
        });
    };
    ReportFormStage0Component.prototype.originatorChanged = function (selectedOpts) {
        this.select2Changed(selectedOpts, this.form.originatorType);
        var optionsBeforeChange = Object.assign({}, this.originatorOptions);
        var originatorVal = this.form.originatorType.value;
        var originatorOption = selectedOpts.find(function (type) { return type.id === originatorVal; });
        this.originatorOptions.isFamilyMember = false;
        this.originatorOptions.isStaffMember = false;
        if (originatorOption && (!!originatorOption.text.match(/Staff Member/)
            || !!originatorOption.text.match(/Visitor/))) {
            Object.assign(this.originatorOptions, {
                residentRoomNumber: false,
                residentName: false,
                residentEmail: false,
                originatorEmail: true,
                isStaffMember: true
            });
        }
        else if (originatorOption && !!originatorOption.text.match(/Family Member/)) {
            Object.assign(this.originatorOptions, {
                residentRoomNumber: true,
                residentName: true,
                residentEmail: false,
                originatorEmail: true,
                isFamilyMember: true
            });
        }
        else {
            Object.assign(this.originatorOptions, {
                residentRoomNumber: true,
                residentName: false,
                residentEmail: true,
                originatorEmail: false
            });
        }
        // This should always be evaluated
        if (this.originatorOptions.isStaffMember) {
            this.form.residentRoomNumber.validation = FormField.ValidationMethods.None;
            this.form.originatorEmail.validation = FormField.ValidationMethods.IsEmail;
        }
        else {
            this.form.residentRoomNumber.validation =
                (this.originatorOptions.residentRoomNumber) ? FormField.ValidationMethods.IsNotBlank : FormField.ValidationMethods.None;
            // @ts-ignore - Typescript is warning that an array cannot be assigned to the singular
            // when the class is specifically designed for this
            this.form.originatorEmail.validation = [
                FormField.ValidationMethods.IsEmail,
                FormField.ValidationMethods.IsBlank,
            ];
        }
        this.form.residentRoomNumber.validate();
        if (this.originatorOptions.isFamilyMember)
            this.form.residentRoomNumber.placeholder = 'Room Number';
        else
            this.form.residentRoomNumber.placeholder = 'Unit/SA Number';
        // End Always Evaluate
        if (optionsBeforeChange.residentEmail !== this.originatorOptions.residentEmail) {
            this.form.residentEmail.validate();
        }
        if (optionsBeforeChange.residentName !== this.originatorOptions.residentName) {
            this.form.residentName.validate();
        }
        if (optionsBeforeChange.originatorEmail !== this.originatorOptions.originatorEmail) {
            this.form.originatorEmail.validate();
        }
    };
    return ReportFormStage0Component;
}(FormComponent));
export { ReportFormStage0Component };
