
  <div class="input-group">
      <input class="form-control relative-input"
             [placeholder]="placeholder || 'DD/MM/YYYY'"
             [disabled]="disabled"
             [(ngModel)]="model"
             (dateSelect)="handleDateSelect()"
             (focus)="d.open()"
             [markDisabled]="markDisabledBound"
             (ngModelChange)="validateModelChange()"
             [ngClass]="validationClasses"
             ngbDatepicker #d="ngbDatepicker" 
             #datePicker>
      <span class="clear-icon" *ngIf="clearable && model" (click)="clearDate($event)" title="Clear">&times;</span>
      <div class="input-group-append">
          <button class="btn btn-outline-secondary" [disabled]="disabled" (click)="d.toggle()" type="button">
              <i class="fa fa-calendar"></i>
          </button>
      </div>
  </div>
  <small class="form-text text-danger" *ngIf="isTouched && isDateInvalid && !disableValidationStyles">Invalid date</small>
  