/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./preloaderWrapper.component";
var styles_PreloaderWrapperComponent = [".preload-spinner[_ngcontent-%COMP%] {\n        font-size: 1.6em;\n        color: #AAA;\n    }\n    .preloader-row[_ngcontent-%COMP%] {\n        height: 100%;\n    }\n    .preloader-col[_ngcontent-%COMP%] {\n        margin-top: auto;\n        margin-bottom: auto;\n    }"];
var RenderType_PreloaderWrapperComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_PreloaderWrapperComponent, data: {} });
export { RenderType_PreloaderWrapperComponent as RenderType_PreloaderWrapperComponent };
function View_PreloaderWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row preloader-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "col-12 text-center preloader-col"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-pulse preload-spinner"]], null, null, null, null, null))], null, null); }
function View_PreloaderWrapperComponent_2(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_PreloaderWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreloaderWrapperComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreloaderWrapperComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoaded; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isLoaded; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PreloaderWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "preloader-wrapper", [], null, null, null, View_PreloaderWrapperComponent_0, RenderType_PreloaderWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i2.PreloaderWrapperComponent, [], null, null)], null, null); }
var PreloaderWrapperComponentNgFactory = i0.ɵccf("preloader-wrapper", i2.PreloaderWrapperComponent, View_PreloaderWrapperComponent_Host_0, { isLoaded: "isLoaded" }, {}, ["*"]);
export { PreloaderWrapperComponentNgFactory as PreloaderWrapperComponentNgFactory };
