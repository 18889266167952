import { Component, Input, ComponentFactoryResolver, ViewContainerRef, ViewChild, OnInit } from '@angular/core';
import { IFormOutputModel, IFormRecordOutputModel } from "../../../../../../common/contracts/form";
import { RiskAssessmentFormStage0Component } from "../stage0/riskAssessmentFormStage0.component";
import { RiskAssessmentFormStage1Component } from "../stage1/riskAssessmentFormStage1.component";
import { RiskAssessmentFormStage2Component } from "../stage2/riskAssessmentFormStage2.component";
import { RiskAssessmentFormStage3Component } from "../stage3/riskAssessmentFormStage3.component";

const stageFormMap = {
  0: RiskAssessmentFormStage0Component,
  1: RiskAssessmentFormStage1Component,
  2: RiskAssessmentFormStage2Component,
  3: RiskAssessmentFormStage3Component
};

/*
  This is the final, read only state for all the stages
*/

@Component({
  selector: 'risk-assessment-form-4',
  templateUrl: './riskAssessmentFormStage4.component.html'
})
export class RiskAssessmentFormStage4Component implements OnInit {
  // Existing Form Data
  @Input() public formData: IFormOutputModel;

  @ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef;

  public isReportEnabled = false;

  constructor(
    private factoryResolver: ComponentFactoryResolver,
  ) { }

  ngOnInit() {
    this.addReadOnlyForms();
  }

  public addReadOnlyForms() {
    if (!this.formData || !this.formData.records)
      return;

    //Get completed Records
    this.formData.records
      .filter(record => record.isComplete)
      .forEach(record => this.addReadOnlyForm(record))
  }

  public addReadOnlyForm(record: IFormRecordOutputModel) {
    const factory = this.factoryResolver.resolveComponentFactory(stageFormMap[record.stage]);
    const component = this.vc.createComponent(factory);

    // @ts-ignore
    component.instance.readOnly = true;
    // @ts-ignore
    component.instance.formData = this.formData;
    // @ts-ignore
    component.instance.hideHeader = !(record.stage === 0 && this.formData.archivedAt);
    // @ts-ignore
    component.instance.sequence = record.sequence;

    this.vc.insert(component.hostView);

    this.isReportEnabled = true;
  }
}
