
    <div class="row bg-default my-3 py-4">
        <div class="col-12 offset-0 col-lg-4 offset-lg-2 offset-xl-0 p-1 text-center mt-lg-2 mt-xl-0">
            <span>Updated By: <span class="text-primary">{{ userString }}</span></span>
        </div>
        <div class="col-12 col-lg-5 p-1 text-center mt-lg-2 mt-xl-0">
            <span>Date Updated: <span class="text-primary">{{ dateString }}</span></span>
            <i class="fa fa-pencil text-primary hover-cursor ml-3" *ngIf="isDateEditable" (click)="updateRecordDate()"></i>
        </div>
    </div>
  