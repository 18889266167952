<div class="row">
    <div class="col-8 text-lg-left">
        <h1 class="text-uppercase text-orange">Form Templates</h1>
    </div>
    <div class="col-4 p-1 text-center d-flex align-items-center justify-content-center">
        <div class="btn-group p-2 border border-dashed d-flex justify-content-center">
            <button class="btn btn-sm btn-orange" (click)="handleUserClick('audit','new')">New Audit Template</button>
            <button class="btn btn-sm btn-primary" (click)="handleRunSchedules()">Run Schedules</button>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-12">
        <table class="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">
                    <span class="sortable-col-category"
                          (click)="handleSortChange('category')"
                          [ngClass]="{active: sortBy === 'category', reverse: sortOrder !== 'asc'}">
                        Type<i class="fa fa-caret-up"></i>
                    </span>
                </th>
                <th scope="col">
                    <span class="sortable-col-Form"
                          (click)="handleSortChange('summary')"
                          [ngClass]="{active: sortBy === 'summary', reverse: sortOrder !== 'asc'}">
                        Form<i class="fa fa-caret-up"></i>
                    </span>
                </th>
                <th scope="col">
                    <span class="sortable-col-Form"
                          (click)="handleSortChange('templateLocation')"
                          [ngClass]="{active: sortBy === 'templateLocation', reverse: sortOrder !== 'asc'}">
                        Location(s)<i class="fa fa-caret-up"></i>
                    </span>
                </th>
                <th scope="col">
                    <span class="sortable-col-name"
                          (click)="handleSortChange('assignedTo')"
                          [ngClass]="{active: sortBy === 'assignedTo', reverse: sortOrder !== 'asc'}">
                        Assigned To<i class="fa fa-caret-up"></i>
                    </span>
                </th>
                <th scope="col">
                    Next Execution
                </th>
                <th scope="col">
                    Actions
                </th>
            </tr>
            </thead>
            <tbody>
            <tr class="hover-cursor" 
                *ngFor="let record of dashboardRecords" 
            >
                <td>{{record.type}}</td>
                <td>{{record.title}}</td>
                <td [innerHTML]="record.locations"></td>
                <td>
                    <span *ngIf="record.assignedUser">
                        {{ record.assignedUser }}
                    </span>
                    <span *ngIf="!record.assignedUser && record.assignedGroup">
                        {{ record.assignedGroup }} Department
                    </span>
                </td>
                <td>
                    <span *ngIf="!record.schedule">Never</span>
                    <span *ngIf="record.schedule && !record.schedule.nextExecution">Unknown</span>
                    <span *ngIf="record.schedule && record.schedule.nextExecution">
                        {{ record.schedule.nextExecution | date: 'mediumDate' }}
                    </span>
                </td>
                <td>
                    <div class="btn-group d-block text-center">
                        <button class="btn btn-sm btn-success" (click)="handleFormCreate(record.id)">Create {{record.type}}</button>
                        <button class="btn btn-sm btn-warning" (click)="showCronEdit(record)">Schedule</button>
                    </div>

                    <div class="btn-group d-block pt-1 text-center">
                        <button class="btn btn-sm btn-success" (click)="handleUserClick('audit',record.id)">Edit</button>
                        <button class="btn btn-sm btn-danger" (click)="deleteForm(record.id)">Delete</button>
                    </div>
                </td>
            </tr>
            <tr *ngIf="dashboardRecords.length === 0">
                <td colSpan="6" class="text-center">
                    No Templates
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <pagination
                aria-label="Default pagination"
                [collectionSize]="totalItems"
                [(page)]="currentPage"
                (pageChange)="handlePageChange()"
                [(pageSize)]="pageSize"
        ></pagination>
    </div>
</div>