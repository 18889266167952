import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { RxFormsService } from "../../../../../service/util/reactiveForms";
import { ModalService } from "../../../../../service/ModalService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { trimRequiredValidator } from "../../../../../validators/reactiveFormValidators";
import { ILocationOutputModel } from "../../../../../../common/contracts/location";
import { LocationService } from "../../../../../service/LocationService";

@Component({
  templateUrl: './editLocation.component.html',
})
export class EditLocationComponent implements OnInit {

  public location: ILocationOutputModel;
  public isNew: boolean = false;
  private locationId: number | null = null;

  public locationForm = new FormGroup({
    name: new FormControl('', [trimRequiredValidator]),
  });

  constructor(
    private locationService: LocationService,
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private errorHandlerService: ErrorHandlerService) {

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.locationId) {

        if (/^\d+$/.test(params.locationId) !== true) {
          console.error('Invalid location router param');
          this.router.navigate(['/404'], { replaceUrl: true });
          return;
        }

        this.locationId = parseInt(params.locationId, 10);

        this.locationService.getLocationById(this.locationId).subscribe((location) => {
          this.location = location;

          this.locationForm.setValue({
            name: location.name,
          });

        }, (err) => this.errorHandlerService.handleHttpError(err));

      } else {
        this.isNew = true;
      }
    });
  }

  public submit() {
    if (this.locationForm.invalid) {
      RxFormsService.touchAllFormControls(this.locationForm);
      return;
    }

    const observer = {
      next: () => this.goBack(),
      error: error => this.errorHandlerService.handleHttpError(error),
    };

    if (!this.locationId) {
      this.locationService.createLocation({
        name: this.locationForm.controls.name.value.trim(),
      }).subscribe(observer);
    } else {
      this.locationService.updateLocation({
        id: this.locationId,
        name: this.locationForm.controls.name.value.trim(),
      }).subscribe(observer);
    }
  }

  public archiveLocation() {
    this.modalService.confirmRx({
      title: "Warning",
      message: "Are you sure that you want to delete this location?"
    }).subscribe((isConfirmed) => {
      if (isConfirmed && this.locationId) {
        this.locationService.archiveLocation(this.locationId).subscribe(
          () => this.goBack(),
          (err) => this.errorHandlerService.handleHttpError(err)
        );
      }
    });
  }

  private goBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

}
