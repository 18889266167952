import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Subscription, timer } from "rxjs";

class FollowUpTemplate {
  userGroupId: string = '';
  description: string = '';
  dueDate: string = '';
  isValid: boolean = true;
}

@Component({
  selector: 'follow-up-widget',
  template: `
    <div class="border">
      <div class="row p-2">
          <div class="col-12">
              <div class="row" *ngIf="!hideHeader">
                  <div class="col-12">
                      <span class="h5">Follow Up:</span>
                  </div>
              </div>
              <div class="row {{i===0?'pt-1':'pt-3'}}" *ngFor="let followUp of followUps; let i=index">
                  <div class="col-12 {{i===0?'':'pb-1'}}" *ngIf="!readOnly">
                    <span class="fa fa-close pull-right hover-cursor" *ngIf="followUps.length > 1 || isNotEmpty" (click)="deleteFollowUp(i)"></span>
                  </div>
                  <div class="col-12 offset-0 col-md-6">
                      <div class="form-group">
                          <label *ngIf="i===0">Department:</label>
                          <user-group-select
                            [value]="followUps[i].userGroupId"
                            [disabled]="readOnly"
                            (change)="setUserGroupId($event, i);"
                          ></user-group-select>
                      </div>
                  </div>
                  <div class="col-12 offset-0 col-md-6">
                      <div class="form-group">
                          <label *ngIf="i===0">Due Date:</label>
                          <date-picker [disabled]="readOnly" [(value)]="followUps[i].dueDate" (valueChange)="emit()" [disablePastDates]="true"></date-picker>
                      </div>
                  </div>
                  <div class="col-12">
                      <textarea
                        class="form-control"
                        replaceAmpersand
                        autosize
                        [minRows]="2"
                        [placeholder]="readOnly ? '' : 'Description'"
                        [spellcheck]="true"
                        [disabled]="readOnly"
                        [(ngModel)]="followUps[i].description"
                        (input)="emit()"
                        [ngModelOptions]="{standalone: true}"
                      ></textarea>
                  </div>
                  <div class="col-12" *ngIf="!followUps[i].isValid && !readOnly">
                    <small class="form-text text-danger text-right">Please Ensure all fields are filled.</small>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12 pt-2">
                      <div class="border-bottom border-dashed"></div>
                  </div>
              </div>
          </div>
          <div class="col-12 pt-2" *ngIf="!readOnly">
              <div class="d-inline-block hover-cursor" (click)="addFollowUp()">
                  <span class="fa fa-plus text-success"></span> Follow Up (Add one per department)
              </div>
          </div>
      </div>
  </div>
  `
})
export class FollowUpWidgetComponent implements OnInit {

  private skipChanges = false;
  private _model: string;

  private updateSubscription: Subscription | null = null;

  @Input()
  public hideHeader: boolean = false;

  @Input('model')
  get model() {
    return this._model;
  }
  set model(value: string) { // this fix for correct loading followUps property after component initialization
    if (this.skipChanges) {
      this.skipChanges = false;
      return;
    }
    if (value && value !== '[]') {
      this._model = value;
      this.followUps = JSON.parse(value);
      this.isNotEmpty = true;
    } else {
      this._model = '[]';
      this.followUps = [
        new FollowUpTemplate(),
      ];
      this.isNotEmpty = false;
    }
  }


  @Output() modelChange = new EventEmitter<string>();
  @Input() readOnly: boolean;

  followUps:FollowUpTemplate[] = [
    new FollowUpTemplate()
  ];

  public isNotEmpty = false;

  constructor() {}

  ngOnInit() {
  }

  public setUserGroupId(data:IdTextPair[], idx:number) {
    if( data.length === 0 )
      this.followUps[idx].userGroupId = '';
    else
      this.followUps[idx].userGroupId = data[0].id;

    this.emit();
  }

  public addFollowUp() {
    this.followUps.push(new FollowUpTemplate());

    this.emit();
  }

  public deleteFollowUp(idx:number) {
    this.followUps.splice(idx, 1);

    if( this.followUps.length === 0 ) {
      this.addFollowUp();
      return;
    }

    this.emit();
  }

  public emit(): void {

    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }

    this.updateSubscription = timer(300).subscribe(() => { // in order to avoid sending updates to parent form too often there is a delay 300ms
      const notEmptyFollowUps = this.followUps.filter( followUp =>
        followUp.userGroupId.length > 0 || followUp.description.length > 0 || followUp.dueDate.length > 0
      );
      this.isNotEmpty = notEmptyFollowUps.length > 0;
      this.skipChanges = true;
      this.modelChange.emit(JSON.stringify(notEmptyFollowUps));
      this.updateSubscription = null;
    });

    // Do not emit empty followUps
  }
  
  /*
   * Crude validation method for simple followUp form items
   */
  public validate():boolean {
    let foundError = false;

    const notEmptyFollowUps = this.followUps.filter( followUp =>
      followUp.userGroupId.length > 0 || followUp.description.length > 0 || followUp.dueDate.length > 0
    );
  
    notEmptyFollowUps.forEach( (followUp:FollowUpTemplate) => {
      followUp.isValid = true;
      if(
        followUp.description.length === 0
        || followUp.userGroupId.length === 0
        || followUp.dueDate.length === 0
      ) {
        foundError = true;
        followUp.isValid = false;
      }
    });
    
    return !foundError;
  }
}
