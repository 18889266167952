import { Component } from '@angular/core';
import { FormField } from '../../../model/Form';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent {

  emailAddress: FormField<String> = new FormField<String>('', {
    validation: FormField.ValidationMethods.IsEmail,
  });
  password: FormField<String> = new FormField<String>('', {
    validation: FormField.ValidationMethods.Match(/^.{6,}$/),
    onChange: ():void => {
      this.passwordVerification.validate();
    },
  });
  passwordVerification: FormField<String> = new FormField<String>('', {
    validation: FormField.ValidationMethods.IsEqual(this.password),
  });

  // Indicate that the form was invalid after an attempt to submit
  showFormHasError: boolean = false;
  errorMessage: string;

  constructor(
    public router: Router,
  ) {}

  validateRegisterForm():boolean {
    let hasError = false;

    [
      this.emailAddress,
      this.password,
      this.passwordVerification,
    ].forEach((field) => {
      if (!field.isValid) {
        field.showError = true;
        hasError = true;
      }
    });

    this.showFormHasError = hasError;

    return !hasError;
  }

  performRegister() {

    if (!this.validateRegisterForm()) {
      return;
    }
  }
}
