import { GenericService } from './GenericService';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourcePack } from 'model/ResourcePack';
import { IDocumentType } from '../../common/contracts/document';
import { Document } from 'model/Document';

@Injectable()
export class ResourcePackService extends GenericService {

    readonly apiPrefix = '/api/resource-packs';

    constructor(private http: HttpClient) {
        super();
    }

    create(name: string) {
        return this.http.post<ResourcePack>(this.apiPrefix, { name })
    }

    delete(id: number) {
        return this.http.delete(this.apiPrefix.concat('/',id.toString()));
    }

    fetch() {
        return this.http.get<Array<ResourcePack>>(this.apiPrefix);
    }

    fetchById(resourcePackId: string) {
        return this.http.get<ResourcePack>(this.apiPrefix.concat('/', resourcePackId))
    }

    updateName(id: number, name: string) {
        return this.http.put(this.apiPrefix.concat('/', id.toString()), { name });
    }

    attachDocument(resourcePackId: number, document: IDocumentType) {
        return this.http.post<Document>(this.apiPrefix.concat('/', resourcePackId.toString(), '/documents'), document);
    }

    deleteDocument(documentId: number) {
        return this.http.delete(this.apiPrefix.concat('/documents/', documentId.toString()));
    }
}
