<ng-container #vc></ng-container>

<div class="row">
    <div class="col-12 col-md-6 col-lg-6 col-xl-6">
        <download-report-link *ngIf="isReportEnabled" [formId]="formData.id" filePrefix="follow-up-report"></download-report-link>
    </div>
    <div class="col-12 col-md-6 col-lg-6 col-xl-6 pt-2" *ngIf="isReportEnabled">
        <form-due-at [formData]="formData" [inline]="true"></form-due-at>
    </div>
</div>
