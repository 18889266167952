/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resource-packs.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./resource-pack-create/resource-pack-create.component.ngfactory";
import * as i5 from "./resource-pack-create/resource-pack-create.component";
import * as i6 from "../../service/ResourcePackService";
import * as i7 from "../../service/ErrorHandlerService";
import * as i8 from "../shared/preloaderWrapper.component.ngfactory";
import * as i9 from "../shared/preloaderWrapper.component";
import * as i10 from "./resource-packs.component";
var styles_ResourcePacksComponent = [i0.styles];
var RenderType_ResourcePacksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResourcePacksComponent, data: {} });
export { RenderType_ResourcePacksComponent as RenderType_ResourcePacksComponent };
function View_ResourcePacksComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["scope", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "td", [["width", "150"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["class", "btn btn-primary btn-sm text-white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 2), (_l()(), i1.ɵted(-1, null, ["View / Edit"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "btn btn-danger btn-sm text-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteResourcePack(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], function (_ck, _v) { var currVal_3 = _ck(_v, 7, 0, "documents", _v.context.$implicit.id); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).target; var currVal_2 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_1, currVal_2); }); }
function View_ResourcePacksComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "table", [["class", "table table-striped table-inverse"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "thead", [["class", "thead-inverse"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourcePacksComponent_2)), i1.ɵdid(10, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resourcePacks; _ck(_v, 10, 0, currVal_0); }, null); }
function View_ResourcePacksComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Resource Packs Available"]))], null, null); }
export function View_ResourcePacksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "text-uppercase text-orange"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Resource Packs"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-resource-pack-create", [], null, [[null, "onCreate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onCreate" === en)) {
        var pd_0 = (_co.fetchData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ResourcePackCreateComponent_0, i4.RenderType_ResourcePackCreateComponent)), i1.ɵdid(3, 114688, null, 0, i5.ResourcePackCreateComponent, [i6.ResourcePackService, i7.ErrorHandlerService], null, { onCreate: "onCreate" }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "preloader-wrapper", [], null, null, null, i8.View_PreloaderWrapperComponent_0, i8.RenderType_PreloaderWrapperComponent)), i1.ɵdid(6, 49152, null, 0, i9.PreloaderWrapperComponent, [], { isLoaded: [0, "isLoaded"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ResourcePacksComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["empty", 2]], null, 0, null, View_ResourcePacksComponent_3))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _co.isLoaded; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.resourcePacks.length; var currVal_2 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_1, currVal_2); }, null); }
export function View_ResourcePacksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-resource-packs", [], null, null, null, View_ResourcePacksComponent_0, RenderType_ResourcePacksComponent)), i1.ɵdid(1, 114688, null, 0, i10.ResourcePacksComponent, [i6.ResourcePackService, i7.ErrorHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourcePacksComponentNgFactory = i1.ɵccf("app-resource-packs", i10.ResourcePacksComponent, View_ResourcePacksComponent_Host_0, {}, {}, []);
export { ResourcePacksComponentNgFactory as ResourcePacksComponentNgFactory };
