<a class="mr-2 text-body item-content d-inline-block text-center {{small?'small':''}}" [routerLink]="['/profile']" *ngIf="!this.session.credentials">
  <div class="icon"><i class="fa fa-user-circle"></i></div>
  Login
</a>

<a class="mr-2 text-body item-content d-inline-block text-center {{small?'small':''}}" [routerLink]="['/profile']"  *ngIf="this.session.credentials">
  <div class="icon"><i class="fa fa-gear"></i></div>
  Profile
</a>

<a class="ml-2 text-body item-content d-inline-block text-center {{small?'small':''}}" (click)="signOut()" *ngIf="this.session.credentials">
  <div class="icon"><i class="fa fa-user-circle"></i></div>
  Logout
</a>