import { Component, Input, OnInit } from "@angular/core";
import { FormField } from "model/Form";
import { Subscription } from "rxjs";

const OffsetOptions = [
  'Same day',
  'Next day',
  'Custom number of days',
  'Monday this week',
  'Monday of next week',
  'Custom number of weeks',
  '1st of this month',
  '1st of next month',
  'Custom number of months',
]

@Component({
  selector: 'date-offset-picker',
  styles: [`
  `],
  template: `
  <div class="form-inline">
    <div class="form-group">
      <input type="text"
        *ngIf="showOffsetAmount"
        class="col-2 form-control text-center p-1"
        [placeholder]="'#'"
        [(ngModel)]="offsetAmount"
		(input)="onInput($event)"
		(keyup)="onKeyUp($event)"
        [ngModelOptions]="{standalone: true}"
        numbersOnly
        maxlength="4">
      <div 
        class="drop-down-wrapper pt-1"
        [ngClass]="{'pl-2': showOffsetAmount}"
        placement="bottom-right"
        ngbDropdown
      >
        <a class="dropdown-link" ngbDropdownToggle>{{selectedOffsetPeriod||'Select Offset'}}</a>
        <div ngbDropdownMenu>
            <button 
              class="dropdown-item" 
              *ngFor="let option of offsetOptions" 
              (click)="changeOffsetOption(option)">{{option}}</button>
        </div>
      </div>
    </div>
  </div>
  `
})
export class DateOffsetPicker implements OnInit {
  public offsetOptions = OffsetOptions;
  public selectedOffsetPeriod:string|null = null;
  public showOffsetAmount:boolean = false;
  public offsetAmount:number|null = 1;
  private changeSubscriber:Subscription;

  @Input()
  periodField: FormField<string>;

  @Input()
  offsetField: FormField<number>;

  constructor() {}

  ngOnInit():void {
    this.periodField.valueChanges
    .subscribe( newValue => {
      this.selectedOffsetPeriod = newValue;
      this.updateOffsetDisplayOptions();
    });

    this.selectedOffsetPeriod = this.periodField.value;

	/**
	 * The changesubscriber will attach itself to the form itself and wait for updates
	 * 
	 * This allows for the stack to wait on ngInit in parent components to push an update through
	 * to the offset picker before detatching the subscriber and updating the model as expected
	 */
    this.changeSubscriber = this.offsetField.valueChanges
    .subscribe( newValue => this.offsetAmount = this.offsetField.value );

    this.offsetAmount = this.offsetField.value;
    
    this.updateOffsetDisplayOptions();
  }

  changeOffsetOption(val:string):void {
    this.selectedOffsetPeriod = val;

    this.periodField.value = val;

    if( !this.showOffsetAmount && !this.offsetField.value === null )
      this.offsetField.value = null;

    this.updateOffsetDisplayOptions();
  }

  updateOffsetDisplayOptions():void {
    const val = this.periodField.value || '';

    this.showOffsetAmount = !!val.toLowerCase().match(/^custom number.+$/i );
  }

  destroyChangeSubscriber():void {
	if( this.changeSubscriber )
	  this.changeSubscriber.unsubscribe();
  }

  onInput(event:any):void {
    if( event.data && this.offsetField ) {
		this.destroyChangeSubscriber();

        this.offsetField.value = event.target.value;
    }
  }

  /**
   * Detects a keypress, removes the temporary subscriber if applicable and then updates the underlying value
   * 
   * @param event 
   */
  onKeyUp(event:any):void {
    if( event.target.value && this.offsetField ) {
		this.offsetField.value = event.target.value;
	}
  }
}
