import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { CurrentUserService } from "../currentUser/CurrentUserService";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class AdminRouterGuard implements CanActivate {

  constructor(public currentUserService: CurrentUserService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.currentUserService.isAdministrator.pipe(tap( (isAdmin) => {
      if (!isAdmin) {
        console.error('Forbidden route has been requested!');
        this.router.navigate(["/403"], { replaceUrl: true });
      }
    }));
  }
}
