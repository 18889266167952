var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { GenericService } from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import { of } from "rxjs";
import { share, map, tap } from "rxjs/operators";
import { logger } from 'service/util/Logger';
var GroupsService = /** @class */ (function (_super) {
    __extends(GroupsService, _super);
    function GroupsService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.className = "GroupsService";
        _this.cachedGroups = null;
        var queryParams = new HttpParams({
            fromObject: {
                skip: '0',
                limit: '50',
                sortBy: 'groupName',
                order: 'asc',
            }
        });
        _this.getGroupsRequest = _this.http.get(GroupsService.apiPrefix, { params: queryParams }).pipe(map(function (data) {
            _this.cachedGroups = data.items;
            setTimeout(function () {
                _this.cachedGroups = null; // invalidate groups cache in 20 minutes
            }, 20 * 60 * 1000);
            return data.items;
        }), share() //share results between multiple subscriptions
        );
        return _this;
    }
    GroupsService.prototype.getGroups = function () {
        return this.cachedGroups ? of(this.cachedGroups) : this.getGroupsRequest;
    };
    GroupsService.prototype.getGroupByName = function (name) {
        return __awaiter(this, void 0, void 0, function () {
            var signature, groups, targetGroup;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        signature = this.className + '.getGroupByName: ';
                        return [4 /*yield*/, this.getGroups().toPromise()];
                    case 1:
                        groups = _a.sent();
                        targetGroup = groups.find(function (group) { return group.groupName.toLowerCase() === name.toLowerCase(); });
                        logger.silly(signature + ((targetGroup ? "Found Group[" + targetGroup.id + "]" : 'Did not find group') + " by Name[" + name + "] in Total[" + groups.length + "] groups"));
                        return [2 /*return*/, targetGroup];
                }
            });
        });
    };
    GroupsService.prototype.getGroupIdByName = function (name) {
        return __awaiter(this, void 0, void 0, function () {
            var group;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getGroupByName(name)];
                    case 1:
                        group = _a.sent();
                        if (group) {
                            return [2 /*return*/, group.id];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    GroupsService.prototype.getPagedGroups = function (offset, pageSize, sortBy, order, countUsers) {
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 20; }
        if (sortBy === void 0) { sortBy = 'groupName'; }
        if (order === void 0) { order = 'asc'; }
        if (countUsers === void 0) { countUsers = true; }
        var queryParams = new HttpParams({
            fromObject: {
                skip: offset.toString(),
                limit: pageSize.toString(),
                sortBy: sortBy,
                order: order,
                countUsers: countUsers ? '1' : '0',
            }
        });
        return this.http.get(GroupsService.apiPrefix, { params: queryParams });
    };
    GroupsService.prototype.getGroupById = function (id) {
        return this.http.get(GroupsService.apiPrefix + "/" + id);
    };
    GroupsService.prototype.createGroup = function (groupData) {
        var _this = this;
        return this.http.post(GroupsService.apiPrefix, groupData).pipe(tap(function () {
            _this.cachedGroups = null;
        }));
    };
    GroupsService.prototype.updateGroup = function (groupChanges) {
        var _this = this;
        return this.http.put(GroupsService.apiPrefix, groupChanges).pipe(tap(function () {
            _this.cachedGroups = null;
        }));
    };
    GroupsService.prototype.archiveGroup = function (id) {
        var _this = this;
        return this.http.delete(GroupsService.apiPrefix + "/" + id).pipe(tap(function () {
            _this.cachedGroups = null;
        }));
    };
    GroupsService.apiPrefix = '/api/groups';
    return GroupsService;
}(GenericService));
export { GroupsService };
