var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from "@angular/core";
import { GenericService } from "../GenericService";
import { HttpClient } from "@angular/common/http";
import lscache from 'lscache';
import { catchError, tap } from "rxjs/operators";
import { Session } from "../util/Session";
import { BehaviorSubject } from "rxjs";
import * as Sentry from "@sentry/browser";
var CurrentUserService = /** @class */ (function (_super) {
    __extends(CurrentUserService, _super);
    function CurrentUserService(http, session) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.session = session;
        _this.userData = null;
        _this.logoutEvents = new EventEmitter();
        _this.currentUserId = new BehaviorSubject(null);
        _this.currentUserFullName = new BehaviorSubject('');
        _this.isAdministrator = new BehaviorSubject(false);
        _this.isAdministratorOrManager = new BehaviorSubject(false);
        _this.isAdminOrDocumentManager = new BehaviorSubject(false);
        _this.currentUserData = new BehaviorSubject(null);
        return _this;
    }
    CurrentUserService.prototype.getCurrentUser = function () {
        var _this = this;
        return this.http.get(CurrentUserService.apiPrefix).pipe(tap(function (user) {
            _this.userData = user;
            Sentry.configureScope(function (scope) {
                scope.setUser({
                    id: user.id.toString(),
                    email: user.username
                });
            });
            _this.currentUserId.next(_this.userData.id);
            _this.currentUserFullName.next(_this.userData.firstName + " " + _this.userData.lastName);
            _this.isAdministrator.next(_this.userData.role === 'administrator');
            _this.isAdministratorOrManager.next(_this.userData.role === 'administrator' || _this.userData.role === 'manager');
            _this.isAdminOrDocumentManager.next(_this.userData.role === 'administrator' || (_this.userData.role === 'manager' &&
                !!_this.userData.groups &&
                _this.userData.groups.length > 0 &&
                !!_this.userData.groups.find(function (group) { return group.groupName === 'Update Documents'; })));
            _this.currentUserData.next(user);
        }), catchError(function (err) {
            _this.cleanUserCredentials();
            throw err; // TODO check if it's needed to throw here
        }));
    };
    CurrentUserService.prototype.updateUserProfile = function (profile) {
        return this.http.put(CurrentUserService.apiPrefix, profile);
    };
    CurrentUserService.prototype.getCurrentUserIdOrFail = function () {
        var result;
        if (this.userData)
            result = this.userData.id;
        if (!result)
            throw new Error("CurrentUserService did not have appropriate data");
        return result;
    };
    /**
     * it's better to avoid using direct calls to service methods from angular templates because angular doesn't know
     * when a result of a method changes and it begins to run constantly this method expecting for changes.
     * It's better to use reactive approach with observables, please use "currentUserFullName | async" instead.
     *
     * Retained for Posterity
     */
    /*public getCurrentUserFullName() : string {
  
      if( this.userData ) {
        return `${this.userData.firstName } ${this.userData.lastName}`;
      }
  
      return '';
    }*/
    CurrentUserService.prototype.recoverTokenFromLocalStorage = function () {
        var token = lscache.get('token');
        if (token) {
            this.token = token;
            this.session.credentials = {
                accessToken: token,
                tokenType: "Bearer",
            };
            return true;
        }
        else {
            return false;
        }
    };
    CurrentUserService.prototype.cleanUserCredentials = function () {
        lscache.remove('token');
        Sentry.configureScope(function (scope) {
            scope.clear();
        });
        this.userData = null;
        this.session.credentials = null;
        GenericService._token = null;
        this.currentUserFullName.next('');
        this.isAdministrator.next(false);
        this.currentUserData.next(null);
        this.logoutEvents.emit();
    };
    CurrentUserService.apiPrefix = '/api/current_user';
    return CurrentUserService;
}(GenericService));
export { CurrentUserService };
