var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericService } from "./GenericService";
import { HttpClient } from "@angular/common/http";
import { of } from "rxjs";
import { share, tap } from "rxjs/operators";
import { CurrentUserService } from "./currentUser/CurrentUserService";
var EnumService = /** @class */ (function (_super) {
    __extends(EnumService, _super);
    function EnumService(http, currentUserService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.currentUserService = currentUserService;
        _this.cachedEnumsMap = {};
        _this.requestsMap = {};
        /**
         * Invalidate cache on logout
         */
        _this.currentUserService.logoutEvents.subscribe(function () {
            _this.cachedEnumsMap = {};
            _this.requestsMap = {};
        });
        return _this;
    }
    EnumService.prototype.getEnums = function () {
        return this.http.get(EnumService.apiPrefix);
    };
    EnumService.prototype.getEnumsByName = function (enumName, useCachedEnums) {
        if (useCachedEnums === void 0) { useCachedEnums = true; }
        var cacheKey = enumName.toLowerCase();
        if (!useCachedEnums) {
            return this.getGetEnumsRequest(enumName);
        }
        if (this.cachedEnumsMap[cacheKey]) {
            return of(this.cachedEnumsMap[cacheKey]);
        }
        if (this.requestsMap[cacheKey]) {
            return this.requestsMap[cacheKey];
        }
        this.requestsMap[cacheKey] = this.getGetEnumsRequest(enumName);
        return this.requestsMap[cacheKey];
    };
    EnumService.prototype.getGetEnumsRequest = function (enumName) {
        var _this = this;
        var cacheKey = enumName.toLowerCase();
        return this.http.get(EnumService.apiPrefix + "/" + enumName).pipe(tap(function (enums) {
            _this.cachedEnumsMap[cacheKey] = enums;
        }), share());
    };
    EnumService.prototype.createEnumsSet = function (data) {
        return this.http.post(EnumService.apiPrefix, data);
    };
    EnumService.prototype.updateEnumsSet = function (data) {
        var cacheKey = data.name.toLowerCase();
        delete this.cachedEnumsMap[cacheKey]; // clear cached enums
        delete this.requestsMap[cacheKey];
        return this.http.put(EnumService.apiPrefix, data);
    };
    EnumService.apiPrefix = '/api/enum';
    return EnumService;
}(GenericService));
export { EnumService };
