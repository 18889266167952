/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "./pagination.component";
import * as i5 from "../../service/PersistentStorageService";
var styles_PaginationComponent = [".pagination-wrapper[_ngcontent-%COMP%] {\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n    }\n    .drop-down-wrapper[_ngcontent-%COMP%] {\n        padding-top: 6px;\n        padding-left: 14px;\n    }\n    a.dropdown-link[_ngcontent-%COMP%] {\n        color: #007bff;\n        cursor: pointer;\n        padding-bottom: 6px;\n    }\n    a.dropdown-link[_ngcontent-%COMP%]:hover {\n        color: #0056b3;\n    }"];
var RenderType_PaginationComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_PaginationComponent, data: {} });
export { RenderType_PaginationComponent as RenderType_PaginationComponent };
export function View_PaginationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 26, "div", [["class", "pagination-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "ngb-pagination", [["role", "navigation"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.page = $event) !== false);
        ad = (pd_0 && ad);
    } if (("pageChange" === en)) {
        var pd_1 = (_co.handlePageChange() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_NgbPagination_0, i1.RenderType_NgbPagination)), i0.ɵdid(2, 573440, null, 6, i2.NgbPagination, [i2.NgbPaginationConfig], { collectionSize: [0, "collectionSize"], maxSize: [1, "maxSize"], page: [2, "page"], pageSize: [3, "pageSize"] }, { pageChange: "pageChange" }), i0.ɵqud(335544320, 1, { tplEllipsis: 0 }), i0.ɵqud(335544320, 2, { tplFirst: 0 }), i0.ɵqud(335544320, 3, { tplLast: 0 }), i0.ɵqud(335544320, 4, { tplNext: 0 }), i0.ɵqud(335544320, 5, { tplNumber: 0 }), i0.ɵqud(335544320, 6, { tplPrevious: 0 }), (_l()(), i0.ɵeld(9, 0, null, null, 17, "div", [["class", "drop-down-wrapper"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i0.ɵdid(10, 737280, null, 3, i2.NgbDropdown, [i0.ChangeDetectorRef, i2.NgbDropdownConfig, i3.DOCUMENT, i0.NgZone, i0.ElementRef, i0.Renderer2, [2, i2.ɵm]], { placement: [0, "placement"] }, null), i0.ɵqud(335544320, 7, { _menu: 0 }), i0.ɵqud(335544320, 8, { _menuElement: 0 }), i0.ɵqud(335544320, 9, { _anchor: 0 }), (_l()(), i0.ɵeld(14, 0, null, null, 3, "a", [["aria-haspopup", "true"], ["class", "dropdown-link dropdown-toggle"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i0.ɵnov(_v, 15).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 15).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i0.ɵnov(_v, 15).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i0.ɵnov(_v, 15).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i0.ElementRef], null, null), i0.ɵprd(2048, [[9, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i0.ɵted(17, null, ["", " per page"])), (_l()(), i0.ɵeld(18, 0, [[8, 0]], null, 8, "div", [["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i0.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i0.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i0.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i0.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i0.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(19, 16384, [[7, 4]], 1, i2.NgbDropdownMenu, [i2.NgbDropdown], null, null), i0.ɵqud(603979776, 10, { menuItems: 1 }), (_l()(), i0.ɵeld(21, 0, null, null, 1, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handlePageSizeChange(10) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["10"])), (_l()(), i0.ɵeld(23, 0, null, null, 1, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handlePageSizeChange(20) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["20"])), (_l()(), i0.ɵeld(25, 0, null, null, 1, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handlePageSizeChange(50) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["50"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collectionSize; var currVal_1 = 10; var currVal_2 = _co.page; var currVal_3 = _co.pageSize; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = _co.dropdownPlacement; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = i0.ɵnov(_v, 10).isOpen(); _ck(_v, 9, 0, currVal_4); var currVal_6 = i0.ɵnov(_v, 15).dropdown.isOpen(); _ck(_v, 14, 0, currVal_6); var currVal_7 = _co.pageSize; _ck(_v, 17, 0, currVal_7); var currVal_8 = true; var currVal_9 = i0.ɵnov(_v, 19).dropdown.isOpen(); var currVal_10 = i0.ɵnov(_v, 19).placement; _ck(_v, 18, 0, currVal_8, currVal_9, currVal_10); }); }
export function View_PaginationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pagination", [], null, null, null, View_PaginationComponent_0, RenderType_PaginationComponent)), i0.ɵdid(1, 49152, null, 0, i4.PaginationComponent, [i5.PersistentStorageService], null, null)], null, null); }
var PaginationComponentNgFactory = i0.ɵccf("pagination", i4.PaginationComponent, View_PaginationComponent_Host_0, { collectionSize: "collectionSize", page: "page", pageSize: "pageSize", dropdownPlacement: "dropdownPlacement" }, { pageChange: "pageChange", pageSizeChange: "pageSizeChange" }, []);
export { PaginationComponentNgFactory as PaginationComponentNgFactory };
