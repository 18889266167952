var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericService } from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import { of } from "rxjs";
import { share, map, tap } from "rxjs/operators";
var TagsService = /** @class */ (function (_super) {
    __extends(TagsService, _super);
    function TagsService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.cachedTags = null;
        var queryParams = new HttpParams({
            fromObject: {
                skip: '0',
                limit: '50',
                sortBy: 'name',
                order: 'asc',
            }
        });
        _this.getTagsRequest = _this.http.get(TagsService.apiPrefix, { params: queryParams }).pipe(map(function (data) {
            _this.cachedTags = data.items;
            setTimeout(function () {
                _this.cachedTags = null; // invalidate tags cache in 20 minutes
            }, 20 * 60 * 1000);
            return data.items;
        }), share() //share results between multiple subscriptions
        );
        return _this;
    }
    TagsService.prototype.getTags = function () {
        return this.cachedTags ? of(this.cachedTags) : this.getTagsRequest;
    };
    TagsService.prototype.getPagedTags = function (offset, pageSize, sortBy, order, counts) {
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 20; }
        if (sortBy === void 0) { sortBy = 'name'; }
        if (order === void 0) { order = 'asc'; }
        if (counts === void 0) { counts = true; }
        var queryParams = new HttpParams({
            fromObject: {
                skip: offset.toString(),
                limit: pageSize.toString(),
                sortBy: sortBy,
                order: order,
            }
        });
        return this.http.get(TagsService.apiPrefix, { params: queryParams });
    };
    TagsService.prototype.getTagById = function (id) {
        return this.http.get(TagsService.apiPrefix + "/" + id);
    };
    TagsService.prototype.createTag = function (tagData) {
        var _this = this;
        return this.http.post(TagsService.apiPrefix, tagData).pipe(tap(function () {
            _this.cachedTags = null;
        }));
    };
    TagsService.prototype.updateTag = function (tagChanges) {
        var _this = this;
        return this.http.put(TagsService.apiPrefix, tagChanges).pipe(tap(function () {
            _this.cachedTags = null;
        }));
    };
    TagsService.prototype.archiveTag = function (id) {
        var _this = this;
        return this.http.delete(TagsService.apiPrefix + "/" + id).pipe(tap(function () {
            _this.cachedTags = null;
        }));
    };
    TagsService.apiPrefix = '/api/tag';
    return TagsService;
}(GenericService));
export { TagsService };
