// tslint:disable-next-line:max-line-length
export const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ISODateRegEx = /\b[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]{2,3})?Z\b/;


/**
 * This is the static role and group name of the users that are able to edit documents
 */
export const DocumentEditRole = "manager";
export const DocumentEditGroup = "Update Documents";
export const timeZone = "Australia/Brisbane";
