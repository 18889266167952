/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./splash.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../authentication-icon/authentication-icon.component.ngfactory";
import * as i3 from "../authentication-icon/authentication-icon.component";
import * as i4 from "@angular/router";
import * as i5 from "../../service/util/Session";
import * as i6 from "../../service/currentUser/CurrentUserService";
import * as i7 from "./splash.component";
var styles_SplashComponent = [i0.styles];
var RenderType_SplashComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SplashComponent, data: {} });
export { RenderType_SplashComponent as RenderType_SplashComponent };
export function View_SplashComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container d-flex h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-12 align-self-center text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "col-12 col-lg-6 offset-lg-3 col-md-8 offset-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["src", "assets/images/logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "profileIcon d-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "authentication-icon", [], null, null, null, i2.View_AuthenticationIconComponent_0, i2.RenderType_AuthenticationIconComponent)), i1.ɵdid(8, 114688, null, 0, i3.AuthenticationIconComponent, [i4.Router, i5.Session, i6.CurrentUserService], null, null)], function (_ck, _v) { _ck(_v, 8, 0); }, null); }
export function View_SplashComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-splash", [], null, null, null, View_SplashComponent_0, RenderType_SplashComponent)), i1.ɵdid(1, 49152, null, 0, i7.SplashComponent, [], null, null)], null, null); }
var SplashComponentNgFactory = i1.ɵccf("app-splash", i7.SplashComponent, View_SplashComponent_Host_0, {}, {}, []);
export { SplashComponentNgFactory as SplashComponentNgFactory };
