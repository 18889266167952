<div class="col-12">
	<div class="row">
		<div class="col-10 col-sm-8 col-lg-10">
			<div class="form-group search-input-group">
				<input class="form-control search-input-control" type="text" placeholder="Search 🔍" [(ngModel)]="filter.search"
					(keyup.enter)="publishFilter()" (keyup.escape)="clearSearch()" (blur)="publishFilter()">
				<span class="search-input-clear" title="Clear" *ngIf="filter.search.length > 0"
					(click)="clearSearch()">&times;</span>
			</div>
		</div>
		<div class="col-2 col-sm-4 col-lg-2">
			<span class="btn btn-secondary w-100" (click)="setDefaultFilter()">Reset</span>
		</div>
	</div>

	<div class="row" *ngIf="isAdminOrManager">

		<div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6 pb-2">
			<user-select [field]="assignedUserId" placeholder="View Queue For User"></user-select>
		</div>

		<div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6 pb-2">
			<location-select [field]="locations" placeholder="Location" [restrictLocations]="true"
				multiple="true"></location-select>
		</div>

		<div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6 pb-2">
			<user-group-select [field]="groups" placeholder="Department" [restrictGroups]="!isAdmin"
				multiple="true"></user-group-select>
		</div>

	</div>

	<div class="row">
		<div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6">
			<div class="form-group search-input-group">
				<input class="form-control search-input-control" type="text" placeholder="Originator Name 🔍"
					[(ngModel)]="filter.originatorName" (keyup.enter)="publishFilter()" (keyup.escape)="clearOriginatorSearch()"
					(blur)="publishFilter()">
				<span class="search-input-clear" title="Clear" *ngIf="filter.originatorName.length > 0"
					(click)="clearOriginatorSearch()">&times;</span>
			</div>
		</div>

		<div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6">
			<form-category-select [field]="category" placeholder="All Categories" [showValidation]="false"></form-category-select>
		</div>

		<div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6">
			<enum-select placeholder="Feedback Form Type" enum="ReportFormType" [field]="reportFormType"></enum-select>
		</div>
	</div>
</div>

<div class="col-12">
	<div class="second-line-filter">

		<div class="date-filter-block">
			<div ngbDropdown class="dropdown-link-wrapper" placement="bottom-left">
				<a class="dropdown-link" ngbDropdownToggle>{{dateFilterMap[selectedDateFilter]}}</a>
				<div ngbDropdownMenu>
					<button class="dropdown-item" (click)="handleDateFilterChange('createdAt')"
						*ngIf="selectedDateFilter !== 'createdAt'">Created At</button>
					<button class="dropdown-item" (click)="handleDateFilterChange('dueAt')"
						*ngIf="selectedDateFilter !== 'dueAt'">Due Date</button>
					<button class="dropdown-item" (click)="handleDateFilterChange('none')"
						*ngIf="selectedDateFilter !== 'none'">Clear Filter</button>
				</div>
			</div>
			<div class="date-picker-container" *ngIf="selectedDateFilter !== 'none'">
				<date-picker placeholder="Start date" [clearable]="true" [formField]="filterStartDate" [disableValidationStyles]="true"></date-picker>
			</div>
			<div class="date-picker-container" *ngIf="selectedDateFilter !== 'none'">
				<date-picker placeholder="End date" [clearable]="true" [formField]="filterEndDate" [disableValidationStyles]="true"></date-picker>
			</div>
		</div>

		<div class="show-all-filter-block" *ngIf="isAdminOrManager">
			<button-group-toggle [options]="showAllOptions" [value]="filter.showAll"
				(valueChange)="handleShowAllFilterChange($event)"></button-group-toggle>
		</div>
	</div>
</div>