var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericService } from "../GenericService";
import { HttpClient } from "@angular/common/http";
var SettingsService = /** @class */ (function (_super) {
    __extends(SettingsService, _super);
    function SettingsService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    SettingsService.prototype.getSettings = function () {
        return this.http.get(SettingsService.apiPrefix);
    };
    SettingsService.prototype.updateSettings = function (data) {
        return this.http.put(SettingsService.apiPrefix, data);
    };
    SettingsService.apiPrefix = '/api/settings';
    return SettingsService;
}(GenericService));
export { SettingsService };
