import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import "moment-timezone";
import { FormService } from "../../../../service/FormService";
import { Session } from "../../../../service/util/Session";
import { FormsPaginationResponse, IFormsQueryParams } from "../../../../../common/contracts/form";
import { CurrentUserService } from "../../../../service/currentUser/CurrentUserService";
import { PersistentStorageService } from "../../../../service/PersistentStorageService";
import { TruncatePipe } from "../../../../pipes/truncate.pipe";
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
import { stringifyObjectValues } from "../../../../service/util/stringifyObjectValues";
import { UserSelectComponent } from "../../../shared/userSelect.component";
import { ReportService } from "../../../../service/ReportService";
import { CategoryService } from "../../../../service/CategoryService";
import { ICategoryOutputModel } from "../../../../../common/contracts/category";
import { Observable } from "rxjs";
import { logger } from "service/util/Logger";
import { DocumentIntanceService } from "service/admin/DocumentInstanceService";
import { IDocumentInstanceOutputType, IDocumentInstanceParams } from "../../../../../common/contracts/documentInstance"
import { FormField } from "model/Form";
import { environment } from "../../../../environments/environment";
import { NgbTabset } from "@ng-bootstrap/ng-bootstrap";

/**
 * Filtering Requirements:
 *  only able to see reassigned items when clear name. More detail in email sent 10 June.
 * seeing reassigned tasks in department when on own user filter
 * 
 * Administrator
 * 
 * Default View:
 * All items assigned to their name
 * All items assigned to their department(s) and location(s) that have not been assigned to anyone
 * 
 * Unfiltered View:
 * All items assigned to their location(s) (all departments), regardless of whether or not they have been assigned to anyone
 * 
 * Manager:
 * Default View:
 * All items assigned to their name
 * All items assigned to their department(s) AND location(s) that have not been assigned to anyone
 * 
 * Unfiltered View:
 * All items assigned to their department(s) AND location(s), regardless of whether or not they have been assigned to anyone
 * 
 * User:
 * Default View:
 * All items assigned to their name
 * 
 * Unfiltered View:
 * All items assigned to their name
 */

class DashboardRow {
  id: number;

  type: string;
  title: string;
  location: string | null;
  formType: string;
  documentName: string;

  createdDate: Date;
  dueDate: Date | null;

  createdAtDateString: string;
  dueDateString: string;
  archivedAtDateString: string;

  isAlert: boolean;
  isWarning: boolean;
  isFinalized: boolean;

  assignedUser: string | null;
  assignedGroup: string | null;

  stage: number;
}

class AprovedDocumentRow {
  id: number;

  documentName: string;
  documentType: string;
  documentCode: string;
  documentIdentity: string;
  documentId: number;

  documentCollectionName: string;
  documentCollectionId: number;

  aprovedAt: Date | null;
  path: string;
  version: number;
}

const className = "DRMDashboardComponent";

@Component({
  selector: 'app-admin',
  templateUrl: './drm-dashboard.component.html',
  styleUrls: ['./drm-dashboard.component.scss'],
})
export class DRMDashboardComponent implements OnInit {

  @ViewChild('userSelect') userSelectorRef: UserSelectComponent;

  @ViewChild('tabSet') tabset: NgbTabset;

  public dashboardRecords: DashboardRow[] = [];
  public aprovedDocuments: AprovedDocumentRow[] = [];

  public alertCount: number = 0;
  public warningCount: number = 0;
  public taskCount: number = 0;
  public notificationCount: number = 0;
  public totalDocuments: number;
  public totalForms: number;
  public tab: 'Search' | 'Tasks' = 'Tasks';

  public formsQueryParams: IFormsQueryParams = {
    skip: 0,
    limit: 10,
    sortBy: 'dueAt',
    order: 'asc',
    assignedUserId: undefined,
    locationId: undefined,
    showAll: 'active',
    dueFilter: undefined,
    createdAtStart: undefined,
    createdAtEnd: undefined,
    dueAtStart: undefined,
    dueAtEnd: undefined,
    search: undefined,
    category: undefined,
  };

  public documentInstanceQueryParams: IDocumentInstanceParams = {
    skip: 0,
    limit: 10,
    sortBy: 'dueAt',
    order: 'asc',
    locationId: undefined,
    lastUpdatedAtStart: undefined,
    lastUpdatedAtEnd: undefined,
    search: undefined,
    category: undefined,
    standard: undefined,
    tags: undefined,
    metaTags: '',
    metaTagValue: ''
  };

  public documentStandard = new FormField<String>('', {});

  public currentPage = 1;
  public pageSize = this.pss.pageSize;

  public isAdmin: boolean;
  public isAdminOrManager: boolean;
  public currentUserId: number | null;
  public defaultAssignedUserId: string;
  public defaultAssignedGroupId: string;
  public defaultAssignedLocationId: string;

  public showAllOptions = [
    { value: 'all', text: 'All' },
    { value: 'complete', text: 'Complete' },
    { value: 'active', text: 'Active' },
  ];

  public searchFilter: string = '';
  public originatorFilter: string = '';

  public dateFilterMap = {
    none: 'Filter By Date',
    createdAt: 'Created At',
    dueAt: 'Due Date',
  };

  public metaTag: string;
  public metaTagValueModel: any = '';

  public selectedDateFilter: string = 'none';

  public selectedCategoryFilter: ICategoryOutputModel | null = null;

  public filterStartDate: string = '';
  public filterEndDate: string = '';
  public formCategories: Observable<ICategoryOutputModel[]>;
  public currentMetaTag = { id: '', value: '', name: '' };
  public metaTags: any = [];
  public includeArchived: boolean = false;

  constructor(
    public formService: FormService,
    private categoryService: CategoryService,
    public session: Session,
    public router: Router,
    public currentUserService: CurrentUserService,
    private route: ActivatedRoute,
    private pss: PersistentStorageService,
    private truncatePipe: TruncatePipe,
    private errorHandler: ErrorHandlerService,
    private reportService: ReportService,
    private documentService: DocumentIntanceService,
  ) {
    const signature = className + ".constructor: ";
    logger.silly(signature + 'Started');
  }

  ngOnInit() {
    const signature = className + ".ngOnInit: ";
    logger.silly("Dashboard Component Init");
    this.isAdmin = this.currentUserService.isAdministrator.getValue();
    this.isAdminOrManager = this.isAdmin || this.currentUserService.userData!.role === 'manager';

    logger.silly(signature + `isAdminOrManager[${this.isAdminOrManager}]`);
    if (this.isAdminOrManager) {
      this.currentUserId = this.currentUserService.currentUserId.getValue();
      this.defaultAssignedUserId = this.currentUserId!.toString();

      this.defaultAssignedGroupId =
        (this.currentUserService.currentUserData.value!.groups || [])
          .map(group => group.id.toString())
          .join(",");

      this.defaultAssignedLocationId =
        (this.currentUserService.currentUserData.value!.locations || [])
          .map(location => location.id.toString())
          .join(",");

      logger.silly(signature + `defaultAssignedLocationId[${this.defaultAssignedLocationId}] defaultAssignedGroupId[${this.defaultAssignedGroupId}] defaultAssignedUserId[${this.defaultAssignedUserId}]`);
    }
    if (this.isAdmin) {
      this.showAllOptions.splice(2, 0, { value: 'deleted', text: 'Deleted' });
    }

    const allowedShowAllValues: string[] = this.showAllOptions.map(v => v.value);

    /**
     * all filtering, pagination should go through query params
     */
    this.route.queryParams.subscribe( queryParams => {
      logger.silly(signature + `QueryParams[${JSON.stringify(queryParams)}] Changed`);
      /**
       * if page param is undefined push default query params
       */
      if (!queryParams.page || !queryParams.category) {
        this.pushDefaultQueryParams();
        return;
      }

      if (queryParams.category) {
        this.formsQueryParams.category = queryParams.category;
      }

      this.currentPage = parseInt(queryParams.page, 10);
      logger.silly(signature + `Set currentPage[${this.currentPage}]`);
      // Defer setting the current page due to an observed race condition occurring occasionally
      setTimeout(() => {
        this.currentPage = parseInt(queryParams.page, 10);
        logger.silly(signature + `(Deferred) Set currentPage[${this.currentPage}]`);
      }, 200);

      if (isNaN(this.currentPage) || this.currentPage <= 0) {
        this.pushDefaultQueryParams();
        return;
      }

      if (this.isAdminOrManager) {
        if (queryParams.assignedUser) {
          this.formsQueryParams.assignedUserId = parseInt(queryParams.assignedUser, 10) || undefined;
          if (!this.userSelectorRef && this.formsQueryParams.assignedUserId) { // fix for recovering user filter after page refresh
            this.defaultAssignedUserId = this.formsQueryParams.assignedUserId.toString();
          } else if (
            this.formsQueryParams.assignedUserId &&
            (this.userSelectorRef.selectedUserId ? this.userSelectorRef.selectedUserId.toString() : '') !== (this.formsQueryParams.assignedUserId ? this.formsQueryParams.assignedUserId.toString() : '')) {
            this.userSelectorRef.changeSelectedUser(this.formsQueryParams.assignedUserId);
          }
        }

        logger.silly(`Processing params.assignedUser[(${typeof queryParams.assignedUser}) ${queryParams.assignedUser}] into assignedUserId[${this.formsQueryParams.assignedUserId}]`);

        /**
         * This cannot rely on a truthy assertion as params.group
         * will return false when it is a blank string
         */
        if (typeof queryParams.group === 'string') {
          this.formsQueryParams.groupId = queryParams.group;
        } else {
          logger.silly('Setting queryParams.groupId to defaultAssignedGroupId');
          this.formsQueryParams.groupId = this.defaultAssignedGroupId;
        }
        logger.silly(`Processing params.group[(${typeof queryParams.group}) ${queryParams.group}] into groupId[${this.formsQueryParams.groupId}]`);

        if (queryParams.showAll && allowedShowAllValues.includes(queryParams.showAll)) {
          this.formsQueryParams.showAll = queryParams.showAll;
        } else {
          this.formsQueryParams.showAll = 'active';
        }
      }

      if (queryParams.sortBy) {
        this.formsQueryParams.sortBy = queryParams.sortBy;
      } else {
        this.formsQueryParams.sortBy = 'dueAt';
      }

      if (queryParams.order) {
        this.formsQueryParams.order = queryParams.order;
      } else {
        this.formsQueryParams.order = 'asc';
      }

      let startDateFound = false;
      let endDateFound = false;

      ['createdAtStart', 'createdAtEnd', 'dueAtStart', 'dueAtEnd'].forEach(dateParam => {
        this.formsQueryParams[dateParam] = queryParams[dateParam] || undefined;
        if (queryParams[dateParam]) {

          if (dateParam.startsWith('createdAt')) {
            this.selectedDateFilter = 'createdAt';
          } else if (dateParam.startsWith('dueAt')) {
            this.selectedDateFilter = 'dueAt';
          }

          if (dateParam.endsWith('Start')) {
            startDateFound = true;
            this.filterStartDate = queryParams[dateParam].replace(/-/g, '/');
          } else if (dateParam.endsWith('End')) {
            endDateFound = true;
            this.filterEndDate = queryParams[dateParam].replace(/-/g, '/');
          }
        }
      });

      if (!startDateFound) {
        this.filterStartDate = '';
      }

      if (!endDateFound) {
        this.filterEndDate = '';
      }

      if (!startDateFound && !endDateFound) {
        this.selectedDateFilter = 'none';
      }

      if (queryParams.showOnly && ['overdue', 'alert', 'remaining'].includes(queryParams.showOnly)) {
        this.formsQueryParams.dueFilter = queryParams.showOnly;
      } else {
        delete this.formsQueryParams.dueFilter;
      }

      if (queryParams.search && queryParams.search.trim()) {
        this.formsQueryParams.search = queryParams.search.trim();
        this.searchFilter = queryParams.search.trim();
      }

      if (queryParams.originatorName && queryParams.originatorName.trim()) {
        this.formsQueryParams.originatorName = queryParams.originatorName.trim();
        this.originatorFilter = queryParams.originatorName.trim();
      }

      if (queryParams.reportFormType) {
        this.formsQueryParams.reportFormType = parseInt(queryParams.reportFormType, 10) || undefined;
      } else {
        delete this.formsQueryParams.reportFormType;
      }

      if (queryParams.includeArchived) {
        this.documentInstanceQueryParams.includeArchived = queryParams.includeArchived === 'true' ? true : false;
      }

      this.formsQueryParams.skip = (this.currentPage - 1) * this.pageSize;
      this.formsQueryParams.limit = this.pageSize;

      this.documentInstanceQueryParams.skip = (this.currentPage - 1) * this.pageSize;
      this.documentInstanceQueryParams.limit = this.pageSize;
      this.loadForms();
      this.loadDocuments();
    });
  }

  public handlePageChange() {
    const signature = className + '.handlePageChange: ';
    logger.silly(signature + 'Started');
    this.pushQueryParams();
  }

  public handlePageChangeDocumentSearch() {
    this.pushDocumentInstanceQueryParams();
  }

  public handlePageSizeChange(pageSize: number) {
    const signature = className + '.handlePageSizeChange: ';
    this.pageSize = pageSize;
    if (this.currentPage === 1) {
      this.formsQueryParams.skip = 0;
      this.formsQueryParams.limit = this.pageSize;
      this.loadForms();
    } else {
      logger.silly(signature + `Set currentPage[${this.currentPage}]`);
      this.currentPage = 1;
      this.handlePageChange();
    }
  }

  public handlePageSizeChangeDocumentSearch(pageSize: number) {
    const signature = className + '.handlePageSizeChangeDocumentSearch: ';
    this.pageSize = pageSize;
    if (this.currentPage === 1) {
      this.documentInstanceQueryParams.skip = 0;
      this.documentInstanceQueryParams.limit = this.pageSize;
      this.loadDocuments();
    } else {
      logger.silly(signature + `Set currentPage[${this.currentPage}]`);
      this.currentPage = 1;
      this.handlePageChangeDocumentSearch();
    }
  }

  private pushQueryParams() {
    const signature = className + ".pushQueryParams: ";

    if (this.tab !== 'Tasks') {
      logger.info(signature + `Ignoring due to Tab[${this.tab}]`);
      return;
    }

    const queryParams = stringifyObjectValues({
      page: this.currentPage,
      assignedUser: this.isAdminOrManager ? this.formsQueryParams.assignedUserId : undefined,
      group: this.isAdminOrManager ? (this.formsQueryParams.groupId || undefined) : undefined,
      showAll: this.isAdminOrManager ? (this.formsQueryParams.showAll || undefined) : undefined,
      showOnly: this.formsQueryParams.dueFilter || undefined,
      sortBy: this.formsQueryParams.sortBy,
      order: this.formsQueryParams.order,
      createdAtStart: this.formsQueryParams.createdAtStart || undefined,
      createdAtEnd: this.formsQueryParams.createdAtEnd || undefined,
      dueAtStart: this.formsQueryParams.dueAtStart || undefined,
      dueAtEnd: this.formsQueryParams.dueAtEnd || undefined,
      search: this.formsQueryParams.search || undefined,
      category: this.formsQueryParams.category || undefined,
    });

    if (this.isAdminOrManager &&
      typeof this.formsQueryParams.groupId === 'string' &&
      this.formsQueryParams.groupId.length === 0
    ) queryParams.group = '';

    logger.silly("Pushing QueryParams", queryParams);
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: '',
    });
  }

  private pushDefaultQueryParams() {
    const signature = className + ".pushDefaultQueryParams: ";
    logger.silly(signature + `Started`);
    // Setting DRM-Task as default category
    this.categoryService.getCategories().subscribe(async categories => {
      const drmTaskCategory = categories.find(cat => cat.name === 'DRM-Task');

      const paramValues = {
        page: 1,
        assignedUser: this.defaultAssignedUserId,
        showAll: this.isAdminOrManager ? 'active' : undefined,
        sortBy: 'dueAt',
        order: 'asc',
        category: drmTaskCategory && drmTaskCategory.id
      };

      logger.silly(signature + `Appending Default QueryParams[${JSON.stringify(paramValues)}] to Url`);
      const queryParams = stringifyObjectValues(paramValues);
      await this.router.navigate(['.'], {
        relativeTo: this.route,
        replaceUrl: true,
        queryParams,
        queryParamsHandling: '',
      });

      logger.silly(signature + `Completed`);
    });
  }

  public handleUserFilterChange(selected) {
    const signature = className + ".handleUserFilterChange: ";
    if (!selected || selected.length === 0) {
      delete this.formsQueryParams.assignedUserId;
    } else {
      this.formsQueryParams.assignedUserId = selected[0].id;
    }
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushQueryParams();
  }

  public handleShowAllFilterChange(option: 'all' | 'complete' | 'deleted' | 'active') {
    const signature = className + ".handleShowAllFilterChange: ";
    this.formsQueryParams.showAll = option;
    delete this.formsQueryParams.dueFilter;

    /**
     * if sorting by archived date was active and a user switched to active filter, turn sort by dueAt as default
     */
    if (this.formsQueryParams.sortBy === 'archivedAt' && option === 'active') {
      this.formsQueryParams.sortBy = 'dueAt';
      this.formsQueryParams.order = 'asc';
    }
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushQueryParams();
  }

  public handleGroupFilterChange(selected: { id: string }[]) {
    const signature = className + ".handleGroupFilterChange: ";
    console.log('event: handleGroupFilterChange');
    if (!selected || selected.length === 0) {
      logger.silly("Updated QueryParam: groupId: [blank string]");
      this.formsQueryParams.groupId = '';
    } else {
      const oldValue = this.formsQueryParams.groupId;
      const newValue = selected.map(item => item.id).filter(id => id && id.length).join(",");
      logger.silly(`Updated QueryParam: groupId: Old[${oldValue}] New[${newValue}]`);
      this.formsQueryParams.groupId = newValue;
    }
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushQueryParams();
  }

  public handleCategoryFilterChange(selected) {
    const signature = className + ".handleCategoryFilterChange: ";
    if (!selected || selected.length === 0) {
      this.formsQueryParams.category = undefined;
    } else {
      this.formsQueryParams.category = selected[0].id;
    }
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushQueryParams();
  }

  public handleReportFormTypeChange(selected) {
    if (!selected || selected.length === 0) {
      this.formsQueryParams.reportFormType = undefined;
    } else {
      this.formsQueryParams.reportFormType = selected[0].id;
    }
    this.currentPage = 1;
    this.pushQueryParams();
  }

  public loadForms() {
    this.session.lockInputRx(this.formService.getForms(this.formsQueryParams))
      .subscribe((data: FormsPaginationResponse) => {

        let dashboardRecords: DashboardRow[] = [];

        this.totalForms = data.totalCount;

        data.items.forEach(formItem => {
          let formType: string = formItem["category"]["name"];
          if (formType === 'Report') formType = 'Feedback';
          const createdDate: Date = new Date(formItem["createdAt"]);
          const dueDate: Date | null = formItem["dueAt"] ? new Date(formItem["dueAt"] as string) : null;
          const title = `${this.truncatePipe.transform(formItem.summary || 'unknown')}`;

          dashboardRecords.push({
            title,
            formType,
            createdDate,
            dueDate,
            type: formType,
            isAlert: !!formItem.isOverdue,
            isWarning: !!formItem.isAlertOverdue,
            isFinalized: !!formItem.archivedAt,
            id: formItem["id"],
            stage: formItem["stage"],
            archivedAtDateString: formItem["archivedAt"] ? moment(formItem["archivedAt"]).tz(environment.timeZone).format("DD-MM-YY") : '',
            createdAtDateString: moment(createdDate).tz(environment.timeZone).format("DD-MM-YY"),
            dueDateString: dueDate ? moment(dueDate).tz(environment.timeZone).format("DD-MM-YY") : '',
            assignedUser: formItem.assignedUser ? `${formItem.assignedUser.firstName} ${formItem.assignedUser.lastName} ` : null,
            assignedGroup: formItem.userGroup ? `${formItem.userGroup.groupName}` : null,
            location: formItem.formLocation ? formItem.formLocation.name : 'All Locations',
            documentName: formItem.document && formItem.document.documentCode || ''
          });
        });

        this.dashboardRecords = dashboardRecords;
        this.taskCount = data.remainingTasks;
        this.warningCount = data.alertTasks;
        this.alertCount = data.overdueTasks;
      },
        err => {
          console.error('Error while getting forms in dashboard', err);
          this.errorHandler.handleHttpError(err);
        });
  }

  public progressForm(row: DashboardRow) {
    if (row.formType === 'Report' || row.formType === 'Feedback') {
      this.router.navigateByUrl(`/report/${row.id}`);
    } else if (row.formType === 'Follow-Up') {
      this.router.navigateByUrl(`/followUp/${row.id}`);
    } else if (row.formType === 'Audit') {
      this.router.navigateByUrl(`/audit/${row.id}`);
    } else if (row.formType === 'DRM-Task') {
      this.router.navigateByUrl(`/document-review-task/${row.id}`);
    } else {
      console.error(`Unknown Form Type ${row.formType}. Unable to navigate`);
    }
  }

  public toggleTopCardFilter(filter: "overdue" | "alert" | "remaining") {
    const signature = className + ".toggleTopCardFilter: ";
    if (this.formsQueryParams.dueFilter === filter) {
      this.formsQueryParams.dueFilter = undefined;
    } else {
      this.formsQueryParams.dueFilter = filter;
    }
    this.formsQueryParams.showAll = 'active'; 
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushQueryParams();
  }

  public handleSortChange(sortField: DRMDashboardComponent["formsQueryParams"]["sortBy"]) {
    if (sortField !== this.formsQueryParams.sortBy) {
      this.formsQueryParams.sortBy = sortField;
      this.formsQueryParams.order = 'asc';
    } else {
      this.formsQueryParams.order = this.formsQueryParams.order === 'asc' ? 'desc' : 'asc';
    }
    this.pushQueryParams();
  }

  public handleDateFilterChange(value: string) {
    const signature = className + ".handleDateFilterChange: ";
    this.selectedDateFilter = value;
    this.filterStartDate = '';
    this.filterEndDate = '';
    this.formsQueryParams.createdAtStart = undefined;
    this.formsQueryParams.createdAtEnd = undefined;
    this.formsQueryParams.dueAtStart = undefined;
    this.formsQueryParams.dueAtEnd = undefined;
    if (value === 'none') {
      this.currentPage = 1;
      logger.silly(signature + `Set currentPage[${this.currentPage}]`);
      this.pushQueryParams();
    }
  }

  public handleStartDateChanged() {
    const signature = className + ".handleStartDateChanged: ";
    this.formsQueryParams[this.selectedDateFilter + 'Start'] = this.filterStartDate.replace(/\//g, '-'); 
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushQueryParams();
  }

  public handleEndDateChanged() {
    const signature = className + ".handleEndDateChanged: ";
    this.formsQueryParams[this.selectedDateFilter + 'End'] = this.filterEndDate.replace(/\//g, '-'); 
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushQueryParams();
  }

  public performSearch() {
    const signature = className + ".performSearch: ";
    if (this.searchFilter && this.searchFilter.trim()) {
      this.formsQueryParams.search = this.searchFilter.trim();
    } else {
      this.formsQueryParams.search = undefined;
    }

    if (this.originatorFilter && this.originatorFilter.trim()) {
      this.formsQueryParams.originatorName = this.originatorFilter.trim();
    } else {
      this.formsQueryParams.originatorName = undefined;
    }
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushQueryParams();
  }

  public clearSearch() {
    const signature = className + ".clearSearch: ";
    this.searchFilter = '';
    this.formsQueryParams.search = undefined;
    this.documentInstanceQueryParams.search = undefined; 
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushQueryParams();
  }

  public handleCsvExport() {
    this.reportService.downloadDRMDashboardCsvReport(this.formsQueryParams).subscribe((data) => {
      saveAs(data, `drm-dashboard-report-${moment().format('YYYY-MMM-DD')}.csv`);
    }, err => this.errorHandler.handleHttpError(err));
  }

  public handleCategoryFilter(category: ICategoryOutputModel | null) {

    this.selectedCategoryFilter = category;
    this.formsQueryParams.category = category ? category.id : undefined;
    this.pushQueryParams();

  }

  public mapCategoryName(name: string) {
    if (name.toLowerCase() === 'report') return "Feedback";

    return name;
  }

  public loadDocuments() {
    this.documentService.getAllDocuments(this.documentInstanceQueryParams).subscribe(data => {

      let aprovedDocuments: AprovedDocumentRow[] = [];

      this.totalDocuments = data.length;

      data.forEach((documentInstance: IDocumentInstanceOutputType) => {
        const id = documentInstance.id,
          documentName = documentInstance.document.name,
          documentCode = (documentInstance.document.documentCode),
          documentIdentity = (documentInstance.document.documentIdentity),
          documentId = Number(documentInstance.document.id),
          documentType = documentInstance.document.type,
          documentCollectionName = documentInstance.document.documentCollection.name,
          documentCollectionId = Number(documentInstance.document.documentCollectionId),
          aprovedAt = documentInstance.aprovedAt,
          path = documentInstance.path,
          version = documentInstance.version;

        aprovedDocuments.push({
          id,
          documentName,
          documentCode,
          documentIdentity,
          documentId,
          documentType,
          documentCollectionName,
          documentCollectionId,
          aprovedAt,
          path,
          version
        });
      });

      this.aprovedDocuments = aprovedDocuments;
    });
  }

  public downloadDocument(id: number, formName: string) {
    this.documentService.downloadDocument(id).subscribe(res => {
      saveAs(res, formName);
    });
  }

  public onTabChange(event) {
    const signature = className + ".onTabChange: ";
    logger.silly(signature + "Started");
    if (event && event.nextId === 'Search') {
      this.clearSearch();
      this.tab = 'Search';
      this.pushDocumentInstanceQueryParams();
    } else {
      this.clearSearch();
      this.tab = 'Tasks';
      this.pushQueryParams();
    }
  }

  public performDocumentSearch() {
    const signature = className + ".performDocumentSearch: ";
    if (this.searchFilter && this.searchFilter.trim()) {
      this.documentInstanceQueryParams.search = this.searchFilter.trim();
    } else {
      this.documentInstanceQueryParams.search = undefined;
    }
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushDocumentInstanceQueryParams();
  }

  public pushDocumentInstanceQueryParams() {
    const signature = className + ".pushDocumentInstanceQueryParams: ";

    if (!this.isCurrentTab('Search')) {
      logger.info(signature + `Ignoring due to Tab[${this.tab}]`);
      return;
    }

    const queryParamData = {
      page: this.currentPage,
      sortBy: this.formsQueryParams.sortBy,
      order: this.formsQueryParams.order,
      lastUpdatedAtStart: this.documentInstanceQueryParams.lastUpdatedAtStart || undefined,
      lastUpdatedAtEnd: this.documentInstanceQueryParams.lastUpdatedAtEnd || undefined,
      search: this.documentInstanceQueryParams.search || undefined,
      standard: this.documentInstanceQueryParams.standard || undefined,
      category: this.formsQueryParams.category || undefined,
      tags: this.documentInstanceQueryParams.tags || undefined,
      groupId: this.documentInstanceQueryParams.groupId || undefined,
      metaTags: this.documentInstanceQueryParams.metaTags || undefined,
      metaTagValue: this.documentInstanceQueryParams.metaTagValue || undefined,
      includeArchived: this.documentInstanceQueryParams.includeArchived,
      tab: 'Search'
    };
    const queryParams = stringifyObjectValues(queryParamData);

    logger.silly(signature + `Pushing DocumentInstanceQueryParams[${JSON.stringify(queryParamData)}]`);
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: '',
    });

    logger.silly(signature + 'Completed');
  }

  public handleStandardFilterChange(event) {
    const signature = className + ".handleStandardFilterChange: ";
    logger.silly(signature + "Started");
    if (!event || event.length === 0) {
      this.documentInstanceQueryParams.standard = '';
    } else {
      this.documentInstanceQueryParams.standard = event[0].id;
    }
    this.pushDocumentInstanceQueryParams();
  }

  public handleTagFilterChange(selected: { id: string }[]) {
    const signature = className + ".handleTagFilterChange: ";

    if (!this.isCurrentTab('Search')) {
      logger.info(signature + `Ignoring due to Tab[${this.tab}]`);
      return;
    }

    logger.silly(signature + "Started");
    if (!selected || selected.length === 0) {
      logger.silly("Updated QueryParam: tags: [blank string]");
      this.documentInstanceQueryParams.tags = '';
    } else {
      const oldValue = this.documentInstanceQueryParams.tags;
      const newValue = selected.map(item => item.id).filter(id => id && id.length).join(",");
      logger.silly(`Updated QueryParam: tags: Old[${oldValue}] New[${newValue}]`);
      this.documentInstanceQueryParams.tags = newValue;
    }
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushDocumentInstanceQueryParams();
  }

  public handleDocumentGroupFilterChange(selected: { id: string }[]) {
    const signature = className + ".handleDocumentGroupFilterChange: ";
    logger.silly(signature + "Started");
    if (!selected || selected.length === 0) {
      logger.silly("Updated QueryParam: userGroup: [blank string]");
      this.documentInstanceQueryParams.groupId = '';
    } else {
      const oldValue = this.documentInstanceQueryParams.groupId;
      const newValue = selected.map(item => item.id).filter(id => id && id.length).join(",");
      logger.silly(`Updated QueryParam: userGroup: Old[${oldValue}] New[${newValue}]`);
      this.documentInstanceQueryParams.groupId = newValue;
    }
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushDocumentInstanceQueryParams();
  }

  public lastUpdatedAtFromFilterChange(event) {
    const signature = className + ".lastUpdatedAtFromFilterChange: ";
    logger.silly(signature + "Started");
    if (event === "") {
      return
    }
    this.documentInstanceQueryParams.lastUpdatedAtStart = event; 
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushDocumentInstanceQueryParams();
  }

  public lastUpdatedAtEndFilterChange(event) {
    const signature = className + ".lastUpdatedAtEndFilterChange: ";
    logger.silly(signature + "Started");
    if (event === "") {
      return
    }
    this.documentInstanceQueryParams.lastUpdatedAtEnd = event; 
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushDocumentInstanceQueryParams();
  }

  public handleMetaChange(event) {
    this.currentMetaTag.id = event[0].id;
    this.currentMetaTag.name = event[0].text;
  }

  public addNewMeta() {
    const signature = className + ".addNewMeta: ";
    logger.silly(signature + "Started");
    this.currentMetaTag.value = this.metaTagValueModel;
    this.metaTags.push({ id: this.currentMetaTag.id, value: this.metaTagValueModel, name: this.currentMetaTag.name });
    this.metaTagValueModel = '';
    this.metaTag = '';

    this.documentInstanceQueryParams.metaTags = !!this.metaTags.length ? this.metaTags.map(tag => tag.id).join() : undefined,
      this.documentInstanceQueryParams.metaTagValue = !!this.metaTags.length ? this.metaTags.map(tag => tag.value).join() : undefined
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushDocumentInstanceQueryParams();
  }

  public removeMeta(index) {
    const signature = className + ".removeMeta: ";
    logger.silly(signature + "Started");
    this.metaTags.splice(index, 1);
    this.documentInstanceQueryParams.metaTags = !!this.metaTags.length ? this.metaTags.map(tag => tag.id).join() : undefined,
      this.documentInstanceQueryParams.metaTagValue = !!this.metaTags.length ? this.metaTags.map(tag => tag.value).join() : undefined
    this.currentPage = 1;
    logger.silly(signature + `Set currentPage[${this.currentPage}]`);
    this.pushDocumentInstanceQueryParams();
  }

  public handleDocumentRowClick(document) {
    this.router.navigateByUrl(`/document-collections/${document.documentCollectionId}/documents/${document.documentId}`)
  }

  public handleIncludeArchivedToggle() {
    const signature = className + ".handleIncludeArchivedToggle: ";
    logger.silly(signature + "Started");
    this.includeArchived = !this.includeArchived
    this.documentInstanceQueryParams.includeArchived = this.includeArchived;
    this.pushDocumentInstanceQueryParams();
  }

  private isCurrentTab(currentTab: DRMDashboardComponent['tab']): boolean {
    const signature = className + ".isCurrentTab: ";
    logger.silly(signature + "Started");

    return this.tab === currentTab;
  }
} 
