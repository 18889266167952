/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/formDueAt.component.ngfactory";
import * as i2 from "../../../../shared/formDueAt.component";
import * as i3 from "../../../../../service/currentUser/CurrentUserService";
import * as i4 from "../../../../../service/ModalService";
import * as i5 from "../../../../../service/FormService";
import * as i6 from "../../../../../service/ErrorHandlerService";
import * as i7 from "@angular/common";
import * as i8 from "./documentReviewFormStage4.component";
var styles_DocumentReviewFormStage4Component = [];
var RenderType_DocumentReviewFormStage4Component = i0.ɵcrt({ encapsulation: 2, styles: styles_DocumentReviewFormStage4Component, data: {} });
export { RenderType_DocumentReviewFormStage4Component as RenderType_DocumentReviewFormStage4Component };
function View_DocumentReviewFormStage4Component_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12 col-md-6 col-lg-6 col-xl-6 pt-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "form-due-at", [], null, null, null, i1.View_FormDueAtComponent_0, i1.RenderType_FormDueAtComponent)), i0.ɵdid(2, 114688, null, 0, i2.FormDueAtComponent, [i3.CurrentUserService, i4.ModalService, i5.FormService, i6.ErrorHandlerService], { formData: [0, "formData"], inline: [1, "inline"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formData; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_DocumentReviewFormStage4Component_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { vc: 0 }), (_l()(), i0.ɵeld(1, 16777216, [[1, 3], ["vc", 1]], null, 0, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "row d-flex flex-row-reverse"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentReviewFormStage4Component_1)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isReportEnabled; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_DocumentReviewFormStage4Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "document-review-form-4", [], null, null, null, View_DocumentReviewFormStage4Component_0, RenderType_DocumentReviewFormStage4Component)), i0.ɵdid(1, 114688, null, 0, i8.DocumentReviewFormStage4Component, [i0.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentReviewFormStage4ComponentNgFactory = i0.ɵccf("document-review-form-4", i8.DocumentReviewFormStage4Component, View_DocumentReviewFormStage4Component_Host_0, { formData: "formData" }, {}, []);
export { DocumentReviewFormStage4ComponentNgFactory as DocumentReviewFormStage4ComponentNgFactory };
