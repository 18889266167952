/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./childFormsTree.component";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../pipes/truncate.pipe";
import * as i5 from "../../service/CategoryService";
var styles_ChildFormsTreeNode = [".tree-node[_ngcontent-%COMP%] {\n        line-height: 2;\n    }\n    .children-container[_ngcontent-%COMP%] {\n        padding-left: 20px;\n    }"];
var RenderType_ChildFormsTreeNode = i0.ɵcrt({ encapsulation: 0, styles: styles_ChildFormsTreeNode, data: {} });
export { RenderType_ChildFormsTreeNode as RenderType_ChildFormsTreeNode };
function View_ChildFormsTreeNode_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "children-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "child-forms-tree", [], null, null, null, View_ChildFormsTreeComponent_0, RenderType_ChildFormsTreeComponent)), i0.ɵdid(2, 49152, null, 0, i1.ChildFormsTreeComponent, [], { childForms: [0, "childForms"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.treeNode.childForms; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ChildFormsTreeNode_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "tree-node"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(3, 2), (_l()(), i0.ɵted(4, null, ["", ":\u00A0", "\u00A0\u00A0 "])), i0.ɵppd(5, 2), (_l()(), i0.ɵeld(6, 0, null, null, 2, "i", [["class", "fa"]], null, null, null, null, null)), i0.ɵdid(7, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(8, { "fa-pencil": 0, "fa-check": 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChildFormsTreeNode_2)), i0.ɵdid(10, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, _co.routerLinkBase, _co.treeNode.id); _ck(_v, 2, 0, currVal_2); var currVal_5 = "fa"; var currVal_6 = _ck(_v, 8, 0, !_co.treeNode.archivedAt, !!_co.treeNode.archivedAt); _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_7 = (_co.treeNode.childForms && _co.treeNode.childForms.length); _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.displayText; var currVal_4 = i0.ɵunv(_v, 4, 1, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _co.treeNode.summary, 80)); _ck(_v, 4, 0, currVal_3, currVal_4); }); }
export function View_ChildFormsTreeNode_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i4.TruncatePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChildFormsTreeNode_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.treeNode; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChildFormsTreeNode_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "child-forms-tree-node", [], null, null, null, View_ChildFormsTreeNode_0, RenderType_ChildFormsTreeNode)), i0.ɵdid(1, 114688, null, 0, i1.ChildFormsTreeNode, [i5.CategoryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChildFormsTreeNodeNgFactory = i0.ɵccf("child-forms-tree-node", i1.ChildFormsTreeNode, View_ChildFormsTreeNode_Host_0, { treeNode: "treeNode" }, {}, []);
export { ChildFormsTreeNodeNgFactory as ChildFormsTreeNodeNgFactory };
var styles_ChildFormsTreeComponent = [""];
var RenderType_ChildFormsTreeComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ChildFormsTreeComponent, data: {} });
export { RenderType_ChildFormsTreeComponent as RenderType_ChildFormsTreeComponent };
function View_ChildFormsTreeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "child-forms-tree-node", [], null, null, null, View_ChildFormsTreeNode_0, RenderType_ChildFormsTreeNode)), i0.ɵdid(1, 114688, null, 0, i1.ChildFormsTreeNode, [i5.CategoryService], { treeNode: [0, "treeNode"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChildFormsTreeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChildFormsTreeComponent_2)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.childForms; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChildFormsTreeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChildFormsTreeComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.childForms; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ChildFormsTreeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "child-forms-tree", [], null, null, null, View_ChildFormsTreeComponent_0, RenderType_ChildFormsTreeComponent)), i0.ɵdid(1, 49152, null, 0, i1.ChildFormsTreeComponent, [], null, null)], null, null); }
var ChildFormsTreeComponentNgFactory = i0.ɵccf("child-forms-tree", i1.ChildFormsTreeComponent, View_ChildFormsTreeComponent_Host_0, { childForms: "childForms" }, {}, []);
export { ChildFormsTreeComponentNgFactory as ChildFormsTreeComponentNgFactory };
