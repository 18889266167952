import { ComponentFactoryResolver, ViewContainerRef, OnInit } from "@angular/core";
import { AuditFormStage0Component } from "../stage0/auditFormStage0.component";
import { AuditFormStage1Component } from "../stage1/auditFormStage1.component";
import { AuditFormStage2Component } from "../stage2/auditFormStage2.component";
var stageFormMap = {
    0: AuditFormStage0Component,
    1: AuditFormStage1Component,
    2: AuditFormStage2Component
};
/*
  This is the final, read only state for all the stages
*/
var AuditFormStage3Component = /** @class */ (function () {
    function AuditFormStage3Component(factoryResolver) {
        this.factoryResolver = factoryResolver;
        this.isReportEnabled = false;
    }
    Object.defineProperty(AuditFormStage3Component.prototype, "formData", {
        get: function () {
            return this._formData;
        },
        /** this is fix for navigation via child forms tree
         *  since this component might not be unmounted we need to clear all dynamic (read-only) forms
         **/
        set: function (newFormData) {
            this._formData = newFormData;
            this.isReportEnabled = false;
            this.vc.clear();
            this.addReadOnlyForms();
        },
        enumerable: true,
        configurable: true
    });
    AuditFormStage3Component.prototype.ngOnInit = function () {
        // VC Clear added so that if this occurs after formdata is set, the output doesn't double up
        this.vc.clear();
        this.addReadOnlyForms();
    };
    AuditFormStage3Component.prototype.addReadOnlyForms = function () {
        var _this = this;
        if (!this.formData || !this.formData.records) {
            console.error('form data is not defined');
            return;
        }
        /*
          var err = new Error();
          console.log( err.stack );
          console.log(this.formData.records.filter( record => record.isComplete));
        */
        //Get completed Records
        this.formData.records
            .filter(function (record) { return record.isComplete; })
            .forEach(function (record) { return _this.addReadOnlyForm(record); });
    };
    AuditFormStage3Component.prototype.addReadOnlyForm = function (record) {
        var _component = stageFormMap[record.stage];
        if (!_component) {
            console.error('Cannot find follow-up form component for stage ', record.stage);
            return;
        }
        var factory = this.factoryResolver.resolveComponentFactory(_component);
        var component = this.vc.createComponent(factory);
        // @ts-ignore
        component.instance.readOnly = true;
        // @ts-ignore
        component.instance.formData = this.formData;
        // @ts-ignore
        component.instance.hideHeader = !(record.stage === 0 && this.formData.archivedAt);
        // @ts-ignore
        component.instance.sequence = record.sequence;
        this.vc.insert(component.hostView);
        this.isReportEnabled = true;
    };
    return AuditFormStage3Component;
}());
export { AuditFormStage3Component };
