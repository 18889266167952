import { Component } from "@angular/core";
import { CronOptions, CronModalState } from "../../../service/ModalService";
import "moment-timezone";
import * as moment from 'moment';
import { IScheduleParams } from "../../../../common/contracts/form";
import { environment } from "../../../environments/environment";

/**
 * The component displayed in the confirmation modal opened by the ConfirmService.
 */
@Component({
  selector: 'cron-modal',
  templateUrl: './cronModal.component.html',
  styleUrls: ['./cronModal.component.scss']
})
export class CronModalComponent {
  options: CronOptions;
  constructor(private state: CronModalState) {
    this.options = state.options;
  }

  hasStartDate = () => (
    this.options.startDateField && 
    this.options.startDateField.value &&
    this.options.startDateField.value.length
  );

  parseToStartOfDay = (localDate:string) => 
    moment(localDate, 'DD-MM-YYYY')
    .tz(environment.timeZone)
    .set({
        'hour': 0,
        'minute': 0,
        'second': 0,
        'millisecond': 0
      })
    ;

  yes() {
    const expression = this.options.expression||"";
    const startDateString:string = this.hasStartDate() ? this.options.startDateField.value as string : '';
    const startDate =  this.hasStartDate() ? this.parseToStartOfDay(startDateString) : moment();
    const result:IScheduleParams = {
      expression,
      startAt: startDate.toISOString(false) as string
    };

    this.state.modal.close(result);
  }

  no() {
    this.state.modal.dismiss(null);
  }
}
