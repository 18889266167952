var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import * as moment from 'moment';
import "moment-timezone";
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { DocumentIntanceService } from "service/admin/DocumentInstanceService";
import { DocumentService } from "service/DocumentService";
import { saveAs } from "file-saver";
import { DocumentsService } from "service/admin/DocumentsService";
import { environment } from "../../../../../environments/environment";
import { ModalService } from "service/ModalService";
import { Router } from "@angular/router";
var DocumentReviewFormStage1Component = /** @class */ (function (_super) {
    __extends(DocumentReviewFormStage1Component, _super);
    function DocumentReviewFormStage1Component(session, currentUserService, formRecordService, formService, groupsService, errorHandler, documentInstanceService, documentService, documentDRMService, modalService, router) {
        var _this = _super.call(this, router) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formRecordService = formRecordService;
        _this.formService = formService;
        _this.groupsService = groupsService;
        _this.errorHandler = errorHandler;
        _this.documentInstanceService = documentInstanceService;
        _this.documentService = documentService;
        _this.documentDRMService = documentDRMService;
        _this.modalService = modalService;
        _this.router = router;
        _this.readOnly = false;
        _this.hideHeader = false;
        /*
          This should have been done properly using something that implements FormControl but its
          too late now
         */
        _this.form = {
            reassign: new FormField(false, {
                validation: FormField.ValidationMethods.None
            }),
            //Since this will be assigned to a select it must be a string data - Conversion where appropriate
            reassignToUserId: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            followUps: new FormField('[]', {
                nullEquivilent: "[]",
                validation: function (value) {
                    return _this.followUpWidgetRef ? _this.followUpWidgetRef.validate() : true;
                }
            }),
            note: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            isDocumentStillRequired: new FormField(true, {
                validation: FormField.ValidationMethods.IsNotNull
            })
        };
        _this.uploadedDocument = [];
        _this.hasDraftFromCompletedTasks = false;
        return _this;
    }
    DocumentReviewFormStage1Component.prototype.registerFormFields = function () {
        var _this = this;
        var _a;
        (_a = this.formFields).push.apply(_a, Object.keys(this.form).map(function (k) { return _this.form[k]; }));
    };
    DocumentReviewFormStage1Component.prototype.ngOnInit = function () {
        this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);
        this.registerFormFields();
        this.repopulateFormFromData();
        this.getLatestAprovedDocument();
        this.getLatestDraft();
        this.setDefaultDocument();
    };
    DocumentReviewFormStage1Component.prototype.setDefaultDocument = function () {
        var _this = this;
        if (this.uploadedDocument.length === 0) {
            this.documentDRMService.getDefaultDraftDocument(this.documentId, this.formData ? this.formData.id : null).subscribe(function (data) {
                if (data) {
                    _this.uploadedDocument.push(data);
                }
            });
        }
    };
    DocumentReviewFormStage1Component.prototype.getLastSubmission = function () {
        if (!this.formData || !this.formData.records || !this.formData.records.length)
            return null;
        var submissions = this.formData.records.filter(function (record) { return record.isComplete; });
        if (!submissions || !submissions.length)
            return null;
        return submissions[submissions.length - 1];
    };
    DocumentReviewFormStage1Component.prototype.onSubmit = function (isDraft) {
        if (this.uploadedDocument.length === 0) {
            this.modalService.alert({
                title: 'Alert',
                message: 'Document has no approved versions, please upload a draft to continue.'
            });
            return;
        }
        else {
            this.onFormSubmit(isDraft);
        }
    };
    DocumentReviewFormStage1Component.prototype.onFormSubmit = function (isDraft) {
        var _this = this;
        this.session.lockInput(function () {
            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var success, fail, stage, assignedUserId, userGroupId, lastSubmission, assignBack, reassignProperty, groups, adminGroup, properties;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            success = function () {
                                resolve();
                                _this.closeForm();
                            };
                            fail = function (msg, err) {
                                console.error(msg, err);
                                _this.errorHandler.handleHttpError(err);
                                reject();
                            };
                            assignedUserId = null;
                            userGroupId = this.formData.userGroupId;
                            if (this.currentUserService.userData)
                                assignedUserId = this.currentUserService.userData.id;
                            if (!!isDraft) return [3 /*break*/, 5];
                            if (!(this.form.reassign.value && this.form.reassignToUserId.value)) return [3 /*break*/, 1];
                            stage = 1;
                            assignedUserId = Number(this.form.reassignToUserId.value);
                            return [3 /*break*/, 4];
                        case 1:
                            lastSubmission = this.getLastSubmission();
                            assignBack = false;
                            if (lastSubmission && lastSubmission.stage === 1
                                && lastSubmission.createdById !== assignedUserId) {
                                reassignProperty = lastSubmission.properties.find(function (property) { return property.property.name === 'reassignToUserId'; });
                                if (reassignProperty && reassignProperty.intData === assignedUserId)
                                    assignBack = true;
                            }
                            if (!(lastSubmission && assignBack)) return [3 /*break*/, 2];
                            //Assign the submission back to that user, leaving the form in stage 1
                            stage = 1;
                            assignedUserId = lastSubmission.createdById;
                            return [3 /*break*/, 4];
                        case 2:
                            stage = 2;
                            // Assign it to senior management
                            assignedUserId = null;
                            return [4 /*yield*/, this.groupsService.getGroups().toPromise()];
                        case 3:
                            groups = _a.sent();
                            adminGroup = groups.find(function (group) { return group.groupName.match(/^senior management/gi) !== null; });
                            if (adminGroup)
                                userGroupId = adminGroup.id;
                            else
                                userGroupId = null;
                            _a.label = 4;
                        case 4: return [3 /*break*/, 6];
                        case 5:
                            stage = 1;
                            _a.label = 6;
                        case 6:
                            properties = [];
                            this.formService.updateForm({
                                id: this.formData.id,
                                stage: stage,
                                userGroupId: userGroupId,
                                assignedUserId: assignedUserId
                            })
                                .subscribe(function () {
                                properties.push({
                                    name: "reassign",
                                    intData: _this.form.reassign.value ? 1 : 0
                                });
                                if (_this.form.reassign.value) {
                                    // TODO: This fields validation should change from int > 0 to NONE when reassign is true/false
                                    properties.push({
                                        name: "reassignToUserId",
                                        intData: _this.form.reassign.value ? Number(_this.form.reassignToUserId.value) : null,
                                    });
                                }
                                properties.push({
                                    name: "followUps",
                                    jsonData: _this.form.followUps.value
                                });
                                properties.push({
                                    name: "isDocumentStillRequired",
                                    intData: _this.form.isDocumentStillRequired.value ? 1 : 0
                                });
                                if (_this.form.note.value.length > 0)
                                    properties.push({
                                        name: "note",
                                        stringData: _this.form.note.value
                                    });
                                _this.formRecordService.createRecord({
                                    formId: _this.formData.id,
                                    // Intentionally cast the properties object since we know its correct
                                    properties: properties,
                                    stage: 1,
                                    documents: _this.uploadedDocument.map(function (doc) { return ({ id: doc.id, isTicked: !!doc.isTicked }); }),
                                    isComplete: isDraft ? false : true
                                })
                                    .subscribe(function (data) {
                                    if (!isDraft) {
                                        var params = {
                                            userGroupId: userGroupId,
                                            stage: 2,
                                            createdUserId: _this.formData.createdById,
                                            documentId: _this.documentId,
                                            completedBy: _this.currentUserService.userData ? _this.currentUserService.userData.id : null
                                        };
                                        _this.formService.sendReviewFormNotifications(params).subscribe();
                                    }
                                    success();
                                }, function (err) { return fail('Error creating a record', err); });
                            }, function (err) { return fail('Error updating a form', err); });
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    DocumentReviewFormStage1Component.prototype.repopulateFormFromData = function () {
        var _this = this;
        if (!this.formData || !this.formData.records || !this.formData.records.length)
            return;
        var stageRecords = this.formData.records.filter(function (record) { return record.stage === 1; });
        if (stageRecords.length === 0)
            return;
        if (!this.sequence) {
            var mostRecentRecord_1 = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
            if (!mostRecentRecord_1)
                throw new Error("internal error");
            this.formRecord = mostRecentRecord_1;
        }
        else {
            var targetRecord = stageRecords.find(function (record) { return record.sequence === _this.sequence; });
            if (!targetRecord)
                throw new Error("internal error");
            this.formRecord = targetRecord;
        }
        //Convert the properties into easily accessible IFormRecordPropertyParam
        if (!this.formRecord.properties)
            return;
        this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);
        var simpleProperties = {};
        this.formRecord.properties.forEach(function (recordProperty) {
            //eject invalid property
            if (!recordProperty.property)
                return;
            var result = {
                name: recordProperty.property.name
            };
            if (recordProperty.stringData)
                result.stringData = recordProperty.stringData;
            if (recordProperty.intData)
                result.intData = recordProperty.intData;
            if (recordProperty.jsonData)
                result.jsonData = recordProperty.jsonData;
            if (recordProperty.enumId)
                result.enumId = recordProperty.enumId;
            simpleProperties[result.name] = result;
        });
        stageRecords = this.formData.records.filter(function (record) { return record.stage === 1; });
        var mostRecentRecord = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
        if (!mostRecentRecord)
            return;
        var followUpRecord = mostRecentRecord.properties.find(function (recordProperty) { return recordProperty.property.name === 'followUps'; });
        if (followUpRecord) {
            this.form.followUps.value = followUpRecord.jsonData;
        }
        /*
          If the previous record was not from the current user,
          or if a sequence was specified, we only want the followUps
        */
        if (!this.formRecord.isComplete || this.sequence) {
            if (simpleProperties['reassignToUserId'])
                this.form.reassignToUserId.value = String(simpleProperties['reassignToUserId'].intData);
            if (simpleProperties['reassign']
                && simpleProperties['reassign'].intData !== null
                && simpleProperties['reassign'].intData !== undefined) {
                this.form.reassign.value = (simpleProperties['reassign'].intData > 0);
                this.showReassignOptions(this.form.reassign.value);
            }
            this.uploadedDocument = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
            if (simpleProperties['note'])
                this.form.note.value = simpleProperties['note'].stringData;
        }
        if (simpleProperties['isDocumentStillRequired'])
            this.form.isDocumentStillRequired.value = simpleProperties['isDocumentStillRequired'].intData ? true : false;
        if (simpleProperties['followUps'])
            this.form.followUps.value = simpleProperties['followUps'].jsonData;
    };
    DocumentReviewFormStage1Component.prototype.showReassignOptions = function (state) {
        this.form.reassign.value = state;
        if (state) {
            this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.IsNotBlank);
            this.setFieldValidation(this.form.summary, FormField.ValidationMethods.None);
        }
        else {
            this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.summary, FormField.ValidationMethods.IsNotBlank);
        }
    };
    DocumentReviewFormStage1Component.prototype.getLatestDraft = function () {
        var _this = this;
        this.formService.getFormsFromDocumentId(this.documentId).subscribe(function (data) {
            if (data.length === 0) {
                _this.documentInstanceService.getDraftDocument(_this.documentId).subscribe(function (data) {
                    if (data) {
                        _this.latestDraftRecord = data;
                        _this.hasDraftFromCompletedTasks = true;
                    }
                    else {
                        _this.latestDraftRecord = null;
                    }
                });
            }
            else {
                var formsCreatedAtArray = data.map(function (form) { return new Date(form.createdAt); }), latestFormCreatedAt_1 = new Date(Math.max.apply(null, formsCreatedAtArray)), latestFormRecords_1 = data.find(function (form) { return (new Date(form.createdAt).getTime() === latestFormCreatedAt_1.getTime()); }).records, latestRecord = latestFormRecords_1.find(function (record) { return new Date(record.createdAt).getTime() === new Date(Math.max.apply(null, latestFormRecords_1.filter(function (record) { return !!record.documents.length; }).map(function (record) { return new Date(record.createdAt); }))).getTime(); });
                _this.latestDraftRecord = latestRecord;
            }
        });
    };
    DocumentReviewFormStage1Component.prototype.downloadLatestDraft = function () {
        var _this = this;
        if (this.latestDraftRecord === null) {
            this.downloadLatestAprovedVersion();
        }
        else if (this.hasDraftFromCompletedTasks && this.latestDraftRecord) {
            this.documentInstanceService.downloadDraftDocument(this.latestDraftRecord.id).subscribe(function (data) {
                saveAs(data, _this.latestDraftRecord.originalFileName);
            });
        }
        else {
            this.documentService.downloadDocument(this.latestDraftRecord.documents[0].id).subscribe(function (file) {
                saveAs(file, _this.latestDraftRecord.documents[0].fileName);
            });
        }
    };
    DocumentReviewFormStage1Component.prototype.getLatestAprovedDocument = function () {
        var _this = this;
        if (this.formData) {
            var formPropertiesArray = this.formData.records.map(function (record) { return record.properties; }), flatenedFormPropertiesArray = [].concat.apply([], formPropertiesArray), propertyWithDocumentId = flatenedFormPropertiesArray.find(function (property) { return property.property.name === "documentId"; });
            this.documentId = propertyWithDocumentId.stringData;
        }
        this.documentDRMService.getDocumentInstanceByDocumentId(this.documentId).subscribe(function (file) {
            _this.latestAprovedVersion = file;
        });
    };
    DocumentReviewFormStage1Component.prototype.downloadLatestAprovedVersion = function () {
        var _this = this;
        this.documentInstanceService.downloadDocument(this.latestAprovedVersion.id).subscribe(function (data) {
            saveAs(data, _this.latestAprovedVersion.fileName);
        });
    };
    return DocumentReviewFormStage1Component;
}(FormComponent));
export { DocumentReviewFormStage1Component };
