/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tags.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../shared/pagination.component.ngfactory";
import * as i4 from "../../../../shared/pagination.component";
import * as i5 from "../../../../../service/PersistentStorageService";
import * as i6 from "./tags.component";
import * as i7 from "../../../../../service/admin/TagsService";
import * as i8 from "@angular/router";
import * as i9 from "../../../../../service/ErrorHandlerService";
var styles_TagsComponent = [i0.styles];
var RenderType_TagsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagsComponent, data: {} });
export { RenderType_TagsComponent as RenderType_TagsComponent };
function View_TagsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [["class", "hover-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleTagClick(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 2)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.isMeta ? "Yes" : "No"); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.createdAt, "mediumDate")); _ck(_v, 6, 0, currVal_2); }); }
export function View_TagsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-9 text-lg-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "text-uppercase text-orange"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tag Management"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-3 text-md-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-md btn-orange"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleTagClick("new") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["New Tag"])), (_l()(), i1.ɵeld(8, 0, null, null, 28, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 24, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 23, "table", [["class", "table table-hover table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 19, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 18, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "span", [["class", "sortable-col-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleSortChange("name") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { active: 0, reverse: 1 }), (_l()(), i1.ɵted(-1, null, [" Name"])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "fa fa-caret-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 5, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 4, "span", [["class", "sortable-col-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleSortChange("isMeta") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(22, { active: 0, reverse: 1 }), (_l()(), i1.ɵted(-1, null, [" Is Meta Data"])), (_l()(), i1.ɵeld(24, 0, null, null, 0, "i", [["class", "fa fa-caret-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 5, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 4, "span", [["class", "sortable-col-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleSortChange("createdAt") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(27, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(28, { active: 0, reverse: 1 }), (_l()(), i1.ɵted(-1, null, [" Created At"])), (_l()(), i1.ɵeld(30, 0, null, null, 0, "i", [["class", "fa fa-caret-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsComponent_1)), i1.ɵdid(33, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(34, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 1, "pagination", [], null, [[null, "pageChange"], [null, "pageSizeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.currentPage = $event) !== false);
        ad = (pd_0 && ad);
    } if (("pageSizeChange" === en)) {
        var pd_1 = ((_co.pageSize = $event) !== false);
        ad = (pd_1 && ad);
    } if (("pageChange" === en)) {
        var pd_2 = (_co.handlePageChange() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_PaginationComponent_0, i3.RenderType_PaginationComponent)), i1.ɵdid(36, 49152, null, 0, i4.PaginationComponent, [i5.PersistentStorageService], { collectionSize: [0, "collectionSize"], page: [1, "page"], pageSize: [2, "pageSize"] }, { pageChange: "pageChange", pageSizeChange: "pageSizeChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sortable-col-name"; var currVal_1 = _ck(_v, 16, 0, (_co.sortBy === "name"), (_co.sortOrder !== "asc")); _ck(_v, 15, 0, currVal_0, currVal_1); var currVal_2 = "sortable-col-name"; var currVal_3 = _ck(_v, 22, 0, (_co.sortBy === "isMeta"), (_co.sortOrder !== "asc")); _ck(_v, 21, 0, currVal_2, currVal_3); var currVal_4 = "sortable-col-name"; var currVal_5 = _ck(_v, 28, 0, (_co.sortBy === "createdAt"), (_co.sortOrder !== "asc")); _ck(_v, 27, 0, currVal_4, currVal_5); var currVal_6 = _co.tags; _ck(_v, 33, 0, currVal_6); var currVal_7 = _co.totalItems; var currVal_8 = _co.currentPage; var currVal_9 = _co.pageSize; _ck(_v, 36, 0, currVal_7, currVal_8, currVal_9); }, null); }
export function View_TagsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-groups-component", [], null, null, null, View_TagsComponent_0, RenderType_TagsComponent)), i1.ɵdid(1, 114688, null, 0, i6.TagsComponent, [i7.TagsService, i8.Router, i8.ActivatedRoute, i5.PersistentStorageService, i9.ErrorHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TagsComponentNgFactory = i1.ɵccf("app-groups-component", i6.TagsComponent, View_TagsComponent_Host_0, {}, {}, []);
export { TagsComponentNgFactory as TagsComponentNgFactory };
