/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./authentication-icon.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./authentication-icon.component";
import * as i5 from "../../service/util/Session";
import * as i6 from "../../service/currentUser/CurrentUserService";
var styles_AuthenticationIconComponent = [i0.styles];
var RenderType_AuthenticationIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthenticationIconComponent, data: {} });
export { RenderType_AuthenticationIconComponent as RenderType_AuthenticationIconComponent };
function View_AuthenticationIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [], [[8, "className", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-user-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Login\n"]))], function (_ck, _v) { var currVal_3 = _ck(_v, 2, 0, "/profile"); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "mr-2 text-body item-content d-inline-block text-center ", (_co.small ? "small" : ""), ""); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_AuthenticationIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [], [[8, "className", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-gear"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Profile\n"]))], function (_ck, _v) { var currVal_3 = _ck(_v, 2, 0, "/profile"); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "mr-2 text-body item-content d-inline-block text-center ", (_co.small ? "small" : ""), ""); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_AuthenticationIconComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-user-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Logout\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "ml-2 text-body item-content d-inline-block text-center ", (_co.small ? "small" : ""), ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_AuthenticationIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthenticationIconComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthenticationIconComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthenticationIconComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.session.credentials; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.session.credentials; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.session.credentials; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_AuthenticationIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "authentication-icon", [], null, null, null, View_AuthenticationIconComponent_0, RenderType_AuthenticationIconComponent)), i1.ɵdid(1, 114688, null, 0, i4.AuthenticationIconComponent, [i2.Router, i5.Session, i6.CurrentUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthenticationIconComponentNgFactory = i1.ɵccf("authentication-icon", i4.AuthenticationIconComponent, View_AuthenticationIconComponent_Host_0, { small: "small" }, {}, []);
export { AuthenticationIconComponentNgFactory as AuthenticationIconComponentNgFactory };
