import { DocumentReviewFormComponent } from './../views/authenticated/admin/documentReviewForm/documentReviewForm.component';
import { DRMDashboardComponent } from './../views/authenticated/admin/drm-dashboard/drm-dashboard.component';
import { EditTagComponent } from './../views/authenticated/admin/tags/edit/editTag.component';
import { TagsComponent } from './../views/authenticated/admin/tags/list/tags.component';
import { EditDocumentComponent } from './../views/authenticated/admin/documents/edit/editDocument.component';
import { RouterModule } from '@angular/router';
import { SplashComponent } from '../views/splash/splash.component';
import { AuthenticatedComponent } from '../views/authenticated/_main/authenticated.component';
import { AdminComponent } from '../views/authenticated/admin/template/admin.component';
import { ProfileComponent } from '../views/authenticated/profile/profile.component';
import { MainComponent } from '../views/_main/main.component';
import { LoginComponent } from "../views/auth/login/login.component";
import { ResetComponent } from "../views/auth/reset/reset.component";
import { ForgotComponent } from "../views/auth/forgot/forgot.component";
import { RegisterComponent } from "../views/auth/register/register.component";
import { GroupsComponent } from "../views/authenticated/admin/groups/list/groups.component";
import { UsersComponent } from "../views/authenticated/admin/users/list/users.component";
import { DashboardComponent } from "../views/authenticated/admin/dashboard/dashboard.component";
import { ReportFormComponent } from "../views/authenticated/admin/reportForm/reportForm.component";
import { FollowUpFormComponent } from "../views/authenticated/admin/followUpForm/followUpForm.component";
import { EditUserComponent } from "../views/authenticated/admin/users/edit/editUser.component";
import { NotFoundComponent } from "../views/not-found/notFound.component";
import { EditGroupComponent } from "../views/authenticated/admin/groups/edit/editGroup.component";
import { EnumsComponent } from "../views/authenticated/admin/enums/list/enums.component";
import { EditEnumsSetComponent } from "../views/authenticated/admin/enums/edit/editEnumsSet.component";
import { AdminRouterGuard } from "../service/routerGuards/admin.guard";
import { CurrentUserRouterGuard } from "../service/routerGuards/currentUser.guard";
import { CanDeactivateGuard } from "../views/shared/canDeactivate/CanDeactivateGuard";
import { ForbiddenComponent } from "../views/forbidden/forbidden.component";
import { LocationsComponent } from "../views/authenticated/admin/locations/list/locations.component";
import { EditLocationComponent } from "../views/authenticated/admin/locations/edit/editLocation.component";
import { EditOptionsComponent } from "../views/authenticated/admin/options/editOptions.component";
import { AuditFormComponent } from "../views/authenticated/admin/auditForm/auditForm.component";
import { ResourcePacksComponent } from '../views/resource-packs/resource-packs.component';
import { ResourcePackDocumentsComponent } from 'views/resource-packs/resource-pack-documents/resource-pack-documents.component';
import { FormTemplatesComponent } from "../views/authenticated/admin/formTemplate/formTemplates.component";
import { DocumentCollectionsComponent } from 'views/authenticated/admin/documentCollections/list/documentCollections.component';
import { EditDocumentCollectionComponent } from 'views/authenticated/admin/documentCollections/edit/editDocumentCollection.component';
import { RAMDashboardComponent } from 'views/authenticated/admin/ram-dashboard/ram-dashboard.component';
import { RiskAssessmentFormComponent } from 'views/authenticated/admin/riskAssessmentForm/riskAssessmentForm.component';
import { RiskComponent } from 'views/authenticated/admin/risks/list/risks.component';
import { EditRiskComponent } from 'views/authenticated/admin/risks/edit/editRisk.component';
var ɵ0 = {
    isTemplate: true
}, ɵ1 = {
    isTemplate: true
};
var routes = [
    { path: '', component: SplashComponent },
    {
        path: '', component: MainComponent,
        children: [
            { path: 'login', component: LoginComponent },
            { path: 'register', component: RegisterComponent },
            { path: 'forgot', component: ForgotComponent },
            { path: 'reset/:resetToken', component: ResetComponent },
            { path: '404', component: NotFoundComponent },
            { path: '403', component: ForbiddenComponent },
            {
                path: '',
                component: AuthenticatedComponent,
                canActivate: [CurrentUserRouterGuard],
                children: [
                    { path: '', component: DashboardComponent },
                    { path: 'dashboard', component: DashboardComponent },
                    { path: 'drm-dashboard', component: DRMDashboardComponent },
                    { path: 'ram-dashboard', component: RAMDashboardComponent },
                    { path: 'report', component: ReportFormComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'report/:reportId', component: ReportFormComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'followUp', component: FollowUpFormComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'followUp/:reportId', component: FollowUpFormComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'audit', component: AuditFormComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'audit/:reportId', component: AuditFormComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'profile', component: ProfileComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'document-collections', component: DocumentCollectionsComponent },
                    { path: 'document-collections/new', component: EditDocumentCollectionComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'document-collections/:documentCollectionId', component: EditDocumentCollectionComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'document-collections/:documentCollectionId/documents/new', component: EditDocumentComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'document-collections/:documentCollectionId/documents/:documentId', component: EditDocumentComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'document-review-task', component: DocumentReviewFormComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'document-review-task/:taskId', component: DocumentReviewFormComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'risk-assessment-task', component: RiskAssessmentFormComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'risk-assessment-task/:taskId', component: RiskAssessmentFormComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'risks', component: RiskComponent, canDeactivate: [CanDeactivateGuard] },
                    { path: 'risks/:riskId', component: EditRiskComponent, canDeactivate: [CanDeactivateGuard] },
                    {
                        path: 'admin',
                        component: AdminComponent,
                        canActivate: [AdminRouterGuard],
                        children: [
                            { path: 'tags', component: TagsComponent },
                            { path: 'tags/new', component: EditTagComponent, canDeactivate: [CanDeactivateGuard] },
                            { path: 'tags/:tagId', component: EditTagComponent, canDeactivate: [CanDeactivateGuard] },
                            { path: 'groups', component: GroupsComponent },
                            { path: 'groups/new', component: EditGroupComponent, canDeactivate: [CanDeactivateGuard] },
                            { path: 'groups/:groupId', component: EditGroupComponent, canDeactivate: [CanDeactivateGuard] },
                            { path: 'locations', component: LocationsComponent },
                            { path: 'locations/new', component: EditLocationComponent, canDeactivate: [CanDeactivateGuard] },
                            { path: 'locations/:locationId', component: EditLocationComponent, canDeactivate: [CanDeactivateGuard] },
                            { path: 'users', component: UsersComponent },
                            { path: 'users/new', component: EditUserComponent, canDeactivate: [CanDeactivateGuard] },
                            { path: 'users/:userId', component: EditUserComponent, canDeactivate: [CanDeactivateGuard] },
                            { path: 'enums', component: EnumsComponent },
                            { path: 'enums/new', component: EditEnumsSetComponent, canDeactivate: [CanDeactivateGuard] },
                            { path: 'enums/:enumName', component: EditEnumsSetComponent, canDeactivate: [CanDeactivateGuard] },
                            { path: 'resource-pack', canDeactivate: [CanDeactivateGuard], component: ResourcePacksComponent },
                            { path: 'resource-pack/documents/:id', canDeactivate: [CanDeactivateGuard], component: ResourcePackDocumentsComponent },
                            { path: 'options', component: EditOptionsComponent },
                            { path: 'templates', component: FormTemplatesComponent },
                            {
                                path: 'templates/audit/new',
                                component: AuditFormComponent,
                                canDeactivate: [CanDeactivateGuard],
                                data: ɵ0
                            },
                            {
                                path: 'templates/audit/:reportId',
                                component: AuditFormComponent,
                                canDeactivate: [CanDeactivateGuard],
                                data: ɵ1
                            }
                        ]
                    },
                ],
            },
        ],
    },
];
// tslint:disable-next-line:variable-name
export var AppRoutingProviders = [];
// tslint:disable-next-line:variable-name
export var Routing = RouterModule.forRoot(routes, {
    useHash: false,
});
export { ɵ0, ɵ1 };
