import {Component, Input, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import "moment-timezone";
import {FormField} from "../../../../../model/Form";
import {FormComponent} from "../../../../../model/FormComponent";
import {Session} from "../../../../../service/util/Session";
import {CurrentUserService} from "../../../../../service/currentUser/CurrentUserService";
import {FormService} from "../../../../../service/FormService";
import {FormCategory} from "../../../../../../model/Category";
import {CategoryService} from "../../../../../service/CategoryService";
import {FormRecordService} from "../../../../../service/FormRecordService";
import {
  IFormOutputModel,
  IFormRecordOutputModel,
  IFormRecordPropertyParam
} from "../../../../../../common/contracts/form";
import { IButtonsSwitchOption } from "../../../../shared/buttonsSwitch.component";
import {GroupsService} from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { Router } from "@angular/router";
import {FollowUpWidgetComponent} from "../../../../shared/followUpWidget.component";
import {IDocumentType} from "../../../../../../common/contracts/document";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'follow-up-form-1',
  templateUrl: './followUpFormStage1.component.html',
})
export class FollowUpFormStage1Component extends FormComponent implements OnInit {

  // Existing Form Data
  @Input() readOnly: boolean = false;
  @Input() hideHeader: boolean = false;

  @Input() formData: IFormOutputModel;
  @Input() sequence: number;

  public formRecord: IFormRecordOutputModel;
  
  @ViewChild('followUpWidget') followUpWidgetRef: FollowUpWidgetComponent;

  public dateString: string;
  public formCategoryId: number;

  public furStatusOptions: IButtonsSwitchOption[] = [
    {
      text: 'Now Resolved',
      value: 'resolved',
    },
    {
      text: 'On Track',
      value: 'onTrack',
    },
    {
      text: 'Other',
      value: 'other',
    },
  ];

  /*
    This should have been done properly using something that implements FormControl but its
    too late now
   */
  private customFollowUpValidation = (value:String | null):boolean => {
    if( !value || ( value && value === '[]' ) ) return false;

    return value && value.length > 0;
  };

  public form:{ [key:string]:FormField<any> } = {
    furStatus: new FormField<String>('', {
      validation: FormField.ValidationMethods.IsNotBlank
    }),
    hasContactedOriginator: new FormField<boolean>(false, {
      validation: FormField.ValidationMethods.None
    }),
    followUps: new FormField<string>('[]', {
      nullEquivilent : "[]",
      validation: (value:string) => {
        return this.followUpWidgetRef ? this.followUpWidgetRef.validate() : true;
      }
    }),
    detail: new FormField<String>('', {
      validation: FormField.ValidationMethods.None
    }),
    notes: new FormField<String>('', {
      validation: FormField.ValidationMethods.None
    }),
    
    /* Reassign Fields */
  
    reassign: new FormField<boolean>(false, {
      validation: FormField.ValidationMethods.None
    }),
    //Since this will be assigned to a select it must be a string data - Conversion where appropriate
    reassignToUserId: new FormField<string>('', {
      validation: FormField.ValidationMethods.None
    }),
  };

  public documents: Array<IDocumentType> = [];

  constructor(
    public session: Session,
    public currentUserService: CurrentUserService,
    public formService: FormService,
    public categoryService: CategoryService,
    public formRecordService: FormRecordService,
    public groupsService: GroupsService,
    private errorHandler: ErrorHandlerService,
    public router: Router
  ) {
    super(router);
  }

  registerFormFields() {
    this.formFields.push( ...Object.keys(this.form).map((k:string) => this.form[k]) );
  }
  
  private getLastSubmission() : IFormRecordOutputModel | null {
    if( !this.formData || !this.formData.records || !this.formData.records.length )
      return null;
    
    let submissions = this.formData.records.filter( record => record.isComplete );
    
    if( !submissions || !submissions.length )
      return null;
    
    return submissions[ submissions.length - 1 ];
  }
  
  onSubmit(isDraft: boolean):void {
    this.session.lockInput(  () => {
      return new Promise(async (resolve, reject) => {
        
        let success = () => {
          resolve();
          
          this.router.navigateByUrl('/dashboard');
          //this.goBack();
        };
  
        let fail = (msg:string, err:any) => {
          console.error(msg, err);
          this.errorHandler.handleHttpError(err);
          reject();
        };
        
        let stage: number;
        let assignedUserId: number | null = null;
        let userGroupId = this.formData.userGroupId;
        
        if( this.currentUserService.userData )
          assignedUserId = this.currentUserService.userData.id;
        
        if( !isDraft ) {
          if( this.form.reassign.value && this.form.reassignToUserId.value) {
            stage = 1;
            
            assignedUserId = Number(this.form.reassignToUserId.value);
          } else {
            let lastSubmission = this.getLastSubmission();
            
            /*
              If:
               There is a last submission
               AND that last submission is a stage 1 submission
               AND that last submission was made by another user
               AND that last submission assigned the form to the current user
            */
            let assignBack:boolean = false;
            
            if( lastSubmission && lastSubmission.stage === 1
              && lastSubmission.createdById !== assignedUserId
            ) {
              // Prevent the searching of this reassignment property until we're sure it needs to be checked
              let reassignProperty = lastSubmission.properties.find( property => property.property.name === 'reassignToUserId');
              
              if( reassignProperty && reassignProperty.intData === assignedUserId )
                assignBack = true;
            }
            
            if( lastSubmission && assignBack ) {
              //Assign the submission back to that user, leaving the form in stage 1
              stage = 1;
              
              assignedUserId = lastSubmission.createdById;
            } else {
              stage = 2;
              
              // Assign it to senior management
              assignedUserId = null;
              
              let groups = await this.groupsService.getGroups().toPromise();
              let adminGroup = groups.find(group => group.groupName.match(/^senior management/gi) !== null);
              
              if (adminGroup)
                userGroupId = adminGroup.id;
              else
                userGroupId = null;
            }
          }
        } else
          stage = 1;
        
        let properties: Partial<IFormRecordPropertyParam>[] = [];
        
        this.formService.updateForm({
          id: this.formData.id,
          stage,
          userGroupId,
          assignedUserId
        })
        .subscribe(() => {
          properties.push({
            name: "reassign",
            intData: this.form.reassign.value ? 1 : 0
          });
          
          if( this.form.reassign.value ) {
            // TODO: This fields validation should change from int > 0 to NONE when reassign is true/false
            properties.push({
              name: "reassignToUserId",
              intData: this.form.reassign.value ? Number(this.form.reassignToUserId.value) : null,
            });
          }
          
          properties.push({
            name: "hasContactedOriginator",
            intData: this.form.hasContactedOriginator.value ? 1 : 0
          });
          
          properties.push({
            name: "followUps",
            jsonData: this.form.followUps.value
          });
  
          if( this.form.furStatus.value )
          properties.push({
            name: "furStatus",
            stringData: this.form.furStatus.value
          });
          
          if( this.form.detail.value.length > 0 )
            properties.push({
              name: "detail",
              stringData: this.form.detail.value
            });
          
          if( this.form.notes.value.length > 0 )
            properties.push({
              name: "notes",
              stringData: this.form.notes.value
            });
          
          this.formRecordService.createRecord({
            formId: this.formData.id,
            // Intentionally cast the properties object since we know its correct
            properties: properties as any,
            stage: 1,
            documents: this.documents.map(doc => ({id: doc.id, isTicked: !!doc.isTicked})),
            isComplete: !isDraft
          })
          .subscribe( success,
            err => fail('Error creating a record', err)
            );
        }, err => fail('Error updating a form', err));
      });
    });
  }

  ngOnInit() {
    this.dateString = moment().tz(environment.timeZone).format("DD-MM-YYYY");

    this.registerFormFields();

    this.loadEnums();

    this.repopulateFormFromData();
  }
  
  private repopulateFormFromData() {
    if( !this.formData || !this.formData.records || !this.formData.records.length )
      return;
    
    let stageRecords = this.formData.records.filter( record => record.stage === 1);
    if( stageRecords.length === 0 )
      return;
    
    if( !this.sequence ) {
      let mostRecentRecord = stageRecords.sort( (a, b) => a.sequence > b.sequence ? 1 : -1 ).pop();
      
      if (!mostRecentRecord)
        throw new Error("internal error");
      
      this.formRecord = mostRecentRecord;
    } else {
      let targetRecord = stageRecords.find( record => record.sequence === this.sequence );
      
      if( !targetRecord )
        throw new Error("internal error");
      
      this.formRecord = targetRecord;
    }
    
    //Convert the properties into easily accessible IFormRecordPropertyParam
    if( !this.formRecord.properties )
      return;
    
    this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);
    
    let simpleProperties:{[key:string]: IFormRecordPropertyParam} = {};
    
    this.formRecord.properties.forEach( recordProperty => {
      //eject invalid property
      if( !recordProperty.property )
        return;
      
      let result:Partial<IFormRecordPropertyParam> = {
        name: recordProperty.property.name
      };
      
      if(recordProperty.stringData)
        result.stringData = recordProperty.stringData;
      
      if(recordProperty.intData)
        result.intData = recordProperty.intData;
      
      if(recordProperty.jsonData)
        result.jsonData = recordProperty.jsonData;
      
      if(recordProperty.enumId)
        result.enumId = recordProperty.enumId;
      
      simpleProperties[result.name as string] = result as IFormRecordPropertyParam;
    });
    
    /*
      It's also possible for follow ups to come from higher up the chain, so if need be
      check for and acknowledge them
     */
    let futureStageRecords = this.formData.records.filter( record => record.stage > 1 );
    if( futureStageRecords && futureStageRecords.length > 0 ) {
      // We cannot simply pop the latest as the latest may not contain the right property, so sort them, largest to smallest
      futureStageRecords = futureStageRecords.sort( (a, b) => a.sequence > b.sequence ? -1 : 1 );
      
      let followUpRecord;
      for( let i = 0; i < futureStageRecords.length; i+=1 ) {
        followUpRecord = futureStageRecords[i].properties.find(
          recordProperty => recordProperty.property.name === 'followUps'
        );
        
        if( followUpRecord ) {
          if( simpleProperties['followUps'] ) {
            simpleProperties['followUps'].jsonData = followUpRecord.jsonData;
          } else {
            simpleProperties['followUps'] = {
              jsonData : followUpRecord.jsonData
            } as IFormRecordPropertyParam;
          }
          
          i = futureStageRecords.length;
        }
      }
    }

    if( !this.formRecord.isComplete || this.sequence ) {
      if( simpleProperties['reassignToUserId'] )
        this.form.reassignToUserId.value = String(simpleProperties['reassignToUserId'].intData);
      
      if( simpleProperties['reassign']
        && simpleProperties['reassign'].intData !== null
        && simpleProperties['reassign'].intData !== undefined ) {
        this.form.reassign.value = (simpleProperties['reassign'].intData > 0);
        this.showReassignOptions(this.form.reassign.value);
      }
      
      if( simpleProperties['hasContactedOriginator']
        && simpleProperties['hasContactedOriginator'].intData !== null
        && simpleProperties['hasContactedOriginator'].intData !== undefined )
        this.form.hasContactedOriginator.value = ( simpleProperties['hasContactedOriginator'].intData > 0);
      
      if( simpleProperties['detail'] )
        this.form.detail.value = simpleProperties['detail'].stringData;
  
      if( simpleProperties['notes'] )
        this.form.notes.value = simpleProperties['notes'].stringData;
      
      this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
    }
    
    if( simpleProperties['followUps'] )
      this.form.followUps.value = simpleProperties['followUps'].jsonData;
  
    if( simpleProperties['furStatus'] )
      this.form.furStatus.value = simpleProperties['furStatus'].stringData;
  }

  public handleStatusChanged(event:string) {
    if( event.toLowerCase() === 'ontrack' ) {
      // fur is required
      this.form.followUps.validation = this.customFollowUpValidation;
    } else {
      // fur is optional
      this.form.followUps.validation = FormField.ValidationMethods.None
    }

    this.form.followUps.validate();
  }

  private loadEnums() {
    this.session.lockInputRx(this.categoryService.getCategories())
      .subscribe( (data:FormCategory[]) => {

        let reportCategory:FormCategory | undefined = data.find( (data:FormCategory) => !!data.name.match(/^Follow-Up/) );

        if( reportCategory )
          this.formCategoryId = reportCategory.id as number;
        //else
          //Notify the user an error has occurred
      },
      err => {
        console.error('Error while getting categories', err);
        this.errorHandler.handleHttpError(err);
      });
  }
  
  public showReassignOptions(state: boolean): void {
    this.form.reassign.value = state;
    if (state) {
      this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.IsNotBlank);
      this.setFieldValidation(this.form.furStatus, FormField.ValidationMethods.None);
    } else {
      this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.None);
      this.setFieldValidation(this.form.furStatus, FormField.ValidationMethods.IsNotBlank);
    }
  }
}
