var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericService } from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import { of } from "rxjs";
import { share, map, tap } from "rxjs/operators";
var DocumentCollectionsService = /** @class */ (function (_super) {
    __extends(DocumentCollectionsService, _super);
    function DocumentCollectionsService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.cachedDocumentCollections = null;
        var queryParams = new HttpParams({
            fromObject: {
                skip: '0',
                limit: '50',
                sortBy: 'name',
                order: 'asc',
            }
        });
        _this.getDocumentCollectionsRequest = _this.http.get(DocumentCollectionsService.apiPrefix, { params: queryParams }).pipe(map(function (data) {
            _this.cachedDocumentCollections = data.items;
            setTimeout(function () {
                _this.cachedDocumentCollections = null; // invalidate documentCollections cache in 20 minutes
            }, 20 * 60 * 1000);
            return data.items;
        }), share() //share results between multiple subscriptions
        );
        return _this;
    }
    /**
     * @description Clear collection cache
     */
    DocumentCollectionsService.prototype.clearCache = function () {
        this.cachedDocumentCollections = null;
    };
    DocumentCollectionsService.prototype.getDocumentCollections = function () {
        return this.cachedDocumentCollections ? of(this.cachedDocumentCollections) : this.getDocumentCollectionsRequest;
    };
    DocumentCollectionsService.prototype.getPagedDocumentCollections = function (offset, pageSize, sortBy, order, counts) {
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 20; }
        if (sortBy === void 0) { sortBy = 'name'; }
        if (order === void 0) { order = 'asc'; }
        if (counts === void 0) { counts = true; }
        var queryParams = new HttpParams({
            fromObject: {
                skip: offset.toString(),
                limit: pageSize.toString(),
                sortBy: sortBy,
                order: order,
            }
        });
        return this.http.get(DocumentCollectionsService.apiPrefix, { params: queryParams });
    };
    DocumentCollectionsService.prototype.getDocumentCollectionById = function (id) {
        return this.http.get(DocumentCollectionsService.apiPrefix + "/" + id);
    };
    DocumentCollectionsService.prototype.createDocumentCollection = function (documentCollectionData) {
        var _this = this;
        return this.http.post(DocumentCollectionsService.apiPrefix, documentCollectionData).pipe(tap(function () {
            _this.cachedDocumentCollections = null;
        }));
    };
    DocumentCollectionsService.prototype.updateDocumentCollection = function (documentCollectionChanges) {
        var _this = this;
        return this.http.put(DocumentCollectionsService.apiPrefix, documentCollectionChanges).pipe(tap(function () {
            _this.cachedDocumentCollections = null;
        }));
    };
    DocumentCollectionsService.prototype.archiveDocumentCollection = function (id) {
        var _this = this;
        return this.http.delete(DocumentCollectionsService.apiPrefix + "/" + id).pipe(tap(function () {
            _this.cachedDocumentCollections = null;
        }));
    };
    /**
     * @description Executes a collection import using the document supplied
     * @param documentId
     */
    DocumentCollectionsService.prototype.performCollectionImport = function (documentId) {
        return this.http.post(DocumentCollectionsService.apiPrefix + "/import/" + documentId, {});
    };
    DocumentCollectionsService.apiPrefix = '/api/documentCollection';
    return DocumentCollectionsService;
}(GenericService));
export { DocumentCollectionsService };
