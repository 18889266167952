/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../shared/submissionDetail.component.ngfactory";
import * as i3 from "../../../shared/submissionDetail.component";
import * as i4 from "../../../../service/currentUser/CurrentUserService";
import * as i5 from "../../../../service/FormRecordService";
import * as i6 from "../../../../service/ModalService";
import * as i7 from "../../../../service/ErrorHandlerService";
import * as i8 from "./footer.component";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "col-12 pt-2 col-md-6 pt-md-0 col-lg-5 col-xl-3 col-xxl-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "btn btn-block btn-lg btn-primary rounded-0 text-center text-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "capitalize"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Save Draft"]))], null, null); }
function View_FooterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "button", [["class", "btn btn-block btn-lg btn-success rounded-0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "capitalize"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Submit"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDraft; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "submission-detail", [], null, null, null, i2.View_SubmissionDetailComponent_0, i2.RenderType_SubmissionDetailComponent)), i0.ɵdid(1, 114688, null, 0, i3.SubmissionDetailComponent, [i4.CurrentUserService, i5.FormRecordService, i6.ModalService, i7.ErrorHandlerService], { formRecord: [0, "formRecord"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formRecord; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.readOnly; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 49152, null, 0, i8.FooterComponent, [], null, null)], null, null); }
var FooterComponentNgFactory = i0.ɵccf("footer", i8.FooterComponent, View_FooterComponent_Host_0, { readOnly: "readOnly", formRecord: "formRecord", showDraft: "showDraft" }, { onSubmit: "onSubmit" }, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
