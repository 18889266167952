import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges } from '@angular/core';
import { Session } from "../../service/util/Session";
import { FormField } from "../../model/Form";
import { Select2Component } from 'ng2-select2';
import { TagsService } from "../../service/admin/TagsService";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import { CurrentUserService } from '../../service/currentUser/CurrentUserService';
import { logger } from "../../service/util/Logger";
import { ITagOutputModel } from '../../../common/contracts/tag';


type ExIdTextPair = IdTextPair & { selected?: boolean };

@Component({
	selector: 'tag-select',
	template: `<select2
    #select2Component
    class="form-control select2-form-control {{disabled?'bg-grey':''}} {{!disabled && field && field.iifIsValid('border-success','border-danger')}}"
    [data]="tagSelectData"
    [options]="tagSelectOptions"
    (valueChanged)="valueChanged($event.data)"
    [value]="defaultValue"
    [disabled]="disabled"

  ></select2>`,
})
export class TagSelectComponent implements OnInit, OnChanges {


	private _value: string = '';

	@Input('value')
	get value() {
		return this._value;
	}
	/**
	 * By preventing assigning non-unique values during the set
	 * we can prevent a loop of observable subscribers
	 */
	set value(newValue: string) {
		this._value = newValue;

		if (!this.field && !this.multiple) {
			this.defaultValue = this._value;
		}
	}

	// Implies a read only state
	@Input()
	disabled: boolean = false;

	@Input()
	placeholder: string;

	// /**
	//  * Restrict available tags to the tags available to the current user
	//  */
	// @Input()
	// restrictTags: boolean = false;
	@Input()
	isMeta: boolean = true;

	@Input()
	multiple: boolean = false;

	public defaultValue: string = '';

	@Input()
	field: FormField<any>;

	public tagSelectOptions: Select2Options;
	public tagSelectData: ExIdTextPair[] = [];

	// Reference firstNameInput variable inside Component
	@ViewChild('select2Component') select2ComponentRef: Select2Component;

	@Output()
	change: EventEmitter<ExIdTextPair[]> = new EventEmitter<IdTextPair[]>();

	constructor(
		public TagsService: TagsService,
		public session: Session,
		private errorHandler: ErrorHandlerService,
		public currentUserService: CurrentUserService
	) {
	}

	ngOnInit() {
		this.init();
	}
	ngOnChanges() {
		this.init();
	}
	init() {
		this.tagSelectOptions = { allowClear: !this.multiple, placeholder: this.placeholder || "Select tag(s)", multiple: this.multiple };


		this.session.lockInputRx(this.TagsService.getTags())
			.subscribe((items: ITagOutputModel[]) => {
				let newSelectOptions: ExIdTextPair[] = [{ id: "", text: "" }];

				items = items.filter((tag) => {
					return (tag.isMeta === this.isMeta);
				});

				items.forEach((tag) => newSelectOptions.push({ id: String(tag.id), text: tag.name }));
				this.tagSelectData = newSelectOptions;

				//Force the change detection to cycle again to prevent race

				if (this.field) {
					if (String(this.field.value) !== this.defaultValue)
						this.defaultValue = String(this.field.value);
				} else {
					//If the default value was manually set we need to re-trigger the process
					if (this._value !== '') {
						const valueArr: string[] | undefined = this.multiple && this._value && this._value.length ? this._value.split(",") : undefined;
						//this.defaultValue = (this.value);
						const options = this.tagSelectData.filter(o => (this.multiple ? (
							valueArr ? valueArr.find(selectedValue => selectedValue === o.id) : false
						) : o.id === this._value));

						if (options && options.length) {
							options.map(opt => opt.selected = true);
						}
					}
				}
			}, err => this.errorHandler.handleHttpError(err));
	}

	valueChanged(selectedOpts: ExIdTextPair[]) {

		if (selectedOpts.length === 0 || (
			selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0
		)) {
			if (this.field)
				this.field.value = null;
		}

		if (selectedOpts.length > 1 && !this.multiple)
			throw ("Selected options unexpectedly contained multiple results");

		if (selectedOpts.length === 1 && !this.multiple) {
			if (this.field) {
				this.field.value = selectedOpts[0].id;
			} else {
				if (this._value !== selectedOpts[0].id) { // if the value has been changed - emit event
					this._value = selectedOpts[0].id;
					this.change.emit(selectedOpts);
					return;
				}
			}
		}

		if (this.multiple) {
			const actualSelected = selectedOpts.filter(opt => opt.id && opt.id.length);
			const newValue = actualSelected.map(opt => opt.id).join(",");

			if (this.field) {
				this.field.value = newValue;
			} else {
				logger.silly(`TagSelectComponent: Checking internalValue change Value[(${typeof newValue}) ${newValue}] !== _value[(${typeof this._value}) ${this._value}]`);
				if (this._value !== newValue) { // if the value has been changed - emit event
					logger.silly("TagSelectComponent: Updating Internal Value");
					this._value = newValue;
					this.change.emit(actualSelected);
				}
			}
		}
	}

}
