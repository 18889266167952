import { Component, EventEmitter, Input, Output } from "@angular/core";

export interface IButtonsSwitchOption {
  text: string,
  value: string,
  disabled?: boolean,
}

@Component({
  selector: 'buttons-switch',
  styles: [`
      label.btn-secondary.disabled {
          background-color: #BFBFBF;
          border-color: #BFBFBF;
      }

      label.btn-secondary.disabled.active {
          background-color: #6c757d;
          border-color: #6c757d;
      }
  `],
  template: `
<div class="btn-group-toggle d-block text-uppercase p-3 text-center justify-content-around d-flex" data-toggle="buttons">
    <label *ngFor="let option of options" class="btn btn-secondary px-4"
        [ngClass]="{'disabled': disabled || option.disabled, 'active': option.value === value }"
        (click)="handleClick(option.value)">{{option.text}}</label>
</div>`
})
export class ButtonsSwitchComponent {

  @Input()
  options: IButtonsSwitchOption[] = [];

  @Input()
  value: string;
  
  @Output()
  valueChange = new EventEmitter<string>();

  @Input()
  disabled: boolean = false;

  public handleClick(val: string) {
    if (!this.disabled) {
      if (val !== this.value) {
        this.value = val;
        this.valueChange.emit(val);
      }
    }
  }
}
