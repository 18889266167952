export class Risk {
	public static className: string = "Risk";

	public static Likelihood = {
		'Almost Certain': { id: 1, order: 1, constant: 6 },
		'Likely': { id: 2, order: 2, constant: 5 },
		'Possible': { id: 3, order: 3, constant: 4 },
		'Unlikely': { id: 4, order: 4, constant: 3 },
		'Rare': { id: 5, order: 5, constant: 2 },
	};

	public static LikelihoodOptions = Object.keys(Risk.Likelihood)
		.sort((keyA, keyB) => Risk.Likelihood[keyB].order > Risk.Likelihood[keyA].order ? 1 : 0)
		.map(key => {
			const likelihood = Risk.Likelihood[key];
			return {
				id: likelihood.id,
				text: key
			}
		});

	public static Severity = {
		'Insignificant': { id: 1, order: 1, constant: 3 },
		'Minor': { id: 2, order: 2, constant: 5 },
		'Moderate': { id: 3, order: 3, constant: 7 },
		'Major': { id: 4, order: 4, constant: 9 },
		'Catastrophic': { id: 5, order: 5, constant: 11 }
	};

	public static SeverityOptions = Object.keys(Risk.Severity)
		.sort((keyA, keyB) => Risk.Severity[keyB].order > Risk.Severity[keyA].order ? 1 : 0)
		.map(key => {
			const severity = Risk.Severity[key];
			return {
				id: severity.id,
				text: key
			}
		});

	public static Risk = {
		'Critical': { id: 1, order: 1, start: 45, nextAssessmentMonthOffset: 1 },
		'High': { id: 2, order: 2, start: 31, end: 44, nextAssessmentMonthOffset: 1 },
		'Moderate': { id: 3, order: 3, start: 15, end: 31, nextAssessmentMonthOffset: 2 },
		'Low': { id: 4, order: 4, start: 1, end: 15, nextAssessmentMonthOffset: 3 }
	};

	public static RiskOptions = Object.keys(Risk.Risk)
		.sort((keyA, keyB) => Risk.Risk[keyB].order > Risk.Risk[keyA].order ? 1 : 0)
		.map(key => {
			const risk = Risk.Risk[key];
			return {
				id: risk.id,
				text: key
			}
		});

	/**
	 * @description True if RiskB.end > RiskA.end. If neither has 'end', return RiskB.start > RiskA.start. If one has no 'end', return the one with no 'end'
	 * @param riskIdA 
	 * @param riskIdB 
	 */
	public static isHigherRisk(riskAId: string | number | null, riskBId: string | number | null) {
		const riskA: (typeof Risk.Risk[keyof typeof Risk.Risk] & { end?: number }) | null = Risk.getEnumById(riskAId, Risk.Risk);
		const riskB: (typeof Risk.Risk[keyof typeof Risk.Risk] & { end?: number }) | null = Risk.getEnumById(riskBId, Risk.Risk);

		if (!riskB) return false;
		if (!riskA) return true;
		if (!riskA.end && riskB.end) return true;
		if (riskA.end && !riskB.end) return false;
		if (riskA.end && riskB.end) return riskB.end > riskA.end;
		return riskB.start > riskA.start;
	}

	/**
	 * @description Verifies that the supplied key belongs to the enum
	 * @param {string|number|symbol} key
	 * @param {Risk.Risk|Risk.Likelihood|Risk.SeveritytheEnum} theEnum
	 * @returns {key is (keyof T)}
	 */
	public static isKeyOf<T = typeof Risk.Risk | typeof Risk.Likelihood | typeof Risk.Severity>(key: string | number | symbol, theEnum: T): key is keyof T {
		return !!theEnum[key];
	}

	/**
	 * @description Reliably returns an EnumKey by the supplied id
	 * @param {string|number|null} id 
	 * @param {Risk.Risk|Risk.Likelihood|Risk.SeveritytheEnum} theEnum
	 * @param {number} defaultId 
	 * @returns {keyof T} An key from the enum or null
	 */
	public static getEnumKeyById<T = typeof Risk.Risk | typeof Risk.Likelihood | typeof Risk.Severity>(id: string | number | null, theEnum: T): keyof T | null {
		if (!id) return null;
		const intId = typeof id === 'string' ? Number(id) : id;
		const items = Object.keys(theEnum).filter(key => theEnum[key].id === intId);
		if (items.length && Risk.isKeyOf(items[0], theEnum)) return items[0] as keyof T;
		return null;
	}

	/**
	 * @description Reliably returns an EnumItem by the supplied id
	 * @param {string|number|null} id 
	 * @param {Risk.Risk|Risk.Likelihood|Risk.SeveritytheEnum} theEnum
	 * @param {number} defaultId 
	 * @returns {T[keyof T]} An Item from the enum or null
	 */
	public static getEnumById<T = typeof Risk.Risk | typeof Risk.Likelihood | typeof Risk.Severity>(id: string | number | null, theEnum: T): T[keyof T] | null {
		if (!id) return null;
		const intId = typeof id === 'string' ? Number(id) : id;
		const items = Object.keys(theEnum).filter(key => theEnum[key].id === intId).map(key => theEnum[key]);
		if (items.length) return items[0];
		return null;
	}

	/**
	 * @description Reliably returns an EnumItem by the supplied key
	 * @param {string|number|null} key
	 * @param {Risk.Risk|Risk.Likelihood|Risk.SeveritytheEnum} theEnum
	 * @param {number} defaultId 
	 * @returns {T[keyof T]} An Item from the enum or null
	 */
	public static getEnumByKey<T = typeof Risk.Risk | typeof Risk.Likelihood | typeof Risk.Severity>(key: string | number | null, theEnum: T): T[keyof T] | null {
		if (!key) return null;
		const strKey = (typeof key !== 'string' ? String(key) : key).toLowerCase();
		const items = Object.keys(theEnum).filter(enumKey => enumKey.toLowerCase() === strKey).map(key => theEnum[key]);
		if (items.length) return items[0];
		return null;
	}

	/**
	 * @description Returns the id that corresponds to the Risk which should be selected givne the provided likelihood and severity
	 * @param {number|string|null} likelihood 
	 * @param {number|string|null} severity 
	 * @param {number} defaultRiskId
	 */
	public static calculateRisk(likelihoodId: string | number | null, severityId: string | number | null): (typeof Risk.Risk)[keyof typeof Risk.Risk] {
		const signature = Risk.className + ".calculateRisk: ";

		const likelihood = Risk.getEnumById(likelihoodId, Risk.Likelihood);
		const likelihoodConst = likelihood ? likelihood.constant : 1;
		Risk.debug(signature + `Calculating risk from LikelihoodId[${likelihoodId}] found Likelihood[${likelihood && likelihood.id}] with Const[${likelihoodConst}]`);

		const severity = Risk.getEnumById(severityId, Risk.Severity);
		const severityConst = severity ? severity.constant : 1;
		Risk.debug(signature + `Calculating risk from SeverityId[${severityId}] found Severity[${severity && severity.id}] with Const[${severityConst}]`);

		const riskLevel = likelihoodConst * severityConst;
		const matchingRisks = Object.keys(Risk.Risk).filter(key => {
			const risk = Risk.getEnumByKey(key, Risk.Risk);
			if (!risk) return false;
			if (risk.start > riskLevel) return false;
			if (Risk.hasOwnProperty(risk, 'end') && risk.end && risk.end < riskLevel) return false;
			return true;
		});
		if (matchingRisks.length) {
			return Risk.Risk[matchingRisks[0]];
		}
		return Risk.Risk.Low;
	}

	/**
	 * @description Typeguard for hasOwnProperty without needing to fallback to tsIgnore
	 * @param obj 
	 * @param prop 
	 * @returns 
	 */
	public static hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
		return obj.hasOwnProperty(prop);
	}

	/**
	 * @description Centralised enable/disable of log messages for this class
	 * @param {unknown} msg
	 * @returns {void}
	 */
	public static debug(msg:unknown):void {
		// console.log(msg);
	}
}