<div class="row">
    <div class="col-9 text-lg-left">
        <h1 class="text-uppercase text-orange">User Management</h1>
    </div>
    <div class="col-3 text-md-right">
        <button class="btn btn-md btn-orange" (click)="handleUserClick('new')">New User</button>
    </div>
</div>

<div class="row">
  <div class="col-12 col-sm-8 col-md-6">
    <div class="form-group">
      <label>Include Archived Users:</label>
      <select2
        class="form-control select2-form-control"
        [data]="includeArchiveSelectOptions" [options]="{ placeholder: 'Include Archived Users' }"
        [value]="form.includeArchiveUsers.value"
        (valueChanged)="form.includeArchiveUsers.value = $event.value">
      </select2>
    </div>
  </div>
</div>


<div class="row">
    <div class="col-12">
        <table class="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">
                    <span class="sortable-col-name"
                          (click)="handleSortChange('name')"
                          [ngClass]="{active: sortBy === 'name', reverse: sortOrder !== 'asc'}">
                            Name<i class="fa fa-caret-up"></i>
                    </span>
                </th>
                <th scope="col">
                    <span class="sortable-col-name"
                          (click)="handleSortChange('username')"
                          [ngClass]="{active: sortBy === 'username', reverse: sortOrder !== 'asc'}">
                            E-mail<i class="fa fa-caret-up"></i>
                    </span>
                </th>
                <th scope="col">
                    <span class="sortable-col-name"
                          (click)="handleSortChange('role')"
                          [ngClass]="{active: sortBy === 'role', reverse: sortOrder !== 'asc'}">
                            Role<i class="fa fa-caret-up"></i>
                    </span>
                </th>
                <th scope="col">
                    <span class="sortable-col-name"
                          (click)="handleSortChange('createdAt')"
                          [ngClass]="{active: sortBy === 'createdAt', reverse: sortOrder !== 'asc'}">
                            Created At<i class="fa fa-caret-up"></i>
                    </span>
                </th>
                <th scope="col" *ngIf="includeArchived">
                    <span class="sortable-col-name"
                          (click)="handleSortChange('isArchived')"
                          [ngClass]="{active: sortBy === 'isArchived', reverse: sortOrder !== 'asc'}">
                            Is Archived<i class="fa fa-caret-up"></i>
                    </span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr class="hover-cursor" *ngFor="let user of users" (click)="handleUserClick(user.id)">
                <td>{{user.lastName + ' ' + user.firstName}}</td>
                <td>{{user.username}}</td>
                <td>{{user.role}}</td>
                <td>{{user.createdAt | date : 'mediumDate'}}</td>
                <td *ngIf="includeArchived" class="text-center"><i class="fa fa-check" *ngIf="user.isArchived"></i></td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <pagination
                aria-label="Default pagination"
                [collectionSize]="totalItems"
                [(page)]="currentPage"
                (pageChange)="handlePageChange()"
                [(pageSize)]="pageSize"
        ></pagination>
    </div>
</div>
