<preloader-wrapper [isLoaded]="resourcePack">
  <div *ngIf="resourcePack">
    <h1 class="text-uppercase text-orange">Resource Pack: {{resourcePack.name}}</h1>
    <div class="form-group">
      <label for="name">Name</label>
      <input type="text" name="name" id="name" [(ngModel)]="resourcePack.name" class="form-control" placeholder="Name">
      <small class="text-muted">Resource pack name</small>
    </div>
    <button class="btn btn-success rounded-0" [disabled]="updating" (click)="updateName()">
      <span class="capitalize">{{ updating ? '...' : 'Update' }}</span>
    </button>
    <hr/>
    <div class="row">
      <div class="col-8">
        <div class="row" *ngIf="resourcePack.documents.length;else empty">
          <div class="col">

            <table class="table table-striped table-inverse">
              <thead class="thead-inverse">
                <tr>
                  <th>FileName</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let document of resourcePack.documents">
                  <td scope="row" width="100%">{{document.path}}</td>
                  <td width="170px" class="btn-group">
                    <a (click)="download(document)" class="btn btn-primary btn-sm text-white">Download</a>
                    <a class="btn btn-danger btn-sm text-white" (click)="deleteDocument(document)">Delete</a>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <div class="col">
        <document-uploads
          (documentUploaded)="addNewDocumentToResourcePack($event)"
          [disableTicking]="true"
          [disableSend]="true"
          [displayUploads]="false"
        ></document-uploads>
      </div>
    </div>
  </div>

</preloader-wrapper>

<ng-template #empty>
  <div class="text-center">No Documents</div>
</ng-template>