import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { EnumService } from "../../../../../service/EnumService";
import { IEnumsListOutputModel } from "../../../../../../common/contracts/enums";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";

@Component({
  selector: 'app-groups-component',
  templateUrl: './enums.component.html',
  styleUrls: ['./enums.component.scss']
})
export class EnumsComponent implements OnInit {

  public enums: IEnumsListOutputModel = [];

  constructor(
    private enumsService: EnumService,
    private router: Router,
    private route: ActivatedRoute,
    private errorService: ErrorHandlerService) { }

  ngOnInit() {
    this.loadEnumsList();
  }

  public handleEnumClick(enumName: string) {
    this.router.navigate([`${enumName}`], {relativeTo: this.route});
  }

  public loadEnumsList() {
    this.enumsService.getEnums().subscribe((data) => {
      this.enums = data;
    }, err => this.errorService.handleHttpError(err));
  }

}
