/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../directives/numbersOnly.directive";
import * as i3 from "@angular/common";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "./dateOffsetPicker.component";
var styles_DateOffsetPicker = [""];
var RenderType_DateOffsetPicker = i0.ɵcrt({ encapsulation: 0, styles: styles_DateOffsetPicker, data: {} });
export { RenderType_DateOffsetPicker as RenderType_DateOffsetPicker };
function View_DateOffsetPicker_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "input", [["class", "col-2 form-control text-center p-1"], ["maxlength", "4"], ["numbersOnly", ""], ["type", "text"]], [[8, "placeholder", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "keyup"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 9).onInputChange($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.offsetAmount = $event) !== false);
        ad = (pd_5 && ad);
    } if (("input" === en)) {
        var pd_6 = (_co.onInput($event) !== false);
        ad = (pd_6 && ad);
    } if (("keyup" === en)) {
        var pd_7 = (_co.onKeyUp($event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(2, 540672, null, 0, i1.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i0.ɵprd(1024, null, i1.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i1.MaxLengthValidator]), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(5, 671744, null, 0, i1.NgModel, [[8, null], [6, i1.NG_VALIDATORS], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"], options: [1, "options"] }, { update: "ngModelChange" }), i0.ɵpod(6, { standalone: 0 }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(8, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), i0.ɵdid(9, 16384, null, 0, i2.NumbersOnlyDirective, [i0.ElementRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = "4"; _ck(_v, 2, 0, currVal_9); var currVal_10 = _co.offsetAmount; var currVal_11 = _ck(_v, 6, 0, true); _ck(_v, 5, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = "#"; var currVal_1 = (i0.ɵnov(_v, 2).maxlength ? i0.ɵnov(_v, 2).maxlength : null); var currVal_2 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 8).ngClassValid; var currVal_7 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_DateOffsetPicker_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeOffsetOption(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_DateOffsetPicker_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "div", [["class", "form-inline"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 18, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DateOffsetPicker_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 15, "div", [["class", "drop-down-wrapper pt-1"], ["ngbDropdown", ""], ["placement", "bottom-right"]], [[2, "show", null]], null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(6, { "pl-2": 0 }), i0.ɵdid(7, 737280, null, 3, i4.NgbDropdown, [i0.ChangeDetectorRef, i4.NgbDropdownConfig, i3.DOCUMENT, i0.NgZone, i0.ElementRef, i0.Renderer2, [2, i4.ɵm]], { placement: [0, "placement"] }, null), i0.ɵqud(335544320, 1, { _menu: 0 }), i0.ɵqud(335544320, 2, { _menuElement: 0 }), i0.ɵqud(335544320, 3, { _anchor: 0 }), (_l()(), i0.ɵeld(11, 0, null, null, 3, "a", [["aria-haspopup", "true"], ["class", "dropdown-link dropdown-toggle"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i0.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i0.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i0.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 16384, null, 0, i4.NgbDropdownToggle, [i4.NgbDropdown, i0.ElementRef], null, null), i0.ɵprd(2048, [[3, 4]], i4.NgbDropdownAnchor, null, [i4.NgbDropdownToggle]), (_l()(), i0.ɵted(14, null, ["", ""])), (_l()(), i0.ɵeld(15, 0, [[2, 0]], null, 4, "div", [["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i0.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i0.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i0.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i0.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(16, 16384, [[1, 4]], 1, i4.NgbDropdownMenu, [i4.NgbDropdown], null, null), i0.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DateOffsetPicker_2)), i0.ɵdid(19, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showOffsetAmount; _ck(_v, 3, 0, currVal_0); var currVal_2 = "drop-down-wrapper pt-1"; var currVal_3 = _ck(_v, 6, 0, _co.showOffsetAmount); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = "bottom-right"; _ck(_v, 7, 0, currVal_4); var currVal_10 = _co.offsetOptions; _ck(_v, 19, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵnov(_v, 7).isOpen(); _ck(_v, 4, 0, currVal_1); var currVal_5 = i0.ɵnov(_v, 12).dropdown.isOpen(); _ck(_v, 11, 0, currVal_5); var currVal_6 = (_co.selectedOffsetPeriod || "Select Offset"); _ck(_v, 14, 0, currVal_6); var currVal_7 = true; var currVal_8 = i0.ɵnov(_v, 16).dropdown.isOpen(); var currVal_9 = i0.ɵnov(_v, 16).placement; _ck(_v, 15, 0, currVal_7, currVal_8, currVal_9); }); }
export function View_DateOffsetPicker_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "date-offset-picker", [], null, null, null, View_DateOffsetPicker_0, RenderType_DateOffsetPicker)), i0.ɵdid(1, 114688, null, 0, i5.DateOffsetPicker, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DateOffsetPickerNgFactory = i0.ɵccf("date-offset-picker", i5.DateOffsetPicker, View_DateOffsetPicker_Host_0, { periodField: "periodField", offsetField: "offsetField" }, {}, []);
export { DateOffsetPickerNgFactory as DateOffsetPickerNgFactory };
