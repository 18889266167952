import { Component } from "@angular/core";
import { ConfirmOptions, ConfirmModalState } from "../../../service/ModalService";

/**
 * The component displayed in the confirmation modal opened by the ConfirmService.
 */
@Component({
  selector: 'confirm-modal-component',
  templateUrl: './confirmModal.component.html',
})
export class ConfirmModalComponent {
  options: ConfirmOptions;
  constructor(private state: ConfirmModalState) {
    this.options = state.options;
  }

  yes() {
    this.state.modal.close('confirmed');
  }

  no() {
    this.state.modal.dismiss('not confirmed');
  }
}
