<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-10 pb-2">
                Welcome {{ currentUserService.currentUserFullName | async }}
            </div>
            <div class="col-2 text-right">
                <div ngbDropdown class="dropdown-link-wrapper" placement="bottom-right">
                    <a class="dropdown-link" ngbDropdownToggle>Export to</a>
                    <div ngbDropdownMenu>
                        <button class="dropdown-item" (click)="handleCsvExport()">CSV file</button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <h1 class="main-top-header">DASHBOARD</h1>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card-deck">
            <div class="card text-white bg-danger mb-3 top-card-filter"
                 (click)="toggleTopCardFilter('overdue')"
                 [ngClass]="{'active': dashboardFilter.filter.dueFilter === 'overdue'}">
                <div class="card-body">
                    <h1 class="card-title text-center text-xxl">{{alertCount}}</h1>
                </div>
                <div class="card-footer text-center">OVERDUE</div>
            </div>
            <div class="card text-white bg-warning mb-3 top-card-filter"
                 (click)="toggleTopCardFilter('alert')"
                 [ngClass]="{'active': dashboardFilter.filter.dueFilter === 'alert'}">
                <div class="card-body">
                    <h1 class="card-title text-center text-xxl">{{warningCount}}</h1>
                </div>
                <div class="card-footer text-center">Requires Attention</div>
            </div>
            <div class="card text-white bg-success mb-3 top-card-filter"
                 (click)="toggleTopCardFilter('remaining')"
                 [ngClass]="{'active': dashboardFilter.filter.dueFilter === 'remaining'}">
                <div class="card-body">
                    <h1 class="card-title text-center text-xxl">{{taskCount}}</h1>
                </div>
                <div class="card-footer text-center">Remaining Tasks</div>
            </div>
            <div class="card text-white bg-light mb-3 top-card-filter" [routerLink]="['/drm-dashboard']">
                <div class="card-body">
                    <h1 class="card-title text-center text-dark text-xxl">{{notificationCount}}</h1>
                </div>
                <div class="card-footer text-center text-dark">Review Tasks</div>
            </div>
        </div>
    </div>

    <dashboard-filter #dashboardFilter class="w-100"></dashboard-filter>

    <div class="col-12">
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th scope="col">
                        <span class="sortable-column"
                              (click)="handleSortChange('category')"
                              [ngClass]="{active: dashboardFilter.filter.sortBy === 'category', reverse: dashboardFilter.filter.order !== 'asc'}">
                            Type<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-column"
                              (click)="handleSortChange('summary')"
                              [ngClass]="{active: dashboardFilter.filter.sortBy === 'summary', reverse: dashboardFilter.filter.order !== 'asc'}">
                            Form<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-column"
                            (click)="handleSortChange('location')"
                            [ngClass]="{active: dashboardFilter.filter.sortBy === 'location', reverse: dashboardFilter.filter.order !== 'asc'}"
                        >
                            Location<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-column"
                              (click)="handleSortChange('assignedTo')"
                              [ngClass]="{active: dashboardFilter.filter.sortBy === 'assignedTo', reverse: dashboardFilter.filter.order !== 'asc'}">
                            Assigned To<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col" class="text-center">
                        <span class="sortable-column"
                            (click)="handleSortChange('createdAt')"
                            [ngClass]="{active: dashboardFilter.filter.sortBy === 'createdAt', reverse: dashboardFilter.filter.order !== 'asc'}">
                            Created At<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col" class="text-center">
                        <span class="sortable-column"
                            (click)="handleSortChange('dueAt')"
                            [ngClass]="{active: dashboardFilter.filter.sortBy === 'dueAt', reverse: dashboardFilter.filter.order !== 'asc'}">
                            Due Date<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col" class="text-center" *ngIf="dashboardFilter.filter.showAll !== 'active'">
                        <span class="sortable-column"
                              (click)="handleSortChange('archivedAt')"
                              [ngClass]="{active: dashboardFilter.filter.sortBy === 'archivedAt', reverse: dashboardFilter.filter.order !== 'asc'}">
                            {{dashboardFilter.filter.showAll === 'deleted' ? 'Deleted On' : 'Completed On'}}<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    class="hover-cursor"
                    [ngClass]="{
                        'table-danger': record.isAlert, 
                        'table-warning': record.isWarning, 
                        'table-success':  !record.isAlert && !record.isWarning, 
                        'table-secondary': record.isFinalized
                    }"
                    *ngFor="let record of dashboardRecords"
                    (click)="progressForm(record)"
                >
                    <td>{{record.type}}</td>
                    <td>{{record.title}}</td>
                    <td>{{record.location}}</td>
                    <td>
                        <span *ngIf="record.assignedUser">
                            {{ record.assignedUser }}
                        </span>
                        <span *ngIf="!record.assignedUser && record.assignedGroup">
                            {{ record.assignedGroup }} Department
                        </span>
                    </td>
                    <td class="text-center">{{record.createdAtDateString}}</td>
                    <td class="text-center">{{record.dueDateString}}</td>
                    <td class="text-center">{{record.archivedAtDateString}}</td>
                </tr>
                <tr *ngIf="dashboardRecords.length === 0">
                    <td [colSpan]="dashboardFilter.filter.showAll === 'active' ? 6 : 7" class="text-center">
                        No Tasks
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12" *ngIf="dashboardRecords.length > 0">
        <pagination
                [collectionSize]="totalForms"
                [(page)]="currentPage"
                (pageChange)="handlePageChange()"
                [pageSize]="pageSize"
                (pageSizeChange)="handlePageSizeChange($event)"
        ></pagination>
    </div>
</div>
