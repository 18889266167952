import { Injectable } from '@angular/core';
import { GenericService } from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
	GroupsPaginationResponse,
	ICreateUserGroupInputModel,
	IGroupsSortedField,
	IUpdateUserGroupInputModel,
	IUserGroupOutputModel
} from "../../../common/contracts/groups";
import { ISortOrderValue } from "../../../common/contracts/common";
import { Observable, of } from "rxjs";
import { share, map, tap } from "rxjs/operators";
import { logger } from 'service/util/Logger';

@Injectable()
export class GroupsService extends GenericService {

	private className = "GroupsService";

	static readonly apiPrefix = '/api/groups';

	private cachedGroups: IUserGroupOutputModel[] | null = null;
	private readonly getGroupsRequest: Observable<IUserGroupOutputModel[]>;

	constructor(private http: HttpClient) {
		super();

		const queryParams = new HttpParams({
			fromObject: {
				skip: '0',
				limit: '50',
				sortBy: 'groupName',
				order: 'asc',
			}
		});

		this.getGroupsRequest = this.http.get<GroupsPaginationResponse>(GroupsService.apiPrefix, { params: queryParams }).pipe(
			map(data => {
				this.cachedGroups = data.items;
				setTimeout(() => {
					this.cachedGroups = null; // invalidate groups cache in 20 minutes
				}, 20 * 60 * 1000);
				return data.items;
			}),
			share() //share results between multiple subscriptions
		);
	}

	public getGroups(): Observable<IUserGroupOutputModel[]> {
		return this.cachedGroups ? of(this.cachedGroups) : this.getGroupsRequest;
	}

	public async getGroupByName(name: string) {
		const signature = this.className + '.getGroupByName: ';
		const groups = await this.getGroups().toPromise();
		const targetGroup = groups.find(group => group.groupName.toLowerCase() === name.toLowerCase());

		logger.silly(signature + `${targetGroup?`Found Group[${targetGroup.id}]`:'Did not find group'} by Name[${name}] in Total[${groups.length}] groups`);

		return targetGroup;
	}

	public async getGroupIdByName(name: string): Promise<number | null> {
		const group = await this.getGroupByName(name);

		if (group) {
			return group.id;
		}

		return null;
	}

	public getPagedGroups(offset: number = 0, pageSize: number = 20, sortBy: IGroupsSortedField = 'groupName', order: ISortOrderValue = 'asc', countUsers: boolean = true) {
		const queryParams = new HttpParams({
			fromObject: {
				skip: offset.toString(),
				limit: pageSize.toString(),
				sortBy,
				order,
				countUsers: countUsers ? '1' : '0',
			}
		});
		return this.http.get<GroupsPaginationResponse>(GroupsService.apiPrefix, { params: queryParams });
	}

	public getGroupById(id: number) {
		return this.http.get<IUserGroupOutputModel>(`${GroupsService.apiPrefix}/${id}`);
	}

	public createGroup(groupData: ICreateUserGroupInputModel) {
		return this.http.post(GroupsService.apiPrefix, groupData).pipe(tap(() => {
			this.cachedGroups = null;
		}));
	}

	public updateGroup(groupChanges: IUpdateUserGroupInputModel) {
		return this.http.put(GroupsService.apiPrefix, groupChanges).pipe(tap(() => {
			this.cachedGroups = null;
		}));
	}

	public archiveGroup(id: number) {
		return this.http.delete(`${GroupsService.apiPrefix}/${id}`).pipe(tap(() => {
			this.cachedGroups = null;
		}));
	}

}
