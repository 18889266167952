import { Component, OnInit } from '@angular/core';
import {FormField} from "../../../model/Form";
import {FormComponent} from "../../../model/FormComponent";
import {Session} from "../../../service/util/Session";
import {AuthService} from "../../../service/security/AuthService";
import { Router } from '@angular/router';
import {JWTPayload} from "../../../../model/Auth";
import {environment} from "../../../environments/environment";
import {ModalService} from "../../../service/ModalService";
// import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends FormComponent implements OnInit {

  emailAddress: FormField<String> = new FormField<String>('', {
    validation: FormField.ValidationMethods.IsEmail
  });

  password: FormField<String> = new FormField<String>('', {
    validation: FormField.ValidationMethods.IsNotBlank
  });

  constructor(
    private session: Session,
    private authService: AuthService,
    public router: Router,
    private modalService: ModalService
  ) {
    super(router);
  }

  ngOnInit() {
    this.registerFormFields();
  }

  registerFormFields() {
    this.formFields.push(
      this.password,
      this.emailAddress
    );

    //Debug Values
    this.emailAddress.value = environment.defaultAuthEmail || '';
    this.password.value = environment.defaultAuthPassword || '';
  }

  onSubmit() {
    this.session.lockInputRx(
      this.authService.login({
        username: this.emailAddress.value as string,
        password: this.password.value as string
      })).subscribe( (data: JWTPayload) => {
        // Grant the credentials
        this.authService.token = data.accessToken;
        this.session.credentials = data as JWTPayload;
      
        if( this.emailAddress.value === this.password.value ) {
          
          if( !environment.production ) {
            console.warn("User password is equal to user email");
            console.warn("In Production ENV User would be warned and requested to update password");
            
            this.router.navigateByUrl('/dashboard');
          } else {
            this.modalService.confirmRx({
              title: "Password Security",
              message: "To enhance security, we request that you reset your password using your account settings.",
              confirmText: "Reset Password",
              declineText: "Ok"
            }).subscribe((isConfirmed) => {
              if (isConfirmed) {
                this.router.navigateByUrl('/profile');
              } else {
                // Allow the user to move onto the dashboard
                this.router.navigateByUrl('/dashboard');
              }
            });
          }
        } else {
          // Grant the credentials
          this.authService.token = data.accessToken;
          this.session.credentials = data as JWTPayload;
          
          this.router.navigateByUrl('/dashboard');
        }
      }, err => {
        if( err.status === 403 ) {
          this.emailAddress.value = '';
          this.password.value = '';
        }

        this.errorMessage = err.message;
      });
  }
  
  /*private openPasswordReset():Promise<boolean> {
    return this.router.navigateByUrl(
      this.router.createUrlTree(
        ['forgot'],
        {
          queryParams: {
            email: this.emailAddress.value
          }
        }
      )
    );
  }*/
}
