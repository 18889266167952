<header [formData]="formData" *ngIf="!hideHeader"></header>
<page-title text="Audit Review" titleType="2"></page-title>

<form>

    <div class="row">
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <label>Reassign Report to another Staff Member:</label>
                <button-toggle [value]="form.reassign.value" (valueChange)="showReassignOptions($event)" [disabled]="readOnly"></button-toggle>
            </div>
        </div>
        <div class="col-12 col-sm-6" *ngIf="form.reassign.value===true">
            <div class="form-group">
                <label>&nbsp;</label>
                <user-select 
                    [field]="form.reassignToUserId" 
                    [disabled]="readOnly"
                ></user-select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-xl-6">
            <buttons-switch
                [disabled]="readOnly"
                [options]="facilityOptions"
                [(value)]="form.facilityOption.value"
            >
                <!--[(value)]="form.followUpType.value"-->
            </buttons-switch>
        </div>
        <div class="col-12 col-xl-6" *ngIf="form.facilityOption.value!=='facilitywide'">
            <div class="form-group py-3 mb-0">
                <input
                    type="text"
                    class="form-control {{!readOnly && form.facilityDetail.iifIsValid('border-success','border-danger')}}"
                    placeholder="Additional Detail"
                    [(ngModel)]="form.facilityDetail.value"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="readOnly"
                >
                <small class="form-text text-danger" *ngIf="form.facilityDetail.showErrorHelp()">Invalid Detail</small>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-8 border-lg-right border-dashed">
            <div class="row my-3">
                <div class="col-12">
                    <div class="form-group">
                        <label>3. Analysis/Findings/Action - Any Adverse results or action items identifed?</label>
                        <textarea
                            class="form-control {{!readOnly && form.summary.iifIsValid('border-success','border-danger')}}"
                            replaceAmpersand
                            autosize
                            [minRows]="3"
                            [placeholder]="readOnly ? '' : 'Summary'"
                            [spellcheck]="true"
                            [(ngModel)]="form.summary.value"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="readOnly"
                        ></textarea>
                    </div>
                    <small class="form-text text-danger" *ngIf="form.summary.showErrorHelp()">Invalid Summary</small>
                </div>
            </div>
            <div class="row mt-3 mb-1">
                <div class="col-12">
                    <div class="form-group">
                        <label>4. Any action/s mentioned in point 3 above?</label>
                        <button-toggle
                            [value]="form.anyActions.value"
                            [disabled]="readOnly"
                            (valueChange)="updateActionOptions($event)">
                        </button-toggle>
                    </div>
                </div>
            </div>
            <div class="row my-3" *ngIf="form.anyActions.value">
                <div class="col-12">
                    <div class="form-group">
                        <label>Summary of Action/s</label>
                        <textarea
                            class="form-control {{!readOnly && form.actionSummary.iifIsValid('border-success','border-danger')}}"
                            replaceAmpersand
                            autosize
                            [minRows]="3"
                            [placeholder]="readOnly ? '' : 'Summary'"
                            [spellcheck]="true"
                            [(ngModel)]="form.actionSummary.value"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="readOnly"
                        ></textarea>
                    </div>
                    <small class="form-text text-danger" *ngIf="form.summary.showErrorHelp()">Invalid Summary</small>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <div class="form-group">
                        <label>5. Are all actions resolved?</label>
                        <button-toggle
                            [value]="form.actionsResolved.value"
                            [disabled]="readOnly"
                            (valueChange)="form.actionsResolved.value = $event">
                        </button-toggle>
                    </div>
                </div>
            </div>
            <div class="row mb-3" *ngIf="!form.actionsResolved.value">
                <div class="col-12">
                    <follow-up-widget
                        #followUpWidget
                        hideHeader="true"
                        [model]="form.followUps.value"
                        (modelChange)="form.followUps.value=$event"
                        [readOnly]="readOnly"
                    ></follow-up-widget>
                </div>
            </div>
        </div>
        <div class="col-12 offset-0 col-lg-4 pt-0 pt-lg-4">
            <div class="row">
                <div class="col-12 offset-0 col-xl-10 offset-xl-1">
                    <document-uploads [(documents)]="documents" [readOnly]="readOnly" [disableTicking]="true"></document-uploads>
                </div>
            </div>
        </div>
    </div>

    <footer 
        [formRecord]="formRecord" 
        [readOnly]="readOnly" 
        (onSubmit)="submit($event)">
    </footer>
</form>

