import {Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges} from '@angular/core';
import {Session} from "../../service/util/Session";
import {FormField} from "../../model/Form";
import { Select2Component } from 'ng2-select2';
import {EnumService} from "../../service/EnumService";
import {FormEnum} from "../../../model/FormEnum";
import { IEnumsOutputModel } from "../../../common/contracts/enums";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";

@Component({
  selector: 'standard-select',
  template: `<select2
    #select2Component
    class="form-control select2-form-control {{disabled?'bg-grey':''}} {{!disabled && field && field.iifIsValid('border-success','border-danger')}}"
    [data]="enumSelectData"
    [options]="enumSelectOptions"
    (valueChanged)="valueChanged($event.data)"
    [value]="defaultValue"
    [disabled]="disabled"
  ></select2>`
})
export class DocumentStandardSelectComponent implements OnInit,OnChanges {

  @Input()
  field: FormField<any>;

  @Input()
  placeholder: string = "";

  @Input()
  enum/*Name*/: string;

  @Input()
  allowClear: boolean = true;

  @Input()
  disabled: boolean = false;
  
  private _value: string = '';
  
  @Input('value')
  get value() {
    return this._value;
  }
  set value(newValue: string) {
    this._value = newValue;
  }

  @Output()
  change: EventEmitter<IdTextPair[]> = new EventEmitter<IdTextPair[]>();

  public enumSelectOptions:Select2Options = {
    allowClear: true,
    placeholder: ''
  };

  public enumSelectData: IdTextPair[] = [];

  public defaultValue: string = '';

  // Reference firstNameInput variable inside Component
  @ViewChild('select2Component') select2ComponentRef: Select2Component;

  constructor(
    public enumService: EnumService,
    public session: Session,
    private errorHandler: ErrorHandlerService,
  ) {}
  ngOnChanges() {
    //Force the change detection to cycle again to prevent race
    if( this.enumSelectData.length > 0 && this.field && String(this.field.value) !== this.defaultValue ){
      this.defaultValue = String(this.field.value);
    }
  }
  ngOnInit() {
    if( !this.enum )
      throw("DocumentStandardSelectComponent must have enum defined");

    if( this.placeholder )
      this.enumSelectOptions.placeholder = this.placeholder;

    if( this.allowClear !== null )
      this.enumSelectOptions.allowClear = this.allowClear;

    this.session.lockInputRx(this.enumService.getEnumsByName(this.enum))
      .subscribe( (data:IEnumsOutputModel[]) => {
        let newSelectOptions:IdTextPair[] = [];
        if( this.allowClear )
          newSelectOptions.push({id:"",text:""});

        data.forEach( (_enum:FormEnum) => newSelectOptions.push({ 
          id: String(_enum.id as number), text: _enum.value 
        }));

        this.enumSelectData = newSelectOptions;

        //Force the change detection to cycle again to prevent race
        if( this.field && String(this.field.value) !== this.defaultValue )
          this.defaultValue = String(this.field.value);
        
        if( !this.field && this._value )
          this.defaultValue = this._value;

      }, err => {
        console.error('Error getting enum by name', err);
        this.errorHandler.handleHttpError(err);
      });
  }

  valueChanged(selectedOpts:IdTextPair[]) {
    if( selectedOpts.length === 0 || (
      selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0
    )) {
      if( this.field )
        this.field.value = null;
  
      this.change.emit(selectedOpts);
      
      return;
    }

    if( selectedOpts.length > 1 )
      throw("Selected options unexpectedly contained multiple results");

    if( this.field )
      this.field.value = selectedOpts[0].id;

    this.change.emit(selectedOpts);
  }
}
