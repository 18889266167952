import { Injectable } from '@angular/core';
import {GenericService} from "../GenericService";
import { HttpClient } from "@angular/common/http";
import { ISettingsOutputModel, IUpdateSettingsParams } from "../../../common/contracts/settings";


@Injectable()
export class SettingsService extends GenericService {

  static apiPrefix = '/api/settings';

  constructor(private http: HttpClient) {
    super();
  }

  public getSettings() {
    return this.http.get<ISettingsOutputModel>(SettingsService.apiPrefix);
  }

  public updateSettings(data: IUpdateSettingsParams) {
    return this.http.put(SettingsService.apiPrefix, data);
  }

}
