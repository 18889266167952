import * as Sentry from "@sentry/browser";
import {ErrorHandler, Injectable} from "@angular/core";
import {environment} from "../../environments/environment";

Sentry.init({
  dsn: "https://6f5804dffc7e426ea270aa8c82d0ac5c@sentry.io/1423881",
  environment: environment.environmentName || 'unknown'
});

// I do not fully understand why sentry works this way but if I do not rebind the handler, window events are not logged to console
if( window ) {
  let oldOnError:ErrorEventHandler;
  
  if( window.onerror ) {
    oldOnError = window.onerror;
  }
  
  window.onerror = function(event: ErrorEvent | string, source?: string, fileno?: number, columnNumber?: number, error?: Error): void {
    //console.log(event.message);
    if( oldOnError )
      oldOnError.apply(this, arguments);
  }
}

/*
  Unhandled Exception Handler
 */
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    console.error(error);
    Sentry.captureException(error);
    //throw error;
  }
}