/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./riskModal.component";
import * as i3 from "../../../service/ModalService";
var styles_RiskModalComponent = [".rotate[_ngcontent-%COMP%] {\n\t\t\n\t\t-moz-transform: rotate(-90.0deg);\n\t\t\n\t\t-o-transform: rotate(-90.0deg);\n\t\t\n\t\t-webkit-transform: rotate(-90.0deg);\n\t\t\n\t\tfilter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.083);\n\t\t\n\t\t-ms-filter: \"progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)\";\n\t\t\n\t\ttransform: rotate(-90.0deg);\n\t}"];
var RenderType_RiskModalComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_RiskModalComponent, data: {} });
export { RenderType_RiskModalComponent as RenderType_RiskModalComponent };
function View_RiskModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.text; _ck(_v, 1, 0, currVal_0); }); }
function View_RiskModalComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "th", [], [[8, "rowSpan", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "rotate"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Severity"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.severityOptionCount; _ck(_v, 0, 0, currVal_0); }); }
function View_RiskModalComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.riskText(_v.context.$implicit.id, _v.parent.context.$implicit.id); _ck(_v, 1, 0, currVal_0); }); }
function View_RiskModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RiskModalComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "th", [["scope", "row"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RiskModalComponent_4)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index === 0); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.likelihoodOptions; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.text; _ck(_v, 4, 0, currVal_1); }); }
export function View_RiskModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Risk Matrix"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 13, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 12, "table", [["class", "table table-striped"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 8, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "th", [], [[8, "colSpan", 0]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "th", [["class", "text-center"]], [[8, "colSpan", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Likelihood"])), (_l()(), i0.ɵeld(13, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "th", [], [[8, "colSpan", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RiskModalComponent_1)), i0.ɵdid(16, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(17, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RiskModalComponent_2)), i0.ɵdid(19, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(20, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["OK"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.likelihoodOptions; _ck(_v, 16, 0, currVal_3); var currVal_4 = _co.severityOptions; _ck(_v, 19, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = 2; _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.likelihoodOptionCount; _ck(_v, 11, 0, currVal_1); var currVal_2 = 2; _ck(_v, 14, 0, currVal_2); }); }
export function View_RiskModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "risk-modal", [], null, null, null, View_RiskModalComponent_0, RenderType_RiskModalComponent)), i0.ɵdid(1, 49152, null, 0, i2.RiskModalComponent, [i3.RiskModalState], null, null)], null, null); }
var RiskModalComponentNgFactory = i0.ɵccf("risk-modal", i2.RiskModalComponent, View_RiskModalComponent_Host_0, {}, {}, []);
export { RiskModalComponentNgFactory as RiskModalComponentNgFactory };
