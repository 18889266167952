var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericService } from "./GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import { of } from "rxjs";
import { share, map, tap } from "rxjs/operators";
import { CurrentUserService } from "./currentUser/CurrentUserService";
import { stringifyObjectValues } from "./util/stringifyObjectValues";
var LocationService = /** @class */ (function (_super) {
    __extends(LocationService, _super);
    function LocationService(http, currentUserService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.currentUserService = currentUserService;
        _this.locationsCache = null;
        var queryParams = new HttpParams({
            fromObject: {
                skip: '0',
                limit: '50',
                sortBy: 'name',
                order: 'asc',
            }
        });
        _this.getLocationsRequest = _this.http.get(LocationService.apiPrefix, { params: queryParams }).pipe(map(function (data) {
            _this.locationsCache = data.items; //cache results
            return data.items;
        }), share());
        /**
         * Invalidate cache on logout
         */
        _this.currentUserService.logoutEvents.subscribe(function () {
            _this.locationsCache = null;
        });
        return _this;
    }
    LocationService.prototype.getLocations = function () {
        return this.locationsCache ? of(this.locationsCache) : this.getLocationsRequest;
    };
    LocationService.prototype.getPagedLocations = function (params) {
        var queryParams = new HttpParams({
            fromObject: stringifyObjectValues(params),
        });
        return this.http.get(LocationService.apiPrefix, { params: queryParams });
    };
    LocationService.prototype.getLocationById = function (id) {
        return this.http.get(LocationService.apiPrefix + "/" + id);
    };
    LocationService.prototype.createLocation = function (locationData) {
        var _this = this;
        return this.http.post(LocationService.apiPrefix, locationData).pipe(tap(function () {
            _this.locationsCache = null;
        }));
    };
    LocationService.prototype.updateLocation = function (locationChanges) {
        var _this = this;
        return this.http.put(LocationService.apiPrefix, locationChanges).pipe(tap(function () {
            _this.locationsCache = null;
        }));
    };
    LocationService.prototype.archiveLocation = function (id) {
        var _this = this;
        return this.http.delete(LocationService.apiPrefix + "/" + id).pipe(tap(function () {
            _this.locationsCache = null;
        }));
    };
    LocationService.apiPrefix = '/api/location';
    return LocationService;
}(GenericService));
export { LocationService };
