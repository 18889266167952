
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <button
            *ngFor="let option of options"
            class="btn btn-secondary {{value === option.value ? 'active' : ''}}"
            [disabled]="disabled"
            (click)="handleClick(option.value)">
            {{ option.text }}
        </button>
    </div>
  