<div class="modal-header">
    <h4 class="modal-title">{{options.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="no()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12 col-lg-4 pull-right">
            <div class="form-group">
                <label>Start Date</label>
                <date-picker 
                    [disablePastDates]="true"
                    [formField]="options.startDateField"
                ></date-picker>
            </div>
        </div>
    </div>
    <cron-editor 
        [(cron)]="options.expression" 
        [(options)]="options.editor"
    ></cron-editor>
    <span class="d-none">{{options.expression}}</span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="yes()" ngbAutofocus>Save</button>
    <button type="button" class="btn btn-secondary" (click)="no()">Cancel</button>
</div>
