/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./uploadModal.component";
import * as i3 from "../../../service/ModalService";
import * as i4 from "../../../service/DocumentService";
var styles_UploadModalComponent = [".rotate[_ngcontent-%COMP%] {\n\t\t\n\t\t-moz-transform: rotate(-90.0deg);\n\t\t\n\t\t-o-transform: rotate(-90.0deg);\n\t\t\n\t\t-webkit-transform: rotate(-90.0deg);\n\t\t\n\t\tfilter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.083);\n\t\t\n\t\t-ms-filter: \"progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)\";\n\t\t\n\t\ttransform: rotate(-90.0deg);\n\t}\n\n\t.hidden-input[_ngcontent-%COMP%] {\n\t\tdisplay: none;\n\t}"];
var RenderType_UploadModalComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_UploadModalComponent, data: {} });
export { RenderType_UploadModalComponent as RenderType_UploadModalComponent };
function View_UploadModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-upload"]], null, null, null, null, null))], null, null); }
function View_UploadModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-circle-o-notch fa-spin"]], null, null, null, null, null))], null, null); }
export function View_UploadModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Select File"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 13, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 12, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 10, "button", [["class", "btn btn-orange rounded-0 btn-block"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 19).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_UploadModalComponent_1)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_UploadModalComponent_2)), i0.ɵdid(14, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(16, 0, null, null, 2, "strong", [["class", "upload-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(17, null, ["", ""])), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(19, 0, [["fileInput", 1]], null, 0, "input", [["class", "hidden-input"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleFileSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform(_co.state.isUploading)); _ck(_v, 11, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform(_co.state.isUploading)); _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.state.isUploading)); _ck(_v, 8, 0, currVal_0); var currVal_3 = (i0.ɵunv(_v, 17, 0, i0.ɵnov(_v, 18).transform(_co.state.isUploading)) ? "UPLOADING" : "UPLOAD FILE"); _ck(_v, 17, 0, currVal_3); }); }
export function View_UploadModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "upload-modal", [], null, null, null, View_UploadModalComponent_0, RenderType_UploadModalComponent)), i0.ɵdid(1, 49152, null, 0, i2.UploadModalComponent, [i3.UploadModalState, i4.DocumentService], null, null)], null, null); }
var UploadModalComponentNgFactory = i0.ɵccf("upload-modal", i2.UploadModalComponent, View_UploadModalComponent_Host_0, {}, {}, []);
export { UploadModalComponentNgFactory as UploadModalComponentNgFactory };
