/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./buttonsSwitch.component";
var styles_ButtonsSwitchComponent = ["label.btn-secondary.disabled[_ngcontent-%COMP%] {\n          background-color: #BFBFBF;\n          border-color: #BFBFBF;\n      }\n\n      label.btn-secondary.disabled.active[_ngcontent-%COMP%] {\n          background-color: #6c757d;\n          border-color: #6c757d;\n      }"];
var RenderType_ButtonsSwitchComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ButtonsSwitchComponent, data: {} });
export { RenderType_ButtonsSwitchComponent as RenderType_ButtonsSwitchComponent };
function View_ButtonsSwitchComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "label", [["class", "btn btn-secondary px-4"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "disabled": 0, "active": 1 }), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn btn-secondary px-4"; var currVal_1 = _ck(_v, 2, 0, (_co.disabled || _v.context.$implicit.disabled), (_v.context.$implicit.value === _co.value)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.text; _ck(_v, 3, 0, currVal_2); }); }
export function View_ButtonsSwitchComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "btn-group-toggle d-block text-uppercase p-3 text-center justify-content-around d-flex"], ["data-toggle", "buttons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ButtonsSwitchComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ButtonsSwitchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "buttons-switch", [], null, null, null, View_ButtonsSwitchComponent_0, RenderType_ButtonsSwitchComponent)), i0.ɵdid(1, 49152, null, 0, i2.ButtonsSwitchComponent, [], null, null)], null, null); }
var ButtonsSwitchComponentNgFactory = i0.ɵccf("buttons-switch", i2.ButtonsSwitchComponent, View_ButtonsSwitchComponent_Host_0, { options: "options", value: "value", disabled: "disabled" }, { valueChange: "valueChange" }, []);
export { ButtonsSwitchComponentNgFactory as ButtonsSwitchComponentNgFactory };
