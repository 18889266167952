import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges
} from "@angular/core";
import { Session } from "../../service/util/Session";
import { FormField } from "../../model/Form";
import { Select2Component } from "ng2-select2";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import { DocumentCollectionsService } from "service/admin/DocumentCollectionsService";

@Component({
  selector: 'document-collection-select',
  template: `<select2
    #select2Component
    class="form-control select2-form-control {{disabled?'bg-grey':''}} {{!disabled && field && field.iifIsValid('border-success','border-danger')}}"
    [data]="selectData"
    [options]="selectOptions"
    (valueChanged)="valueChanged($event.data)"
    [value]="defaultValue"
    [disabled]="disabled"
  ></select2>`
})
export class DocumentCollectionSelectComponent implements OnInit, OnChanges {

  @Input()
  field: FormField<any>;

  @Input()
  placeholder: string = "";

  @Input()
  allowClear: boolean = true;

  @Input()
  disabled: boolean = false;

  private _value: string = '';

  @Input('value')
  get value() {
    return this._value;
  }
  set value(newValue: string) {
    this._value = newValue;
  }

  @Output()
  change: EventEmitter<IdTextPair[]> = new EventEmitter<IdTextPair[]>();

  public selectOptions: Select2Options = {
    allowClear: true,
    placeholder: ''
  };

  public selectData: IdTextPair[] = [];

  public defaultValue: string = '';

  // Reference firstNameInput variable inside Component
  @ViewChild('select2Component') select2ComponentRef: Select2Component;

  constructor(
    public documentCollectionsService: DocumentCollectionsService,
    public session: Session,
    private errorHandler: ErrorHandlerService,
  ) { }
  ngOnChanges() {
    //Force the change detection to cycle again to prevent race
    if (this.selectData.length > 0 && this.field && String(this.field.value) !== this.defaultValue) {
      this.defaultValue = String(this.field.value);
    }
  }
  ngOnInit() {
    if (this.placeholder)
      this.selectOptions.placeholder = this.placeholder;

    if (this.allowClear !== null)
      this.selectOptions.allowClear = this.allowClear;

    this.session.lockInputRx(this.documentCollectionsService.getDocumentCollections())
      .subscribe(data => {
        let newSelectOptions: IdTextPair[] = [];
        if (this.allowClear)
          newSelectOptions.push({ id: "", text: "" });

        data.forEach(item => newSelectOptions.push({
          id: String(item.id),
          text: item.name
        }));

        this.selectData = newSelectOptions;

        //Force the change detection to cycle again to prevent race
        if (this.field && String(this.field.value) !== this.defaultValue)
          this.defaultValue = String(this.field.value);

        if (!this.field && this._value)
          this.defaultValue = this._value;

      }, err => {
        console.error('Error getting document collections', err);
        this.errorHandler.handleHttpError(err);
      });
  }

  valueChanged(selectedOpts: IdTextPair[]) {
    if (selectedOpts.length === 0 || (
      selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0
    )) {
      if (this.field)
        this.field.value = null;

      this.change.emit(selectedOpts);

      return;
    }

    if (selectedOpts.length > 1)
      throw ("Selected options unexpectedly contained multiple results");

    if (this.field)
      this.field.value = selectedOpts[0].id;

    this.change.emit(selectedOpts);
  }
}