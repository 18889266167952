<div class="row">
    <div class="col-12 text-lg-left">
        <h1 class="text-uppercase text-orange">{{isNew ? 'New' : 'Edit'}} Enum Set</h1>
    </div>
</div>


<form [formGroup]="enumForm">
    <div class="row">
        <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
            <div class="form-group">
                <label for="enumNameInput">Enum Name:</label>
                <input type="text" id="enumNameInput" class="form-control input-lg" formControlName="name" rxControl
                    placeholder="Enum set name">
                <small class="form-text text-danger"
                    *ngIf="enumForm.controls.name.touched && enumForm.controls.name.hasError('required')">This field is
                    required.</small>
                <small class="form-text text-danger"
                    *ngIf="enumForm.controls.name.touched && enumForm.controls.name.hasError('minlength')">Must have at
                    least 3 characters.</small>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
            <h5>Enum Entries</h5>
        </div>
    </div>
    
    <div [sortablejs]="valuesFormArray.controls" [sortablejsOptions]="sortableOptions">
        <div class="row enum-value-row" formArrayName="values"
            *ngFor="let item of valuesFormArray.controls; let i = index;">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 offset-0 col-sm-10 col-lg-10 offset-lg-0 col-xl-10 offset-xl-0">
                        <div [formGroupName]="i" class="form-group">
                            <input type="text" class="form-control input-lg" formControlName="value" rxControl
                                placeholder="Value">
                            <small class="form-text text-danger"
                                *ngIf="item.controls.value.touched && item.controls.value.hasError('required')">This field is
                                required.</small>
                        </div>
                    </div>
                    <div class="col-12 offset-0 col-sm-1 col-lg-1 offset-lg-0 col-xl-1 offset-xl-0">
                        <i class="fa fa-remove" (click)="handleRemoveValue(i)"
                            *ngIf="!isNew || valuesFormArray.controls.length > 1"></i>
                    </div>
                    <div class="col-12 offset-0 col-sm-1 col-lg-1 offset-lg-0 col-xl-1 offset-xl-0">
                        <i class="fa fa-bars" *ngIf="valuesFormArray.controls.length > 1"></i>
                    </div>
                </div>

                <div class="row pt-2">
                    <div class="col-6 offset-4">
                        <div class="form-group"  [formGroupName]="i">
                            <label for="enumNameInput">Resource Pack:</label>
                            <select class="form-control" formControlName="resourcePack">
                                <option [selected]="item.controls.resourcePack.value == rp.id" *ngFor="let rp of resourcePacks" [value]="rp.id"> {{rp.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
            <button class="btn btn-link" (click)="handleAddEntry()">Add entry</button>
        </div>
    </div>
    <div class="row bg-default mx-1 my-3 py-4">
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
            <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit()"
                [disabled]="enumForm.pristine">
                <span class="capitalize">save</span>
            </button>
        </div>
        <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-4 col-xl-2 col-xxl-1">
            <a class="btn btn-block btn-lg btn-secondary rounded-0 text-center" [routerLink]="['/admin/enums']"><span
                    class="capitalize">cancel</span></a>
        </div>
    </div>
</form>