import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { Session } from '../../../service/util/Session';
import { ActivatedRoute, Router } from '@angular/router';
import * as has from 'has';
import { AuthService } from "../../../service/security/AuthService";
import { ErrorHandlerService } from "../../../service/ErrorHandlerService";
import { ModalService } from "../../../service/ModalService";
import { emailValidator, trimRequiredValidator } from "../../../validators/reactiveFormValidators";

@Component({
  selector: 'app-login',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css'],
})
export class ForgotComponent implements OnInit {

  errorMessage: string = '';

  public forgotForm = new FormGroup({
    email: new FormControl('', [trimRequiredValidator, emailValidator])
  });

  constructor(
    public session: Session,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private errorHandler: ErrorHandlerService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (has(params, 'errorMessage')) {
        this.errorMessage = params['errorMessage'];
      }

      if (has(params, 'email')) {
        this.forgotForm.setValue({email: params.email});
      }
    });
  }

  sendResetCode() {
    this.authService.forgotPassword({
      email: this.forgotForm.controls.email.value.trim(),
    }).subscribe(() => {
      this.modalService.alert({
        title: 'Action Required',
        message: 'Please check your email and click on the password reset link.'
      }).then(() => {
        this.router.navigate(['/login']);
      });
    }, err => {
      switch(err.status) {
        case 409:
          this.errorMessage = err.message;
        break;
        default:
          this.errorHandler.handleHttpError(err);
      }
    });
  }
}
