import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { IGroupsSortedField } from "../../../../../common/contracts/groups";
import { ISortOrderValue } from "../../../../../common/contracts/common";
import { PersistentStorageService } from "../../../../service/PersistentStorageService";
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
import { FormService } from 'service/FormService';
import { IFormOutputModel, IFormsSortedField, IScheduleParams } from '../../../../../common/contracts/form';
import { TruncatePipe } from "../../../../pipes/truncate.pipe";
import { ModalService } from 'service/ModalService';
import { FormField } from 'model/Form';
import * as moment from 'moment';
import "moment-timezone";
import { FormLocation } from '../../../../../model/FormLocation';
import { LocationService } from 'service/LocationService';
import { Session } from 'service/util/Session';
import { numStrToArr } from 'service/util/converter';
import { environment } from '../../../../environments/environment';

class TemplateRow {
	id: number;

	type: string;
	title: string;
	location: string | null;
	formType: string;

	assignedUser: string | null;
	assignedGroup: string | null;

	schedule: IScheduleParams | null;

	locations: string;
}

@Component({
	selector: 'form-templates',
	templateUrl: './formTemplates.component.html',
	styleUrls: ['./formTemplates.component.scss'],
})
export class FormTemplatesComponent implements OnInit {

	public forms: IFormOutputModel[] = [];
	public dashboardRecords: TemplateRow[] = [];

	public totalItems = 0;
	public currentPage = 1;
	public pageSize: number = this.pss.pageSize;
	public sortBy: IGroupsSortedField = 'createdAt';
	public sortOrder: ISortOrderValue = 'asc';
	public allLocations: FormLocation[] = [];

	public form: { [key: string]: FormField<any> } = {
		startDate: new FormField<String>('', {
			validation: FormField.ValidationMethods.None
		}),
	};

	constructor(
		private formService: FormService,
		private router: Router,
		private route: ActivatedRoute,
		private pss: PersistentStorageService,
		private errorHandler: ErrorHandlerService,
		private truncatePipe: TruncatePipe,
		private modalService: ModalService,
		private session: Session,
		private locationService: LocationService
	) {
	}

	ngOnInit() {

		this.session.lockInputRx(this.locationService.getLocations()).subscribe(
			(data: FormLocation[]) => {
				this.allLocations = data || [];

				return this.handlePageChange();
			});
	}

	public handleUserClick(type: string, id: number | string) {
		this.router.navigate([`${type}/${id}`], { relativeTo: this.route });
	}

	public handleRunSchedules() {
		this.formService.runSchedules().subscribe(result => {
			const errors = result.filter(form => !form.executionSuccess);
			const errorMessages = errors.map((form, idx) => {
				let errorMessage = (idx + 1).toString() + ".";

				if (form.issueNumber) errorMessage += " #" + form.issueNumber + ".";

				if (form.summary) errorMessage += " " + form.summary + "<br />";

				return errorMessage + (form.executionMessage || "Unknown Error");
			});

			const errorMessage: string | null = errorMessages.length ? errorMessages.join("<br />") : null;

			this.modalService.alert({
				title: 'Execution Complete',
				message: errorMessage ? "Some Errors Occurred:<br />" + errorMessage : "All templates executed successfuly"
			});

			this.handlePageChange();
		});
	}

	public handleFormCreate(id: number | string) {
		this.formService.runTemplate(id).subscribe(result => {
			const errorMessage: string | null = !result.executionSuccess ? result.executionMessage || "Unknown Error" : null;

			this.modalService.alert({
				title: 'Execution Complete',
				message: errorMessage ? "An Error Occurred:<br />" + errorMessage : "Template executed successfuly"
			});

			this.handlePageChange();
		});
	}

	public handlePageChange() {
		this.formService.getForms({
			skip: this.pageSize * (this.currentPage - 1),
			limit: this.pageSize,
			sortBy: this.sortBy,
			order: this.sortOrder,
			search: undefined,
			isTemplate: true,

		}).subscribe((data) => {
			this.forms = data.items;
			this.totalItems = data.totalCount;

			this.parseDashboardRecords();

		}, (err) => this.errorHandler.handleHttpError(err));
	}

	parseDashboardRecords = () => {
		let dashboardRecords: TemplateRow[] = [];

		this.forms.forEach(formItem => {
			const formType: string = formItem["category"]["name"];
			const title = `${this.truncatePipe.transform(formItem.summary || 'unknown')}`;
			const lastSubmission = this.getLastSubmission(formItem);
			const locationProperty = lastSubmission.properties.find(prop => prop.property.name === 'templateLocation');
			let locations: string = 'All Locations';
			if (locationProperty && locationProperty.stringData) {
				const locationData = locationProperty.stringData;
				try {
					let locationIds = numStrToArr(locationData);

					locations = locationIds.map(
						locationId => this.allLocations.find(location => location.id === locationId)
					)
						.filter(location => location)
						.map(location => location!.name)
						.join("<br />")
						;
				} catch (e) {
					console.error("---- ERROR Creating Location Name ----");
					console.error(e);
				}
			}

			dashboardRecords.push({
				title,
				formType,
				type: formType,
				id: formItem["id"],
				assignedUser: formItem.assignedUser ? `${formItem.assignedUser.firstName} ${formItem.assignedUser.lastName} ` : null,
				assignedGroup: formItem.userGroup ? `${formItem.userGroup.groupName}` : null,
				location: formItem.formLocation ? formItem.formLocation.name : 'All Locations',
				schedule: formItem.schedule || null,
				locations
			});
		});

		this.dashboardRecords = dashboardRecords;
	}

	private getLastSubmission(formItem: IFormOutputModel) {
		if (!formItem || !formItem.records || !formItem.records.length)
			throw new Error("Null FormItem found in GetLastSubmission");

		let submissions = formItem.records.filter(record => record.isComplete);

		if (!submissions || !submissions.length)
			throw new Error("Null Submissions found in GetLastSubmission");

		return submissions[submissions.length - 1];
	};

	public handleSortChange(sortField: IFormsSortedField) {
		if (sortField === this.sortBy) {
			this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			this.sortBy = sortField;
			this.sortOrder = 'asc';
		}
		this.handlePageChange();
	}

	deleteForm = (id: number) => {
		this.formService.deleteForm({
			id,
			deleteReason: "System: Deleting Form Template"
		}).subscribe(resp => {
			const idx = this.forms.findIndex(form => form.id === id);
			this.forms.splice(idx, 1);
			this.totalItems--;
			this.parseDashboardRecords();
		},
			err => this.errorHandler.handleHttpError(err));
	}

	showCronEdit = (form: IFormOutputModel) => {

		if (form.schedule && form.schedule.startAt)
			this.form.startDate.value = moment(form.schedule.startAt)
				.tz(environment.timeZone)
				.format('DD/MM/YYYY');
		else
			this.form.startDate.value = '';

		this.modalService.cronRx({
			title: "Execution Schedule",
			startDateField: this.form.startDate,
			expression: form.schedule ? form.schedule.expression : undefined
		}).subscribe(res => {
			if (!res)
				return;

			this.formService.updateFormSchedule({
				id: form.id,
				schedule: res
			}).subscribe(newSchedule => {
				form.schedule = newSchedule;
				// this.parseDashboardRecords();
			},
				err => this.errorHandler.handleHttpError(err));
		});
	}
}
