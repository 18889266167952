/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resource-pack-documents.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../shared/uploads.component.ngfactory";
import * as i5 from "../../shared/uploads.component";
import * as i6 from "../../../service/DocumentService";
import * as i7 from "../../../service/ModalService";
import * as i8 from "../../../service/ErrorHandlerService";
import * as i9 from "../../shared/preloaderWrapper.component.ngfactory";
import * as i10 from "../../shared/preloaderWrapper.component";
import * as i11 from "./resource-pack-documents.component";
import * as i12 from "@angular/router";
import * as i13 from "../../../service/ResourcePackService";
var styles_ResourcePackDocumentsComponent = [i0.styles];
var RenderType_ResourcePackDocumentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResourcePackDocumentsComponent, data: {} });
export { RenderType_ResourcePackDocumentsComponent as RenderType_ResourcePackDocumentsComponent };
function View_ResourcePackDocumentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["scope", "row"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "td", [["class", "btn-group"], ["width", "170px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "btn btn-primary btn-sm text-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "btn btn-danger btn-sm text-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteDocument(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.path; _ck(_v, 2, 0, currVal_0); }); }
function View_ResourcePackDocumentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "table", [["class", "table table-striped table-inverse"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "thead", [["class", "thead-inverse"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["FileName"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourcePackDocumentsComponent_3)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resourcePack.documents; _ck(_v, 10, 0, currVal_0); }, null); }
function View_ResourcePackDocumentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "text-uppercase text-orange"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Resource Pack: ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [["for", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "input", [["class", "form-control"], ["id", "name"], ["name", "name"], ["placeholder", "Name"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.resourcePack.name = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(9, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(11, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "small", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Resource pack name"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "btn btn-success rounded-0"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateName() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["class", "capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourcePackDocumentsComponent_2)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "document-uploads", [], null, [[null, "documentUploaded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("documentUploaded" === en)) {
        var pd_0 = (_co.addNewDocumentToResourcePack($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_UploadsComponent_0, i4.RenderType_UploadsComponent)), i1.ɵdid(24, 49152, null, 0, i5.UploadsComponent, [i6.DocumentService, i7.ModalService, i8.ErrorHandlerService], { displayUploads: [0, "displayUploads"], disableTicking: [1, "disableTicking"], disableSend: [2, "disableSend"] }, { documentUploaded: "documentUploaded" })], function (_ck, _v) { var _co = _v.component; var currVal_8 = "name"; var currVal_9 = _co.resourcePack.name; _ck(_v, 9, 0, currVal_8, currVal_9); var currVal_12 = _co.resourcePack.documents.length; var currVal_13 = i1.ɵnov(_v.parent, 4); _ck(_v, 21, 0, currVal_12, currVal_13); var currVal_14 = false; var currVal_15 = true; var currVal_16 = true; _ck(_v, 24, 0, currVal_14, currVal_15, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resourcePack.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 11).ngClassValid; var currVal_6 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = _co.updating; _ck(_v, 14, 0, currVal_10); var currVal_11 = (_co.updating ? "..." : "Update"); _ck(_v, 16, 0, currVal_11); }); }
function View_ResourcePackDocumentsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Documents"]))], null, null); }
export function View_ResourcePackDocumentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "preloader-wrapper", [], null, null, null, i9.View_PreloaderWrapperComponent_0, i9.RenderType_PreloaderWrapperComponent)), i1.ɵdid(1, 49152, null, 0, i10.PreloaderWrapperComponent, [], { isLoaded: [0, "isLoaded"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ResourcePackDocumentsComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["empty", 2]], null, 0, null, View_ResourcePackDocumentsComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resourcePack; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.resourcePack; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ResourcePackDocumentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-resource-pack-documents", [], null, null, null, View_ResourcePackDocumentsComponent_0, RenderType_ResourcePackDocumentsComponent)), i1.ɵdid(1, 114688, null, 0, i11.ResourcePackDocumentsComponent, [i12.ActivatedRoute, i13.ResourcePackService, i8.ErrorHandlerService, i6.DocumentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourcePackDocumentsComponentNgFactory = i1.ɵccf("app-resource-pack-documents", i11.ResourcePackDocumentsComponent, View_ResourcePackDocumentsComponent_Host_0, {}, {}, []);
export { ResourcePackDocumentsComponentNgFactory as ResourcePackDocumentsComponentNgFactory };
