import {saveAs} from 'file-saver';
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IDocumentType } from "../../../common/contracts/document";
import { DocumentService } from "../../service/DocumentService";
import { ModalService } from "../../service/ModalService";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";

@Component({
  selector: 'drm-document-uploads',
  styles: [`
      .hidden-input {
          display: none;
      }

      .download-document-reference {
          text-decoration: underline;
          cursor: pointer;
          font-size: 0.8em;
          line-height: 1.2;
          padding-bottom: 4px;
      }

      .remove-document-reference {
          cursor: pointer;
          font-size: 1em;
          margin-left: 8px;
      }

      .upload-title {
          margin-left: 10px;
      }

      button[disabled] {
          background-color: #ff9b3b;
          color: white;
      }
    
    .attachment-icon {
        font-size: 20px;
        cursor: pointer;
    }
    
    .light-grey-text {
        color: #AAAAAA;
    }

  `],
  template: `
      <div class="row" *ngIf="!readOnly">
          <div class="col-12">
              <button class="btn btn-orange rounded-0 btn-block" (click)="fileInput.click()" [disabled]="isUploading">
                  <i class="fa fa-upload" *ngIf="!isUploading"></i>
                  <i class="fa fa-circle-o-notch fa-spin" *ngIf="isUploading"></i>
                  <strong class="upload-title">{{isUploading ? 'UPLOADING' : 'UPLOAD FILE'}}</strong>
              </button>
              <input class="hidden-input" type="file" #fileInput (change)="handleFileSelected($event)" (click)="fileInput.value=''">
          </div>
      </div>   
      <ng-container *ngIf="displayUploads">
        <div class="row mt-2 mt-lg-3">
            <div class="col-12">
                <span>Files Uploaded:</span>
            </div>
        </div>
        <div *ngIf="documents.length === 0" class="mt-1 pt-1 pl-3 border-top">
            <span>No Files Uploaded</span>
        </div>
        
        <div *ngFor="let document of documents; let i = index" class="mt-1 pt-1 border-top">
            <i class="fa fa-paperclip attachment-icon"
               *ngIf="!disableTicking"
               [title]="document.isTicked ? 'Detach document from the final report' : 'Attach document to the final report'"
               [ngClass]="{'text-primary': document.isTicked, 'light-grey-text': !document.isTicked}"
               (click)="handleDocumentTick(document)"></i>&nbsp;
            <span class="download-document-reference" (click)="handleDocumentDownload(document)">{{document.fileName}}</span>
            <span class="remove-document-reference" title="Remove document" (click)="handleRemoveDocument(i)" *ngIf="!readOnly">&times;</span>
        </div>
      </ng-container>
  `
})
export class DRMUploadsComponent {
  
  @Input()
  displayUploads: boolean = true;

  @Input()
  documents: IDocumentType[] = [];
  
  @Output()
  documentsChange = new EventEmitter<IDocumentType[]>();
  
  @Output()
  documentUploaded = new EventEmitter<IDocumentType>();

  @Input()
  readOnly: boolean = false;

  @Input()
  disableTicking: boolean = false;
  
  @Input()
  disableSend: boolean = false;
  
  @Input()
  defaultTickedState: boolean = false;
  
  public isUploading = false;
  public hasUploaded = this.documents.length !== 0;

  private newDocumentsMap: {[id: number]: true} = {};

  constructor(
    private documentService: DocumentService,
    private modalService: ModalService,
    private errorHandler: ErrorHandlerService) {
  }

  /**
   * uploads handlers
   */

  public handleFileSelected(event) {
    if (event.target.files.length !== 1) {
      return;
    }
    if (this.hasUploaded || this.documents.length > 0) {
      this.modalService.alert({
        title: 'Warning',
        message: "Please remove existing document."
      });
      return;
    }
    const file: File = event.target.files[0];
    this.isUploading = true;
    this.documentService.uploadDocument(file).subscribe((newDocument: IDocumentType) => {
      newDocument.isTicked = this.defaultTickedState;
      this.documentUploaded.emit(newDocument);
      this.documents.push(newDocument);
      this.newDocumentsMap[newDocument.id] = true;
      this.isUploading = false;
      this.hasUploaded = true;
      this.documentsChange.emit(this.documents);
    }, err => {
      this.isUploading = false;
      this.errorHandler.handleHttpError(err);
    });
  }

  public handleDocumentDownload(document: IDocumentType) {
    this.documentService.downloadDocument(document.id).subscribe(
      data => {
        saveAs(data, document.fileName);
      },
      error => this.errorHandler.handleHttpError(error)
    );
  }

  public handleRemoveDocument(index: number) {
    this.modalService.confirmRx({
      title: 'Warning',
      message: "Are you sure that you want to remove this document?"
    }).subscribe(isConfirmed => {
      if (isConfirmed) {
        if (!this.newDocumentsMap[this.documents[index].id]) { // if the document isn't new - just remove it from the list
          this.documents.splice(index, 1);
          this.documentsChange.emit(this.documents);
        } else { // if document was new and not attached to the record - send delete request to API to remove it from the server
          this.documentService.deleteDocument(this.documents[index].id).subscribe(() => {
            this.documents.splice(index, 1);
            this.documentsChange.emit(this.documents);
          }, (err) => this.errorHandler.handleHttpError(err));
        }
        this.hasUploaded = false; 
      }
    });
  }

  public handleDocumentTick(document: IDocumentType) {
    if (this.readOnly) {
      return;
    }
    document.isTicked = !document.isTicked;
    this.documentsChange.emit(this.documents);
  }

}
