var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import * as moment from 'moment';
import "moment-timezone";
import { isNil } from "lodash";
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
var ReportFormStage3Component = /** @class */ (function (_super) {
    __extends(ReportFormStage3Component, _super);
    function ReportFormStage3Component(session, currentUserService, formService, formRecordService, errorHandler, router) {
        var _this = _super.call(this, router) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formService = formService;
        _this.formRecordService = formRecordService;
        _this.errorHandler = errorHandler;
        _this.router = router;
        _this.readOnly = false;
        _this.hideHeader = false;
        /*
          This should have been done properly using something that implements FormControl but its
          too late now
         */
        _this.form = {
            adminComplete: new FormField(false, {
                validation: FormField.ValidationMethods.None
            }),
            notes: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
        };
        return _this;
    }
    ReportFormStage3Component.prototype.registerFormFields = function () {
        var _this = this;
        var _a;
        (_a = this.formFields).push.apply(_a, Object.keys(this.form).map(function (k) { return _this.form[k]; }));
    };
    ReportFormStage3Component.prototype.ngOnInit = function () {
        this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);
        this.registerFormFields();
        this.repopulateFormFromData();
    };
    ReportFormStage3Component.prototype.repopulateFormFromData = function () {
        var _this = this;
        if (!this.formData || !this.formData.records || !this.formData.records.length)
            return;
        var stageRecords = this.formData.records.filter(function (record) { return record.stage === 3; });
        if (stageRecords.length === 0)
            return;
        if (!this.sequence) {
            var mostRecentRecord = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
            if (!mostRecentRecord)
                throw new Error("internal error");
            // If the most recent record was a submission, we are not going to use it
            if (mostRecentRecord.isComplete)
                return;
            this.formRecord = mostRecentRecord;
        }
        else {
            var targetRecord = stageRecords.find(function (record) { return record.sequence === _this.sequence; });
            if (!targetRecord)
                throw new Error("internal error");
            this.formRecord = targetRecord;
        }
        this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);
        var simpleProperties = {};
        this.formRecord.properties.forEach(function (recordProperty) {
            //eject invalid property
            if (!recordProperty.property)
                return;
            var result = {
                name: recordProperty.property.name
            };
            if (recordProperty.stringData)
                result.stringData = recordProperty.stringData;
            if (recordProperty.intData)
                result.intData = recordProperty.intData;
            if (recordProperty.jsonData)
                result.jsonData = recordProperty.jsonData;
            if (recordProperty.enumId)
                result.enumId = recordProperty.enumId;
            simpleProperties[result.name] = result;
        });
        if (simpleProperties['adminComplete'] && !isNil(simpleProperties['adminComplete'].intData))
            this.form.adminComplete.value = (simpleProperties['adminComplete'].intData > 0);
        if (simpleProperties['notes'])
            this.form.notes.value = simpleProperties['notes'].stringData;
    };
    ReportFormStage3Component.prototype.onSubmit = function (isDraft) {
        var _this = this;
        this.session.lockInput(function () {
            return new Promise(function (resolve, reject) {
                var success = function () {
                    resolve();
                    _this.closeForm();
                };
                var fail = function (msg, err) {
                    console.error(msg, err);
                    _this.errorHandler.handleHttpError(err);
                    reject();
                };
                var stage = isDraft ? 3 : 4;
                var properties = [];
                _this.formService.updateForm({
                    id: _this.formData.id,
                    stage: stage
                })
                    .subscribe(function () {
                    properties.push({
                        name: "adminComplete",
                        intData: _this.form.adminComplete.value ? 1 : 0
                    });
                    if (_this.form.notes.value.length > 0)
                        properties.push({
                            name: "notes",
                            stringData: _this.form.notes.value
                        });
                    _this.formRecordService.createRecord({
                        formId: _this.formData.id,
                        // Intentionally cast the properties object since we know its correct
                        properties: properties,
                        stage: 3,
                        documents: [],
                        isComplete: !isDraft
                    })
                        .subscribe(function () {
                        if (stage === 4)
                            return _this.formService.finalizeForm(_this.formData.id).subscribe(success);
                        return success();
                    }, function (err) { return fail('Error while creating a record', err); });
                }, function (err) { return fail('Error while updating a form', err); });
            });
        });
    };
    return ReportFormStage3Component;
}(FormComponent));
export { ReportFormStage3Component };
