<div class="row" *ngIf="!hideHeader">
  <div class="col-12 col-lg-6">
    <img src="assets/images/logo.png" class="logoImg">
  </div>
  <div class="col-12 pt-2 pt-lg-0 col-lg-6 text-center">
    <div class="border border-dark rounded border-dashed d-inline-block float-lg-right py-3 px-5">
      <div class="row">
        <div class="col-12">
          <strong class="font-weight-heavy">Issue Number:</strong>
          {{ formData ? formData.issueNumber || "TBA" : "TBA" }}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <strong class="font-weight-heavy">Date Created:</strong> {{dateString}}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <h1 class="text-uppercase text-white bg-teal p-2 px-3 my-2">Senior Management Review</h1>
  </div>
</div>
<form>
  <div class="row">
    <div class="col-12 col-sm-6">
      <div class="form-group">
        <label>Reassign Report to another Staff Member:</label>
        <button-toggle [value]="form.reassign.value" (valueChange)="form.reassign.value = $event"
          [disabled]="readOnly"></button-toggle>
      </div>
    </div>
    <div class="col-12 col-sm-6" *ngIf="form.reassign.value===true">
      <div class="form-group">
        <label>&nbsp;</label>
        <user-select [field]="form.reassignToUserId" [disabled]="readOnly"></user-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-8 border-lg-right border-dashed">
      <div class="row">
        <div class="col-12">
          <span>Senior Management Comments:</span>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12">
          <textarea class="form-control {{form.summary.iifIsValid('border-success','border-danger')}}" autosize
            replaceAmpersand [minRows]="3" [placeholder]="readOnly ? '' : 'Enter your brief summary here...'"
            [disabled]="readOnly" [spellcheck]="true" [(ngModel)]="form.summary.value"
            [ngModelOptions]="{standalone: true}"></textarea>
          <small class="form-text text-danger" *ngIf="form.summary.showErrorHelp()">Invalid Summary</small>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span>Internal Notes:</span>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12">
          <textarea class="form-control {{form.notes.iifIsValid('border-success','border-danger')}}" autosize
            replaceAmpersand [minRows]="3" [placeholder]="readOnly ? '' : 'Enter your notes'" [disabled]="readOnly"
            [spellcheck]="true" [(ngModel)]="form.notes.value" [ngModelOptions]="{standalone: true}"></textarea>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12">
          <follow-up-widget #followUpWidget [model]="form.followUps.value" (modelChange)="form.followUps.value=$event"
            [readOnly]="readOnly"></follow-up-widget>
        </div>
      </div>

      <div class="row" *ngIf="!form.reassign.value">
        <div class="col-12">
          <div class="form-group">
            <label>Communication Options:</label>
            <select2
              class="form-control select2-form-control"
              [data]="communicationSelectOptions" [options]="{ placeholder: 'Communication Options' }"
              [value]="form.furtherCommunication.value"
              [disabled]="readOnly"
              (valueChanged)="form.furtherCommunication.value = $event.value">
            </select2>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!form.reassign.value && emailsEnabled && form.furtherCommunication.value === communicationSelectOption.RESPONSE_REQUIRED_EMAIL">
        <div class="col-12">
          <div class="form-group">
            <label>Email copy of report to another party?</label>
            <button-toggle [value]="form.emailOther.value" (valueChange)="form.emailOther.value = $event"
              [disabled]="readOnly"></button-toggle>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>Email Address:</label>
            <input type="text" class="form-control {{form.otherEmail.iifIsValid('border-success','border-danger')}}"
              [placeholder]="readOnly ? '' : 'Other Email Address'" [disabled]="readOnly"
              [(ngModel)]="form.otherEmail.value" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          Information:
          <ul>
            <li *ngIf="!emailsEnabled">Emails are disabled.</li>
            <li *ngIf="emailsEnabled">Emails are enabled.</li>
            <li *ngIf="!formData.notifyOnComplete && emailsEnabled">No originator email address could be found.</li>
            <li *ngIf="formData.notifyOnComplete && emailsEnabled">
              Upon Submission, this pdf (<download-report-link [inline]="true" [formId]="formData.id"
              filePrefix="feedback-form-report"></download-report-link>) will be emailed to {{ formData.notifyOnComplete }}. No further actions will be required.
            </li>
            <li *ngIf="form.emailOther.value && form.otherEmail.value && emailsEnabled">
              Upon submission, this pdf (<download-report-link [inline]="true" [formId]="formData.id"
              filePrefix="feedback-form-report"></download-report-link>) will be emailed to {{ form.otherEmail.value }}.
            </li>
            <li *ngIf="emailsEnabled && (!form.emailOther.value || !form.otherEmail.value)">
              No <i>other party</i> email address could be found.
            </li>
          </ul>
          Outcome:
          <ul>
            <li *ngIf="form.reassign.value">Task will be reassigned</li>
            <li *ngIf="!form.reassign.value && form.furtherCommunication.value === communicationSelectOption.RESPONSE_REQUIRED_MANUAL">Further admin processing is required.</li>
            <li *ngIf="!form.reassign.value && form.furtherCommunication.value !== communicationSelectOption.RESPONSE_REQUIRED_MANUAL">Feedback will be marked as complete.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-12 offset-0 col-lg-4 pt-0 pt-lg-4">
      <div class="row">
        <div class="col-12 offset-0 col-xl-10 offset-xl-1">
          <document-uploads [(documents)]="documents" [readOnly]="readOnly"></document-uploads>
        </div>
      </div>
    </div>
  </div>
  <submission-detail [formRecord]="formRecord"></submission-detail>
  <div class="row" *ngIf="!readOnly">
    <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-2">
      <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit(false)"><span
          class="capitalize">Submit</span></button>
    </div>
    <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-5 col-xl-3 col-xxl-2">
      <a class="btn btn-block btn-lg btn-primary rounded-0 text-center text-white" (click)="submit(true)"><span
          class="capitalize">Save Draft</span></a>
    </div>
  </div>
</form>