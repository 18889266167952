<div class="row mt-4 mb-4">
    <div class="offset-0 col-3 offset-lg-1 col-lg-2">
        <nav class="nav flex-column bg-white border border-light rounded shadow-sm">
            <a class="nav-link active" [routerLink]="['/dashboard']" [queryParamsHandling]="">Dashboard</a>

            <a class="nav-link active" [routerLink]="['/report']">New Feedback</a>
            <a class="nav-link active" [routerLink]="['/followUp']">New Follow-Up</a>
            <a class="nav-link active" [routerLink]="['/audit']">New Audit</a>


			<span class="text-secondary pt-3 pl-2">Document Review</span>
			<a class="nav-link active" [routerLink]="['/drm-dashboard']" [queryParamsHandling]="">Doc Dashboard</a>
			<a class="nav-link active" [routerLink]="['/document-collections']">Document Collections</a>

			<span class="text-secondary pt-3 pl-2">Risk Assessment</span>
			<a class="nav-link active" [routerLink]="['/ram-dashboard']" [queryParamsHandling]="">Risk Dashboard</a>
			<a class="nav-link active" [routerLink]="['/risks']" [queryParamsHandling]="">Risk Register</a>
			<a class="nav-link active" [routerLink]="['/risk-assessment-task']">New Risk Assessment</a>
            
            <ng-container *ngIf="currentUserService.isAdministrator | async">
                <span class="text-secondary pt-3 pl-2">Admin</span>
                <a class="nav-link active" [routerLink]="['/admin/users']">Users</a>
                <a class="nav-link active" [routerLink]="['/admin/groups']">Groups</a>
                <a class="nav-link active" [routerLink]="['/admin/locations']">Locations</a>
                <a class="nav-link active" [routerLink]="['/admin/enums']">Enums</a>
                <a class="nav-link active" [routerLink]="['/admin/options']">Options</a>
                <a class="nav-link active" [routerLink]="['/admin/templates']">Templates</a>
                <a class="nav-link active" [routerLink]="['/admin/resource-pack']">Resource Pack</a>
                <a class="nav-link active" [routerLink]="['/admin/tags']">Tags &amp; Meta Data</a>
            </ng-container>

        </nav>
    </div>
    <div class="col-9 col-lg-8 bg-white border border-light rounded p-4 shadow-sm">
        <router-outlet></router-outlet>
    </div>
</div>
