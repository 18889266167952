import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Session } from '../../../service/util/Session';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RxFormsService } from "../../../service/util/reactiveForms";
import { AuthService } from "../../../service/security/AuthService";
import { ErrorHandlerService } from "../../../service/ErrorHandlerService";
import { ModalService } from "../../../service/ModalService";
import { JWTPayload } from "../../../../model/Auth";
import {
  emailValidator,
  trimRequiredValidator,
  validateConfirmPassword
} from "../../../validators/reactiveFormValidators";

@Component({
  selector: 'app-login',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css'],
})
export class ResetComponent implements OnInit {

  private resetToken: string;

  public resetForm: FormGroup;

  constructor(
    public session: Session,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private errorHandler: ErrorHandlerService,
    private modalService: ModalService,
  ) {
    this.resetForm = new FormGroup({
      username: new FormControl('', [trimRequiredValidator, emailValidator]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', validateConfirmPassword),
    });

    this.resetForm.controls.password.valueChanges.subscribe((value) => {
      this.resetForm.controls.confirmPassword.updateValueAndValidity();
      if (value) {
        this.resetForm.controls.confirmPassword.markAsTouched();
      }
    });
  }

  ngOnInit() {

    combineLatest(this.activatedRoute.queryParams, this.activatedRoute.params).subscribe(([queryParams, routeParams]) => {

      if (queryParams.email) {
        this.resetForm.controls.username.setValue(queryParams.email);
      }

      this.resetToken = routeParams.resetToken;
    });
  }

  public submitReset() {

    if (this.resetForm.invalid) {
      RxFormsService.touchAllFormControls(this.resetForm);
      return;
    }

    this.authService.resetPassword({
      resetToken: this.resetToken,
      username: this.resetForm.controls.username.value.trim(),
      password: this.resetForm.controls.password.value,
    }).subscribe((data: JWTPayload) => {
      /**
       * auto authorization after password reset, /api/reset returns JwtPayload
       */
      this.authService.token = data.accessToken;
      this.session.credentials = data as JWTPayload;

      this.modalService.alert({
        title: 'Success',
        message: 'Your password has been changed successfully.'
      }).then(() => {
        this.router.navigate(['/dashboard']);
      });

    }, err => this.errorHandler.handleHttpError(err));
  }

}
