<div class="row d-flex align-items-center py-4">
  <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-xl-4 offset-xl-4 bg-white align-self-center border border-grey rounded shadow-lg">
    <h3 class="py-1 my-0">Reset Password</h3>
    <div class="row bg-light border-top border-grey">
      <div class="col-12">
        <form class="py-2" [formGroup]="forgotForm">
          <div class="alert alert-danger" *ngIf="errorMessage">
            Some things weren't quite right...
            <div *ngIf="errorMessage">
              {{errorMessage}}
            </div>
          </div>
          <div class="form-group">
            <label for="emailAddress">Email Address</label>
            <input type="email" class="form-control" id="emailAddress" placeholder="Enter Email" formControlName="email" rxControl>
            <small class="form-text text-danger" *ngIf="forgotForm.controls.email.touched && forgotForm.controls.email.hasError('required')">This field is required.</small>
            <small class="form-text text-danger" *ngIf="forgotForm.controls.email.touched && forgotForm.controls.email.hasError('email')">Must be a valid E-mail.</small>
          </div>
          <div class="row">
            <a class="btn btn-primary col-4 offset-1" [routerLink]="'/login'">Cancel</a>
            <button class="btn btn-success col-4 offset-2" (click)="sendResetCode()" [disabled]="forgotForm.invalid">Reset Password</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
