import { Injectable } from '@angular/core';
import {GenericService} from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  DocumentCollectionPaginationResponse,
  ICreateDocumentCollectionInputModel,
  IDocumentCollectionSortedField,
  IUpdateDocumentCollectionInputModel,
  IDocumentCollectionOutputModel,
  IDocumentImportOutput
} from "../../../common/contracts/documentCollection";
import { ISortOrderValue } from "../../../common/contracts/common";
import { Observable, of } from "rxjs";
import { share, map, tap } from "rxjs/operators";

@Injectable()
export class DocumentCollectionsService extends GenericService {

  static readonly apiPrefix = '/api/documentCollection';

  private cachedDocumentCollections: IDocumentCollectionOutputModel[] | null = null;
  private readonly getDocumentCollectionsRequest: Observable<IDocumentCollectionOutputModel[]>;

  constructor(private http: HttpClient) {
    super();

    const queryParams = new HttpParams({
      fromObject: {
        skip: '0',
        limit: '50',
        sortBy: 'name',
        order: 'asc',
      }
    });

    this.getDocumentCollectionsRequest = this.http.get<DocumentCollectionPaginationResponse>(DocumentCollectionsService.apiPrefix, { params: queryParams}).pipe(
      map(data => {
        this.cachedDocumentCollections = data.items;
        setTimeout(() => {
          this.cachedDocumentCollections = null; // invalidate documentCollections cache in 20 minutes
        }, 20 * 60 * 1000);
        return data.items;
      }),
      share() //share results between multiple subscriptions
    );
  }

  /**
   * @description Clear collection cache
   */
  public clearCache() {
    this.cachedDocumentCollections = null;
  }

  public getDocumentCollections(): Observable<IDocumentCollectionOutputModel[]> {
    return this.cachedDocumentCollections ? of(this.cachedDocumentCollections) : this.getDocumentCollectionsRequest;
  }

  public getPagedDocumentCollections(offset: number = 0, pageSize: number = 20, sortBy: IDocumentCollectionSortedField = 'name', order: ISortOrderValue = 'asc', counts: boolean = true) {
    const queryParams = new HttpParams({
      fromObject: {
        skip: offset.toString(),
        limit: pageSize.toString(),
        sortBy,
        order,
        // counts: counts ? '1' : '0',
      }
    });
    return this.http.get<DocumentCollectionPaginationResponse>(DocumentCollectionsService.apiPrefix, { params: queryParams});
  }

  public getDocumentCollectionById(id: number) {
    return this.http.get<IDocumentCollectionOutputModel>(`${DocumentCollectionsService.apiPrefix}/${id}`);
  }

  public createDocumentCollection(documentCollectionData: ICreateDocumentCollectionInputModel) {
    return this.http.post(DocumentCollectionsService.apiPrefix, documentCollectionData).pipe(tap(() => {
      this.cachedDocumentCollections = null;
    }));
  }

  public updateDocumentCollection(documentCollectionChanges: IUpdateDocumentCollectionInputModel) {
    return this.http.put(DocumentCollectionsService.apiPrefix, documentCollectionChanges).pipe(tap(() => {
      this.cachedDocumentCollections = null;
    }));
  }

  public archiveDocumentCollection(id: number) {
    return this.http.delete(`${DocumentCollectionsService.apiPrefix}/${id}`).pipe(tap(() => {
      this.cachedDocumentCollections = null;
    }));
  }

  /**
   * @description Executes a collection import using the document supplied
   * @param documentId 
   */
  public performCollectionImport(documentId: number) {
    return this.http.post<IDocumentImportOutput>(`${DocumentCollectionsService.apiPrefix}/import/${documentId}`, {});
  }
}
