/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./buttonToggle.component";
var styles_ButtonToggleComponent = ["button.btn-secondary.active[_ngcontent-%COMP%] {\n          box-shadow: none !important;\n      }"];
var RenderType_ButtonToggleComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ButtonToggleComponent, data: {} });
export { RenderType_ButtonToggleComponent as RenderType_ButtonToggleComponent };
export function View_ButtonToggleComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["data-toggle", "buttons"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [], [[8, "className", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "button", [], [[8, "className", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "btn-group btn-group-toggle  ", (_co.inline ? "mb-1" : "d-block"), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(2, "btn ", (_co.inline ? "btn-sm" : ""), " btn-secondary ", (_co.value ? "active" : ""), ""); var currVal_2 = _co.disabled; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.enableText; _ck(_v, 2, 0, currVal_3); var currVal_4 = i0.ɵinlineInterpolate(2, "btn ", (_co.inline ? "btn-sm" : ""), " btn-secondary ", (_co.value ? "" : "active"), ""); var currVal_5 = _co.disabled; _ck(_v, 3, 0, currVal_4, currVal_5); var currVal_6 = _co.disableText; _ck(_v, 4, 0, currVal_6); }); }
export function View_ButtonToggleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button-toggle", [], null, null, null, View_ButtonToggleComponent_0, RenderType_ButtonToggleComponent)), i0.ɵdid(1, 114688, null, 0, i1.ButtonToggleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonToggleComponentNgFactory = i0.ɵccf("button-toggle", i1.ButtonToggleComponent, View_ButtonToggleComponent_Host_0, { control: "control", value: "value", disabled: "disabled", inline: "inline", enableText: "enableText", disableText: "disableText" }, { valueChange: "valueChange" }, []);
export { ButtonToggleComponentNgFactory as ButtonToggleComponentNgFactory };
