/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./submissionDetail.component";
import * as i3 from "../../service/currentUser/CurrentUserService";
import * as i4 from "../../service/FormRecordService";
import * as i5 from "../../service/ModalService";
import * as i6 from "../../service/ErrorHandlerService";
var styles_SubmissionDetailComponent = [];
var RenderType_SubmissionDetailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubmissionDetailComponent, data: {} });
export { RenderType_SubmissionDetailComponent as RenderType_SubmissionDetailComponent };
function View_SubmissionDetailComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-pencil text-primary hover-cursor ml-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateRecordDate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_SubmissionDetailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "row bg-default my-3 py-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "col-12 offset-0 col-lg-4 offset-lg-2 offset-xl-0 p-1 text-center mt-lg-2 mt-xl-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Updated By: "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "text-primary"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "col-12 col-lg-5 p-1 text-center mt-lg-2 mt-xl-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Date Updated: "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [["class", "text-primary"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubmissionDetailComponent_1)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isDateEditable; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userString; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.dateString; _ck(_v, 10, 0, currVal_1); }); }
export function View_SubmissionDetailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "submission-detail", [], null, null, null, View_SubmissionDetailComponent_0, RenderType_SubmissionDetailComponent)), i0.ɵdid(1, 114688, null, 0, i2.SubmissionDetailComponent, [i3.CurrentUserService, i4.FormRecordService, i5.ModalService, i6.ErrorHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubmissionDetailComponentNgFactory = i0.ɵccf("submission-detail", i2.SubmissionDetailComponent, View_SubmissionDetailComponent_Host_0, { formRecord: "formRecord" }, {}, []);
export { SubmissionDetailComponentNgFactory as SubmissionDetailComponentNgFactory };
