import {Injectable} from "@angular/core";
import {CanDeactivate} from "@angular/router";
import {ComponentCanDeactivate} from "./ComponentCanDeactivate";
import has = Reflect.has;
import { ModalService } from "../../../service/ModalService";
import { Observable, from, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private modalService: ModalService) {}

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> | boolean {

    if (has(component, "canDeactivate") && !component.canDeactivate()) {
      return from(this.modalService.confirm({
        title: "Caution",
        message: "You have unsaved changes! If you leave, your changes will be lost.",
        confirmText: "Leave",
        declineText: "Stay",
      })).pipe(
        map(res => res === "confirmed"),
        catchError(() => of(false))
      );
    }

    return true;
  }
}