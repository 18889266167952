var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpHeaders } from '@angular/common/http';
import lscache from 'lscache';
import { throwError } from 'rxjs';
import * as has from 'has';
import * as Sentry from "@sentry/browser";
var defaultHeaders = {
    'Content-Type': 'application/json',
    'Accept': "application/json"
};
var GenericService = /** @class */ (function () {
    function GenericService() {
    }
    GenericService.prototype.handleError = function (error) {
        var errorMessage = null;
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
            errorMessage = error.error.message;
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            if (error.error !== null) {
                console.error("Backend returned code " + error.status + ", " +
                    ("body was: " + JSON.stringify(error.error)));
                if (has(error.error, 'message')) {
                    Sentry.captureException(new Error(error.error.message));
                    // return an observable with a user-facing error message
                    return throwError({
                        message: error.error.message,
                        status: error.status
                    });
                }
            }
            else {
                console.error("Backend returned code " + error.status + ", " +
                    "body was: [empty String]");
            }
        }
        if (!errorMessage)
            errorMessage = error.message;
        Sentry.captureException(new Error(errorMessage));
        // return an observable with a user-facing error message
        return throwError({
            message: 'Something bad happened; please try again later.',
            status: error.status
        });
    };
    ;
    Object.defineProperty(GenericService.prototype, "token", {
        get: function () {
            return GenericService._token || lscache.get('token');
        },
        set: function (newToken) {
            GenericService._token = newToken;
            var headers = __assign({}, defaultHeaders);
            if (newToken) {
                headers['Authorization'] = 'Bearer ' + newToken;
            }
            GenericService.httpOptions = {
                headers: new HttpHeaders(headers),
            };
            lscache.set('token', newToken);
        },
        enumerable: true,
        configurable: true
    });
    GenericService.httpOptions = {
        headers: new HttpHeaders(defaultHeaders)
    };
    return GenericService;
}());
export { GenericService };
