import { Injectable } from '@angular/core';
import { ModalService } from "./ModalService";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable()
export class ErrorHandlerService {

  constructor(private modalService: ModalService, private router: Router) {
  }

  /*
    The purpose of this is to show alerts to the user explaining the error
  */
  public handleHttpError(error: any): any {
    let alertOptions = {title: 'Error', message: 'Unknown error'};

    if (error instanceof HttpErrorResponse) {

      if (error.status === 500) {
        alertOptions = {title: 'Server Error', message: this.getErrorMessage(error, 'Internal server error. Please contact an administrator.') };
      } else if (error.status === 400) {
        alertOptions = {title: 'Error', message: this.getErrorMessage(error, 'Unknown error.')};
      } else if (error.status === 401) {
        return this.router.navigate(['/login']);
      } else if (error.status === 403) {
        alertOptions = {title: 'Forbidden', message: this.getErrorMessage(error,'You are not allowed to perform this action.')};
      } else if (error.status === 404) {
        alertOptions = {title: 'Not Found', message: this.getErrorMessage(error,'Server responded with not found error.')};
      } else if (error.status === 406) {
        alertOptions = {title: 'Server Validation Error', message: (error.error && error.error.errors && error.error.errors[0] && error.error.errors[0]) || 'Unknown validation error.'};
      } else {
        alertOptions = {title: 'Error', message: 'Unknown HTTP error'};
      }

    } else {
      alertOptions = {title: 'Error', message: 'Unknown network error'};
    }

    this.modalService.alert(alertOptions);
  }
  
  public raiseError(message:string, title?:string) {
    let alertOptions = {
      title: "Error",
      message
    };
    
    if( title )
      alertOptions.title = title;
  
    this.modalService.alert(alertOptions).then(() => {});
  }

  private getErrorMessage(error: HttpErrorResponse, defaultValue: string = '', delimiter: string = '<br />') {
    if( !Object.prototype.hasOwnProperty.call(error, 'error') ) {
      return defaultValue;
    }

    if( Object.prototype.hasOwnProperty.call(error.error, 'errors') && Array.isArray(error.error.errors) && error.error.errors.length ) {
      return Array.prototype.join.call(error.error.errors, delimiter);
    }

    if( !Object.prototype.hasOwnProperty.call(error.error, 'message') || !error.error.message.length ) {
      return defaultValue;
    }

    return error.error.message;
  }
}
