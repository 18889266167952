<div class="row">
    <div class="col-12 col-lg-6">
        <img src="assets/images/logo.png" class="logoImg">
    </div>
    <div class="col-12 pt-2 pt-lg-0 col-lg-6 text-center">
        <div class="w-100 border border-dark rounded border-dashed d-inline-block float-lg-right py-3 px-5">
            <div class="row">
                <div class="col-12">
                    <strong class="font-weight-heavy">Issue Number:</strong>
                    {{ issueNumber ? issueNumber || "TBA" : "TBA" }}
                </div>
                <div class="col-12">
                    <strong class="font-weight-heavy">Document ID:</strong>
                    {{ documentId }}
                </div>
                <div class="col-12" *ngIf="document">
                    <strong class="font-weight-heavy">Document Code: </strong>
                    <a [routerLink]="['/document-collections',document.documentCollectionId,'documents',document.id]">{{
                        document.documentCode }}</a>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <strong class="font-weight-heavy">Date Created:</strong> {{dateString}}
                </div>
            </div>
        </div>
    </div>
</div>