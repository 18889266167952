import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RxFormsService } from "../../../service/util/reactiveForms";
import { CurrentUserService } from "../../../service/currentUser/CurrentUserService";
import { IUserOutputModel } from "../../../../common/contracts/users";
import { ErrorHandlerService } from "../../../service/ErrorHandlerService";
import { ModalService } from "../../../service/ModalService";
import { IUpdateUserProfileInputModel } from "../../../../common/contracts/currentUser";
import {
  emailValidator,
  trimRequiredValidator,
  validateConfirmPassword
} from "../../../validators/reactiveFormValidators";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  public setPassword = false;

  public profileForm: FormGroup;

  constructor(
    private currentUserService: CurrentUserService,
    private errorHandler: ErrorHandlerService,
    private modalService: ModalService,
    public location: Location) {

    this.profileForm = new FormGroup({
      firstName: new FormControl('', trimRequiredValidator),
      lastName: new FormControl('', trimRequiredValidator),
      username: new FormControl('', [trimRequiredValidator, emailValidator]),
      phone: new FormControl('', Validators.pattern(/^\d{10}$/)),
      oldPassword: new FormControl(''),
      password: new FormControl(''),
      confirmPassword: new FormControl('', validateConfirmPassword),
    });

    this.profileForm.controls.password.valueChanges.subscribe((value) => {
      this.profileForm.controls.confirmPassword.updateValueAndValidity();
      if (value) {
        this.profileForm.controls.confirmPassword.markAsTouched();
      }
    });

  }

  ngOnInit() {
    this.currentUserService.getCurrentUser().subscribe((user: IUserOutputModel) => {
      this.profileForm.setValue({
        firstName:  user.firstName,
        lastName: user.lastName,
        username: user.username,
        phone: user.phone,
        oldPassword: '',
        password: '',
        confirmPassword: '',
      });
    }, (err) => this.errorHandler.handleHttpError(err));
  }

  public toggleChangePassword() {
    this.setPassword = !this.setPassword;

    if (this.setPassword) {
      this.profileForm.controls.password.setValidators([Validators.required, Validators.minLength(6)]);
      this.profileForm.controls.oldPassword.setValidators([Validators.required, Validators.minLength(6)]);
    } else {
      this.profileForm.controls.password.clearValidators();
      this.profileForm.controls.password.setValue('');
      this.profileForm.controls.password.markAsUntouched();
      this.profileForm.controls.confirmPassword.setValue('');
      this.profileForm.controls.confirmPassword.markAsUntouched();
      this.profileForm.controls.oldPassword.clearValidators();
      this.profileForm.controls.oldPassword.setValue('');
      this.profileForm.controls.oldPassword.markAsUntouched();
    }
  }

  submitProfile() {

    if (this.profileForm.invalid) {
      RxFormsService.touchAllFormControls(this.profileForm);
      return;
    }

    const profile: IUpdateUserProfileInputModel = {
      username: this.profileForm.controls.username.value.trim(),
      firstName: this.profileForm.controls.firstName.value.trim(),
      lastName: this.profileForm.controls.lastName.value.trim(),
      oldPassword: this.setPassword ? this.profileForm.controls.oldPassword.value : null,
      password: this.setPassword ? this.profileForm.controls.password.value : null,
      phone: this.profileForm.controls.phone.value || null,
    };

    this.currentUserService.updateUserProfile(profile).subscribe(() => {

      // update current user info after update
      this.currentUserService.getCurrentUser().subscribe(
        () => undefined,
        err => {
          console.error('Cannot get current user after update', err);
          this.errorHandler.handleHttpError(err)
        });

      this.modalService.alert({title: 'Success', message: 'Your user profile has been updated successfully.'})
        .then(() => {
          this.profileForm.markAsPristine();
          if (this.setPassword) {
            this.toggleChangePassword();
          }
        });
    }, err => {
      console.error('Cannot update user profile', err);
      this.errorHandler.handleHttpError(err);
    });

  }
}
