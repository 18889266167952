import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
	selector: 'button-toggle',
	styles: [`
      button.btn-secondary.active {
          box-shadow: none !important;
      }
  `],
	template: `
    <div class="btn-group btn-group-toggle  {{inline ? 'mb-1' : 'd-block'}}" data-toggle="buttons">
        <button class="btn {{inline ? 'btn-sm' : ''}} btn-secondary {{value ? 'active' : ''}}" [disabled]="disabled" (click)="handleClick(true)">
            {{ enableText }}
        </button>
        <button class="btn {{inline ? 'btn-sm' : ''}} btn-secondary {{value ? '' : 'active'}}" [disabled]="disabled" (click)="handleClick(false)">
            {{ disableText }}
        </button>
    </div>
  `
})
export class ButtonToggleComponent implements OnInit {

	@Input()
	control: FormControl;

	@Input()
	value: boolean = false;

	@Output()
	valueChange = new EventEmitter<boolean>();

	@Input()
	disabled: boolean = false;

	@Input()
	inline: boolean = false;

	@Input()
	enableText: string = "Yes";

	@Input()
	disableText: string = "No";

	public handleClick(val: boolean) {
		this.updateValue(val);
	}

	ngOnInit() {
		this.observeControl();
	}

	private updateValue(val: boolean) {
		this.whenEnabled(() => {
			if (val !== this.value) {
				this.value = val;
				this.valueChange.emit(val);
			}

			this.updateControl();
		});
	}

	private withControl(func: () => void): void {
		if (this.control) {
			func.call(this);
		}
	}

	private whenEnabled(func: () => void): void {
		if (!this.disabled) {
			func.call(this);
		}
	}

	private updateControl(): void {
		this.withControl(() => {
			this.control.setValue(this.value);
		});
	}

	private observeControl(): void {
		this.withControl(() => {
			this.control.valueChanges.subscribe(() => {
				this.value = this.control.value;
			});
		});
	}
}
