<preloader-wrapper [isLoaded]="isLoaded">

    <div class="row">
        <div class="col-12 text-lg-left">
            <h1 class="text-uppercase text-orange">System Options</h1>
        </div>
    </div>

    <form [formGroup]="settingForm">
        <div class="row mt-5 mb-5">
            <div class="col-6 pl-4 pt-1">
                Emails service enabled:
            </div>
            <div class="col-6">
                <button-toggle [value]="settingForm.controls.isEmailsEnabled.value"
                    (valueChange)="settingForm.controls.isEmailsEnabled.setValue($event); settingForm.markAsDirty()">
                </button-toggle>
            </div>
        </div>

        <div class="row mt-5 mb-5">
            <div class="col-6 pl-4 pt-1">
                Process Audit Templates On Submit:
            </div>
            <div class="col-6">
                <button-toggle [value]="settingForm.controls.processTemplates.value"
                    (valueChange)="settingForm.controls.processTemplates.setValue($event); settingForm.markAsDirty()">
                </button-toggle>
            </div>
        </div>

        <div class="row mt-5 mb-5">
            <div class="col-6 pl-4 pt-1">
                Process Document Review Schedules On Submit:
            </div>
            <div class="col-6">
                <button-toggle [value]="settingForm.controls.processDocumentReviews.value"
                    (valueChange)="settingForm.controls.processDocumentReviews.setValue($event); settingForm.markAsDirty()">
                </button-toggle>
            </div>
        </div>

        <div class="row bg-default mx-1 my-3 py-4"></div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
                <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit()"
                    [disabled]="settingForm.pristine">
                    <span class="capitalize">save</span>
                </button>
            </div>
            <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-4 col-xl-2 col-xxl-1">
                <a class="btn btn-block btn-lg btn-secondary rounded-0 text-center text-white" (click)="goBack()">
                    <span class="capitalize">cancel</span>
                </a>
            </div>
        </div>
    </form>

</preloader-wrapper>