<!-- Tabs -->
<ul class="nav nav-tabs tab-nav" role="tablist">
    <li [ngClass]="{'active': activeTab === 'minutes'}" *ngIf="!options.hideMinutesTab">
        <a aria-controls="minutes" role="tab" data-toggle="tab" (click)="setActiveTab('minutes')">
            Minutes
        </a>
    </li>

    <li role="presentation" *ngIf="!options.hideHourlyTab" [ngClass]="{'active': activeTab === 'hourly'}">
        <a aria-controls="hourly" role="tab" data-toggle="tab" (click)="setActiveTab('hourly')">
            Hourly
        </a>
    </li>

    <li role="presentation" *ngIf="!options.hideDailyTab" [ngClass]="{'active': activeTab === 'daily'}">
        <a aria-controls="daily" role="tab" data-toggle="tab" (click)="setActiveTab('daily')">
            Daily
        </a>
    </li>

    <li role="presentation" *ngIf="!options.hideWeeklyTab" [ngClass]="{'active': activeTab === 'weekly'}">
        <a aria-controls="weekly" role="tab" data-toggle="tab" (click)="setActiveTab('weekly')">
            Weekly
        </a>
    </li>

    <li role="presentation" *ngIf="!options.hideMonthlyTab" [ngClass]="{'active': activeTab === 'monthly'}">
        <a aria-controls="monthly" role="tab" data-toggle="tab" (click)="setActiveTab('monthly')">
            Monthly
        </a>
    </li>

    <li role="presentation" *ngIf="!options.hideYearlyTab" [ngClass]="{'active': activeTab === 'yearly'}">
        <a aria-controls="yearly" role="tab" data-toggle="tab" (click)="setActiveTab('yearly')">
            Yearly
        </a>
    </li>

    <li role="presentation" *ngIf="!options.hideAdvancedTab" [ngClass]="{'active': activeTab === 'advanced'}">
        <a aria-controls="advanced" role="tab" data-toggle="tab" (click)="setActiveTab('advanced')">
            Advanced
        </a>
    </li>
</ul>

<!-- Tab content -->
<div class="cron-editor-container">
    <div class="row">
        <div class="col-xs-12">
            <div class="tab-content">
                <!-- Minutes-->
                <div class="tab-pane" *ngIf="!options.hideMinutesTab" [ngClass]="{'active': activeTab === 'minutes'}">
                    <div class="well well-small">
                        Every
                        <select class="minutes" [disabled]="disabled || activeTab !== 'minutes'" (change)="regenerateCron()"
                            [(ngModel)]="state.minutes.minutes" [ngClass]="options.formSelectClass">
                            <option *ngFor="let minute of selectOptions.minutes" [ngValue]="minute">
                                {{minute}}
                            </option>
                        </select> minute(s)
                        <span *ngIf="!options.hideSeconds">on second</span>
                        <select class="seconds" *ngIf="!options.hideSeconds" [disabled]="disabled || activeTab !== 'minutes'"
                            (change)="regenerateCron()" [(ngModel)]="state.minutes.seconds" [ngClass]="options.formSelectClass">
                            <option *ngFor="let second of selectOptions.seconds" [ngValue]="second">
                                {{second}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Hourly-->
                <div class="tab-pane" *ngIf="!options.hideHourlyTab" [ngClass]="{'active': activeTab === 'hourly'}">
                    <div class="well well-small">
                        Every
                        <select class="hours" [disabled]="disabled || activeTab !== 'hourly'" (change)="regenerateCron()"
                            [(ngModel)]="state.hourly.hours" [ngClass]="options.formSelectClass">
                            <option *ngFor="let hour of selectOptions.hours" [ngValue]="hour">
                                {{hour}}
                            </option>
                        </select> hour(s) on minute
                        <select class="minutes" [disabled]="disabled || activeTab !== 'hourly'" (change)="regenerateCron()"
                            [(ngModel)]="state.hourly.minutes" [ngClass]="options.formSelectClass">
                            <option *ngFor="let minute of selectOptions.fullMinutes" [ngValue]="minute">
                                {{minute}}
                            </option>
                        </select>
                        <span *ngIf="!options.hideSeconds">and second</span>
                        <select class="seconds" *ngIf="!options.hideSeconds" [disabled]="disabled || activeTab !== 'hourly'"
                            (change)="regenerateCron()" [(ngModel)]="state.hourly.seconds" [ngClass]="options.formSelectClass">
                            <option *ngFor="let second of selectOptions.seconds" [ngValue]="second">
                                {{second}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Daily-->
                <div class="tab-pane" *ngIf="!options.hideDailyTab" [ngClass]="{'active': activeTab === 'daily'}">
                    <div class="well well-small">
                        <input type="radio" name="daily-radio" value="everyDays" [disabled]="disabled" (change)="regenerateCron()"
                            [(ngModel)]="state.daily.subTab" value="everyDays" [disabled]="disabled" (change)="regenerateCron()"
                            [(ngModel)]="state.daily.subTab" [ngClass]="state.formRadioClass" checked="checked">
                        Every
                        <select class="days" [disabled]="disabled || activeTab !== 'daily' || state.daily.subTab !== 'everyDays'"
                            (change)="regenerateCron()" [(ngModel)]="state.daily.everyDays.days" [ngClass]="options.formSelectClass">
                            <option *ngFor="let monthDay of selectOptions.monthDays" [ngValue]="monthDay">
                                {{monthDay}}
                            </option>
                        </select> day(s) at

                        <cron-time-picker [disabled]="disabled || activeTab !== 'daily' || state.daily.subTab !== 'everyDays'"
                            (change)="regenerateCron()" [(time)]="state.daily.everyDays" [selectClass]="options.formSelectClass"
                            [use24HourTime]="options.use24HourTime" [hideSeconds]="options.hideSeconds">
                        </cron-time-picker>
                    </div>

                    <div class="well well-small">
                        <input type="radio" name="daily-radio" value="everyWeekDay" [disabled]="disabled" (change)="regenerateCron()"
                            [(ngModel)]="state.daily.subTab" [ngClass]="state.formRadioClass"> Every working day at
                        <cron-time-picker [disabled]="disabled || activeTab !== 'daily' || state.daily.subTab !== 'everyWeekDay'"
                            (change)="regenerateCron()" [(time)]="state.daily.everyWeekDay" [selectClass]="options.formSelectClass"
                            [use24HourTime]="options.use24HourTime" [hideSeconds]="options.hideSeconds">
                        </cron-time-picker>
                    </div>
                </div>

                <!-- Weekly-->
                <div class="tab-pane" *ngIf="!options.hideWeeklyTab" [ngClass]="{'active': activeTab === 'weekly'}">
                    <div class="well well-small">
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                    [(ngModel)]="state.weekly.MON" [ngClass]="options.formCheckboxClass"> Monday</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                    [(ngModel)]="state.weekly.TUE" [ngClass]="options.formCheckboxClass"> Tuesday</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                    [(ngModel)]="state.weekly.WED" [ngClass]="options.formCheckboxClass"> Wednesday</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                    [(ngModel)]="state.weekly.THU" [ngClass]="options.formCheckboxClass"> Thursday</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                    [(ngModel)]="state.weekly.FRI" [ngClass]="options.formCheckboxClass"> Friday</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                    [(ngModel)]="state.weekly.SAT" [ngClass]="options.formCheckboxClass"> Saturday</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                    [(ngModel)]="state.weekly.SUN" [ngClass]="options.formCheckboxClass"> Sunday</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                at
                                <cron-time-picker [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                    [(time)]="state.weekly" [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                                    [hideSeconds]="options.hideSeconds">
                                </cron-time-picker>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Monthly-->
                <div class="tab-pane" *ngIf="!options.hideMonthlyTab" [ngClass]="{'active': activeTab === 'monthly'}">
                    <div class="well well-small">
                        <input type="radio" name="monthly-radio" value="specificDay" [disabled]="disabled" (change)="regenerateCron()"
                            [(ngModel)]="state.monthly.subTab" [ngClass]="state.formRadioClass"> On the
                        <select class="month-days" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificDay'"
                            (change)="regenerateCron()" [(ngModel)]="state.monthly.specificDay.day" [ngClass]="options.formSelectClass">
                            <option *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts" [ngValue]="monthDaysWithLast">
                                {{monthDayDisplay(monthDaysWithLast)}}
                            </option>
                        </select> of every
                        <select class="months-small" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificDay'"
                            (change)="regenerateCron()" [(ngModel)]="state.monthly.specificDay.months" [ngClass]="options.formSelectClass">
                            <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                                {{month}}
                            </option>
                        </select> month(s) at
                        <cron-time-picker [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificDay'"
                            (change)="regenerateCron()" [(time)]="state.monthly.specificDay" [selectClass]="options.formSelectClass"
                            [use24HourTime]="options.use24HourTime" [hideSeconds]="options.hideSeconds">
                        </cron-time-picker>&nbsp;
                        <label class="advanced-cron-editor-label"><input type="checkbox" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificDay'" 
                            (change)="regenerateCron()" [(ngModel)]="state.monthly.runOnWeekday" [ngClass]="options.formCheckboxClass"> during the nearest weekday</label>
                        <div class="row">
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="disabled || activeTab !== 'monthly' || state.monthly.specificDay.months !== 'selected'" 
                                    (change)="regenerateCron()" [(ngModel)]="state.monthly.JAN" [ngClass]="options.formCheckboxClass"
                                > Janurary</label>
                            </div>
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="disabled || activeTab !== 'monthly' || state.monthly.specificDay.months !== 'selected'" 
                                    (change)="regenerateCron()" [(ngModel)]="state.monthly.FEB" [ngClass]="options.formCheckboxClass"
                                > Feburary</label>
                            </div>
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="disabled || activeTab !== 'monthly' || state.monthly.specificDay.months !== 'selected'" 
                                    (change)="regenerateCron()" [(ngModel)]="state.monthly.MAR" [ngClass]="options.formCheckboxClass"
                                > March</label>
                            </div>
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="disabled || activeTab !== 'monthly' || state.monthly.specificDay.months !== 'selected'" 
                                    (change)="regenerateCron()" [(ngModel)]="state.monthly.APR" [ngClass]="options.formCheckboxClass"
                                > April</label>
                            </div>
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="disabled || activeTab !== 'monthly' || state.monthly.specificDay.months !== 'selected'" 
                                    (change)="regenerateCron()" [(ngModel)]="state.monthly.MAY" [ngClass]="options.formCheckboxClass"
                                > May</label>
                            </div>
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="disabled || activeTab !== 'monthly' || state.monthly.specificDay.months !== 'selected'" 
                                    (change)="regenerateCron()" [(ngModel)]="state.monthly.JUN" [ngClass]="options.formCheckboxClass"
                                > June</label>
                            </div>
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="disabled || activeTab !== 'monthly' || state.monthly.specificDay.months !== 'selected'" 
                                    (change)="regenerateCron()" [(ngModel)]="state.monthly.JUL" [ngClass]="options.formCheckboxClass"
                                > July</label>
                            </div>
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="disabled || activeTab !== 'monthly' || state.monthly.specificDay.months !== 'selected'" 
                                    (change)="regenerateCron()" [(ngModel)]="state.monthly.AUG" [ngClass]="options.formCheckboxClass"
                                > August</label>
                            </div>
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="disabled || activeTab !== 'monthly' || state.monthly.specificDay.months !== 'selected'" 
                                    (change)="regenerateCron()" [(ngModel)]="state.monthly.SEP" [ngClass]="options.formCheckboxClass"
                                > September</label>
                            </div>
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="disabled || activeTab !== 'monthly' || state.monthly.specificDay.months !== 'selected'" 
                                    (change)="regenerateCron()" [(ngModel)]="state.monthly.OCT" [ngClass]="options.formCheckboxClass"
                                > October</label>
                            </div>
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="disabled || activeTab !== 'monthly' || state.monthly.specificDay.months !== 'selected'" 
                                    (change)="regenerateCron()" [(ngModel)]="state.monthly.NOV" [ngClass]="options.formCheckboxClass"
                                > November</label>
                            </div>
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="disabled || activeTab !== 'monthly' || state.monthly.specificDay.months !== 'selected'" 
                                    (change)="regenerateCron()" [(ngModel)]="state.monthly.DEC" [ngClass]="options.formCheckboxClass"
                                > December</label>
                            </div>
                            <div class="col-xs-4 col-sm-3">
                                <label class="advanced-cron-editor-label"><input type="checkbox" 
                                    [disabled]="true" [ngModel]="allMonthsSelected()" [ngClass]="options.formCheckboxClass"
                                > All Months</label>
                            </div>
                        </div>
                    </div>
                    <div class="well well-small">
                        <input type="radio" name="monthly-radio" value="specificWeekDay" [disabled]="disabled" (change)="regenerateCron()"
                            [(ngModel)]="state.monthly.subTab" [ngClass]="state.formRadioClass">
                        On the
                        <select class="day-order-in-month" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                            (change)="regenerateCron()" [(ngModel)]="state.monthly.specificWeekDay.monthWeek" [ngClass]="options.formSelectClass">
                            <option *ngFor="let monthWeek of selectOptions.monthWeeks" [ngValue]="monthWeek">
                                {{monthWeekDisplay(monthWeek)}}
                            </option>
                        </select>
                        <select class="week-days" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                            (change)="regenerateCron()" [(ngModel)]="state.monthly.specificWeekDay.day" [ngClass]="options.formSelectClass">
                            <option *ngFor="let day of selectOptions.days" [ngValue]="day">
                                {{dayDisplay(day)}}
                            </option>
                        </select> of every
                        <select class="months-small" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                            (change)="regenerateCron()" [(ngModel)]="state.monthly.specificWeekDay.months" [ngClass]="options.formSelectClass">
                            <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                                {{month}}
                            </option>
                        </select> month(s) starting in
                        <select class="months" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                            (change)="regenerateCron()" [(ngModel)]="state.monthly.specificWeekDay.startMonth" [ngClass]="options.formSelectClass">
                            <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                                {{monthDisplay(month)}}
                            </option>
                        </select>
                        
                        at
                        <cron-time-picker [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                            (change)="regenerateCron()" [(time)]="state.monthly.specificWeekDay" [selectClass]="options.formSelectClass"
                            [use24HourTime]="options.use24HourTime" [hideSeconds]="options.hideSeconds">
                        </cron-time-picker>
                    </div>
                </div>

                <!-- Yearly-->
                <div class="tab-pane" *ngIf="!options.hideYearlyTab" [ngClass]="{'active': activeTab === 'yearly'}">
                    <div class="well well-small">
                        <input type="radio" name="yearly-radio" value="specificMonthDay" [disabled]="disabled" (change)="regenerateCron()"
                            [(ngModel)]="state.yearly.subTab" [ngClass]="state.formRadioClass">
                        Every
                        <select class="months" [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthDay'"
                            (change)="regenerateCron()" [(ngModel)]="state.yearly.specificMonthDay.month" [ngClass]="options.formSelectClass">
                            <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                                {{monthDisplay(month)}}
                            </option>
                        </select> on the
                        <select class="month-days" [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthDay'"
                            (change)="regenerateCron()" [(ngModel)]="state.yearly.specificMonthDay.day" [ngClass]="options.formSelectClass">
                            <option *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts" [ngValue]="monthDaysWithLast">
                                {{monthDayDisplay(monthDaysWithLast)}}
                            </option>
                        </select> at
                        <cron-time-picker [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthDay'"
                            (change)="regenerateCron()" [(time)]="state.yearly.specificMonthDay" [selectClass]="options.formSelectClass"
                            [use24HourTime]="options.use24HourTime" [hideSeconds]="options.hideSeconds">
                        </cron-time-picker>&nbsp;
                        <label class="advanced-cron-editor-label"><input type="checkbox" (change)="regenerateCron()"
                        [(ngModel)]="state.yearly.runOnWeekday" [ngClass]="options.formCheckboxClass"> during the nearest weekday</label>
                    </div>
                    <div class="well well-small">
                        <input type="radio" name="yearly-radio" value="specificMonthWeek" [disabled]="disabled"
                            (change)="regenerateCron()" [(ngModel)]="state.yearly.subTab" [ngClass]="state.formRadioClass">
                        On the
                        <select class="day-order-in-month" [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthWeek'"
                            (change)="regenerateCron()" [(ngModel)]="state.yearly.specificMonthWeek.monthWeek"
                            [ngClass]="options.formSelectClass">
                            <option *ngFor="let monthWeek of selectOptions.monthWeeks" [ngValue]="monthWeek">
                                {{monthWeekDisplay(monthWeek)}}
                            </option>
                        </select>
                        <select class="week-days" [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthWeek'"
                            (change)="regenerateCron()" [(ngModel)]="state.yearly.specificMonthWeek.day" [ngClass]="options.formSelectClass">
                            <option *ngFor="let day of selectOptions.days" [ngValue]="day">
                                {{dayDisplay(day)}}
                            </option>
                        </select> of
                        <select class="months" [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthWeek'"
                            (change)="regenerateCron()" [(ngModel)]="state.yearly.specificMonthWeek.month" [ngClass]="options.formSelectClass">
                            <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                                {{monthDisplay(month)}}
                            </option>
                        </select> at
                        <cron-time-picker [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthWeek'"
                            (change)="regenerateCron()" [(time)]="state.yearly.specificMonthWeek" [selectClass]="options.formSelectClass"
                            [use24HourTime]="options.use24HourTime" [hideSeconds]="options.hideSeconds">
                        </cron-time-picker>
                    </div>
                </div>

                <!-- Advanced-->
                <div class="tab-pane" *ngIf="!options.hideAdvancedTab" [ngClass]="{'active': activeTab === 'advanced'}">
                    Cron Expression
                    <input type="text" class="advanced-cron-editor-input" ng-disabled="disabled || activeTab !== 'advanced'"
                        (change)="regenerateCron()" [(ngModel)]="state.advanced.expression" [ngClass]="options.formInputClass">
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!state.validation.isValid">
        <code>{{state.validation.errorMessage}}</code>
    </div>
</div>