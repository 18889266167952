/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./enums.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./enums.component";
import * as i4 from "../../../../../service/EnumService";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../service/ErrorHandlerService";
var styles_EnumsComponent = [i0.styles];
var RenderType_EnumsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnumsComponent, data: {} });
export { RenderType_EnumsComponent as RenderType_EnumsComponent };
function View_EnumsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [["class", "hover-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleEnumClick(_v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.itemsCount; _ck(_v, 4, 0, currVal_1); }); }
export function View_EnumsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-9 text-lg-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "text-uppercase text-orange"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enums Management"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-3 text-md-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-md btn-orange"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleEnumClick("new") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["New Enum Set"])), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "table", [["class", "table table-hover table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Name "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Items "])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnumsComponent_1)), i1.ɵdid(18, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.enums; _ck(_v, 18, 0, currVal_0); }, null); }
export function View_EnumsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-groups-component", [], null, null, null, View_EnumsComponent_0, RenderType_EnumsComponent)), i1.ɵdid(1, 114688, null, 0, i3.EnumsComponent, [i4.EnumService, i5.Router, i5.ActivatedRoute, i6.ErrorHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnumsComponentNgFactory = i1.ɵccf("app-groups-component", i3.EnumsComponent, View_EnumsComponent_Host_0, {}, {}, []);
export { EnumsComponentNgFactory as EnumsComponentNgFactory };
