<document-review-form-header 
    *ngIf="!hideHeader" 
    [issueNumber]="formData?.issueNumber" 
    [documentId]="documentId" 
    [dateString]="dateString">
</document-review-form-header>

<div class="row">
    <div class="col-12">
        <h1 class="text-uppercase text-white bg-teal p-2 px-3 my-2">Admin Review</h1>
    </div>
</div>
<form>
    <div class="row">
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <label>Convert Document to PDF:</label>
                <button-toggle [value]="form.keepOriginalDocument.value"
                    (valueChange)="form.keepOriginalDocument.value = $event" [disabled]="readOnly"></button-toggle>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <label>Additional changes made to document:</label>
                <button-toggle [value]="form.additionalChangesRequired.value"
                    (valueChange)="form.additionalChangesRequired.value = $event" [disabled]="readOnly"></button-toggle>
            </div>
            <div class="row my-3" *ngIf="!!form.additionalChangesRequired.value">
                <div class="col-12">
                    <textarea class="form-control {{form.note.iifIsValid('border-success','border-danger')}}" autosize
                        replaceAmpersand [minRows]="3" [placeholder]="readOnly ? '' : 'Enter required changes here...'"
                        [disabled]="readOnly" [spellcheck]="true" [(ngModel)]="form.additionalChanges.value"
                        [ngModelOptions]="{standalone: true}"></textarea>
                    <small class="form-text text-danger" *ngIf="form.note.showErrorHelp()">Invalid note</small>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-lg-8 border-lg-right border-dashed">

            <div class="row my-3">
                <div class="col-12">
                    <follow-up-widget #followUpWidget [model]="form.followUps.value"
                        (modelChange)="form.followUps.value=$event" [readOnly]="true"></follow-up-widget>
                </div>
            </div>
        </div>
        <div class="col-12 offset-0 col-lg-4 pt-0 pt-lg-4">
            <div class="row">
                <div class="col-12 offset-0 col-xl-10 offset-xl-1">
                    <button class="btn btn-orange rounded-0 btn-block my-2" (click)="downloadLatestAprovedVersion()"
                        [disabled]="readOnly" *ngIf="!!latestAprovedVersion && !readOnly">
                        <i class="fa fa-download mx-1"></i>
                        <strong>Latest Approved Version</strong>
                    </button>
                    <button class="btn btn-orange rounded-0 btn-block my-2" (click)="downloadLatestDraft()"
                        *ngIf="(!!latestAprovedVersion || !!latestDraftRecord) && !readOnly">
                        <i class="fa fa-download mx-1"></i>
                        <strong>Latest Draft Version</strong>
                    </button>
                    <drm-document-uploads [(documents)]="uploadedDocument" [readOnly]="readOnly"></drm-document-uploads>
                </div>
            </div>
        </div>
    </div>
    <submission-detail [formRecord]="formRecord"></submission-detail>
    <div class="row" *ngIf="!readOnly">
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-2">
            <!-- <button class="btn btn-block btn-lg btn-success rounded-0" [routerLink]="form.reassign.value && '/document-review-task/1/' || '/document-review-task/4/'"><span class="capitalize">Submit</span></button> -->
            <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit(false)"><span
                    class="capitalize">Submit</span></button>
        </div>
        <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-5 col-xl-3 col-xxl-2">
            <a class="btn btn-block btn-lg btn-primary rounded-0 text-center text-white" (click)="submit(true)"><span
                    class="capitalize">Save Draft</span></a>
        </div>
    </div>
</form>