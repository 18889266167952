var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormField } from "../../../model/Form";
import { FormComponent } from "../../../model/FormComponent";
import { Session } from "../../../service/util/Session";
import { AuthService } from "../../../service/security/AuthService";
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { ModalService } from "../../../service/ModalService";
// import * as moment from 'moment';
import 'moment-timezone';
var LoginComponent = /** @class */ (function (_super) {
    __extends(LoginComponent, _super);
    function LoginComponent(session, authService, router, modalService) {
        var _this = _super.call(this, router) || this;
        _this.session = session;
        _this.authService = authService;
        _this.router = router;
        _this.modalService = modalService;
        _this.emailAddress = new FormField('', {
            validation: FormField.ValidationMethods.IsEmail
        });
        _this.password = new FormField('', {
            validation: FormField.ValidationMethods.IsNotBlank
        });
        return _this;
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.registerFormFields();
    };
    LoginComponent.prototype.registerFormFields = function () {
        this.formFields.push(this.password, this.emailAddress);
        //Debug Values
        this.emailAddress.value = environment.defaultAuthEmail || '';
        this.password.value = environment.defaultAuthPassword || '';
    };
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        this.session.lockInputRx(this.authService.login({
            username: this.emailAddress.value,
            password: this.password.value
        })).subscribe(function (data) {
            // Grant the credentials
            _this.authService.token = data.accessToken;
            _this.session.credentials = data;
            if (_this.emailAddress.value === _this.password.value) {
                if (!environment.production) {
                    console.warn("User password is equal to user email");
                    console.warn("In Production ENV User would be warned and requested to update password");
                    _this.router.navigateByUrl('/dashboard');
                }
                else {
                    _this.modalService.confirmRx({
                        title: "Password Security",
                        message: "To enhance security, we request that you reset your password using your account settings.",
                        confirmText: "Reset Password",
                        declineText: "Ok"
                    }).subscribe(function (isConfirmed) {
                        if (isConfirmed) {
                            _this.router.navigateByUrl('/profile');
                        }
                        else {
                            // Allow the user to move onto the dashboard
                            _this.router.navigateByUrl('/dashboard');
                        }
                    });
                }
            }
            else {
                // Grant the credentials
                _this.authService.token = data.accessToken;
                _this.session.credentials = data;
                _this.router.navigateByUrl('/dashboard');
            }
        }, function (err) {
            if (err.status === 403) {
                _this.emailAddress.value = '';
                _this.password.value = '';
            }
            _this.errorMessage = err.message;
        });
    };
    return LoginComponent;
}(FormComponent));
export { LoginComponent };
