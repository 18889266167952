<div class="row" *ngIf="!hideHeader">
    <div class="col-12 col-lg-6">
        <img src="assets/images/logo.png" class="logoImg">
    </div>
    <div class="col-12 pt-2 pt-lg-0 col-lg-6 text-center">
        <div class="border border-dark rounded border-dashed d-inline-block float-lg-right py-3 px-5">
            <div class="row">
                <div class="col-12">
                    <strong class="font-weight-heavy">Issue Number:</strong>
                    {{ formData ? formData.issueNumber || "TBA" : "TBA" }}
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <strong class="font-weight-heavy">Date Created:</strong> {{dateString}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <h1 class="text-uppercase text-white bg-teal p-2 px-3 my-2">Administrative Completion</h1>
    </div>
</div>
<form>
    <!---
    This text is to be appended to the PDF output, rather than displayed here
    <div class="row">
        <div class="col-12">
            <p>Thank you for taking the time to give us your feedback.</p>

            <p>If at any time you feel we need to revisit this matter, please advise - choose the Further Report Form option in a new Feedback Form.</p>
        </div>
    </div>
    --->
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label>Administration Finalised?</label>
                <button-toggle
                        [value]="form.adminComplete.value"
                        (valueChange)="form.adminComplete.value = $event"
                        [disabled]="readOnly"></button-toggle>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <span>Internal Notes:</span>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-12">
            <textarea
                class="form-control {{form.notes.iifIsValid('border-success','border-danger')}}"
                autosize
                [minRows]="3"
                [placeholder]="readOnly ? '' : 'Enter your notes'"
                [spellcheck]="true"
                [disabled]="readOnly"
                [(ngModel)]="form.notes.value"
                [ngModelOptions]="{standalone: true}"
            ></textarea>
        </div>
    </div>
    <submission-detail [formRecord]="formRecord"></submission-detail>
    <div class="row" *ngIf="!readOnly">
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-2">
            <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit(false)"><span class="capitalize">Submit</span></button>
        </div>
        <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-5 col-xl-3 col-xxl-2">
            <a class="btn btn-block btn-lg btn-primary rounded-0 text-center text-white" (click)="submit(true)"><span class="capitalize">Save Draft</span></a>
        </div>
    </div>
</form>
