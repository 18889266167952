<div class="row d-flex align-items-center py-4">
  <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-xl-4 offset-xl-4 bg-white align-self-center border border-grey rounded shadow-lg">
    <h3 class="py-1 my-0">Password Reset</h3>
    <div class="row bg-light border-top border-grey">
      <div class="col-12">
        <form class="py-2" [formGroup]="resetForm">
          <!--<div class="alert alert-danger" *ngIf="showFormHasError">
            Some things weren't quite right...
            <div *ngIf="errorMessage">
              {{errorMessage}}
            </div>
          </div>-->
          <div class="alert alert-success">
            To reset your password enter new password and confirm it.
          </div>
          <div class="form-group">
            <label for="emailAddress">Email Address</label>
            <input type="email" class="form-control" id="emailAddress" placeholder="Enter Email" formControlName="username" rxControl>
            <small class="form-text text-danger" *ngIf="resetForm.controls.username.touched && resetForm.controls.username.hasError('required')">This field is required.</small>
            <small class="form-text text-danger" *ngIf="resetForm.controls.username.touched && resetForm.controls.username.hasError('email')">Must be a valid E-mail.</small>
          </div>

          <div class="form-group">
            <label for="password">New Password</label>
            <input type="password" class="form-control" id="password" placeholder="Password" formControlName="password" rxControl>
            <small class="form-text text-danger" *ngIf="resetForm.controls.password.touched && resetForm.controls.password.hasError('required')">This field is required.</small>
            <small class="form-text text-danger" *ngIf="resetForm.controls.password.touched && resetForm.controls.password.hasError('minlength')">Must have at least 6 characters.</small>
          </div>
          <div class="form-group">
            <label for="confirmPassword">Confirm Password</label>
            <input type="password" class="form-control" id="confirmPassword" placeholder="Confirm Password" formControlName="confirmPassword" rxControl>
            <small class="form-text text-danger" *ngIf="resetForm.controls.confirmPassword.touched && resetForm.controls.confirmPassword.hasError('confirmError')">Invalid Password Verification.</small>
          </div>

          <div class="row">
            <a class="btn btn-danger col-4 offset-1" [routerLink]="'/login'">Cancel</a>
            <button class="btn btn-success col-4 offset-2" (click)="submitReset()">Complete Reset</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
