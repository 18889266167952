import { ComponentFactoryResolver, ViewContainerRef, OnInit } from "@angular/core";
import { FollowUpFormStage0Component } from "../stage0/followUpFormStage0.component";
import { FollowUpFormStage2Component } from "../stage2/followUpFormStage2.component";
import { FollowUpFormStage1Component } from "../stage1/followUpFormStage1.component";
var stageFormMap = {
    0: FollowUpFormStage0Component,
    1: FollowUpFormStage1Component,
    2: FollowUpFormStage2Component
};
/*
  This is the final, read only state for all the stages
*/
var FollowUpFormStage3Component = /** @class */ (function () {
    function FollowUpFormStage3Component(factoryResolver) {
        this.factoryResolver = factoryResolver;
        this.isReportEnabled = false;
    }
    Object.defineProperty(FollowUpFormStage3Component.prototype, "formData", {
        get: function () {
            return this._formData;
        },
        /** this is fix for navigation via child forms tree
         *  since this component might not be unmounted we need to clear all dynamic (read-only) forms
         **/
        set: function (newFormData) {
            this._formData = newFormData;
            this.isReportEnabled = false;
            this.vc.clear();
            this.addReadOnlyForms();
        },
        enumerable: true,
        configurable: true
    });
    FollowUpFormStage3Component.prototype.ngOnInit = function () { };
    FollowUpFormStage3Component.prototype.addReadOnlyForms = function () {
        var _this = this;
        if (!this.formData || !this.formData.records) {
            console.error('form data is not defined');
            return;
        }
        //Get completed Records
        this.formData.records
            .filter(function (record) { return record.isComplete; })
            .forEach(function (record) { return _this.addReadOnlyForm(record); });
    };
    FollowUpFormStage3Component.prototype.addReadOnlyForm = function (record) {
        var _component = stageFormMap[record.stage];
        if (!_component) {
            console.error('Cannot find follow-up form component for stage ', record.stage);
            return;
        }
        var factory = this.factoryResolver.resolveComponentFactory(_component);
        var component = this.vc.createComponent(factory);
        // @ts-ignore
        component.instance.readOnly = true;
        // @ts-ignore
        component.instance.formData = this.formData;
        // @ts-ignore
        component.instance.hideHeader = true;
        // @ts-ignore
        component.instance.sequence = record.sequence;
        this.vc.insert(component.hostView);
        this.isReportEnabled = true;
    };
    return FollowUpFormStage3Component;
}());
export { FollowUpFormStage3Component };
