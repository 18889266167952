import * as t from 'io-ts';
import { IntFromString } from 'io-ts-types/lib/IntFromString';
import { EmailRegex, timeZone } from '../constants';
import * as moment from 'moment';
import 'moment-timezone';

export const GetByIdParams = t.exact(t.type({id: t.refinement(IntFromString, v => v > 0, 'valid id')}));

export interface IGetByIdParams extends t.TypeOf<typeof GetByIdParams> {}

/*
  * This fails compilation after an unknown change in package (likely io-ts) which has resulted
  * in a discrepency between the result of t.literal and the expected class Mixed, to be returned
  * by t.union
  */
// @ts-ignore
export const SortOrderValues = t.union(['asc', 'desc'].map(f => t.literal(f)));

export type ISortOrderValue = t.TypeOf<typeof SortOrderValues>;

export const PhoneValidationModel = t.union([t.refinement(t.string, s => /^\d{10}$/.test(s), '10 digits'), t.null]);

export const EmailValidationModel = t.refinement(t.string, v => EmailRegex.test(v.toLowerCase()), 'valid email');

/**
 * this io-ts type convert date from a string in format DD-MM-YYYY into JS Date object
 * it sets time at start of the day in timezone Australia/Brisbane
 */

class DateFromQueryParamType extends t.Type<Date, string, t.mixed> {
  readonly _tag: 'DateFromQueryParamType' = 'DateFromQueryParamType';
  constructor() {
    super(
      'DateFromQueryParam',
      (u): u is Date => u instanceof Date,
      (u, c) => {
        const validation = t.string.validate(u, c);
        if (validation.isLeft()) {
          return validation as any;
        } else {
          const s = validation.value;
          if (!/^\d{1,2}-\d{1,2}-\d{4}$/.test(s)) {
            return t.failure(s, c);
          }

          const momentDate = moment(s, 'DD-MM-YYYY').tz(timeZone);

          if (!momentDate.isValid()) {
            return t.failure(s, c);
          }

          momentDate.startOf('day');

          return t.success(momentDate.toDate());
        }
      },
      a => a.toISOString()
    );
  }
}

export const DateFromQueryParam: DateFromQueryParamType = new DateFromQueryParamType();
