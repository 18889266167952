import { Component, Input } from "@angular/core";

@Component({
  selector: 'preloader-wrapper',
  styles: [`
    .preload-spinner {
        font-size: 1.6em;
        color: #AAA;
    }
    .preloader-row {
        height: 100%;
    }
    .preloader-col {
        margin-top: auto;
        margin-bottom: auto;
    }
  `],
  template: `
    <div class="row preloader-row" *ngIf="!isLoaded">
      <div class="col-12 text-center preloader-col">
        <i class="fa fa-spinner fa-pulse preload-spinner"></i>
      </div>
    </div>
    <ng-content *ngIf="isLoaded"></ng-content>
  `
})
export class PreloaderWrapperComponent {

  @Input()
  isLoaded: boolean;

}
