import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ISortOrderValue } from "../../../../../../common/contracts/common";
import { PersistentStorageService } from "../../../../../service/PersistentStorageService";
import { LocationService } from "../../../../../service/LocationService";
import { ILocationOutputModel } from "../../../../../../common/contracts/location";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";

@Component({
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  public locations: ILocationOutputModel[] = [];
  public totalItems = 0;
  public currentPage = 1;
  public pageSize = this.pss.pageSize;
  public sortBy: string = 'name';
  public sortOrder: ISortOrderValue = 'asc';

  constructor(private locationService: LocationService,
              private router: Router,
              private route: ActivatedRoute,
              private pss: PersistentStorageService,
              private errorHandler: ErrorHandlerService) { }

  ngOnInit() {
    this.handlePageChange();
  }

  public handleLocationClick(locationId: number | string) {
    this.router.navigate([`${locationId}`], {relativeTo: this.route});
  }

  public handlePageChange() {
    this.locationService.getPagedLocations({
      skip: ( this.currentPage - 1 ) * this.pageSize,
      limit: this.pageSize,
      sortBy: this.sortBy,
      order: this.sortOrder,
      countUsers: 1,
    }).subscribe((data) => {
      this.locations = data.items;
      this.totalItems = data.totalCount;
    }, (err) => this.errorHandler.handleHttpError(err));
  }

  public handleSortChange(sortField: string) {
    if (sortField === this.sortBy) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = sortField;
      this.sortOrder = 'asc';
    }
    this.handlePageChange();
  }

}
