import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Session } from '../service/util/Session';
import { Observable, timer } from 'rxjs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {

  public animation:AnimationConfig = new AnimationConfig();

  public isLoading: boolean = false;

  constructor(
    public session: Session,
    private cdr: ChangeDetectorRef,
  ) {
    this.isLoading = this.session.isLoading;

    this.session.isLoadingChange.subscribe((v: boolean) => {
      this.isLoading = v;
      this.cdr.detectChanges();
    });
  }
  
  /*
   * Any route change should scroll to the top of the screen as is expected of most web applications
   */
  onActivate(event) {
    console.log("OnScroll");
    window.scroll(0, 0);
  }

  ngOnInit() {
    this.animation.start();

    this.session.init();
  }

  ngOnDestroy() {
    this.animation.stop();
  }
}

/* The loading animation should contain 5 leaves, which activate sequentially to indicate loading */
class AnimationConfig {
  activeLeafIndex: number = 0;
  timerSubscription?: Subscription;
  timer?: Observable<number>;

  public start():void {
    if (!this.timer) {
      this.timer = timer(0, 1000);
    }

    if (!this.timerSubscription) {
      this.timerSubscription = this.timer.subscribe(() => {
        this.activeLeafIndex += 1;

        if (this.activeLeafIndex > 5) {
          this.activeLeafIndex = 1;
        }
      });
    }
  }

  public stop():void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
    }

    this.activeLeafIndex = 0;
  }
}
