import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {GenericService} from "./GenericService";
import { ICategoryOutputModel } from "../../common/contracts/category";
import { tap, share } from "rxjs/operators"
import {Observable, Observer, of} from "rxjs";
import { CurrentUserService } from "./currentUser/CurrentUserService";

@Injectable()
export class CategoryService extends GenericService {

  static apiPrefix = '/api/category';

  private categories: ICategoryOutputModel[] | null = null;
  private readonly getCategoriesRequest: Observable<ICategoryOutputModel[]>;

  constructor(private http: HttpClient, private currentUserService: CurrentUserService) {
    super();
    this.getCategoriesRequest = this.http.get<ICategoryOutputModel[]>(CategoryService.apiPrefix).pipe(
      tap(data => {
        this.categories = data; // cache results
      }),
      share() // allow multiple subscriptions
    );

    /**
     * Invalidate cache on logout
     */
    this.currentUserService.logoutEvents.subscribe(() => {
      this.categories = null;
    });
  }

  public getCategories(): Observable<ICategoryOutputModel[]> {
    return this.categories ? of(this.categories) : this.getCategoriesRequest;
  }
  
  /*
      TODO: what is the best way to add error handling to this?
      
      It should throw an error when the desired category cannot be found
      
      It should also throw an error when the categories http request fails
   */
  public getCategoryIdByName(name:string): Observable<number> {
    return new Observable((observer: Observer<number>) => {
      let categories : Observable<ICategoryOutputModel[]> = this.getCategories();
    
      categories.subscribe((data: ICategoryOutputModel[]) => {
        const reportCategory: ICategoryOutputModel | undefined =
          data.find((data: ICategoryOutputModel) => name.toLowerCase() === data.name.toLowerCase());
      
        if( reportCategory )
          observer.next(reportCategory.id as number);
        // What should this error class be for consistent error handling?
        //else
        //  observer.error()
      
        observer.complete();
      },
      error => observer.error(error) );
    });
  }
}
