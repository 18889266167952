import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { IUserGroupOutputModel } from "../../../../../../common/contracts/groups";
import { RxFormsService } from "../../../../../service/util/reactiveForms";
import { ModalService } from "../../../../../service/ModalService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { trimRequiredValidator } from "../../../../../validators/reactiveFormValidators";

@Component({
  selector: 'app-groups-component',
  templateUrl: './editGroup.component.html',
})
export class EditGroupComponent implements OnInit {

  public group: IUserGroupOutputModel;
  public isNew: boolean = false;
  private groupId: number | null = null;

  public groupForm = new FormGroup({
    groupName: new FormControl('', [trimRequiredValidator]),
  });

  constructor(
    private groupService: GroupsService,
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private errorHandlerService: ErrorHandlerService) {

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe( (params: Params) => {
      if (params.groupId) {

        if (/^\d+$/.test(params.groupId) !== true) {
          console.error('Invalid group router param');
          this.router.navigate(['/404'], {replaceUrl: true});
          return;
        }

        this.groupId = parseInt(params.groupId, 10);

        this.groupService.getGroupById(this.groupId).subscribe((group) => {
          this.group = group;

          this.groupForm.setValue({
            groupName: group.groupName,
          });

        }, (err) => this.errorHandlerService.handleHttpError(err));
      } else {
        this.isNew = true;
      }
    });
  }

  public submit() {
    if (this.groupForm.invalid) {
      RxFormsService.touchAllFormControls(this.groupForm);
      return;
    }

    const observer = {
      next: () => this.goBack(),
      error: error => this.errorHandlerService.handleHttpError(error),
    };

    if (!this.groupId) {
      this.groupService.createGroup({
        groupName: this.groupForm.controls.groupName.value.trim(),
      }).subscribe(observer);

    } else {
      this.groupService.updateGroup({
        id: this.groupId,
        groupName: this.groupForm.controls.groupName.value.trim()
      }).subscribe(observer);
    }
  }

  public archiveGroup() {
    this.modalService.confirmRx({
      title: 'Warning',
      message: 'Are you sure that you want to delete this group?'
    }).subscribe((isConfirmed) => {
      if (isConfirmed && this.groupId) {
        this.groupService.archiveGroup(this.groupId).subscribe(
        () => this.goBack(),
        (err) => this.errorHandlerService.handleHttpError(err)
        );
      }
    });
  }

  private goBack() {
    this.router.navigate(['..'], {relativeTo: this.route});
  }

}
