import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Session } from "../../service/util/Session";
import { FormField } from "../../model/Form";
import { Select2Component } from 'ng2-select2';
import { GroupsService } from "../../service/admin/GroupsService";
import { UserGroup } from "../../../model/UserGroup";
import { IUserGroupOutputModel } from "../../../common/contracts/groups";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import { CurrentUserService } from 'service/currentUser/CurrentUserService';
import { logger } from "../../service/util/Logger";

type ExIdTextPair = IdTextPair & { selected?: boolean };

@Component({
  selector: 'user-group-select',
  template: `<select2
    #select2Component
    class="form-control select2-form-control {{disabled?'bg-grey':''}} {{!disabled && field && field.iifIsValid('border-success','border-danger')}}"
    [data]="userGroupSelectData"
    [options]="userGroupSelectOptions"
    (valueChanged)="valueChanged($event.data)"
    [value]="defaultValue"
    [disabled]="disabled"
  ></select2>`
})
export class UserGroupSelectComponent implements OnInit {


  private _value: string = '';

  @Input('value')
  get value() {
    return this._value;
  }
  /**
   * By preventing assigning non-unique values during the set
   * we can prevent a loop of observable subscribers
   */
  set value(newValue: string) {
    this._value = newValue;

    if (!this.field && !this.multiple) {
      this.defaultValue = this._value;
    }
  }

  // Implies a read only state
  @Input()
  disabled: boolean = false;

  @Input()
  placeholder: string;

  /**
   * Restrict available user groups to the user groups available to the current user
   */
  @Input()
  restrictGroups: boolean = false;

  @Input()
  multiple: boolean = false;

  public defaultValue: string = '';

  @Input()
  field: FormField<any>;

  public userGroupSelectOptions: Select2Options;
  public userGroupSelectData: ExIdTextPair[] = [];

  // Reference firstNameInput variable inside Component
  @ViewChild('select2Component') select2ComponentRef: Select2Component;

  @Output()
  change: EventEmitter<ExIdTextPair[]> = new EventEmitter<IdTextPair[]>();

  constructor(
    public groupsService: GroupsService,
    public session: Session,
    private errorHandler: ErrorHandlerService,
    public currentUserService: CurrentUserService
  ) {
  }

  ngOnInit() {

    this.userGroupSelectOptions = { allowClear: !this.multiple, placeholder: this.placeholder || "Select Department", multiple: this.multiple };

    this.session.lockInputRx(this.groupsService.getGroups())
      .subscribe((items: IUserGroupOutputModel[]) => {
        let newSelectOptions: ExIdTextPair[] = [{ id: "", text: "" }];

        // remove groups that is not assigned to current user with role manager.
        if (this.restrictGroups) {
          const userGroups = this.currentUserService.currentUserData.value!.groups;

          if (userGroups && userGroups.length > 0) {
            items = items.filter(
              item =>
                !!userGroups.find(userGroup => userGroup.id === item.id)
            );
          }
        }

        items.forEach((group: UserGroup) => newSelectOptions.push({ id: String(group.id), text: group.groupName }));

        this.userGroupSelectData = newSelectOptions;

        //Force the change detection to cycle again to prevent race
        if (this.field) {
          const strVal = String(this.field.value);
          if (String(this.field.value) !== this.defaultValue) {
            const fieldValueArr: string[] | undefined = this.multiple && strVal ? strVal.split(",") : undefined;
            if (!this.multiple) { this.defaultValue = strVal; }
            const options = this.userGroupSelectData.filter(o => (this.multiple ? (
              fieldValueArr ? fieldValueArr.find(selectedValue => selectedValue === String(o.id)) : false
            ) : o.id === this._value));
            if (options && options.length) {
              options.map(opt => opt.selected = true);
            }
          }
        } else {
          //If the default value was manually set we need to re-trigger the process
          if (this._value !== '') {
            const valueArr: string[] | undefined = this.multiple && this._value && this._value.length ? this._value.split(",") : undefined;
            if (!this.multiple) { this.defaultValue = this.value; }
            const options = this.userGroupSelectData.filter(o => (this.multiple ? (
              valueArr ? valueArr.find(selectedValue => selectedValue === String(o.id)) : false
            ) : o.id === this._value));
            if (options && options.length) {
              options.map(opt => opt.selected = true);
            }
          }
        }
      }, err => this.errorHandler.handleHttpError(err));
  }

  valueChanged(selectedOpts: ExIdTextPair[]) {
    // console.log("valueChanged");
    // console.log(selectedOpts);

    if (selectedOpts.length === 0 || (
      selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0
    )) {
      if (this.field)
        this.field.value = null;
    }

    if (selectedOpts.length > 1 && !this.multiple)
      throw ("Selected options unexpectedly contained multiple results");

    if (selectedOpts.length === 1 && !this.multiple) {
      if (this.field) {
        this.field.value = selectedOpts[0].id;
      } else {
        if (this._value !== selectedOpts[0].id) { // if the value has been changed - emit event
          this._value = selectedOpts[0].id;
          this.change.emit(selectedOpts);
          return;
        }
      }
    }

    if (this.multiple) {
      const actualSelected = selectedOpts.filter(opt => opt.id && opt.id.length);
      const newValue = actualSelected.map(opt => opt.id).join(",");


      if (this.field) {
        console.log("Setting Field Value ", newValue);
        this.field.value = newValue;
      } else {
        logger.silly(`Checking internalValue change Value[(${typeof newValue}) ${newValue}] !== _value[(${typeof this._value}) ${this._value}]`);
        if (this._value !== newValue) { // if the value has been changed - emit event
          logger.silly("userGroupSelectComponent: Updating Internal Value");
          this._value = newValue;
          this.change.emit(actualSelected);
        }
      }
    }
  }
}
