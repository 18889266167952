import { Injectable } from '@angular/core';
import {GenericService} from "./GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  ICreateLocationInputModel,
  ILocationOutputModel, ILocationsQueryParams,
  IUpdateLocationInputModel, LocationPaginationResponse
} from "../../common/contracts/location";
import { Observable, of } from "rxjs";
import { share, map, tap } from "rxjs/operators";
import { CurrentUserService } from "./currentUser/CurrentUserService";
import { stringifyObjectValues } from "./util/stringifyObjectValues";

@Injectable()
export class LocationService extends GenericService {

  static apiPrefix = '/api/location';

  private locationsCache: ILocationOutputModel[] | null = null;

  private readonly getLocationsRequest: Observable<ILocationOutputModel[]>;

  constructor(private http: HttpClient, private currentUserService: CurrentUserService) {
    super();

    const queryParams = new HttpParams({
      fromObject: {
        skip: '0',
        limit: '50',
        sortBy: 'name',
        order: 'asc',
      }
    });

    this.getLocationsRequest = this.http.get<LocationPaginationResponse>(LocationService.apiPrefix, {params: queryParams}).pipe(
      map(data => {
        this.locationsCache = data.items; //cache results
        return data.items;
      }),
      share(), //share results between multiple subscriptions
    );

    /**
     * Invalidate cache on logout
     */
    this.currentUserService.logoutEvents.subscribe(() => {
      this.locationsCache = null;
    });
  }

  public getLocations() {
    return this.locationsCache ? of(this.locationsCache) : this.getLocationsRequest;
  }

  public getPagedLocations(params: ILocationsQueryParams) {
    const queryParams = new HttpParams({
      fromObject: stringifyObjectValues(params),
    });
    return this.http.get<LocationPaginationResponse>(LocationService.apiPrefix, {params: queryParams});
  }

  public getLocationById(id: number) {
    return this.http.get<ILocationOutputModel>(`${LocationService.apiPrefix}/${id}`);
  }

  public createLocation(locationData: ICreateLocationInputModel) {
    return this.http.post(LocationService.apiPrefix, locationData).pipe(tap(() => {
      this.locationsCache = null;
    }));
  }

  public updateLocation(locationChanges: IUpdateLocationInputModel) {
    return this.http.put(LocationService.apiPrefix, locationChanges).pipe(tap(() => {
      this.locationsCache = null;
    }));
  }

  public archiveLocation(id: number) {
    return this.http.delete(`${LocationService.apiPrefix}/${id}`).pipe(tap(() => {
      this.locationsCache = null;
    }));
  }

}
