import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { RxFormsService } from "../../../../../service/util/reactiveForms";
import { EnumService } from "../../../../../service/EnumService";
import { IEnumsOutputModel } from "../../../../../../common/contracts/enums";
import { ModalService } from "../../../../../service/ModalService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { trimRequiredValidator } from "../../../../../validators/reactiveFormValidators";
import { ResourcePackService } from 'service/ResourcePackService';
import { ResourcePack } from 'model/ResourcePack';

@Component({
  selector: 'app-groups-component',
  templateUrl: './editEnumsSet.component.html',
  styleUrls: ['./editEnumsSet.component.scss'],
})
export class EditEnumsSetComponent implements OnInit {

  public enums: IEnumsOutputModel[] = [];
  public isNew: boolean = false;
  // To prevent "Object Possibly Null" in Template
  public sortableOptions: any = {};
  public resourcePacks: ResourcePack[];
  public enumForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    values: new FormArray([])
  });

  constructor(
    private enumService: EnumService,
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorHandlerService,
    private modalService: ModalService,
    private resourcePackService: ResourcePackService
    ) {

    this.sortableOptions = {
      animation: 150,
      onUpdate: () => {
        this.enumForm.markAsDirty();
      }
    };
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(async (params: Params) => {

      this.resourcePacks = await this.resourcePackService.fetch().toPromise();

      if (params.enumName) {

        this.enumService.getEnumsByName(params.enumName, false).subscribe((enums) => {
          this.enums = enums;

          this.enumForm.setValue({
            name: params.enumName,
            values: []
          });

          this.enumForm.controls.name.disable();

          for (const enumEntry of enums) {
            (this.enumForm.get('values') as FormArray).push(new FormGroup({
              id: new FormControl(enumEntry.id),
              value: new FormControl(enumEntry.value, trimRequiredValidator),
              resourcePack: new FormControl(enumEntry.resourcePackId ? enumEntry.resourcePackId.toString() : '')
            }));
          }
        }, (err) => this.errorService.handleHttpError(err));
      } else {
        this.isNew = true;
        this.valuesFormArray.push(new FormGroup({
          id: new FormControl('new'),
          value: new FormControl('', trimRequiredValidator),
          resourcePack:  new FormControl()
        }));
      }
    });
  }

  get valuesFormArray(): FormArray {
    return this.enumForm.get('values') as FormArray;
  }

  public handleRemoveValue(index: number) {
    // if it's new item - remove without confirmation
    const idValue = (this.valuesFormArray.at(index) as FormGroup).controls.id.value;
    if (idValue === 'new') {
      this.valuesFormArray.removeAt(index);
      this.enumForm.markAsDirty();
      return;
    }

    this.modalService.confirmRx({
      title: 'Warning',
      message: 'Are you sure that you want to remove this enum entry?'
    }).subscribe(isConfirmed => {
      if (isConfirmed) {
        this.valuesFormArray.removeAt(index);
        this.enumForm.markAsDirty();
      }
    });
  }

  public handleAddEntry() {
    this.valuesFormArray.push(new FormGroup({
      id: new FormControl('new'),
      value: new FormControl('', trimRequiredValidator),
      resourcePack:  new FormControl()
    }));
    this.enumForm.markAsDirty();
  }

  public submit() {
    if (this.enumForm.invalid) {
      RxFormsService.touchAllFormControls(this.enumForm);
      return;
    }

    const formData = this.enumForm.getRawValue();
    formData.name = formData.name.trim();
    for (let i = 0; i < formData.values.length; i++) {
      formData.values[i].value = formData.values[i].value.trim();

      if( !formData.values[i].resourcePack || !formData.values[i].resourcePack.length )
        delete formData.values[i].resourcePack;
    }

    const request = this.isNew ? this.enumService.createEnumsSet(formData) : this.enumService.updateEnumsSet(formData);

    request.subscribe(
      () => this.goBack(),
      err => this.errorService.handleHttpError(err)
    );
  }

  private goBack() {
    this.router.navigate(['..'], {relativeTo: this.route});
  }

}
