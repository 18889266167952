var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormService } from "../../../../service/FormService";
import { ActivatedRoute, Router } from "@angular/router";
import { Session } from "../../../../service/util/Session";
var has = Reflect.has;
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { ComponentCanDeactivate } from "../../../shared/canDeactivate/ComponentCanDeactivate";
import { ReportFormStage0Component } from "./stage0/reportFormStage0.component";
import { ReportFormStage1Component } from "./stage1/reportFormStage1.component";
import { ReportFormStage2Component } from "./stage2/reportFormStage2.component";
import { ReportFormStage3Component } from "./stage3/reportFormStage3.component";
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
var ReportFormComponent = /** @class */ (function (_super) {
    __extends(ReportFormComponent, _super);
    function ReportFormComponent(formService, activatedRoute, session, router, errorHandler) {
        var _this = _super.call(this) || this;
        _this.formService = formService;
        _this.activatedRoute = activatedRoute;
        _this.session = session;
        _this.router = router;
        _this.errorHandler = errorHandler;
        _this.showForm = false;
        _this.formData = null;
        return _this;
    }
    ReportFormComponent.prototype.canDeactivate = function () {
        if (!this.showForm)
            return true;
        if (this.formData && this.formData.isDeleted) {
            return true;
        }
        if (this.targetStage === 0)
            return this.reportStage0Form.canDeactivate();
        if (this.targetStage === 1)
            return this.reportStage1Form.canDeactivate();
        if (this.targetStage === 2)
            return this.reportStage2Form.canDeactivate();
        if (this.targetStage === 3)
            return this.reportStage3Form.canDeactivate();
        return true;
    };
    ReportFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.session.lockInputRx(this.activatedRoute.params.pipe(switchMap(function (params) {
            if (!has(params, "reportId")) {
                _this.showForm = true;
                _this.targetStage = 0;
                return of(null);
            }
            else {
                return _this.formService.getFormById(params["reportId"]);
            }
        })))
            .subscribe(function (existingForm) {
            if (!existingForm) {
                return;
            }
            /**
             *  fix for 'Parent Form' link, if form's category is 'Follow-Up' redirect to /followUps/:reportId
             **/
            if (existingForm.category.name === 'Follow-Up') {
                _this.router.navigate(['/followUp', existingForm.id], { replaceUrl: true });
                return;
            }
            /**
             *  fix for 'Parent Form' link, if form's category is 'Report' redirect to /report/:reportId
             **/
            if (existingForm.category.name === 'Audit') {
                if (existingForm.isTemplate) {
                    _this.router.navigate(['/admin/templates/audit', existingForm.id], { replaceUrl: true });
                    return;
                }
                _this.router.navigate(['/audit', existingForm.id], { replaceUrl: true });
                return;
            }
            /**
             *  fix for 'Parent Form' link, if form's category is 'DRM-Task' redirect to /followUps/:reportId
             **/
            if (existingForm.category.name === 'DRM-Task') {
                _this.router.navigate(['/document-review-task', existingForm.id], { replaceUrl: true });
                return;
            }
            _this.formData = existingForm;
            if (_this.formData.records.length === 0) {
                _this.errorHandler.raiseError("Record did not contain valid data. Please contact a system administrator for assistance.", "Invalid Form Data");
            }
            _this.targetStage = _this.formData["stage"] || 0;
            _this.showForm = true;
            if (_this.formData.childForms && _this.formData.childForms.length) {
                _this.childForms = _this.formData.childForms;
            }
        }, function (err) {
            console.error("Cannot get form by id", err);
            _this.errorHandler.handleHttpError(err);
        });
    };
    return ReportFormComponent;
}(ComponentCanDeactivate));
export { ReportFormComponent };
