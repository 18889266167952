/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "./datePicker.component";
var styles_DatePickerComponent = ["input.relative-input[_ngcontent-%COMP%] {\n          position: relative;\n      }\n      span.clear-icon[_ngcontent-%COMP%] {\n          cursor: pointer;\n          width: 20px;\n          height: 20px;\n          position: absolute;\n          top: 8px;\n          right: 44px;\n          text-align: center;\n          font-size: 1.1em;\n          font-weight: bolder;\n          line-height: 1.2;\n      }"];
var RenderType_DatePickerComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_DatePickerComponent, data: {} });
export { RenderType_DatePickerComponent as RenderType_DatePickerComponent };
function View_DatePickerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "clear-icon"], ["title", "Clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearDate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"]))], null, null); }
function View_DatePickerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "small", [["class", "form-text text-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Invalid date"]))], null, null); }
export function View_DatePickerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { datePicker: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 16777216, [[1, 0], ["datePicker", 1]], null, 9, "input", [["class", "form-control relative-input"], ["ngbDatepicker", ""]], [[8, "placeholder", 0], [8, "disabled", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "dateSelect"], [null, "focus"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 6).manualDateChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("change" === en)) {
        var pd_5 = (i0.ɵnov(_v, 6).manualDateChange($event.target.value, true) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 6).onBlur() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.model = $event) !== false);
        ad = (pd_7 && ad);
    } if (("dateSelect" === en)) {
        var pd_8 = (_co.handleDateSelect() !== false);
        ad = (pd_8 && ad);
    } if (("focus" === en)) {
        var pd_9 = (i0.ɵnov(_v, 6).open() !== false);
        ad = (pd_9 && ad);
    } if (("ngModelChange" === en)) {
        var pd_10 = (_co.validateModelChange() !== false);
        ad = (pd_10 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(4, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(512, null, i3.ɵs, i3.ɵs, [i3.NgbCalendar, i3.NgbDatepickerI18n]), i0.ɵdid(6, 671744, [["d", 4]], 0, i3.NgbInputDatepicker, [i3.NgbDateParserFormatter, i0.ElementRef, i0.ViewContainerRef, i0.Renderer2, i0.ComponentFactoryResolver, i0.NgZone, i3.ɵs, i3.NgbCalendar, i3.NgbDateAdapter, i1.DOCUMENT, i0.ChangeDetectorRef], { markDisabled: [0, "markDisabled"], disabled: [1, "disabled"] }, { dateSelect: "dateSelect" }), i0.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.NgbInputDatepicker]), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i3.NgbInputDatepicker]), i0.ɵdid(9, 671744, null, 0, i2.NgModel, [[8, null], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(11, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DatePickerComponent_1)), i0.ɵdid(13, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 2, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "i", [["class", "fa fa-calendar"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DatePickerComponent_2)), i0.ɵdid(18, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = "form-control relative-input"; var currVal_10 = _co.validationClasses; _ck(_v, 3, 0, currVal_9, currVal_10); var currVal_11 = _co.markDisabledBound; var currVal_12 = _co.disabled; _ck(_v, 6, 0, currVal_11, currVal_12); var currVal_13 = _co.disabled; var currVal_14 = _co.model; _ck(_v, 9, 0, currVal_13, currVal_14); var currVal_15 = (_co.clearable && _co.model); _ck(_v, 13, 0, currVal_15); var currVal_17 = ((_co.isTouched && _co.isDateInvalid) && !_co.disableValidationStyles); _ck(_v, 18, 0, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.placeholder || "DD/MM/YYYY"); var currVal_1 = i0.ɵnov(_v, 6).disabled; var currVal_2 = i0.ɵnov(_v, 11).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 11).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 11).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 11).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 11).ngClassValid; var currVal_7 = i0.ɵnov(_v, 11).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 11).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_16 = _co.disabled; _ck(_v, 15, 0, currVal_16); }); }
export function View_DatePickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "date-picker", [], null, null, null, View_DatePickerComponent_0, RenderType_DatePickerComponent)), i0.ɵdid(1, 114688, null, 0, i4.DatePickerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DatePickerComponentNgFactory = i0.ɵccf("date-picker", i4.DatePickerComponent, View_DatePickerComponent_Host_0, { disabled: "disabled", value: "value", formField: "formField", disablePastDates: "disablePastDates", required: "required", placeholder: "placeholder", disableValidationStyles: "disableValidationStyles", clearable: "clearable" }, { valueChange: "valueChange" }, []);
export { DatePickerComponentNgFactory as DatePickerComponentNgFactory };
