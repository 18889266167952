import { Injectable } from '@angular/core';
import { GenericService } from "./GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
	FormsPaginationResponse, IDuplicateFormParams, IFormDeleteParams,
	IFormOutputModel,
	IFormParams, IFormsQueryParams,
	IFormUpdateParams, ISendFormReportParams, IFormScheduleParams, IFormRevertParams
} from "../../common/contracts/form";
import { Observable } from "rxjs";
import { stringifyObjectValues } from "./util/stringifyObjectValues";

@Injectable()
export class FormService extends GenericService {

	static readonly apiPrefix = '/api/form';

	constructor(private http: HttpClient) {
		super();
	}

	public getForms(params: IFormsQueryParams) {
		const queryParams = new HttpParams({
			fromObject: stringifyObjectValues(params)
		});

		return this.http.get<FormsPaginationResponse>(FormService.apiPrefix, { params: queryParams });
	}

	public runSchedules(): Observable<IFormOutputModel[]> {
		return this.http.get<IFormOutputModel[]>(`${FormService.apiPrefix}/schedule/run/all`);
	}

	public runTemplate(id: number | string): Observable<IFormOutputModel> {
		return this.http.get<IFormOutputModel>(`${FormService.apiPrefix}/${id}/execute`);
	}

	public getFormById(id: number) {
		return this.http.get<IFormOutputModel>(`${FormService.apiPrefix}/${id.toString()}`);
	}

	public finalizeForm(id: number) {
		return this.http.get<any>(`${FormService.apiPrefix}/finalize/${id.toString()}`);
	}

	public createForm(form: IFormParams): Observable<any> {
		return this.http.post<any>(FormService.apiPrefix, form);
	}

	public updateFormSchedule(form: IFormScheduleParams): Observable<any> {
		return this.http.put<any>(`${FormService.apiPrefix}/schedule`, form);
	}

	public updateForm(form: IFormUpdateParams): Observable<any> {
		return this.http.put<any>(FormService.apiPrefix, form);
	}

	public deleteForm(params: IFormDeleteParams): Observable<any> {
		return this.http.put(`${FormService.apiPrefix}/delete`, params);
	}

	public revertForm(params: IFormRevertParams): Observable<any> {
		return this.http.put(`${FormService.apiPrefix}/revert`, params);
	}

	public sendFormReport(params: ISendFormReportParams): Observable<any> {
		return this.http.post(`${FormService.apiPrefix}/sendReport`, params);
	}

	public duplicateFollowUp(id: number): Observable<any> {
		let params: IDuplicateFormParams = {
			id: id,
			propertiesToClear: ['dueAt']
		};

		return this.http.post(`${FormService.apiPrefix}/duplicate`, params);
	}

	public getFormsFromDocumentId(id: number): Observable<any> {
		return this.http.get(`${FormService.apiPrefix}/document/${id}`);
	}

	public sendReviewFormNotifications(params: any) {
		return this.http.post(`${FormService.apiPrefix}/reviewNotification`, params);
	}

}
