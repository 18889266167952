import { environment } from '../../environments/environment';

/**
 * @description Rudimentary logger
 */

const logLevels = {
	none: 0,
	silly: 1,
	debug: 2,
	info: 3,
	error: 4,
	all: 5
};

const logLevelName = environment.logLevel || "debug";
const logLevel = logLevels[logLevelName] || logLevels.debug;

export namespace logger {
	export function none(...args:any) {
		// do nothing
	}
	export function silly(...args:any) {
		if( logLevel <= logLevels.silly ) {
			console.log.call({}, 'silly:', ...args);
		}
	}
	export function debug(...args:any) {
		if( logLevel <= logLevels.debug ) {
			console.log.call({}, 'debug:', ...args);
		}
	}
	export function info(...args:any) {
		if( logLevel <= logLevels.info ) {
			console.log.call({}, 'info:', ...args);
		}
	}
	export function error(...args:any) {
		if( logLevel <= logLevels.error ) {
			console.log.call({}, 'error:');
			console.error.call({}, ...args);
		}
	}
	export function all(...args:any) {
		if( logLevel <= logLevels.all ) {
			console.log.call({}, 'all:', ...args);
		}
	}
}

logger.info(`Initalized Log at ${logLevelName} : ${logLevel}`);