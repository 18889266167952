import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourcePackService } from 'service/ResourcePackService';
import { ResourcePack } from 'model/ResourcePack';
import { Document } from 'model/Document';
import { take } from 'rxjs/operators';
import { ErrorHandlerService } from 'service/ErrorHandlerService';
import { IDocumentType } from '../../../../common/contracts/document';
import { DocumentService } from 'service/DocumentService';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-resource-pack-documents',
  templateUrl: './resource-pack-documents.component.html',
  styleUrls: ['./resource-pack-documents.component.css']
})
export class ResourcePackDocumentsComponent implements OnInit {

  resourcePack: ResourcePack;
  updating = false;

  constructor(private route: ActivatedRoute, private service: ResourcePackService, private errorHandler: ErrorHandlerService, private documentService: DocumentService) { }
  
  ngOnInit() {
    this.route.params.pipe(take(1)).subscribe(async params => {
      if (params.id) {
        this.resourcePack = await this.loadResourcePack(params.id).toPromise();
      }
    })

  }

  loadResourcePack(resourcePackId: string) {
    return this.service.fetchById(resourcePackId);
  }

  async updateName() {
    this.updating = true;
    try {
      await this.service.updateName(this.resourcePack.id as number, this.resourcePack.name).toPromise();
    } catch (e) {
      this.errorHandler.handleHttpError(e);
    } finally {
      this.updating = false;
    }
  }

  async addNewDocumentToResourcePack(document: IDocumentType) {
    try {
      const result = await this.service.attachDocument(this.resourcePack.id as number, document).toPromise();
      this.resourcePack.documents.push(result);
    } catch (e) {
      this.errorHandler.handleHttpError(e);
    }
  }

  async deleteDocument(document: Document) {
    if (confirm('Are you sure you want to delete ' + document.path + '?')) {
      try {
        await this.service.deleteDocument(document.id as number).toPromise();
        // remove document from list.
        this.resourcePack.documents = this.resourcePack.documents.filter(doc => doc.id !== document.id);
      } catch (e) {
        this.errorHandler.handleHttpError(e);
      }
    }
  }

  async download(doc: Document) {
      this.documentService.downloadDocument(doc.document.id).subscribe(
        data => {
          saveAs(data, doc.document.fileName);
        },
        error => this.errorHandler.handleHttpError(error)
      );
    
  }
}
