import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PersistentStorageService } from "../../service/PersistentStorageService";
import { logger } from "service/util/Logger";

const className = 'PaginationComponent';

@Component({
  selector: 'pagination',
  styles: [`
    .pagination-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .drop-down-wrapper {
        padding-top: 6px;
        padding-left: 14px;
    }
    a.dropdown-link {
        color: #007bff;
        cursor: pointer;
        padding-bottom: 6px;
    }
    a.dropdown-link:hover {
        color: #0056b3;
    }
  `],
  template: `
  <div class="pagination-wrapper">
      <ngb-pagination
              [collectionSize]="collectionSize"
              [(page)]="page"
              [maxSize]="10"
              (pageChange)="handlePageChange()"
              [pageSize]="pageSize"
      ></ngb-pagination>
      <div class="drop-down-wrapper" ngbDropdown [placement]="dropdownPlacement">
          <a class="dropdown-link" ngbDropdownToggle>{{pageSize}} per page</a>
          <div ngbDropdownMenu>
              <button class="dropdown-item" (click)="handlePageSizeChange(10)">10</button>
              <button class="dropdown-item" (click)="handlePageSizeChange(20)">20</button>
              <button class="dropdown-item" (click)="handlePageSizeChange(50)">50</button>
          </div>
      </div>
  </div>
  `
})
export class PaginationComponent {

  @Input()
  collectionSize: number;

  @Input()
  page: number;
  @Output()
  pageChange = new EventEmitter<number>();

  @Input()
  pageSize: number;
  @Output()
  pageSizeChange = new EventEmitter<number>();

  @Input()
  dropdownPlacement: string = 'bottom-left';

  constructor(private persistentStorageService: PersistentStorageService) {

  }

  public handlePageChange() {
    this.pageChange.emit(this.page);
  }

  public handlePageSizeChange(ps: number) {
    const signature = className + '.handlePageSizeChange: ';
    logger.silly(signature + 'Started');
    this.pageSize = ps;
    this.page = 1; // to avoid requesting empty page - set current page to 1
    this.persistentStorageService.pageSize = ps;
    this.pageSizeChange.emit(this.pageSize);
    this.pageChange.emit(this.page);
  }

}
