var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { GenericService } from "./GenericService";
import { tap, share } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { CurrentUserService } from "./currentUser/CurrentUserService";
var CategoryService = /** @class */ (function (_super) {
    __extends(CategoryService, _super);
    function CategoryService(http, currentUserService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.currentUserService = currentUserService;
        _this.categories = null;
        _this.getCategoriesRequest = _this.http.get(CategoryService.apiPrefix).pipe(tap(function (data) {
            _this.categories = data; // cache results
        }), share() // allow multiple subscriptions
        );
        /**
         * Invalidate cache on logout
         */
        _this.currentUserService.logoutEvents.subscribe(function () {
            _this.categories = null;
        });
        return _this;
    }
    CategoryService.prototype.getCategories = function () {
        return this.categories ? of(this.categories) : this.getCategoriesRequest;
    };
    /*
        TODO: what is the best way to add error handling to this?
        
        It should throw an error when the desired category cannot be found
        
        It should also throw an error when the categories http request fails
     */
    CategoryService.prototype.getCategoryIdByName = function (name) {
        var _this = this;
        return new Observable(function (observer) {
            var categories = _this.getCategories();
            categories.subscribe(function (data) {
                var reportCategory = data.find(function (data) { return name.toLowerCase() === data.name.toLowerCase(); });
                if (reportCategory)
                    observer.next(reportCategory.id);
                // What should this error class be for consistent error handling?
                //else
                //  observer.error()
                observer.complete();
            }, function (error) { return observer.error(error); });
        });
    };
    CategoryService.apiPrefix = '/api/category';
    return CategoryService;
}(GenericService));
export { CategoryService };
